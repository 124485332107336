import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Link } from 'react-router-dom';
import { Card, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch } from '@mui/material';

import DateRangeHeader from 'components/DateRangeHeader';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getAllocationNotRun, getConsumptionAndGenerationData, getCustomerAccountList, getProgram, getAccountToplineMetrics, getGenerationData, getAccountProgramAssignment, getGeneratorProgramAssignment, getPrograms } from 'modules/demo/selectors';
import { IProgram, receiveAllocationResults } from 'modules/demo/slice';
import { getCFEVsNonCFESeries, getCustomerCumulativeProgramMatchSeries, makeChartOptions } from 'demo/chart_helpers';
import ToplineMetrics from 'demo/components/ToplineMetrics';
import './style.css';
import { runAllocation } from 'demo/rec_allocation';

const getProgramDescription = (program?: IProgram) => {
  if (!program) {
    return <em>Customer is not subscribed to any program, standard ratepayer information is shown.</em>
  }

  if (program.is247Program) {
    return `A 24/7 program with ${program.generatorCertificateDistribution} generators for subscribers guaranteeing 80% load matching.`
  } else {
    return `An annual program with ${program.generatorCertificateDistribution} generators for subscribers guaranteeing 100% load matching.`
  }
}

const defaultStartStr = '2021-05-04T00:00:00-05:00';
const defaultEndStr = '2021-05-11T23:59:59.9999-05:00';


const CustomerAllocation = ({accountId}: {accountId: number}) => {
  const [startDate, setStartDate] = React.useState<null | Date>(new Date(defaultStartStr));
  const [endDate, setEndDate] = React.useState<null | Date>(new Date(defaultEndStr));
  const [interval, setInterval] = React.useState('hour');
  const customers = useAppSelector(getCustomerAccountList);
  const [show247Cumulative, setShow247Cumulative] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState(customers[0])
  const programs = useAppSelector(getPrograms);
  const customerAccounts = useAppSelector(getCustomerAccountList);
  const customerAccountAssignment = Object.values(useAppSelector(getAccountProgramAssignment));
  const generatorAssignment = Object.values(useAppSelector(getGeneratorProgramAssignment));
  const dispatch = useAppDispatch();
  const generation = useAppSelector(getGenerationData);
  const shouldRunAllocation = useAppSelector(getAllocationNotRun);

  React.useEffect(() => {
    setSelectedCustomer(customers.find((c) => c.id === accountId));
    if (shouldRunAllocation) {
      runAllocation({
        programs,
        customerAccounts,
        customerAccountAssignment,
        generatorAssignment,
        generation,
        // TODO: make this configurable
        optimizeFor: 'minimalShortfall',
      }).then(data => {
        dispatch(receiveAllocationResults({data}));
      })
    }
  }, [customers, shouldRunAllocation]);
  const consumptionAndGenerationData = useAppSelector((s) => getConsumptionAndGenerationData(s, selectedCustomer.id, startDate, endDate));
  const allocationNotRun = useAppSelector(getAllocationNotRun);
  const data = getCFEVsNonCFESeries(startDate, endDate, interval as 'hour' | 'day' | 'month' | 'year', consumptionAndGenerationData || []);
  const cumulativeData = getCustomerCumulativeProgramMatchSeries(startDate, endDate, interval as 'hour' | 'day' | 'month' | 'year', consumptionAndGenerationData || []);
  const program = useAppSelector(s => getProgram(s, selectedCustomer.programId));
  const toplineMetrics = useAppSelector(s => getAccountToplineMetrics(s, accountId, startDate, endDate));
  const is247Program = !program || program.is247Program;

  const setDates = (start: Date | null, end: Date | null, interval: string) => {
    setStartDate(start);
    setEndDate(end);
    setInterval(interval)
  }

  const cumulativeChartOptions = {dateResolution: interval, animated: true, chartAxisLabelY1: 'Electricity (MWh)', chartData: cumulativeData, chartType: 'areaspline', title: 'Program Trending'};
  const intervalChartOptions = {dateResolution: interval, animated: true, chartAxisLabelY1: `Eiectricity (MWh)`, chartData: data, chartType: 'column', title: 'Program Matching'};

  return <div className="customer-allocation--container">
    <h1>CFE Report</h1>
    <div className="customer-allocation--header">
      <FormControl sx={{ m: 0, minWidth: '100px' }} size="small">
        <Select
          value='uga1'
          className="customer-allocation--select"
        >
          {[<MenuItem value='uga1' key='uga1'>Account #1</MenuItem>]}
        </Select>
      </FormControl>
    </div>

    <div className="customer-allocation--description">
      <strong>{program?.name || 'Standard Ratepayer'}</strong>
      <p>{getProgramDescription(program)}</p>
    </div>

    {!allocationNotRun && <ToplineMetrics {...toplineMetrics} />}

    <Grid container spacing={2} sx={{mt: 0, mb: 2, mr: 2 }}>
      <Grid item xs={12}>
        <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
          {!allocationNotRun && <div className="customer-allocation-chart-header--options">
              {program?.is247Program ? <FormControlLabel control={<Switch checked={show247Cumulative} onChange={(e) => setShow247Cumulative(e.target.checked)} />} label="Cumulative" /> : <div />}
              <DateRangeHeader onChange={setDates} defaultStart={defaultStartStr} defaultEnd={defaultEndStr} defaultInterval='hour'/>
            </div>
          }
          {allocationNotRun && <div>Inventory allocation has not been run. Go to the <Link to="/programs">Program Settings</Link> page to configure and run inventory allocation based on your program setup.</div>}
          {!allocationNotRun &&
            <HighchartsReact
              highcharts={Highcharts}
              options={makeChartOptions(!is247Program || show247Cumulative ? cumulativeChartOptions : intervalChartOptions)}
            />
          }
        </Card>
      </Grid>
    </Grid>
  </div>
};


export default CustomerAllocation;