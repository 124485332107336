let ResourcesConstants;


if (ENVIRONMENT === 'production') {
  ResourcesConstants = {
    AUTH_URL: 'https://login.singularity.energy',
    API_URL: 'https://corporate-carbon.api.singularity.energy',
    XCEL_CUSTOMER_IDS: ['EXEN'],
    // HBS_CUSTOMER_IDS: ['HBS'],
  }
} else if (ENVIRONMENT === 'development') {
  ResourcesConstants = {
    AUTH_URL: 'https://login.qa-singularity.energy',
    API_URL: API_BASE_URL || 'https://corporate-carbon.api.qa-singularity.energy',
    XCEL_CUSTOMER_IDS: ['CGCL', 'EXEN', 'XCEL'],
    // HBS_CUSTOMER_IDS: ['CPQN'],
  }
} else {
  ResourcesConstants = {
    AUTH_URL: 'https://login.qa-singularity.energy',
    API_URL: 'http://lcl.qa-singularity.energy:5010',
    XCEL_CUSTOMER_IDS: ['CGCL', 'EXEN', 'XCEL'],
    // HBS_CUSTOMER_IDS: ['CPQN'],
  }
}

export const DEBUG = ENVIRONMENT !== 'production'
export const XCEL_CUSTOMER_IDS = ResourcesConstants.XCEL_CUSTOMER_IDS
// export const HBS_CUSTOMER_IDS = ResourcesConstants.HBS_CUSTOMER_IDS
export const API_URL = ResourcesConstants.API_URL
export const AUTH_URL = ResourcesConstants.AUTH_URL
