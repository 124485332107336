import { IUser } from 'api/types';

export type UserRole = 'visitor' | 'contributor' | 'user' | 'billing' | 'admin' | 'owner' | 'sadmin' | 'uadmin';

const roleValues: Record<UserRole, number> = {
  visitor: 0,
  contributor: 1,
  user: 2,
  billing: 3,
  admin: 4,
  owner: 5,
  sadmin: 6,
  uadmin: 7,
};

export const roleHasAccess = (userRole: UserRole, roleRequired: UserRole): boolean => {
  return roleValues[userRole] >= roleValues[roleRequired];
}

export const userHasAccess = (user: IUser, roleRequired: UserRole): boolean => roleHasAccess(user.role, roleRequired)

interface ICertificateDevice {
  id: string;
  name: string;
  capacity: number;
  capacityUnits: string;
  biddingZone: string | null;
  balancingAuthority: string | null;
}

interface ICertificateEmissions {
  emissionFactor: number;
  emissionFactorMethodology: string;
  emissionFactorUnits: string; // almost always 'kg_per_mwh'
}

interface ICertificateValue {
  amount: number;
  currency: string; // almost always 'usd_cents'
}

export interface ICertificate {
  id: string; // UUID
  customerId: string;
  ownedBy: string | null; // customer ID for which the certificate is retired
  status: string; // TODO: make an enum
  assetEventId: string | null;
  energyCarrier: string; // TODO: make enum
  deviceOpStart: string; // device operational start date
  start: string;
  end: string;
  issuedAt: string;
  issuedLocation: string;
  productionSource: string;
  productionTechnology: string;
  wattHours: number; // the value of the certificate - almost always 1MWh
  gridInjection: string; // the grid into which the energy is injected
  informsConsumerDisclosure: boolean | null;
  device: ICertificateDevice;
  emissions: ICertificateEmissions | null;
  value: ICertificateValue | null;
}

// A collection of certs for a given time period
export interface IBundledCertificate {
  id: string;  // this is just so rows have a unique id in the bundled certs table
  timestamp: string;
  totalCerts: number;
}
