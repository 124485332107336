import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import InterventionTable from './InterventionTableV1';

import {
  ICustomerIntervention,
  ICustomerInterventionImpact,
  ICustomerProjection
} from 'api/types';
import {
  useSaveCustomerInterventionsMutation,
  useSearchCustomerInterventionsQuery,
  useSimulateProjectionQuery
} from 'api/interventions';

import makeLinearGradient from 'utils/chartGradient';
import Theme from 'config/Theme';
import { makeInterventionPlotPoints } from 'utils/chartAnnotations';

import { useGetCustomerGoalsQuery } from 'api/summary';
import './style.css';


enum ScenarioChartCardMode {
  Costs = 'Costs',
  Emissions = 'Emissions'
}


const ScenarioChartCards = () => {
  const [
    editedInterventionImpact,
    setEditedInterventionImpact
  ] = useState<(ICustomerInterventionImpact & ICustomerIntervention)[]>([]);

  const goalsApi = useGetCustomerGoalsQuery();
  const userGoals = goalsApi.data ? goalsApi.data.data : null;

  const searchApi = useSearchCustomerInterventionsQuery({
    status: ['suggested', 'planned'],
    calculateImpact: true
  });

  // Don't request a projection until there are input interventions to pass.
  const projectionApi = useSimulateProjectionQuery(
    editedInterventionImpact,
    { skip: !searchApi.data }
  );

  const [
    saveCustomerInterventions,
    saveCustomerInterventionsApi
  ] = useSaveCustomerInterventionsMutation();

  const onFetchedInterventions = () => {
    if (!searchApi.data) {
      return;
    }
    // When customer interventions are fetched, store a copy that the user can
    // edit in the frontend.
    setEditedInterventionImpact(structuredClone(searchApi.data.data));
  }

  // Saves the current state of the customer intervention table to the backend.
  const onSaveInterventions = () => {
    saveCustomerInterventions(editedInterventionImpact);
  }

  // Install effects.
  useEffect(onFetchedInterventions, [searchApi.data]);

  const selectedInterventionIds = editedInterventionImpact
    .filter(value => value.status === 'planned')
    .map(value => value.id);

  // Helper function to prepare data for the emissions/costs chart. These charts have identical
  // series, but different units ($ vs tons CO₂).
  const chartDataFactory = (mode: ScenarioChartCardMode) => {
    if (projectionApi.isFetching || projectionApi.isUninitialized) {
      return [];
    }

    const baselineProjections = projectionApi.data.data.baselineProjections;
    const scenarioProjections = projectionApi.data.data.scenarioProjections;

    const opacityHex = '30';
    const whichData = {
      [ScenarioChartCardMode.Emissions]: 'emissions',
      [ScenarioChartCardMode.Costs]: 'costs',
    }[mode];

    const tooltipOptions = {
      [ScenarioChartCardMode.Emissions]: { valueSuffix: ' tons CO₂', valuePrefix: '' },
      [ScenarioChartCardMode.Costs]: { valueSuffix: '', valuePrefix: '$' }
    }[mode];

    const goalMetricValue = {
      [ScenarioChartCardMode.Emissions]: userGoals ? userGoals.annualEmissionsTons : 0,
      [ScenarioChartCardMode.Costs]: userGoals ? userGoals.annualElectricityCost : 0
    }[mode];

    const baselineSeries = baselineProjections.map((value: ICustomerProjection) => {
      const dataValue: number = {
        [ScenarioChartCardMode.Emissions]: value.netEmissionsTonsCo2,
        [ScenarioChartCardMode.Costs]: value.grossElectricityCosts,
      }[mode];
      return { x: new Date(value.startDate).valueOf(), y: dataValue };
    });
    const scenarioSeries = scenarioProjections.map((value: ICustomerProjection) => {
      const dataValue: number = {
        [ScenarioChartCardMode.Emissions]: value.netEmissionsTonsCo2,
        [ScenarioChartCardMode.Costs]: value.grossElectricityCosts,
      }[mode];
      return { x: new Date(value.startDate).valueOf(), y: dataValue };
    });

    const goalYear = userGoals ? (userGoals?.targetYear || 2040) : 2040;
    const goalYearEpoch = new Date(goalYear, 1, 1, 0, 0, 0, 0).valueOf();

    let plotData: any[] = [
      {
        name: 'Goal',
        visible: true,
        type: 'line',
        color: Theme.palette.chartTealColor.main,
        data: baselineSeries
          .map((value) => { return { x: value.x, y: goalMetricValue } })
          .filter((value) => value.x <= goalYearEpoch),
        tooltip: tooltipOptions,
        fillColor: '#FFFFFF',
      },
      {
        name: `Baseline ${whichData}`,
        visible: true,
        type: 'area',
        color: Theme.palette.chartRedColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartRedColor.main, opacityHex),
        data: baselineSeries
          .filter((value) => value.x <= goalYearEpoch),
        tooltip: tooltipOptions
      },
      {
        name: `Scenario ${whichData}`,
        visible: true,
        type: 'area',
        color: Theme.palette.chartBlueColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
        data: scenarioSeries
          .filter((value) => value.x <= goalYearEpoch),
        tooltip: tooltipOptions
      }
    ];

    const plannedInterventions = editedInterventionImpact.filter(value => value.status === 'planned' && new Date(value.startDate).getFullYear() <= goalYear);
    const plannedAnnotations = makeInterventionPlotPoints(plannedInterventions, scenarioSeries, 'Planned projects');
    if (plannedAnnotations && plannedAnnotations.data.length > 0) {
      plotData.push(plannedAnnotations);
    }

    return plotData;
  }

  // Translate API state into component rendering state here.
  const emissionsPlotData = chartDataFactory(ScenarioChartCardMode.Emissions);
  const costPlotData = chartDataFactory(ScenarioChartCardMode.Costs);
  const chartsLoading = projectionApi.isFetching;
  const tableLoading = searchApi.isFetching;
  const tableError = searchApi.isError;
  const savingLoading = saveCustomerInterventionsApi.isLoading;
  const savingError = saveCustomerInterventionsApi.isError;

  // Need to sort rows or they will change location in the table. If we want
  // planned projects to float to the top, then just use rows in unsorted form
  // from the API.
  const rowsSorted = structuredClone(projectionApi.data ? projectionApi.data.data.interventionImpact : editedInterventionImpact);
  rowsSorted.sort((a, b) => (a.id < b.id ? -1 : 1));

  const smallestEmission = projectionApi.data?.data.scenarioProjections.map(d => d.grossEmissionsTonsCo2).reduce((s, n) => s < n ? s : n, 100000000000);
  const smallestCost = projectionApi.data?.data.scenarioProjections.map(d => d.grossElectricityCosts).reduce((s, n) => s < n ? s : n, 100000000000);

  return (
    <>
    <CardBase width={6}>
      <CardHeader
        title={<Typography gutterBottom variant="seCardTitleText" component="div">Projected emissions (tons CO₂)</Typography>}
        action={<Button variant="outlined" color="neutral">Export</Button>}
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          loading={chartsLoading}
          animated={true}
          chartHeight={300}
          chartContainerId={'scenario-chart--emissions'}
          chartData={emissionsPlotData}
          chartAxisLabelY1={undefined}
          dateResolution={'month'}
          overrideOptions={{
            yAxis: {
              min: Math.min((userGoals?.annualEmissionsTons || 0) * 0.8, smallestEmission),
            },
          }}
        />
      </CardContent>
    </CardBase>
    <CardBase width={6}>
      <CardHeader
        title={<Typography gutterBottom variant="seCardTitleText" component="div">Projected electricity cost ($)</Typography>}
        action={<Button variant="outlined" color="neutral">Export</Button>}
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          loading={chartsLoading}
          animated={true}
          chartHeight={300}
          chartContainerId={'scenario-chart--costs'}
          chartData={costPlotData}
          chartAxisLabelY1={undefined}
          dateResolution={'month'}
          overrideOptions={{
            yAxis: {
              min: Math.min((userGoals?.annualElectricityCost || 0) * 0.8, smallestCost),
            },
          }}
        />
      </CardContent>
    </CardBase>
    <InterventionTable
      loading={tableLoading}
      loadingError={tableError}
      saving={savingLoading}
      savingError={savingError}
      selectedInterventionIds={selectedInterventionIds}
      editedInterventionImpact={rowsSorted}
      saveInterventionsCallback={onSaveInterventions}
      setSelectedInterventionIds={(selectedIds: (string | number)[]) => {
        const updatedWithStatus = editedInterventionImpact.map((value) => {
          const statusInTable = selectedIds.includes(value.id) ? 'planned' : 'suggested';
          return { ...value, status: statusInTable };
        });
        setEditedInterventionImpact(updatedWithStatus);
      }}
      setEditedInterventionImpact={setEditedInterventionImpact}
    />
    </>
  );
}


export default ScenarioChartCards;
