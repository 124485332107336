import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fromPairs } from "ramda";

import { IGenerator, generators } from "demo/data/generators";
import loadData, { ICustomerAccountProfile } from "demo/data/duke/customers";
import { IFullGenerationData } from "demo/data/duke/generation";
import { IRunAllocationResults } from "demo/rec_allocation";


export type CertificateDistribution = 'pooled' | 'dedicated';

export const StandardRatepayerProgram: IProgram = {
  id: 'Standard Ratepayer',
  name: 'Standard Ratepayer',
  priority: Infinity,
  description: undefined,
  is247Program: true,
  generatorCertificateDistribution: 'pooled',
  canReleaseToProgramIds: [],
  canReceiveFromProgramIds: [],
}


export interface IProgram {
  id: string;
  priority: number;
  name: string;
  description?: string;
  is247Program: boolean;
  generatorCertificateDistribution: CertificateDistribution;
  canReleaseToProgramIds: string[]
  canReceiveFromProgramIds: string[]
  costPerMWh?: number;
}

export interface IAccountProgramAssignment {
  accountId: number;
  programId: string;
}

export interface IProgramGeneratorAssignment {
  generatorId: string;
  programId: string;
}


interface IDemoSlice {
  programs: IProgram[],
  generatorProgramsByGeneratorId: Record<string, IProgramGeneratorAssignment>,
  generatorsById: Record<string, IGenerator>,
  customerProgramsByAccountId: Record<number, IAccountProgramAssignment>,
  allCustomerAccountLoad: ICustomerAccountProfile[],
  generationData: {
    data: null | IFullGenerationData,
    status: null | 'loading' | 'complete',
  },
  allocationResults: IRunAllocationResults | null;
};

export const staticPrograms: IProgram[] = [
  {
    id: 'e5ed92cf-1204-4588-b6aa-68e326ab104e',
    priority: 1,
    name: 'GPP - Schedule A',
    description: 'Buy specific amounts of green power from generators who were brought online after Jan 1, 2021 for $3/MWh with a minimum one year commitment.',
    is247Program: false,
    generatorCertificateDistribution: 'pooled',
    canReleaseToProgramIds: [StandardRatepayerProgram.id],
    canReceiveFromProgramIds: [],
    costPerMWh: 3,
  },
  {
    id: 'e5792cf1-dec2-419b-ab86-54a38a59634b',
    priority: 2,
    name: 'GPP - Schedule B',
    description: 'Same as schedule A, but generators are designated on a specific location or parcel. As such, locational prices may vary. Subscription timelines are mutually agreed upon.',
    is247Program: false,
    generatorCertificateDistribution: 'pooled',
    canReleaseToProgramIds: [StandardRatepayerProgram.id],
    canReceiveFromProgramIds: [],
    costPerMWh: 2.5,
  },
  {
    id: 'dac8adc4-6858-458a-9510-7b8eaa5d3a29',
    priority: 3,
    name: 'GPP - Schedule C',
    description: 'Buy specific amounts of power from new APS resources that are not a part of the company\'s plan. Customers subscribe for a mutually agreeable term.',
    is247Program: true,
    generatorCertificateDistribution: 'pooled',
    canReleaseToProgramIds: [StandardRatepayerProgram.id],
    canReceiveFromProgramIds: [],
    costPerMWh: 2.5,
  },
  {
    id: 'dac8adc4-6858-458a-9510-7b8eaa5d3a28',
    priority: 4,
    name: 'GPP - Schedule D',
    description: 'Customers subscribe for at least one year to 2,500 MWh of generation from APS resources that were put online before Jan 1, 2021.',
    is247Program: false,
    generatorCertificateDistribution: 'dedicated',
    canReleaseToProgramIds: [StandardRatepayerProgram.id],
    canReceiveFromProgramIds: [],
    costPerMWh: 2,
  },
];

const initialState: IDemoSlice = {
  programs: staticPrograms,
  generatorProgramsByGeneratorId: {},
  customerProgramsByAccountId: {},
  generatorsById: fromPairs(generators.map(generator => ([generator.plant_id_eia, generator]))),
  allCustomerAccountLoad: loadData,
  generationData: {
    status: null,
    data: null,
  },
  allocationResults: null,
};


const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    updateProgramPriority: (state, action: PayloadAction<{ programId: string, priority: number }>) => {
      const program = state.programs.find(p => p.id === action.payload.programId);
      const newPriority = Math.min(state.programs.length, Math.max(1, action.payload.priority));
      const shiftDirection: 'up' | 'down' = program.priority > action.payload.priority ? 'up' : 'down';

      state.programs = state.programs.map(program => {
        if (program.id === action.payload.programId) {
          return { ...program, priority: newPriority };
        } else {
          const needsPriorityShift = program.priority === newPriority;
          if (needsPriorityShift) {
            const shift = shiftDirection === 'up' ? 1 : -1;
            program.priority = program.priority + shift;
          }
          return program;
        }
      })
    },

    updateAccountProgramAssignment: (state, action: PayloadAction<{ programId: string, accountId: number }>) => {
      if (state.allocationResults) {
        state.allocationResults = null;
      }
      state.customerProgramsByAccountId[action.payload.accountId] = action.payload;
    },

    updateGeneratorProgramAssignment: (state, action: PayloadAction<{ programId: string, generatorId: string }>) => {
      if (state.allocationResults) {
        state.allocationResults = null;
      }
      state.generatorProgramsByGeneratorId[action.payload.generatorId] = action.payload
    },

    setGenerationLoading: (state) => {
      state.generationData.status = 'loading';
    },

    receiveGenerationData: (state, action: PayloadAction<{ data: IFullGenerationData }>) => {
      state.generationData.data = action.payload.data;
      state.generationData.status = 'complete';
    },

    receiveAllocationResults: (state, action: PayloadAction<{ data: IRunAllocationResults }>) => {
      state.allocationResults = action.payload.data;
    },

    updateProgram: (state, action: PayloadAction<{ program: IProgram }>) => {
      if (state.allocationResults) {
        state.allocationResults = null;
      }
      const index = state.programs.findIndex(p => p.id === action.payload.program.id);
      if (index !== -1) {
        state.programs[index] = action.payload.program
      }
    },

    discardConsoleChanges: (state) => {
      if (!state.allocationResults) {
        state.programs = staticPrograms;
        state.customerProgramsByAccountId = {};
        state.generatorProgramsByGeneratorId = {};
      } else {
        const lastState = state.allocationResults.lastRunState
        state.programs = lastState.programs;
        state.customerProgramsByAccountId = lastState.customerProgramsByAccountId;
        state.generatorProgramsByGeneratorId = lastState.generatorProgramsByGeneratorId;
      }
    },
  },
});


export default demoSlice.reducer;

export const {
  updateProgramPriority,
  updateAccountProgramAssignment,
  updateGeneratorProgramAssignment,
  setGenerationLoading,
  receiveGenerationData,
  receiveAllocationResults,
  updateProgram,
  discardConsoleChanges,
} = demoSlice.actions;
