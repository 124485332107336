import React from 'react';
import { renderToString } from 'react-dom/server';

import CarbonIntensityIcon from 'components/CarbonIntensityIcon';
import nearestItemInArray from './nearestItemInArray';
import { percentile } from './math';


export default function makeIntensityIconFormatter(epochValues: number[], intensityValues: number[], timeZoneName: string = 'UTC') {
  const cleanThresholdLbsPerMwh = percentile(34, intensityValues);
  const dirtyThresholdLbsPerMwh = percentile(67, intensityValues);

  return function xAxisFormatter() {
    const nearestEpochIndex = nearestItemInArray(epochValues, this.value);
    const icon = <CarbonIntensityIcon
      carbonIntensityLbsPerMwh={intensityValues[nearestEpochIndex]}
      cleanThresholdLbsPerMwh={cleanThresholdLbsPerMwh}
      dirtyThresholdLbsPerMwh={dirtyThresholdLbsPerMwh}
    />
    const hourLabel = new Date(this.value).toLocaleTimeString('default', {hour: 'numeric', timeZone: timeZoneName});
    const html = `<div style="display: flex; flexDirection: column; alignItems: center;">\
      ${hourLabel}
      ${renderToString(icon)}
    </div>`;
    return html;
  }
}
