import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

import CardBase from 'components/CardBase';
import SummaryStatistic from 'components/SummaryStatistic';

import { useGetRealtimeSummaryQuery } from 'api/realtime';

import './style.css';


const ToplineCard = () => {
  const { data, isFetching } = useGetRealtimeSummaryQuery();

  const stats = data
    ? data.data
    : { totalEmissionsToday: 0, totalEnergyUsageToday: 0, totalGasUsageToday: 0 };

  return (
    <CardBase width={12}>
      <CardContent>
        <Grid container>
          <SummaryStatistic
            labelText={'Total carbon emissions today'}
            valueText={(stats.totalEmissionsToday * 2000).toLocaleString('default')}
            unitsText={' lbs CO₂'}
            loading={isFetching}
            labelHeight={'30px'}/>
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Total grid usage today'}
            valueText={stats.totalEnergyUsageToday.toLocaleString('default')}
            unitsText={' kWh'}
            loading={isFetching}
            labelHeight={'30px'}/>
          {/* <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Total gas usage today'}
            valueText={stats.totalGasUsageToday}
            unitsText={' MMBtu'}
            loading={loading}
            labelHeight={'30px'}/> */}
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default ToplineCard;
