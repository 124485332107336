import React, { useState } from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import CardBase from 'components/CardBase';
import ChartBase, { makeXDateFormat } from 'components/ChartBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import CardTitle from 'components/CardTitle';

import { IFuelMixData, IResourceMixData } from 'api/types';
import { useGetRealtimeFuelMixQuery } from 'api/realtime';
import { fuelPalette } from 'utils/fuelPalette';
import { camelToSnake } from 'utils/strings';

import './style.css';


enum ResourceMixCardMode {
  Emissions = 'Emissions',
  Energy = 'kWh'
}


const ResourceMixCard = () => {
  const [mode, setMode] = useState(ResourceMixCardMode.Energy);
  const { data, isFetching } = useGetRealtimeFuelMixQuery();

  const whichMixData: keyof IResourceMixData = {
    [ResourceMixCardMode.Emissions]: 'emissionMix' as const,
    [ResourceMixCardMode.Energy]: 'energyMix' as const
  }[mode];

  const sanitizeFuelType = (fuelType: string): string => {
    return camelToSnake(fuelType).replaceAll('_', ' ');
  }

  const makeSeriesChartData = () => {
    if (!data) {
      return [];
    }
    const chartData = data.data[whichMixData];

    let seriesByFuelType: {
      [fuelType: string]: { data: { x: number, y: number }[], name: string, type: string, color: string, showInLegend: boolean }
    } = {};
    let sumByFuelType: {
      [fuelType: string]: number
    } = {};


    const unitConversionFactor = (mode === ResourceMixCardMode.Emissions) ? 2000 : 1;

    chartData.forEach((fuelMixData: IFuelMixData) => {
      Object.entries(fuelMixData.data).forEach((fuelTypeAndValue: [string, number]) => {
        const fuelType = fuelTypeAndValue[0];
        const fuelValue = fuelTypeAndValue[1] * unitConversionFactor;

        if (!seriesByFuelType[fuelType]) {
          seriesByFuelType[fuelType] = {
            name: sanitizeFuelType(fuelType),
            type: 'column',
            data: [],
            color: fuelPalette[camelToSnake(fuelType)] || '#000000',
            showInLegend: true
          }
          sumByFuelType[fuelType] = 0;
        }
        seriesByFuelType[fuelType].data.push({
          x: new Date(fuelMixData.startDate).valueOf(),
          y: fuelValue
        });
        sumByFuelType[fuelType] += fuelValue
      });
    });

    // Hide any zero-valued series from the legend. This prevents renewables
    // with zero emissions from showing up on the carbon emissions view.
    Object.entries(seriesByFuelType).forEach((value) => {
      value[1]['showInLegend'] = sumByFuelType[value[0]] > 0;
    });

    return Object.entries(seriesByFuelType).map((v) => v[1]);
  }

  const makePieChartData = () => {
    if (!data) {
      return [];
    }

    const units = {
      [ResourceMixCardMode.Emissions]: 'lbs CO₂',
      [ResourceMixCardMode.Energy]: 'kWh'
    }[mode];

    const chartData = data.data[whichMixData];
    const unitConversionFactor = (mode === ResourceMixCardMode.Emissions) ? 2000 : 1;

    let sumByFuelType: { [fuelType: string]: number } = {};
    chartData.forEach((fuelMixData: IFuelMixData) => {
      Object.entries(fuelMixData.data).forEach((fuelTypeAndValue: [string, number]) => {
        const fuelType = fuelTypeAndValue[0];
        const fuelValue = fuelTypeAndValue[1] * unitConversionFactor;

        if (!sumByFuelType[fuelType]) {
          sumByFuelType[fuelType] = 0;
        }
        sumByFuelType[fuelType] += fuelValue;
      });
    });

    return [{
      name: units,
      type: 'pie',
      colorByPoint: true,
      data: Object.entries(sumByFuelType).map((value: [string, number]) => {
        return {
          name: sanitizeFuelType(value[0]),
          y: value[1],
          color: fuelPalette[camelToSnake(value[0])]
        };
      })
    }]
  }

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [ResourceMixCardMode.Emissions]: 'lbs of CO₂ consumed',
    [ResourceMixCardMode.Energy]: 'kWh consumed'
  }[mode];

  const tooltipValueSuffix = {
    [ResourceMixCardMode.Emissions]: ' lbs CO₂',
    [ResourceMixCardMode.Energy]: ' kWh'
  }[mode];

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Breakdown of your grid and emission sources'}/>}
        action={
          <div>
            <CardRadioButtonGroup
              mode={mode}
              mapModeToLabel={{
                [ResourceMixCardMode.Energy]: 'Energy (kWh)',
                [ResourceMixCardMode.Emissions]: 'Carbon (lbs CO₂)',
              }}
              setModeCallback={(value) => setMode(value as ResourceMixCardMode)}
            />
            {/* <Button variant="outlined" color="neutral" sx={{m: 1}}>Export</Button> */}
          </div>
        }
      />
      <CardContent>
        <Grid container>
          {/* Left side */}
          <Grid item xs={3}>
            <ChartBase
              loading={isFetching}
              animated={true}
              chartHeight={300}
              chartContainerId={'resource-mix-card--pie-chart'}
              chartData={makePieChartData()}
              overrideOptions={{
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    innerSize: '50%',
                    dataLabels: {
                      enabled: false,
                    }
                  }
                }
              }}
            />
          </Grid>
          {/* Right side */}
          <Grid item xs={9}>
            <ChartBase
              loading={isFetching}
              animated={true}
              chartHeight={300}
              chartContainerId={'resource-mix-card--series-chart'}
              chartAxisLabelY1={chartAxisLabelY1}
              chartData={makeSeriesChartData()}
              dateResolution={'hour'}
              overrideOptions={{
                plotOptions: {
                  series: {
                    stacking: 'normal'
                  },
                },
                tooltip: {
                  shared: false,
                  valueDecimals: 1,
                  valueSuffix: tooltipValueSuffix,
                  xDateFormat: makeXDateFormat('hour')
                },
                xAxis: {
                  labels: {
                    useHTML: true,
                    formatter: function() {
                      return new Date(this.value).toLocaleTimeString('default', {hour: 'numeric'});
                    }
                  },
                },
                yAxis: {
                  min: 0,
                }
              }}
          />
          </Grid>
        </Grid>
      </CardContent>
    </CardBase>
  );
}


export default ResourceMixCard;
