import React from 'react';

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

import CardTitle from "components/CardTitle";
import Lightning from "images/lightning_bolt.svg";
import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import { IBuildingData } from 'api/types';
import * as MockAPI from 'api/mock';
import CardBase from 'components/CardBase';

import './style.css';


interface IProjectedEnergyConsumptionProps {
  horizon: number;
}


const ProjectedEnergyConsumptionCard = (props: IProjectedEnergyConsumptionProps) => {
  const buildings: IBuildingData[] = MockAPI.fakeBuildings();
  const isDemoCustomer = useAppSelector(getIsDemoCustomer);

  buildings.sort((x: IBuildingData, y: IBuildingData) => y.annualKwh - x.annualKwh);
  const average = buildings.length > 0 ? (buildings.map((x: IBuildingData) => x.annualKwh).reduce((x: number, y: number) => x + y) / buildings.length) : 0;

  return (
    <CardBase width={6}>
      <CardHeader
        title={<Stack direction="row" spacing={1}><Box component="img" src={Lightning} /><CardTitle title='Projected Energy Consumption (kWh)' /></Stack>}
      />
      <CardContent>
        <Stack direction="row" spacing={10} sx={{mb: 2}}>
          <div>
            <Typography variant="seCardSubtitleText">Avg. annual use</Typography>
            <Typography sx={{fontSize: 30}}>{average.toLocaleString('default', {maximumFractionDigits: 0})} kWh</Typography>
          </div>
          <div>
            <Typography variant="seCardSubtitleText">Avg. annual use by {props.horizon}</Typography>
            <Typography sx={{fontSize: 30}}>{(average * 0.85).toLocaleString('default', {maximumFractionDigits: 0})} kWh</Typography>
          </div>
        </Stack>
        {isDemoCustomer &&
          <>
            <Typography variant="seListSubheaderText">Biggest contributors</Typography>
            <Table sx={{ml: 0, "& th, & td": {fontSize: 18, pl: 0, borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}}>
              <TableHead sx={{"& th": {pb: 0}}}>
                <TableRow>
                  <TableCell>Building</TableCell>
                  <TableCell align="right">How much</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buildings.slice(0, 3).map((building: IBuildingData) => (
                  <TableRow key={building.id}>
                    <TableCell>{building.name}</TableCell>
                    <TableCell align="right">{building.annualKwh.toLocaleString('default')} kWh</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        }
        </CardContent>
    </CardBase>
  );
}


export default ProjectedEnergyConsumptionCard;
