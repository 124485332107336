import * as React from 'react';
import { Link } from 'react-router-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Card, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import cx from 'classnames';

import { useAppSelector } from 'modules/store';
import { getAllocationNotRun, getProgram, getProgramCustomerLoadMatching, getPrograms, getResidualMixCarbonIntensity, getProgramToplineMetrics, getProgramCertValues, getProgramLoad, getProgramCarbonIntensityForCustomerLoad, getProgramGenSourceData } from 'modules/demo/selectors';
import DateRangeHeader from 'components/DateRangeHeader';
import { getProgramByGenSourceSeries, getProgramCumulativeGenSourceSeries, makeChartOptions } from 'demo/chart_helpers';
import { IProgram, StandardRatepayerProgram } from 'modules/demo/slice';
import ToplineMetrics from 'demo/components/ToplineMetrics';
import UtilityToplineMetrics from 'demo/components/UtilityToplineMetrics';

import './style.css';


const defaultStartStr = '2021-01-01T00:00:00-05:00';
const defaultEndStr = '2021-12-31T23:59:59.9999-05:00';


const columns = (minPct: number) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    type: 'string',
    flex: 1,
  },
  {
    field: 'id',
    headerName: 'Customer ID',
    width: 120,
    sortable: false,
    type: 'string',
    flex: 1,
  },
  {
    field: 'loadMatched',
    headerName: 'Percent Load Matched',
    width: 200,
    sortable: false,
    type: 'number',
    flex: 1,
    renderCell: (params: GridRenderCellParams<number>) => {
      if (params.row.programId === 'Standard Ratepayer') {
        return <em>N/A</em>
      }
      const classModifier: Record<string, boolean> = {};
      if ((params.value < minPct) && (params.value >= (minPct * 0.9))) {
        classModifier['medium'] = true;
      } else if (params.value < (minPct * 0.9)) {
        classModifier['bad'] = true;
      }
      return (
        <div className={cx("program-allocation-row--coverage", classModifier)}>{Math.floor(params.value)} %</div>
      )
    },
  },
  {
    field: 'totalLoadMWh',
    headerName: 'Total 2021 Load (MWh)',
    minWidth: 200,
    sortable: false,
    type: 'number',
    flex: 1,
  }
];


const CustomerAllocationTable = ({programId}: {programId: string}) => {
  const data = useAppSelector(s => getProgramCustomerLoadMatching(s, programId));
  const program = useAppSelector(s => getProgram(s, programId));

  return (
    <DataGrid
      rowCount={data.length}
      rows={data.map(d => ({...d, programId}))}
      columns={columns(program?.is247Program ? 80 : 100)}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight
    />
  )
};


const ProgramAllocation = () => {
  const programs: IProgram[] = useAppSelector(getPrograms);
  const [selectedProgram, setSelectedProgram] = React.useState(programs[0])
  const [startDate, setStartDate] = React.useState<null | Date>(new Date(defaultStartStr));
  const [endDate, setEndDate] = React.useState<null | Date>(new Date(defaultEndStr));
  const [interval, setInterval] = React.useState('day');
  const allocationNotRun = useAppSelector(getAllocationNotRun);
  const [show247Cumulative, setShow247Cumulative] = React.useState(false);
  const programLoadData = useAppSelector(s => getProgramLoad(s, selectedProgram.id));
  const programGenSourceData = useAppSelector(s => getProgramGenSourceData(s, selectedProgram.id));
  const isStandardRatepayerProgram = !selectedProgram || selectedProgram.id === 'Standard Ratepayer';
  const data = getProgramByGenSourceSeries(startDate, endDate, interval as 'hour' | 'day' | 'month' | 'year', programGenSourceData, programLoadData, isStandardRatepayerProgram);
  const cumulativeData = getProgramCumulativeGenSourceSeries(startDate, endDate, interval as 'hour' | 'day' | 'month' | 'year', programGenSourceData, programLoadData);
  const toplineMetrics = useAppSelector(s => getProgramToplineMetrics(s, selectedProgram.id))
  const certValues = useAppSelector(s => getProgramCertValues(s, selectedProgram.id));
  const ci = useAppSelector(s => getProgramCarbonIntensityForCustomerLoad(s, selectedProgram.id));
  const residualCI = useAppSelector(getResidualMixCarbonIntensity);

  const availablePrograms = programs.concat([StandardRatepayerProgram]);

  const is247Program = !selectedProgram || selectedProgram.is247Program;

  const setDates = (start: Date | null, end: Date | null, interval: string) => {
    setStartDate(start);
    setEndDate(end);
    setInterval(interval)
  }

  const cumulativeChartOptions = {dateResolution: interval, animated: true, chartAxisLabelY1: 'Electricity (MWh)', chartData: cumulativeData, chartType: 'areaspline', title: 'Program Trending'};
  const intervalChartOptions = {dateResolution: interval, animated: true, chartAxisLabelY1: `Eiectricity (MWh)`, chartData: data, chartType: 'column', title: 'Program Matching'};

  return <div className="customer-allocation--container">
    <div className="customer-allocation--header">
      <div className="program-allocation-select--container">
        <FormControl sx={{ m: 0, minWidth: '100px' }} size="small">
          <Select
            value={selectedProgram.id}
            onChange={(event: any) => setSelectedProgram(availablePrograms.find((c) => c.id === event.target.value))}
            className="customer-allocation--select"
          >
            {availablePrograms.map(prog => <MenuItem value={prog.id} key={prog.id}>{prog.name}</MenuItem>)}
          </Select>
        </FormControl>
        {!isStandardRatepayerProgram && <Link to={`/programs/${selectedProgram.id}/edit`} className="program-allocation-edit--link">Edit program</Link>}
      </div>
    </div>

    {!isStandardRatepayerProgram && <div className="customer-allocation--description">
      <strong>Customer commitment</strong>
      <p>{selectedProgram.is247Program ? 'At least 80% load matching for each customer.' : '100% load matching for enrolled customers.'}</p>
    </div>}

    {!allocationNotRun && <ToplineMetrics {...toplineMetrics} />}

    {!allocationNotRun && <UtilityToplineMetrics certificateValue={certValues} carbonIntensity={{marketBased: isNaN(ci) ? residualCI.marketBased : ci, locationBased: residualCI.locationBased}}/>}

    <Grid container spacing={2} sx={{mt: 0, mb: 2, mr: 2 }}>
      <Grid item xs={12}>
        <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
          {!allocationNotRun && <div className="customer-allocation-chart-header--options">
              {selectedProgram.is247Program && !isStandardRatepayerProgram ? <FormControlLabel control={<Switch checked={show247Cumulative} onChange={(e) => setShow247Cumulative(e.target.checked)} />} label="Cumulative" /> : <div />}
              <DateRangeHeader onChange={setDates} defaultStart={defaultStartStr} defaultEnd={defaultEndStr} defaultInterval='day'/>
            </div>
          }
          {allocationNotRun && <div>Inventory allocation has not been run. Go to the <Link to="/programs">Program Settings</Link> page to configure and run inventory allocation based on your program setup.</div>}
          {!allocationNotRun &&
            <HighchartsReact
              highcharts={Highcharts}
              options={makeChartOptions(!is247Program || show247Cumulative ? cumulativeChartOptions : intervalChartOptions)}
            />
          }
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
          <h2>Enrollments</h2>
          <CustomerAllocationTable programId={selectedProgram.id} />
        </Card>
      </Grid>
    </Grid>
  </div>
};


export default ProgramAllocation;