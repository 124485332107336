import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


interface ICardRadioButtonGroupProps {
  mapModeToLabel: {[mode: string]: string}
  mode: string
  setModeCallback?: (mode: string) => void;
}


// A reusable radio button group (select one mode of many) that can be used to
// toggle between several views on a card.
export default function CardRadioButtonGroup(props: ICardRadioButtonGroupProps) {
  const buttons = Object.entries(props.mapModeToLabel).map((keyValuePair: [string, string]) => {
    const [mode, label] = keyValuePair;
    return (
      <ToggleButton value={mode} aria-label={label} size={'small'} key={mode}
          sx={{
            textTransform: 'none',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}>
        {label}
      </ToggleButton>
    );
  });

  return (
    <ToggleButtonGroup
      value={props.mode}
      exclusive
      onChange={(event: any, mode: string) => {
        // Don't do the callback if a mode was unselected. This ensures that one button is active at all times.
        if (props.setModeCallback && mode !== null) {
          props.setModeCallback(mode);
        }
      }}
      aria-label="Chart view mode"
      sx={{m: 1}}
    >
      {buttons}
    </ToggleButtonGroup>
  )
}

