import Theme from 'config/Theme';


interface MockResponse {
  status: number
  data: any
}


async function makeDelayedPromise(response: MockResponse, delay: number = 500): Promise<MockResponse> {
  return await new Promise<MockResponse>(
    resolve => setTimeout(() => resolve(response),
  delay));
}


function fakeLinearSeries(initialDate: Date,
                          endDate: Date,
                          initialValue: number,
                          monthlyIncrement: number,
                          randomScale: number,
                          valueKey: string,
                          forecast: boolean = false) {
  let series = []
  let d = initialDate;
  let v = initialValue;
  while (d.valueOf() <= endDate.valueOf()) {
    d.setMonth(d.getMonth() + 1);
    v = v + monthlyIncrement + randomScale * (Math.random() - 0.5);
    const dataPoint = { start_date: d.toISOString(), [valueKey]: v, forecast: forecast };
    // dataPoint[valueKey] = v;
    series.push(dataPoint);
  }
  return series;
}


function fakeHistoricalSeries(initialKwh: number, monthlyIncrement: number, randomScale: number) {
  // simulate energy
  // simulate cost and carbon from that
  const kwh = fakeLinearSeries(new Date('2021-07-01'), new Date('2022-07-01'), initialKwh, monthlyIncrement, randomScale, 'energy_usage_kwh')
  const cost = kwh.map(input => {
    let mapped = input;
    mapped.cost_dollars = (input.energy_usage_kwh as number) * 0.20; // $ per kwh
    return mapped;
  });
  const emissions = kwh.map(input => {
    let mapped = input;
    // 600 lb / mwh *  1 mwh / 1000 kwh * 1 ton / 2000 lb = x tons / kwh
    const tons_per_kwh = 800 * 1e-3 * 2e-3 + 1e-8 * Math.random();
    mapped.emissions_tons_co2 = (input.energy_usage_kwh as number) * tons_per_kwh;
    return mapped;
  });
  return {
    'energy': kwh,
    'cost': cost,
    'emissions': emissions
  };
}


function fakeRealtimeEnergyUsage() {
  const now = new Date();
  const t = new Date();
  t.setHours(0, 0, 0, 0)
  let data = [];
  let kwh = 5;
  while (t <= now) {
    data.push({
      start_date: t.toISOString(),
      energy_usage_kwh: kwh + 2 * Math.random()
    });
    if (t.getHours() < 12) {
      kwh += 0.5;
    } else {
      kwh -= 0.5;
    }
    t.setHours(t.getHours() + 1)
  }
  return data;
}


function fakeRealtimeCarbonIntensity() {
  const now = new Date();
  const end = new Date();
  end.setHours(end.getHours() + 12)
  const t = new Date();
  t.setHours(0, 0, 0, 0);
  let data = [];
  let lbs_per_mwh = 1000;
  while (t <= end) {
    data.push({
      start_date: t.toISOString(),
      intensity_lbs_co2_per_mwh: lbs_per_mwh + 100*(Math.random() - 1),
      forecast: t.valueOf() > now.valueOf(),
    });
    if (t.getHours() < 14) {
      lbs_per_mwh -= 15;
    } else {
      lbs_per_mwh += 10;
    }
    t.setHours(t.getHours() + 1);
  }
  return data;
}


function fakeRealtimeResourceMix() {
  const now = new Date();
  now.setHours(now.getHours(), 0, 0, 0);
  const t = new Date();
  t.setHours(t.getHours() - 12);
  let data = {
    energy_mix: [],
    emission_mix: [],
  };
  const numFuels = 6;
  const avgKwh = 2.3;
  while (t <= now) {
    let fractionMaxSolar = 0;
    if (t.getHours() > 6 && t.getHours() < 22) {
      fractionMaxSolar = (8 - Math.abs(14 - t.getHours())) / 8;
    }
    data.energy_mix.push({
      start_date: t.toISOString(),
      data: {
        'coal': 2 * (1 - fractionMaxSolar) * avgKwh / numFuels,
        'solar': 2 * fractionMaxSolar * avgKwh / numFuels,
        'wind': 2 * Math.random() * avgKwh / numFuels,
        'hydro': 2 * 0.3 * avgKwh / numFuels,
        'natural_gas': 2 * (1 - fractionMaxSolar) * avgKwh / numFuels,
        'oil': 2 * (1 - fractionMaxSolar + Math.random()) * avgKwh / numFuels,
        'nuclear': 2 * 0.5 * avgKwh / numFuels,
        // 'gas_scope_1': avgKwh * 0.3
      }
    });
    data.emission_mix.push({
      start_date: t.toISOString(),
      data: {
        'coal': data.energy_mix.at(-1).data['coal'] * 2500 * 1e-3,
        'solar': data.energy_mix.at(-1).data['solar'] * 0 * 1e-3,
        'wind': data.energy_mix.at(-1).data['wind'] * 0 * 1e-3,
        'hydro': data.energy_mix.at(-1).data['hydro'] * 0 * 1e-3,
        'natural_gas': data.energy_mix.at(-1).data['natural_gas'] * 600 * 1e-3,
        'oil': data.energy_mix.at(-1).data['oil'] * 1200 * 1e-3,
        'nuclear': data.energy_mix.at(-1).data['nuclear'] * 0 * 1e-3,
        // 'gas_scope_1': data.energy_mix.at(-1).data['gas_scope_1'] * 750 * 1e-3,
      }
    })

    t.setHours(t.getHours() + 1);
  }
  return data;
}


function fakeHistoricalEmissions(targetYear: number) {
  let data = {
    historical_baseline: [],
    historical_actual: []
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startOfTime = new Date(today.valueOf());
  startOfTime.setFullYear(startOfTime.getFullYear() - 5);
  const endOfTime = new Date(`${targetYear}-01-01T00:00:00.000`);

  const baselineAnnualIncrement = 5.5 / 12;
  const actualAnnualIncrement = 4 / 12;
  const expectedAnnualReduction = -2.5 / 12;

  let t = startOfTime;
  let baseline = 750;
  let actual = 740;
  while (t <= endOfTime) {
    if (t.getFullYear() === today.getFullYear()) {
      data.historical_baseline.push({
        start_date: t.toISOString(),
        emissions_tons_co2: baseline,
        forecast: true
      });
      data.historical_actual.push({
        start_date: t.toISOString(),
        emissions_tons_co2: actual,
        forecast: true
      });
    }
    data.historical_baseline.push({
      start_date: t.toISOString(),
      emissions_tons_co2: baseline,
      forecast: t.valueOf() > today.valueOf(),
    });
    data.historical_actual.push({
      start_date: t.toISOString(),
      emissions_tons_co2: actual,
      forecast: t.valueOf() > today.valueOf(),
    });
    baseline += baselineAnnualIncrement + 4 * (Math.random() - 0.5);

    if (t.valueOf() < today.valueOf()) {
      actual += actualAnnualIncrement + (Math.random() - 0.5);
    } else {
      actual += expectedAnnualReduction + (Math.random() - 0.5);
    }
    t.setMonth(t.getMonth() + 1);
  }
  return data;
}


function fakeProjectionsChartData() {
  const tons_co2_per_kwh = 750 * 1e-6;
  const dollars_per_kwh = 0.10;

  const nowDate = new Date();
  nowDate.setHours(0, 0, 0, 0);
  const earliestDate = new Date(nowDate.valueOf());
  earliestDate.setFullYear(nowDate.getFullYear() - 5);
  const latestDate = new Date(nowDate.valueOf());
  latestDate.setFullYear(nowDate.getFullYear() + 20);
  const energyUsageUntilNow = fakeLinearSeries(earliestDate, nowDate, 500, 5, 20, 'energy_usage_kwh', false);
  const emissionsUntilNow = energyUsageUntilNow.map(v => { let v2 = {...v}; v2.emissions_tons_co2 = v2.energy_usage_kwh * tons_co2_per_kwh; delete v2.energy_usage_kwh; return v2; });
  const costUntilNow = energyUsageUntilNow.map(v => { let v2 = {...v}; v2.cost_dollars = v2.energy_usage_kwh * dollars_per_kwh; delete v2.energy_usage_kwh; return v2; });

  const energyUsageNowValue: number = energyUsageUntilNow.at(-1).energy_usage_kwh;
  const midEnergyUsageForecast = fakeLinearSeries(nowDate, latestDate, energyUsageNowValue, -1.143, 0, 'energy_usage_kwh', true);
  const lowEnergyUsageForecast = midEnergyUsageForecast.map((v, i) => {
    let v2 = {...v}; v2.energy_usage_kwh -= 2.5 * i;
    return v2;
  });
  const highEnergyUsageForecast = midEnergyUsageForecast.map((v, i) => {
    let v2 = {...v}; v2.energy_usage_kwh += 2.5 * i;
    return v2;
  });

  const lowEmissionsForecast = lowEnergyUsageForecast.map(v => { let v2 = {...v}; v2.emissions_tons_co2 = v2.energy_usage_kwh * tons_co2_per_kwh; delete v2.energy_usage_kwh; return v2; });
  const midEmissionsForecast = midEnergyUsageForecast.map(v => { let v2 = {...v}; v2.emissions_tons_co2 = v2.energy_usage_kwh * tons_co2_per_kwh; delete v2.energy_usage_kwh; return v2; });
  const highEmissionsForecast = highEnergyUsageForecast.map(v => { let v2 = {...v}; v2.emissions_tons_co2 = v2.energy_usage_kwh * tons_co2_per_kwh; delete v2.energy_usage_kwh; return v2; });

  const lowCostForecast = lowEnergyUsageForecast.map(v => { let v2 = {...v}; v2.cost_dollars = v2.energy_usage_kwh * dollars_per_kwh; delete v2.energy_usage_kwh; return v2; });
  const midCostForecast = midEnergyUsageForecast.map(v => { let v2 = {...v}; v2.cost_dollars = v2.energy_usage_kwh * dollars_per_kwh; delete v2.energy_usage_kwh; return v2; });
  const highCostForecast = highEnergyUsageForecast.map(v => { let v2 = {...v}; v2.cost_dollars = v2.energy_usage_kwh * dollars_per_kwh; delete v2.energy_usage_kwh; return v2; });

  let data = {
    forecast_horizon_year: 2040,
    energy: {
      low_scenario: lowEnergyUsageForecast,
      mid_scenario: [...energyUsageUntilNow, ...midEnergyUsageForecast],
      high_scenario: highEnergyUsageForecast,
    },
    emissions: {
      low_scenario: lowEmissionsForecast,
      mid_scenario: [...emissionsUntilNow, ...midEmissionsForecast],
      high_scenario: highEmissionsForecast
    },
    cost: {
      low_scenario: lowCostForecast,
      mid_scenario: [...costUntilNow, ...midCostForecast],
      high_scenario: highCostForecast
    }
  }

  return data;
}


function fakeInterventions() {
  return [
    {
      id: 'solar-panels',
      name: 'Install solar panels',
      description: 'Install solar panels on the roof of your building',
      tags: [{ text: 'High ROI', color: Theme.palette.chartTealColor.main }],
      emissionReductionTons: 2500,
      costReductionDollars: 20000,
      interventionCostDollars: -10000
    },
    {
      id: 'battery-storage',
      name: 'Battery storage',
      description: 'Add onsite storage to enable load shifting',
      tags: [{ text: 'Utility supported', color: Theme.palette.chartOrangeColor.main }],
      emissionReductionTons: 1200,
      costReductionDollars: 20000,
      interventionCostDollars: -5000
    },
    {
      id: 'wind-farm',
      name: 'Wind farm PPAs',
      description: 'Buy PPAs from a wind farm to reduce net emissions',
      tags: [{ text: 'Easy to implement', color: Theme.palette.chartBlueColor.main }],
      emissionReductionTons: 1000,
      costReductionDollars: 0,
      interventionCostDollars: -15000
    }
  ]
}


function fakeImpactData() {
  return [
    {
      name: 'Onsite projects',
      id: 'onsite-projects',
      color: '#DCE7FB',
      avoidedEmissionsTons: 4500,
    },
    {
      name: 'Offset by RECs',
      id: 'offset-recs',
      color: '#348D48',
      avoidedEmissionsTons: 1000,
    },
    {
      name: 'Offset by PPAs',
      id: 'offset-ppas',
      color: '#8ABC38',
      avoidedEmissionsTons: 1000,
    },
    {
      name: 'Other carbon credits',
      id: 'offset-carbon-credits',
      color: '#3BD9BB',
      avoidedEmissionsTons: 1230,
    },
  ];
}


export function fakeBuildings() {
  return [
    {
      id: 'Lamb_assembly_Elinor',
      name: '414 Market St',
      annualCarbonIntensity: 15,
      realtimeCarbon: 39.54,
      realtimeKwh: 27.76,
      realtimeGas: 0,
      ranking: '72nd',
      annualKwh: 10132,
      annualCosts: 18289
    },
    {
      id: 'Lamb_industrial_Carla',
      name: '201 Evergreen Ln',
      annualCarbonIntensity: 26,
      realtimeCarbon: 215.64,
      realtimeKwh: 151.39,
      realtimeGas: 4.93,
      ranking: '83rd',
      annualKwh: 55257,
      annualCosts: 33075
    },
    {
      id: 'Lamb_office_Bertha',
      name: '19 Cedar St',
      annualCarbonIntensity: 18,
      realtimeCarbon: 151.48,
      realtimeKwh: 106.35,
      realtimeGas: 0,
      ranking: '21st',
      annualKwh: 38817,
      annualCosts: 37764
    },
    {
      id: 'Lamb_education_Willetta',
      name: '449 Oak St',
      annualCarbonIntensity: 16,
      realtimeCarbon: 0.067,
      realtimeKwh: 0.0004,
      realtimeGas: 15.72,
      ranking: '51st',
      annualKwh: 10810,
      annualCosts: 3129
    },
    {
      id: 'Lamb_assembly_Cherie',
      name: '2122 Industrial Way',
      annualCarbonIntensity: 27,
      realtimeCarbon: 110.3,
      realtimeKwh: 77.44,
      realtimeGas: 102.05,
      ranking: '28th',
      annualKwh: 28265,
      annualCosts: 40235
    },
    {
      id: 'Lamb_assembly_Rosa',
      name: '5 Cedarcrest Drive',
      annualCarbonIntensity: 30,
      realtimeCarbon: 134.57,
      realtimeKwh: 94.47,
      realtimeGas: 44.28,
      ranking: '19th',
      annualKwh: 34481,
      annualCosts: 23772
    },
    {
      id: 'Lamb_office_Caitlin',
      name: '90 Ocean View Ave',
      annualCarbonIntensity: 17,
      realtimeCarbon: 63.18,
      realtimeKwh: 44.35,
      realtimeGas: 0.26,
      ranking: '80th',
      annualKwh: 16187,
      annualCosts: 13323
    },
    {
      id: 'Lamb_education_Gabrielle',
      name: '67 Meridian Way',
      annualCarbonIntensity: 25,
      realtimeCarbon: 1.98,
      realtimeKwh: 1.39,
      realtimeGas: 3.44,
      ranking: '99th',
      annualKwh: 35735,
      annualCosts: 10363
    },
    {
      id: 'Lamb_health_Ken',
      name: '144 Heath Circle',
      annualCarbonIntensity: 29,
      realtimeCarbon: 29.46,
      realtimeKwh: 20.68,
      realtimeGas: 97.76,
      ranking: '10th',
      annualKwh: 27548,
      annualCosts: 22874
    }
  ]
}


function getParamSafe(params: any, key: string, defaultValue: any) {
  return params[key] || defaultValue;
}


// Mocks any 'GET' response from the API.
export function get(url: string, params?: any): Promise<MockResponse> {
  if (!params) {
    params = {};
  }
  switch (url) {
    case '/api/v1/buildings':
      return makeDelayedPromise({
        status: 200,
        data: fakeBuildings()
      }, 500)
    case '/api/v1/summary-numbers-card':
      return makeDelayedPromise({
        status: 200,
        data: {
          annualEmissionsGoal: '40,500',
          gapNetPerformanceAndGoal: '60,793',
          cumulativeSavings: '39,000',
          estimatedPenalties: '41,500'
        }
      }, 500);
    case '/api/v1/executive-summary/emissions-over-time':
      const targetYear = getParamSafe(params, 'targetYear', 2030);
      return makeDelayedPromise({
        status: 200,
        data: fakeHistoricalEmissions(targetYear)
      }, 500);
    case '/api/v1/historical-performance-card':
      return makeDelayedPromise({
        status: 200,
        data: {
          grossFootprint: fakeHistoricalSeries(8000, 200, 100),
          netFootprint: fakeHistoricalSeries(8000, 100, 100),
          utilitySpecificFootprint: fakeHistoricalSeries(8000, 80, 100),
          companyGoalFootprint: fakeHistoricalSeries(8000, -100, 100),
          businessAsUsualFootprint: fakeHistoricalSeries(8000, 400, 100),
        }
      }, 500);
    case '/api/v1/real-time/summary-stats-card':
      return makeDelayedPromise({
        status: 200,
        data: {
          totalEmissionsToday: 1503,
          totalEnergyUsageToday: 872,
          totalGasUsageToday: 589
        }
      }, 500);
    case '/api/v1/realtime/intensity-chart':
      return makeDelayedPromise({
        status: 200,
        data: {
          emission_intensity: fakeRealtimeCarbonIntensity(),
          energy_usage: fakeRealtimeEnergyUsage()
        }
      }, 500);
    case '/api/v1/projections/chart':
      return makeDelayedPromise({
        status: 200,
        data: fakeProjectionsChartData()
      }, 500);
    case '/api/v1/realtime/resource-mix':
      return makeDelayedPromise({
        status: 200,
        data: fakeRealtimeResourceMix()
      }, 500);
    case '/api/v1/scenarios/interventions':
      return makeDelayedPromise({
        status: 200,
        data: fakeInterventions()
      }, 500);
    case '/api/v1/historical/cumulative-impact':
      return makeDelayedPromise({
        status: 200,
        data: fakeImpactData()
      }, 500);
    case '/api/v1/summary/project-scenarios':
      return makeDelayedPromise({
        status: 200,
        data: {
          potentialEmissionReductionTons: 544,
          potentialCostSavingsDollars: 45100,
          numberOfPotentialProjects: 3,
        }
      }, 500);
    default:
      return makeDelayedPromise({
        status: 404,
        data: null
      })
  }
}


// Mocks any 'GET' response from the API.
export function post(url: string): Promise<MockResponse> {
  switch (url) {
    case '/api/v1/goals/save':
      return makeDelayedPromise({
        status: 204,
        data: 'ok'
      })
    case '/api/v1/scenarios/save-plan':
      return makeDelayedPromise({
        status: 204,
        data: 'ok'
      }, 500);
    default:
      return makeDelayedPromise({
        status: 404,
        data: null
      })
  }
}
