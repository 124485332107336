import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomer, IUser } from "api/types";


interface IAuthSlice {
  currentUser: null | IUser,
  currentCustomer: null | ICustomer,
  bootstrapDone: boolean,
};


const initialState: IAuthSlice = {
  currentUser: null,
  currentCustomer: null,
  bootstrapDone: false,
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    receiveUser: (state, action: PayloadAction<{user: IUser}>) => {
      state.currentUser = action.payload.user;
    },
    receiveCustomer: (state, action: PayloadAction<{customer: ICustomer}>) => {
      state.currentCustomer = action.payload.customer;
    },
    setBootstrapDone: (state, action: PayloadAction<boolean>) => {
      state.bootstrapDone = action.payload;
    },
  },
});


export default authSlice.reducer;

export const {
  receiveUser,
  receiveCustomer,
  setBootstrapDone,
} = authSlice.actions;
