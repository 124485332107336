import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { Map, Marker } from 'mapbox-gl';

import { useAppSelector } from 'modules/store';
import { getGenerator } from 'modules/demo/selectors';
import { MAPBOXGL_ACCESS_TOKEN } from 'constants/app';
import CertsByGeneratorTable  from './CertsByGeneratorTable';

import './style.css';

const GeneratorView = () => {
  const {id=''} = useParams<{id: string}>();
  const generator = useAppSelector((s) => getGenerator(s, id));
  const [mapLoaded, setMapLoaded] = React.useState(false);

  React.useEffect(() => {
    if (generator.location && !mapLoaded) {
      const map = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        attributionControl: false,
        container: 'generator-map-container',
        interactive: false,
        center: [generator.location.lng, generator.location.lat], // [lng, lat] is expected
        zoom: 9,
        style: 'mapbox://styles/mapbox/light-v10',
      });
      new Marker().setLngLat([generator.location.lng, generator.location.lat]).addTo(map);
      setMapLoaded(true);
    }
  }, [generator]);

  // TODO(brooke): shouldn't use both asset-page and generator-page classes here
  return (
    <div className='generator-page--container'>
      <Breadcrumbs>
        <Link to="/inventory">Inventory</Link>
        <Typography color="text.primary">{generator.plant_id_eia || 'this generator'}</Typography>
      </Breadcrumbs>
      {generator?.location && <div id="generator-map-container"/>}
      <div>
        <div className="asset-page-header--container">
          <h1>EIA ID: {generator.plant_id_eia}</h1>
        </div>
        <div className="generator-page--attributes-container">
          <div className='asset-page-info--container'>
            <label className='asset-page-info--label'>Fuel Type</label>
            <p>{generator.fuel_category}</p>
          </div>
          <div className='asset-page-info--container'>
            <label className='asset-page-info--label'>State</label>
            <p>{generator.state|| 'unknown state'}</p>
          </div>
          <div className='asset-page-info--container'>
            <label className='asset-page-info--label'>Balancing Authority</label>
            <p>{generator.ba_code}</p>
          </div>
        </div>
      </div>
      <CertsByGeneratorTable plant_id_eia={generator.plant_id_eia}/>
    </div>
  );
};

export default GeneratorView;