import * as React from 'react';

import ViewContainerBase from 'components/ViewContainerBase';
import CardGridBase from 'components/CardGridBase';

import './style.css';
import ReportCard from './ReportCard';


const ReportsView = () => {
  return (
    <ViewContainerBase>
      <CardGridBase>
        <ReportCard title='Carbon Emissions Over Time' description='Some description about the report.'/>
        <ReportCard title='Grid Usage Over Time' description='Some description about the report.'/>
        <ReportCard title='Future Projections' description='Some description about the report.'/>
        <ReportCard title='Project Impact Report' description='Some description about the report.'/>
      </CardGridBase>
    </ViewContainerBase>
  )
}


export default ReportsView;
