import React from 'react';

import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';

import './style.css';


interface IReportCardProps {
  title: string
  description: string
}


export default function ReportCard(props: IReportCardProps) {
  return (
    <CardBase width={4}>
      <CardContent sx={{p: 3}}>
        <Typography variant='seReportCardTitle'>{props.title}</Typography>
        <Typography sx={{mt: 2}}>{props.description}</Typography>
        <Box sx={{mt: 2}}>
          <Button sx={{m: 1}} variant='contained'>Export</Button>
          <Button sx={{m: 1}}>View</Button>
        </Box>
      </CardContent>
    </CardBase>
  );
}
