import * as React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button, FormHelperText, Typography } from '@mui/material';
import Alert from "@mui/material/Alert";

import { useAppDispatch, useAppSelector } from 'modules/store';
import { generationDataNeverLoaded, getCustomerAccountList, getGeneratorList, getProgram } from 'modules/demo/selectors';
import { receiveGenerationData, setGenerationLoading } from "modules/demo/slice";
import loadGeneration from "demo/data/duke/generation";

import './style.css';


const ProgramView = () => {
  const {id=''} = useParams<{id: string}>();
  const program = useAppSelector((s) => getProgram(s, id));
  const customers = useAppSelector(getCustomerAccountList);
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const generatorList = useAppSelector(getGeneratorList);
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  const numCustomers = customers.filter(customer => customer.programId === program.id).length;
  const numGenerators = generatorList.filter(generator => generator.programId == program.id).length;

  return <div className='program-page--container'>
    <Breadcrumbs>
      <Link to="/programs">All programs</Link>
      <Typography color="text.primary">{program.name}</Typography>
    </Breadcrumbs>
    <div>
      <div className='program-page-header--container'>
        <h1>{program.name}</h1>
        {program.generatorCertificateDistribution === 'dedicated' && numCustomers > numGenerators &&
          <Alert className='program-page-header--warning' severity="warning">
            Dedicated generation programs may not have more customers than generators.
          </Alert>
        }
        <Button onClick={() => nav(`/programs/${id}/edit`)}>Edit</Button>
      </div>
      <div className='program-page-info--container'>
        <label className='program-page-info--label'>ID</label>
        <p className="program-page-info--value">{program.id}</p>
      </div>
      <div className='program-page-info--container'>
        <label className='program-page-info--label'>Description</label>
        <p className="program-page-info--value">{program.description || 'no description'}</p>
      </div>
      <div className='program-page-info--container'>
        <label className='program-page-info--label'>Hourly Accounting</label>
        <p className="program-page-info--value">{program.is247Program ? 'enabled' : 'disabled'}</p>
        <FormHelperText className='program-page-info--helptext'>Enable this to have 24/7 load matching in this program</FormHelperText>
      </div>
      <div className='program-page-info--container'>
        <label className='program-page-info--label'>Customer Dedicated Cert Assignment</label>
        <p className="program-page-info--value">{program.generatorCertificateDistribution === 'dedicated' ? 'enabled' : 'disabled'}</p>
        <FormHelperText className='program-page-info--helptext'>Enable this to dedicate generators in a program to only one customer in the program.</FormHelperText>
      </div>
    </div>
  </div>
};

export default ProgramView;