import React from 'react';
import Cleave from 'cleave.js/react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { parseNumberBounded } from 'utils/parseNumber';


interface INumberFieldProps {
  id: string
  value: number
  units?: string
  decimals?: number
  error?: boolean
  helperText?: string
  isDate?: boolean
  onValueChanged?: (value: number) => void;
}


const CleaveTextField = React.forwardRef<HTMLInputElement>((props: { options: any }, ref) => {
  return (
    <Cleave {...props}/>
  );
});


// An input field that formats numbers as you type them.
const NumberField = (props: INumberFieldProps) => {
  return (
    <TextField
      id={props.id}
      size={'small'}
      sx={{mb: 1}}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onValueChanged) {
          props.onValueChanged(parseNumberBounded(event.target.value) || 0);
        }
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">{props.units}</InputAdornment>,
        inputComponent: CleaveTextField,
        inputProps: {
          options: {
            numeralDecimalScale: props.decimals || 0,
            numeral: props.isDate ? false : true,
            numeralDecimalMark: ".",
            delimiter: ",",
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'thousand',
          }
        }
      }}
    />
  )
}


export default NumberField;
