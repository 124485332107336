import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardHeader from "@mui/material/CardHeader";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

import * as MockAPI from 'api/mock';
import { IBuildingData } from 'api/types';
import CardTitle from 'components/CardTitle';
import CardBase from 'components/CardBase';
import ExpandArrow from 'components/ExpandArrow';
import GreenBuilding from 'images/building_green.svg';
import ListDivider from 'images/listDivider.svg';
import RedBuilding from 'images/building_red.svg';
import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import './style.css';


export default function SummaryBuildingsCard() {
  const buildings: IBuildingData[] = MockAPI.fakeBuildings();
  const isDemoCustomer = useAppSelector(getIsDemoCustomer);
  const navigate = useNavigate();

  if (!isDemoCustomer) return null;

  buildings.sort((x: IBuildingData, y: IBuildingData) => x.annualCarbonIntensity - y.annualCarbonIntensity);
  const makeBuildingItem = (top: boolean, building: IBuildingData) => {
    return (
      <ListItem disableGutters key={building.id}>
        <ListItemIcon><Icon sx={{width: 46, height: 46}}><Box
          component="img"
          sx={{width: 1}}
          src={top ? GreenBuilding : RedBuilding}
          alt={(top ? 'Green' : 'Red') + ' Building'}
        /></Icon></ListItemIcon>
        <Stack direction="row" justifyContent="space-between" spacing={1} sx={{width: 1}} divider={
          <Box
            component="img"
            src={ListDivider}
            flexShrink="1"
          />
        }>
          <ListItemText primary={building.name} primaryTypographyProps={{fontSize: '16px', whiteSpace: 'nowrap'}} />
          <ListItemText primary={building.annualCarbonIntensity} primaryTypographyProps={{fontSize: '16px', textAlign: 'right'}} />
        </Stack>
      </ListItem>
    );
  };
  const topItems = buildings.slice(0, 3).map((x: IBuildingData) => makeBuildingItem(true, x));
  const bottomItems = buildings.slice(buildings.length - 3, buildings.length).map((x: IBuildingData) => makeBuildingItem(false, x));

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title='Buildings' />}
        subheader={<Typography variant="seCardSubtitleText" sx={{p: 1}}>Carbon Emissions (annual tons CO₂/sq. ft)</Typography>}
        action={
          <ExpandArrow onClick={() => navigate("/historical")}/>
        }
      />
      <CardContent>
        <List
          subheader={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography variant="seListSubheaderText" pb={1}>Top 3 Performers</Typography>
              <Typography>tCO₂/sq. ft</Typography>
            </Stack>
          }
        >
          {topItems}
        </List>
        <List
          subheader={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography variant="seListSubheaderText" pb={1}>Bottom 3 Performers</Typography>
              <Typography>tCO₂/sq. ft</Typography>
            </Stack>
          }
          sx={{mt: 3}}
        >
          {bottomItems}
        </List>
        <Link
          component="button"
          color="#0099FF"
          onClick={() => navigate("/historical")}
          sx={{mt: 2, fontSize: '14px'}}>
            See all {buildings.length} buildings
        </Link>
      </CardContent>
    </CardBase>
  );
}
