import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

import CardBase from 'components/CardBase';
import SummaryStatistic from 'components/SummaryStatistic';

import { useGetToplineMetricsQuery } from 'api/executive';

import './style.css';


const ToplineMetricsCardV2 = () => {
  const { data, isLoading, isFetching, isError } = useGetToplineMetricsQuery();

  const goalTons = data?.goalAnnualTonsCo2;
  const currentTons = data?.currentAnnualTonsCo2;
  let reduceTons = data?.requiredReductionAnnualTonsCo2;

  // Limit reduction to zero (if the customer already hit goal).
  if (reduceTons && reduceTons < 0) {
    reduceTons = 0;
  }

  return (
    <CardBase width={12}>
      <CardContent>
        <Grid container>
          <SummaryStatistic
            labelText={'Goal for annual emissions'}
            valueText={goalTons?.toLocaleString('default', { maximumFractionDigits: 0 }) || 'No goal set'}
            unitsText={goalTons ? ' tons CO₂' : ''}
            loading={isLoading || isFetching}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Current annual emissions'}
            valueText={currentTons?.toLocaleString('default', { maximumFractionDigits: 0 }) || 'Unavailable'}
            unitsText={currentTons ? ' tons CO₂' : ''}
            loading={isLoading || isFetching}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Reduction needed to meet goal'}
            valueText={reduceTons?.toLocaleString('default', { maximumFractionDigits: 0 }) || 'Unavailable'}
            unitsText={reduceTons ? ' tons CO₂' : ''}
            loading={isLoading || isFetching}
          />
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default ToplineMetricsCardV2;
