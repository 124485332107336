import * as React from 'react';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'modules/store';
import { updateAccountProgramAssignment } from 'modules/demo/slice';
import { getCustomerAccountsList, getCustomerAccountsListWithSummedLoad, getLoadMatchingForAccount, getProgram, getProgramsList } from 'modules/demo/selectors';
import { ILoadData } from 'demo/data/duke/customers';
import { Box, Collapse, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { sum } from 'utils/math';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';


// TODO: implement this with the account/sub-customer hierarchy
//   if (includeProgramMatching) {
//     cols.push({
//       field: 'loadMatched',
//       headerName: 'Percent Load Matched',
//       sortable: false,
//       type: 'number',
//       flex: 1,
//       renderCell: (params: GridRenderCellParams<number>) => {
//         const program = useAppSelector(s => getProgram(s, params.row.programId));

//         if (isNaN(params.value) || params.row.programId === 'Standard Ratepayer') {
//           return <em className="program-allocation-row-coverage--na">N/A</em>
//         }

//         const threshold = program.is247Program ? 80 : 100;
//         const classModifier = {};
//         if ((params.value < threshold) && (params.value >= (threshold * 0.9))) {
//           classModifier['medium'] = true;
//         } else if (params.value < (threshold * 0.9)) {
//           classModifier['bad'] = true;
//         }

//         return (
//           <div className={cx("program-allocation-row--coverage", classModifier)}>{Math.floor(params.value)} %</div>
//         )
//       },

//     });
//   }

//   return cols;
// };


const AccountPctLoadMatched = ({value, programId}: {value: number, programId: string}) => {
  const program = useAppSelector(s => getProgram(s, programId));

  if (isNaN(value) || programId === 'Standard Ratepayer') {
    return <em className="program-allocation-row-coverage--na">N/A</em>
  }

  const threshold = program.is247Program ? 80 : 100;
  const classModifier: Record<string, unknown> = {};
  if ((value < threshold) && (value >= (threshold * 0.9))) {
    classModifier['medium'] = true;
  } else if (value < (threshold * 0.9)) {
    classModifier['bad'] = true;
  }

  return (
    <div className={cx("program-allocation-row--coverage", classModifier)}>{Math.floor(value)} %</div>
  )
}

const AccountRow = ({
  account,
  includeProgramMatching,
}: {includeProgramMatching: boolean, account: {
  rootCustomerId: string,
  programId: string,
  id: number,
  name: string,
  load: ILoadData[],
}}) => {

  const programs = useAppSelector(getProgramsList);
  const dispatch = useAppDispatch();
  const loadMatching = useAppSelector(s => getLoadMatchingForAccount(s, account.id));

  const program = programs.find(p => p.id === account.programId);

  return (
    <TableRow>
      <TableCell>{account.name.split('.')[1] || account.name.split('.')[0]}</TableCell>
      <TableCell>{account.id}</TableCell>
      <TableCell>
        {includeProgramMatching ?
          program?.name || 'Standard Ratepayer' :
          <Select
            value={account.programId}
            onChange={(event: any) => dispatch(updateAccountProgramAssignment({ programId: event.target.value, accountId: account.id }))}
          >
            {[...programs, {id: 'Standard Ratepayer', name: 'Standard Ratepayer'}].map(program => <MenuItem key={program.id} value={program.id}>{program.name}</MenuItem>)}
          </ Select>
        }

      </TableCell>
      <TableCell>{(Math.round(sum(account.load.map(d => d.consumed_kwh)) / 1000)).toLocaleString()}</TableCell>
      {includeProgramMatching && <TableCell><AccountPctLoadMatched programId={account.programId} value={loadMatching?.loadMatched || 0}/></TableCell>}
    </TableRow>

  )
}

const CustomerTableRow = ({
  customerAccounts,
  includeProgramMatching,
}: {includeProgramMatching: boolean, customerAccounts: {
  rootCustomerId: string,
  programId: string,
  id: number,
  name: string,
  load: ILoadData[],
  totalLoadMWh: number,
}[]}) => {
  const [open, setOpen] = React.useState(false);

  if (customerAccounts.length === 0) return null;

  const totalLoadMWh = Math.round(sum(customerAccounts.map(d => d.totalLoadMWh)));

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        </TableCell>
        <TableCell>{customerAccounts[0].rootCustomerId}</TableCell>
        <TableCell>{customerAccounts.length}</TableCell>
        <TableCell>{customerAccounts.filter(acc => acc.programId !== 'Standard Ratepayer').length}</TableCell>
        <TableCell>{totalLoadMWh.toLocaleString()}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Accounts
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Account</TableCell>
                    <TableCell>Account ID</TableCell>
                    <TableCell>Program</TableCell>
                    <TableCell>Total 2021 Load (MWh)</TableCell>
                    {includeProgramMatching && <TableCell>Percent Load Matched</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerAccounts.map(account => <AccountRow account={account} includeProgramMatching={includeProgramMatching} key={account.id}/>)}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}


const CustomersTable = ({
  withLoadMatching=false
}: {withLoadMatching?: boolean}) => {
  const customerAccounts = useAppSelector(getCustomerAccountsListWithSummedLoad);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Name</TableCell>
          <TableCell>Number of Accounts</TableCell>
          <TableCell>Program Enrollment Count</TableCell>
          <TableCell>Total 2021 Load (MWh)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customerAccounts.map((cust, i) => <CustomerTableRow includeProgramMatching={withLoadMatching} customerAccounts={cust} key={cust[0]?.rootCustomerId || i} />)}
      </TableBody>
    </Table>
  )
};

export default CustomersTable;