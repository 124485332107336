// Take a monthly series and roll it up into a yearly series.
export const makeYearlyPlotData = (
    monthlyData: { x: number, y: number }[],
    offsetByHours: number = 12,
    average: boolean = false
  ) => {
  const totalByYear: {[year: string]: number} = {};
  const countByYear: {[year: string]: number} = {};
  const offsetByMs = 1e3 * 3600 * offsetByHours;
  monthlyData.forEach(v => {
    const year = new Date(v.x + offsetByMs).getFullYear();
    if (!totalByYear[year]) {
      totalByYear[year] = 0;
      countByYear[year] = 0;
    }
    totalByYear[year] += v.y;
    countByYear[year] += 1;
  });

  const years = Object.keys(totalByYear).sort();
  return years.map(y => ({
    x: new Date(parseInt(y), 0, 1).valueOf(),
    y: totalByYear[y] / countByYear[y] * (average ? 1 : 12)
  }));
}
