import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { useFetchCertsPageQuery } from 'api/certificates';
import { ICustomer } from 'api/types';
import { ICertificate } from 'constants/types';
import CertificateDrawer from 'components/CertificateDrawer';
import loadGeneration from 'demo/data/duke/generation';
import { getCurrentCustomer } from 'modules/auth/selectors';
import { generationDataLoading, generationDataNeverLoaded, getGenerationData, getGenerator, getProgramForGenerator } from 'modules/demo/selectors';
import { receiveGenerationData, setGenerationLoading } from 'modules/demo/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { dateToString, moneyToString, numberToString } from 'utils/strings';

import './style.css';

const columns = [
  {
    field: 'issuedAt',
    headerName: 'Issued At',
    width: 200,
    type: 'string',
    sortable: false,
    valueGetter: (params: {row: ICertificate}): string | number => {
      return dateToString(new Date(params.row.issuedAt));
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    sortable: false,
    type: 'string',
  },
  {
    field: 'productionSource',
    headerName: 'Source',
    width: 100,
    sortable: false,
    type: 'string',
  },
  {
    field: 'emissions',
    headerName: 'Emissions',
    width: 160,
    sortable: false,
    type: 'string',
    valueGetter: (params: {row: ICertificate}): string | number => {
      return `${numberToString(params.row.emissions.emissionFactor)} ${params.row.emissions.emissionFactorUnits.replaceAll('_', ' ')}`;
    },
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 100,
    sortable: false,
    type: 'string',
    valueGetter: (params: {row: ICertificate}): string | number => {
      return moneyToString(params.row.value.amount / 100);
    },
  },
  {
    field: 'issuedLocation',
    headerName: 'Location',
    sortable: false,
    width: 240,
  }
]


const CertificatesTable = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [viewingCertificate, setViewingCertificate] = React.useState<null | ICertificate>(null);
  const [totalCerts, setTotalCerts] = React.useState(0);
  const {id=''} = useParams<{id: string}>();
  const generator = useAppSelector((s) => getGenerator(s, id));
  const {timestamp=''} = useParams<{timestamp: string}>();

  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const generationLoading = useAppSelector(generationDataLoading);
  const rawGenerationData = useAppSelector(getGenerationData);
  const program = useAppSelector(s => getProgramForGenerator(s, id));
  const customer: ICustomer = useAppSelector(getCurrentCustomer);
  const dispatch = useAppDispatch();

  const {data, isError, isFetching, isLoading} = useFetchCertsPageQuery({
    timestamp,
    generator,
    customerId: customer.id,
    per_page: rowsPerPage,
    total: totalCerts,
    page,
    costPerCert: !!program ? (program.costPerMWh || 1) * 100 : 0,
  });

  const setViewingRow = (row: {row: ICertificate}) => {
    setViewingCertificate(row.row);
  }

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  React.useEffect(() => {
    if (rawGenerationData) {
      rawGenerationData.sampled.forEach(sampledGen => {
        if (sampledGen.plant_id_eia === id && sampledGen.datetime_utc === timestamp) {
          return setTotalCerts(Math.floor(sampledGen.net_generation_mwh));
        }
      });
      rawGenerationData.purchased_specified.forEach(purchasedGen => {
        if (purchasedGen.plant_id_eia === id && purchasedGen.datetime_utc === timestamp) {
          return setTotalCerts(Math.floor(purchasedGen.net_generation_mwh));
        }
      });
      rawGenerationData.unbundled_recs.forEach(recGen => {
        if (recGen.plant_id_eia === id && recGen.datetime_utc === timestamp) {
          return setTotalCerts(Math.floor(recGen.net_generation_mwh));
        }
      });
    }
  }, [rawGenerationData]);

  return (
    <div className='certificates-table--container'>
      <Breadcrumbs>
        <Link to="/inventory">Inventory</Link>
        <Link to={`/inventory/generators/${id}`}>Generator {id}</Link>
        <Typography color="text.primary">Certs for {timestamp || 'the selected hour'}</Typography>
      </Breadcrumbs>
      <DataGrid
        className='certificates-table--data-grid'
        loading={isLoading || isFetching || generationLoading}
        rowCount={(data?.meta.pagination.total || 0)}
        pagination={true}
        rows={data?.data || []}
        error={isError || undefined}
        columns={columns}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={setRowsPerPage}
        onPageChange={(p) => setPage(p + 1)}
        paginationMode="server"
        autoHeight={true}
        onRowClick={setViewingRow}
      />
      <CertificateDrawer isOpen={!!viewingCertificate} onClose={() => setViewingCertificate(null)} cert={viewingCertificate}/>
    </div>
  )
};

export default CertificatesTable;