import * as React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs, CircularProgress, Fade, Typography, Button, TextField, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { IPatchAsset, useFetchAssetQuery, usePatchAssetMutation } from 'api/assets';
import { allRegions, assetTypeToName, timeResolutionToName } from 'constants/app';

import './style.css';


const EditAssetView = () => {
  const {id=''} = useParams<{id: string}>();
  const {isLoading, isFetching, isError, data} = useFetchAssetQuery({id});
  const nav = useNavigate();
  const [patch, patchRes] = usePatchAssetMutation();

  const loading = isLoading || isFetching;

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    // this method relies on the "name" property of the form inputs
    // aligning with the key names in the request payload
    const form = new FormData(e.currentTarget);
    const payload = (Object.fromEntries(form) as unknown) as IPatchAsset;
    patch({ id, payload })
      .then(() => nav(`/assets/${id}`));
  };

  return <div className='asset-page--container'>
    <Breadcrumbs>
      <Link to="/assets">All assets</Link>
      <Link to={`/assets/${id}`}>{data?.data.name || 'this asset'}</Link>
      <Typography color="text.primary">edit</Typography>
    </Breadcrumbs>
    {isError && <Typography color="error">Failed to load asset</Typography>}
    {data &&
      <form onSubmit={onSubmit}>
        <div className="asset-page-info--container asset-page-header--container">
          <TextField className="asset-page-edit-input--header" name="name" label="Name" variant="outlined" defaultValue={data.data.name || ''} placeholder='Name'/>
          <div>
            <LoadingButton variant="contained" style={{marginRight: '16px'}} loading={patchRes.isLoading} type='submit'>Save</LoadingButton>
            <Button onClick={() => nav(`/assets/${id}`)} variant="outlined" disabled={patchRes.isLoading}>Cancel</Button>
          </div>
        </div>

        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>ID</label>
          <p>{data.data.id}</p>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Description</label>
          <TextField label="description" variant="outlined" className="asset-page-edit-input--field asset-page-edit-input--description" name="description" defaultValue={data.data.description || ''}/>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Region</label>
          <Select
            defaultValue={data.data.location.region || ''}
            label="Region"
            name="region"
            className='asset-page-edit-input--field'
          >
            {allRegions.map((region) => (<MenuItem key={region.code} value={region.code}>{region.name} ({region.code})</MenuItem>))}
          </Select>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Type</label>
          <Select
            defaultValue={data.data.assetType}
            label="Type"
            name="asset_type"
            className='asset-page-edit-input--field'
          >
            {Object.entries(assetTypeToName).map(([value, name]) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}
          </Select>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Base Resolution</label>
          <Select
            defaultValue={data.data.baseResolution}
            label="Base Resolution"
            name="base_resolution"
            className='asset-page-edit-input--field'
          >
            {Object.entries(timeResolutionToName).map(([value, name]) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}
          </Select>
        </div>
      </form>
    }
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '100ms' : '0ms',
      }}
      unmountOnExit
    >
      <CircularProgress />
    </Fade>
  </div>
};

export default EditAssetView;