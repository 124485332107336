import * as React from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import './style.css';


export interface ICardBaseProps {
  // How wide to make this card, from 1-12. See these docs to understand layout:
  // https://mui.com/material-ui/react-grid/
  width: number
  children: any
  sx?: any
}


// This outer card simply display any children that are nested inside of its JSX.
// NOTE: This must be rendered inside of a <Grid/> or <CardGridBase/> container!
export default class CardBase extends React.Component<ICardBaseProps> {
  render() {
    return (
      <Grid item xs={this.props.width || 12}>
        <Card variant='outlined' className='se--card-base' sx={{p: 2, ...(this.props.sx || {})}}>
          {this.props.children}
        </Card>
      </Grid>
    );
  }
}
