import { IMockHistoricalChartData } from "api/types";
import { sum } from "utils/math";


// Find the most sliding window of 1Y of data, and calculate the total emissions
// during that year. Note that this will not account for missing data! So if a
// customer does not have a full year of data we will underestimate their emissions.
export const getLastYearEmissionsTonsCo2 = (data: IMockHistoricalChartData[]): number | undefined => {
  if (data.length === 0) {
    return undefined;
  }

  const now = new Date();
  const dataBeforeNow = data.filter(d => new Date(d.startDate).valueOf() < now.valueOf());

  if (dataBeforeNow.length === 0) {
    return undefined;
  }

  const dataWindowEnd = new Date(dataBeforeNow.at(-1).startDate);
  const dataWindowStart = dataWindowEnd.setFullYear(dataWindowEnd.getFullYear() - 1);
  const dataWindow = dataBeforeNow.filter(d => new Date(d.startDate).valueOf() > dataWindowStart);

  return sum(dataWindow.map(d => d.totalEmissionsTonsCo2));
}


export const getLastYearCost = (data: IMockHistoricalChartData[]): number | undefined => {
  if (data.length === 0) {
    return undefined;
  }

  const now = new Date();
  const dataBeforeNow = data.filter(d => new Date(d.startDate).valueOf() < now.valueOf());

  if (dataBeforeNow.length === 0) {
    return undefined;
  }

  const dataWindowEnd = new Date(dataBeforeNow.at(-1).startDate);
  const dataWindowStart = dataWindowEnd.setFullYear(dataWindowEnd.getFullYear() - 1);
  const dataWindow = dataBeforeNow.filter(d => new Date(d.startDate).valueOf() > dataWindowStart);

  return sum(dataWindow.map(d => d.totalCostDollars));
}


export const getAxisScaling = (data: { x: number, y: number }[], paddingFraction: number = 0.1) => {
  const yValues = data.map(v => v.y);
  const min = Math.min(...yValues);
  const max = Math.max(...yValues);

  const range = max - min;
  const paddingY = paddingFraction * range;

  if (range === 0) {
    return { minY: 0, maxY: 1 };
  }

  return { minY: min - paddingY, maxY: max + paddingY };
}
