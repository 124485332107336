import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';

import { ICustomerProjectionResponse } from 'api/types';
import makeLinearGradient from 'utils/chartGradient';

import { useAppSelector } from 'modules/store';
import { getPhysicalUtcOffsetMins } from 'modules/auth/selectors';

import Theme from 'config/Theme';


interface ICumulativeEmissionsChartProps {
  loading: boolean
  goalYear: number
  data?: ICustomerProjectionResponse
}


// Convert a series of x/y points into a cumulative series.
const makeCumulativeData = (data: { x: number, y: number }[]) => {
  const output: { x: number, y: number }[] = [];
  data.reduce((runningTotal: {x: number, y: number}, value: { x: number, y: number }) => {
    const cumulativeY = runningTotal.y + value.y;
    output.push({ x: value.x, y: cumulativeY });
    return { x: runningTotal.x, y: cumulativeY };
  }, { x: 0, y: 0 });
  return output;
}


const CumulativeEmissionsChart = (props: ICumulativeEmissionsChartProps) => {
  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);

  const baselineSeries = props.data?.projections?.MIDRENEWABLECOST?.baseline
    .map(v => ({ x: new Date(v.startDate).valueOf(), y: v.netEmissionsLbsCo2 / 2000 })) || [];

  const plannedNetSeries = props.data?.projections?.MIDRENEWABLECOST?.planned
    .map(v => ({ x: new Date(v.startDate).valueOf(), y: v.netEmissionsLbsCo2 / 2000 })) || [];

  const opacityHex = '30';
  const tooltipOptions = { valueSuffix: ' tons CO₂' };
  const goalYearEpoch = new Date(props.goalYear || 2040, 1, 1, 0, 0, 0, 0).valueOf();

  const chartData: any[] = [
    {
      name: `No action`,
      visible: true,
      type: 'areaspline',
      color: Theme.palette.chartRedColor.main,
      fillColor: makeLinearGradient(Theme.palette.chartRedColor.main, opacityHex),
      data: makeCumulativeData(baselineSeries)
        .filter((value) => value.x <= goalYearEpoch) as Highcharts.Point[],
      tooltip: tooltipOptions,
    },
    {
      name: `With projects`,
      visible: true,
      type: 'areaspline',
      color: Theme.palette.chartBlueColor.main,
      fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
      data: makeCumulativeData(plannedNetSeries)
        .filter(value => value.x <= goalYearEpoch) as Highcharts.Point[],
      tooltip: tooltipOptions,
    },
  ];

  return (
    <CardBase width={12}>
      <CardHeader
        title={<Typography gutterBottom variant="seCardTitleText" component="div">Cumulative carbon emissions</Typography>}
        action={<Button variant="outlined" color="neutral">Export</Button>}
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          loading={props.loading}
          animated={true}
          chartHeight={300}
          chartContainerId={'cumulative-emissions-chart'}
          chartData={chartData}
          chartAxisLabelY1={'cumulative tons of CO₂'}
          dateResolution={'month'}
          overrideOptions={{
            time: {
              timezoneOffset: -physicalUtcOffsetMins,
              useUTC: true
            }
          }}
        />
      </CardContent>
    </CardBase>
  );
}


export default CumulativeEmissionsChart;
