import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

import CardBase from 'components/CardBase';
import SummaryStatistic from 'components/SummaryStatistic';

import { useGetRealtimeChartDataV2Query } from 'api/realtime';

import './style.css';


const ToplineCard = () => {
  const params = {};
  const { data, isLoading, isFetching, isError } = useGetRealtimeChartDataV2Query(params);

  const totalEmissionsTonsToday = data?.totalEmissionsLbs / 2000 || 0;
  const totalEnergyUsageMwhToday = data?.totalConsumedKwh * 1e-3 || 0;

  return (
    <CardBase width={12}>
      <CardContent>
        <Grid container>
          <SummaryStatistic
            labelText={'Total carbon emissions today'}
            valueText={totalEmissionsTonsToday.toLocaleString('default', { maximumFractionDigits: 0 })}
            unitsText={' tons CO₂'}
            loading={isFetching || isLoading}
            labelHeight={'30px'}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Total grid usage today'}
            valueText={totalEnergyUsageMwhToday.toLocaleString('default', { maximumFractionDigits: 0 })}
            unitsText={' MWh'}
            loading={isFetching || isLoading}
            labelHeight={'30px'}
          />
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default ToplineCard;
