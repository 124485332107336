import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import sunburst from 'highcharts/modules/sunburst';
import { useAppDispatch, useAppSelector } from 'modules/store';
sunburst(Highcharts);

import CardBase from 'components/CardBase';
import CardTitle from 'components/CardTitle';
import SummaryStatistic from 'components/SummaryStatistic';
import loadGeneration from 'demo/data/duke/generation';
import { generationDataLoading, generationDataNeverLoaded, getGenerationData, getGenerators } from 'modules/demo/selectors';
import { receiveGenerationData, setGenerationLoading } from 'modules/demo/slice';
import { numberToShortString } from 'utils/strings';

import './style.css';
import GenerationChart from './generation';

const fuelTypeToName: any = {
  petroleum: 'Petroleum',
  natural_gas: 'Natural Gas',
  hydro: 'Hydro',
  coal: 'Coal',
  nuclear: 'Nuclear',
  biomass: 'Biomass',
  waste: 'Waste',
  other: 'Other',
  solar: 'Solar',
  wind: 'Wind',
};

const COLORS: any = {
  owned: {
    base: '#5250a3', // navy

    nuclear: '#3BBC95',
    solar: '#0F9F73',
    natural_gas: '#E6AB7A',
    coal: '#DC4D4E',
    hydro: '#10AE7E',
    biomass: '#B54040',
  },
  purchased: {
    base: '#365475', // blue
    import: '#EBBC94'
  },
};

type SunburstDataSlice = {
  id: string,
  name: string,
  color: string,
  parent: string,
  value: number ,
}

const InventoryOverview = () => {
  const [ownedGenerationMWh, setOwnedGenerationMWh] = React.useState(0);
  const [ownedGenerationByFuel, setOwnedGenerationByFuel] = React.useState([]);
  const [purchasedSpecifiedMWh, setPurchasedSpecifiedMWh] = React.useState(0);
  const [purchasedGenerationByFuel, setPurchasedGenerationByFuel] = React.useState([]);
  const [purchasedUnspecifiedMWh, setPurchasedUnspecifiedMWh] = React.useState(0);
  const [unbundledRecsMWh, setUnbundledRecsMWh] = React.useState(0);
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const generationLoading = useAppSelector(generationDataLoading);
  const rawGenerationData = useAppSelector(getGenerationData);
  const generators = useAppSelector(getGenerators);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  React.useEffect(() => {
    if (rawGenerationData) {
      const ownedGenByFuelMap: Record<string, number> = {};
      let totalOwnedGenMWh = 0;
      rawGenerationData.sampled.forEach(sampledGenData => {
        const { plant_id_eia, net_generation_mwh } = sampledGenData;
        totalOwnedGenMWh += net_generation_mwh;
        const generator = generators.find(g => g.plant_id_eia == plant_id_eia);
        const { fuel_category } = generator;
        if (ownedGenByFuelMap.hasOwnProperty(fuel_category)) {
          ownedGenByFuelMap[fuel_category] += net_generation_mwh;
        } else {
          ownedGenByFuelMap[fuel_category] = net_generation_mwh;
        }
      });
      setOwnedGenerationMWh(totalOwnedGenMWh);
      const ownedGenerationFuelList: SunburstDataSlice[] = [];
      Object.entries(ownedGenByFuelMap).forEach(([fuelType, generation]) => {
        ownedGenerationFuelList.push({
          id: `owned.${fuelType}`,
          name: fuelTypeToName[fuelType],
          color: COLORS.owned[fuelType],
          parent: 'owned',
          value: generation,
        });
      });
      setOwnedGenerationByFuel(ownedGenerationFuelList.sort((a, b) => a.value - b.value));


      const purchasedPowerByFuelMap: Record<string, number> = {};
      let totalPurchasedSpecified = 0;
      rawGenerationData.purchased_specified.forEach(purchasedSpecGenData => {
        const { plant_id_eia, net_generation_mwh } = purchasedSpecGenData;
        totalPurchasedSpecified += net_generation_mwh;
        const generator = generators.find(g => g.plant_id_eia == plant_id_eia);
        const { fuel_category } = generator;
        if (purchasedPowerByFuelMap.hasOwnProperty(fuel_category)) {
          purchasedPowerByFuelMap[fuel_category] += net_generation_mwh;
        } else {
          purchasedPowerByFuelMap[fuel_category] = net_generation_mwh;
        }
      });
      setPurchasedSpecifiedMWh(totalPurchasedSpecified);
      const purchasedSpecifiedFuelList: SunburstDataSlice[] = [];
      Object.entries(purchasedPowerByFuelMap).forEach(([fuelType, generation]) => {
        purchasedSpecifiedFuelList.push({
          id: `purchased.${fuelType}`,
          name: fuelTypeToName[fuelType],
          color: COLORS.owned[fuelType],
          parent: 'purchased',
          value: generation,
        });
      });
      setPurchasedGenerationByFuel(purchasedSpecifiedFuelList.sort((a, b) => a.value - b.value));


      let totalPurchasedUnspecified = 0;
      rawGenerationData.purchased_unspecified.forEach(purchasedUnspecGenData => {
        totalPurchasedUnspecified += purchasedUnspecGenData.net_generation_mwh;
      });
      setPurchasedUnspecifiedMWh(totalPurchasedUnspecified);

      let totalUnbundledRecs = 0;
      rawGenerationData.unbundled_recs.forEach(unbundledRecData => {
        totalUnbundledRecs += unbundledRecData.net_generation_mwh;
      });
      setUnbundledRecsMWh(totalUnbundledRecs)
    }
  }, [rawGenerationData]);

  React.useEffect(() => {
    if (rawGenerationData) {
      Highcharts.chart('sunburst-chart-container', {
        series: [{
            // specific options for this series instance
            type: 'sunburst',
            dataLabels: {
              rotationMode: 'perpendicular',
            },
            data: [{
              id: 'owned',
              name: 'Owned',
              parent: '',
              color: COLORS.owned.base,
            }, {
              id: 'purchased',
              name: 'Purchased',
              parent: '',
              color: COLORS.purchased.import,
            }, {
              id: 'recs',
              name: 'REC',
              parent: '',
              color: COLORS.owned.solar,
            }, {
              id: 'recs.solar',
              name: 'Solar',
              parent: 'recs',
              value: unbundledRecsMWh * 1.8,
            }, {
              id: 'purchased.unspecified',
              name: 'Unspecified',
              parent: 'purchased',
              value: purchasedUnspecifiedMWh,
              color: COLORS.purchased.import,
            },
            ...purchasedGenerationByFuel,
            ...ownedGenerationByFuel,
          ],
        }],
        tooltip: {
          valueDecimals: 0,
        },
        title: {
          text: undefined,
        },
        credits: {
          enabled: false,
        },
      });
    }
  }, [ownedGenerationByFuel, purchasedGenerationByFuel]);

  const scaledUnbundledRecsMWh = unbundledRecsMWh * 1.8;
  return (
    <Grid container spacing={4} sx={{mt: 0, mb: 2}}>
      <CardBase width={12}>
        <CardContent>
          <Grid container>
            <SummaryStatistic
              labelText={'Total Generation'}
              valueText={numberToShortString(ownedGenerationMWh + purchasedSpecifiedMWh + purchasedUnspecifiedMWh + scaledUnbundledRecsMWh)}
              unitsText={'MWh'}
              loading={generationLoading}
            />
            <Divider orientation="vertical" flexItem/>
            <SummaryStatistic
              labelText={'Owned Generation'}
              valueText={numberToShortString(ownedGenerationMWh)}
              unitsText={'MWh'}
              loading={generationLoading}
            />
            <Divider orientation="vertical" flexItem/>
            <SummaryStatistic
              labelText={'Purchased Power'}
              valueText={numberToShortString(purchasedSpecifiedMWh + purchasedUnspecifiedMWh)}
              unitsText={'MWh'}
              loading={generationLoading}
            />
            <Divider orientation="vertical" flexItem/>
            <SummaryStatistic
              labelText={'REC Purchases'}
              valueText={numberToShortString(scaledUnbundledRecsMWh)}
              unitsText={'MWh'}
              loading={generationLoading}
            />
          </Grid>
        </CardContent>
      </CardBase>

      <CardBase width={12}>
        <CardHeader
          title={<CardTitle title={'Your generation overview'}/>}
        />
        <CardContent>
          <div id='sunburst-chart-container'/>
        </CardContent>
      </CardBase>

      <CardBase width={12}>
        <GenerationChart />
      </CardBase>
    </Grid>
  );
};

export default InventoryOverview;