// https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript
export const camelToSnake = (str: string): string => str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

// https://stackoverflow.com/questions/40710628/how-to-convert-snake-case-to-camelcase-in-my-app
export const snakeToCamel = (str: string) => (
  str.replace(/([-_][A-Za-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''))
);

// https://stackoverflow.com/questions/64489395/converting-snake-case-string-to-title-case
export const snakeToTitle = (str: string) => (
  str.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
);

const moneyFmt = new Intl.NumberFormat('default', { style: 'currency', currency: 'USD' });
const numberFmt = new Intl.NumberFormat('default', {});
const dateFmt = new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

export const moneyToString = (money: number) => moneyFmt.format(money);
export const dateToString = (date: Date) => dateFmt.format(date);
export const numberToString = (number: number) => numberFmt.format(number);

// Converts any number to a shorthand notation with 3 sigfigs. e.g. 4,567,890 -> 4.57M
export const numberToShortString = (number: number) => number.toLocaleString('default', { notation: 'compact', maximumFractionDigits: 2, maximumSignificantDigits: 3 });
