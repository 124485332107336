import { api } from './base';
import {
  ICustomerGoals,
  IExecutiveSummaryStats,
  IHistoricalEmissionsData,
  ICustomerPerformanceSummary
} from './types';


interface IGetSummaryStatsResponse {
  data: IExecutiveSummaryStats
}


interface IGetEmissionsSummaryParams {
  targetYear: number
}

interface IGetEmissionsSummaryResponse {
  data: IHistoricalEmissionsData
}


interface IGetCustomerGoalsResponse {
  data: ICustomerGoals
}


interface IGetCustomerPerformanceSummaryResponse {
  data: ICustomerPerformanceSummary
}


export const summaryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSummaryStats: build.query<IGetSummaryStatsResponse, void>({
      query: () => {
        return {
          url: '/v1/mock/executive-overview/summary',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'CustomerSummaryStats' }]
    }),
    getEmissionsSummary: build.query<IGetEmissionsSummaryResponse, IGetEmissionsSummaryParams>({
      query: (params: IGetEmissionsSummaryParams) => {
        return {
          url: '/v1/mock/executive-overview/emissions',
          method: 'GET',
          params: params
        }
      },
      providesTags: [{ type: 'CustomerEmissionsSummary' }]
    }),
    getCustomerGoals: build.query<IGetCustomerGoalsResponse, void>({
      query: () => {
        return {
          url: '/v1/customers/properties/customer_goals',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'CustomerGoals' }]
    }),
    saveCustomerGoals: build.mutation<void, ICustomerGoals>({
      query: (params: ICustomerGoals) => {
        return {
          url: '/v1/customers/properties/customer_goals',
          method: 'POST',
          body: params
        }
      },
      invalidatesTags: [{ type: 'CustomerGoals' }]
    }),
    getCustomerPerformanceSummary: build.query<IGetCustomerPerformanceSummaryResponse, void>({
      queryFn: (arg, api, extraOptions, baseQuery) => {
        return {
          data: {
            data: {
              annualElectricityCost: 413000,
              annualEmissionsTons: 5120,
            }
          }
        }
      },
      providesTags: [{ type: 'CustomerPerformanceSummary' }]
    })
  })
});


export const {
  useGetSummaryStatsQuery,
  useGetEmissionsSummaryQuery,
  useGetCustomerGoalsQuery,
  useSaveCustomerGoalsMutation,
  useGetCustomerPerformanceSummaryQuery
} = summaryApi;
