import { api } from "./base";


interface IAssetEvent {
  dedupKey?: string;
  // these should match the values in the Python class shared.constants.CustomerAssetEventTypes
  eventType: 'metered_consumption' | 'energy_generation' | 'battery_charge_state' | 'consumption_emissions';
  // these should match the values in the Python class shared.constants.TimeResolution
  resolution: '5m' | '15m' | '1h' | '1d' | '1M' | '1Y';
  customerId: string;
  assetId: string;
  startDate: string;
  data: Record<string, unknown>;
  meta?: Record<string, unknown>;
}


export const assetEventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchAssetEvents: build.query<{data: IAssetEvent[]}, {start: string, end: string, resolution: string, assetId: string, eventTypes: string[]}>({
      query:({start, end, resolution, assetId, eventTypes}) => {
        const qs = new URLSearchParams({ start, end, resolution, asset_id: assetId });
        eventTypes.forEach(et => qs.append('event_type', et));
        return { 
          url: `v2/asset_events/search?${qs}`,
          method: 'GET',
        }
      }
    }),
  })
});


export const {
  useSearchAssetEventsQuery,
} = assetEventsApi;
