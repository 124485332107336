import React, { useState } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { useSearchAssetEventsQuery } from 'api/asset_events';
import { TimeResolution } from 'api/types';
import CardBase from 'components/CardBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import BasicDropdown from 'components/BasicDropdown';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';
import DateRangeHeader from 'components/DateRangeHeader';
// import { HBS_CUSTOMER_IDS } from 'config/resources';
import Theme from 'config/Theme';
import makeLinearGradient from 'utils/chartGradient';

import './style.css';


enum CardMode {
  Emissions = 'Emissions',
  SoC = 'State of Charge',
}

const DEFAULT_START_DATE: string = '2023-09-01T00:00:00-05:00';
const OPACITY_HEX: string = '30';

const BatteryChart = (props: { batteryAssetId?: string }) => {
  const [startDate, setStartDate] = React.useState<null | Date>(new Date(DEFAULT_START_DATE));
  const [endDate, setEndDate] = React.useState<null | Date>(moment().toDate());
  const [mode, setMode] = useState(CardMode.SoC);
  const [resolution, setResolution] = useState(TimeResolution.HOUR);

  const { data: eventData, isFetching: eventIsFetching, isLoading: eventIsLoading } = useSearchAssetEventsQuery({ 
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    assetId: props.batteryAssetId,
    eventTypes: ['battery_charge_state', 'consumption_emissions'],
    resolution,
  }, { skip: !props.batteryAssetId || !startDate || !endDate });

  const makeChartData = () => {
    if (!eventData?.data) {
      return [];
    }

    const tooltipOptions = {
      [CardMode.Emissions]: {
        valueSuffix: ' tons CO₂'
      },
      [CardMode.SoC]: {
        valueSuffix: ' %'
      },
    }[mode];

    const chartData = eventData.data.filter(d => (
      mode === CardMode.Emissions ? d.eventType === 'consumption_emissions' : d.eventType === 'battery_charge_state'
    ));

    const extractValue = (d: { currentChargePercent?: number, lbsCo2?: number }) => {
      return (mode === CardMode.Emissions) ? d.lbsCo2 / 2000 : d.currentChargePercent * 100;
    }

    return [
      {
        name: mode,
        type: 'areaspline',
        color: Theme.palette.chartTealColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartTealColor.main, OPACITY_HEX),
        data: chartData.map((d) => {
          // for large numbers of points, highcharts wants data in 2d array format
          // see: https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
          return [new Date(d.startDate).valueOf(), extractValue(d.data)];
        }),
        tooltip: tooltipOptions
      },
    ];
  }

  const chartRef = React.createRef<ChartBase>();  // Used for exporting.

  const perWhatTimeInterval = {
    [TimeResolution.HOUR]: 'per hour',
    [TimeResolution.DAY]: 'per day',
    [TimeResolution.MONTH]: 'per month',
    [TimeResolution.YEAR]: 'per year'
  }[resolution as TimeResolution.HOUR | TimeResolution.DAY | TimeResolution.MONTH | TimeResolution.YEAR];

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [CardMode.Emissions]: 'tons of CO₂ ' + perWhatTimeInterval,
    [CardMode.SoC]: 'Percent Charge',
  }[mode];

  const setDates = (start: Date | null, end: Date | null) => {
    setStartDate(start || startDate);
    setEndDate(end || endDate);
  }

  return (
    <CardBase width={12}>
      <Grid container>
        <DateRangeHeader
          onChange={setDates}
          defaultStart={DEFAULT_START_DATE}
          defaultEnd={moment().format()}
          minStart={'2022-01-01'}
          maxEnd={moment().endOf('day').add(1, 'day').format()}
          hideInterval={true}
        />
        <Grid item xs={12}>
          <CardHeader
            title={<CardTitle title={'Battery'}/>}
            action={
              <div>
                <CardRadioButtonGroup
                  mode={mode}
                  mapModeToLabel={{
                    [CardMode.Emissions]: 'Emissions',
                    [CardMode.SoC]: 'State of Charge',
                  }}
                  setModeCallback={(value) => setMode(value as CardMode)}
                />
                <BasicDropdown
                  id={'time-resolution-dropdown'}
                  value={resolution}
                  mapValueToLabel={{
                    // [TimeResolution.YEAR]: 'Yearly',
                    [TimeResolution.MONTH]: 'Monthly',
                    [TimeResolution.DAY]: 'Daily',
                    [TimeResolution.HOUR]: 'Hourly',
                  }}
                  updateCallback={(value: TimeResolution) => setResolution(value as TimeResolution)}
                  formControlSx={{mt: 1, mr: 2, ml: 1}}
                />
              </div>
            }
          />
          <CardContent>
            <ChartBase
              ref={chartRef}
              loading={eventIsFetching || eventIsLoading}
              animated={true}
              chartHeight={300}
              chartContainerId={'battery-charge-chart'}
              chartData={makeChartData()}
              dateResolution={'month'}
              downloadFilename={'battery_state'}
              chartAxisLabelY1={chartAxisLabelY1}
              overrideOptions={{
                legend: {
                  enabled: false
                },
                plotOptions: {
                  column: {
                    groupPadding: 0.2,
                    pointPadding: 0,
                  }
                },
              }}
            />
          </CardContent>
        </Grid>
      </Grid>
    </CardBase>
  );
}

export default BatteryChart;