import { ICertificate } from "constants/types";
import { CertificateFactory } from "demo/factory";

import { api } from "./base";
import { IPaginatedResponse } from "./types";
import { IGenerator } from "demo/data/generators";


const certPage = { type: 'Certificate' as const, id: 'PARTIAL_LIST'};

const certFactory = CertificateFactory({});

export const certsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCertsPage: build.query<IPaginatedResponse<ICertificate>, {page: number, per_page: number, total: number, timestamp: string, generator: IGenerator, customerId: string, costPerCert: number}>({
      query: () => {
        return {
          url: `/v2/assets`,
          method: 'GET'
        }
      },

      transformResponse: (_res, _meta, arg) => {
        const totalItems = arg.total;
        const onLastPage = arg.page > totalItems / arg.per_page;
        const numCerts = onLastPage ? totalItems % arg.per_page : arg.per_page;
        const data: ICertificate[] = [];
        for (let _ = 0; _ < numCerts; _++) {
          data.push(certFactory(arg.customerId, arg.generator, arg.timestamp, _, arg.costPerCert));
        }
        return {
          data,
          meta: {
            pagination: {
              first: 1,
              this: arg.page,
              last: Math.ceil(totalItems / arg.per_page),
              total: totalItems,
              totalItems: totalItems,
            }
          }
        }
      },

      providesTags: (result) => [
        certPage,
        ...result ? result.data.map(asset => ({type: 'Certificate' as const, id: asset.id})) : [],
      ],
    }),
  })
});


export const {
  useFetchCertsPageQuery,
  usePrefetch,
} = certsApi;
