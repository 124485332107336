import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

import CardBase from 'components/CardBase';
import SummaryStatistic from 'components/SummaryStatistic';

import './style.css';
import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import { useSearchCustomerInterventionsQuery, useSimulateProjectionQuery } from 'api/interventions';
import { ICustomerProjection } from 'api/types';
import { useGetHistoricalPerformanceQuery } from 'api/historical';
import { getLastYearCost } from 'utils/dataUtils';


const ProjectionsToplineCard1 = ({ horizon }: {horizon: number}) => {
  // We don't show this card to demo customers (i.e customers without real data).
  const isDemo = useAppSelector(getIsDemoCustomer);
  if (isDemo) return null;

  // Get the total electricity cost from the most recent year of data.
  const dataApi = useGetHistoricalPerformanceQuery();
  const currentAnnualCost = getLastYearCost(dataApi?.data?.data?.netFootprint || []);

  // Copied from PojectionsChardCard
  // Don't request a projection until there are input interventions to pass.
  const plannedInterventionsApi = useSearchCustomerInterventionsQuery({
    status: ['planned'],
    calculateImpact: true
  });
  const projectionApi = useSimulateProjectionQuery(
    plannedInterventionsApi.data ? plannedInterventionsApi.data.data : [],
    { skip: !plannedInterventionsApi.data }
  );
  const projection = projectionApi.data && projectionApi.data.data && projectionApi.data.data.scenarioProjections && projectionApi.data.data.scenarioProjections.length > 0
    ? projectionApi.data.data.scenarioProjections
    : [{ electricityCostPerKwh: 0.111, electricityUsageKwh: (currentAnnualCost || 0) / 0.111, startDate: '2022-01-01T00:00:00+00:00' }];
  const horizonData = projection
    .filter((value: ICustomerProjection) => new Date(value.startDate).getFullYear() <= horizon)
    .slice(-1)[0];

  return (
    <CardBase width={12}>
      <CardContent>
        <Grid container>
          <SummaryStatistic
            labelText={'Average annual cost today'}
            valueText={currentAnnualCost?.toLocaleString('default', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}) || ''}
            loading={dataApi?.isFetching}
            labelHeight={'30px'}/>
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={`Average annual cost by ${horizon}`}
            valueText={(horizonData.electricityUsageKwh * horizonData.electricityCostPerKwh).toLocaleString('default', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})}
            loading={dataApi?.isFetching}
            labelHeight={'30px'}/>
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Total grid usage this year'}
            valueText={projection[0].electricityUsageKwh.toLocaleString('default', {maximumFractionDigits: 0})}
            unitsText={' kWh'}
            loading={dataApi?.isFetching}
            labelHeight={'30px'}/>
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={`Total grid usage by  ${horizon}`}
            valueText={(horizonData.electricityUsageKwh).toLocaleString('default', {maximumFractionDigits: 0})}
            unitsText={' kWh'}
            loading={dataApi?.isFetching}
            labelHeight={'30px'}/>
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default ProjectionsToplineCard1;
