import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface IAppSlice {
};


const initialState: IAppSlice = {
};


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});


export default appSlice.reducer;


export const {
} = appSlice.actions;
