import { UserRole } from 'constants/types';

export interface IUser {
  name: string;
  email: string;
  customerId: string;
  role: UserRole;
  id: string;
  status: string;
}


export interface ICustomer {
  name: string;
  id: string;
  parentId: string;
  status: string;
  apiKeyId: string;
  apiKeyValue: string;
  attributes: {
    name: string;
    namespace: string;
    value: boolean | Record<string, unknown> | string | number;
  }[];
}


export enum DashboardConfigPages {
  EXECUTIVE_VIEW = "EXECUTIVE_VIEW",
  PROGRAM_SUMMARY = "PROGRAM_SUMMARY",
  REAL_TIME = "REAL_TIME",
  HISTORICAL = "HISTORICAL",
  PROJECTIONS = "PROJECTIONS",
  SCENARIOS = "SCENARIOS",
  CUSTOMERS = "CUSTOMERS",
  ASSET_MGMT = 'ASSET_MGMT',
  SETTINGS = 'SETTINGS',
  REC_OVERVIEW = 'REC_OVERVIEW',
  REC_INVENTORY = 'REC_INVENTORY',
  REC_ALLOCATION = 'REC_ALLOCATION',
  REC_REPORTS = 'REC_REPORTS',
  REC_PROGRAMS = 'REC_PROGRAMS',
  SOLAR_BATTERY_INT = 'SOLAR_BATTERY_INT',
  SOLAR_BATTERY_INT_HISTORICAL = 'SOLAR_BATTERY_INT_HISTORICAL',
}


export interface IPhysicalAsset {
  name: string
  id: string
}


export interface ITimestampedDataBase {
  startDate: string
}


export interface ITimestampedIntensityData extends ITimestampedDataBase {
  intensityLbsCo2PerMwh: number,
  forecast: boolean
}


export interface ITimestampedEmissionData extends ITimestampedDataBase {
  emissionsTonsCo2: number
  forecast: boolean
}


export interface ITimestampedCostData extends ITimestampedDataBase {
  costDollars: number
  forecast: boolean
}


export interface ITimestampedEnergyUsageData extends ITimestampedDataBase {
  energyUsageKwh: number
  forecast: boolean
}


export interface IHistoricalEmissionsData {
  historicalBaseline: IMockHistoricalChartData[]
  historicalActual: IMockHistoricalChartData[]
}


export interface IRealtimeChartData {
  emissionIntensity: ITimestampedIntensityData[]
  energyUsage: ITimestampedEnergyUsageData[]
}


export interface IProjectionsChartData {
  forecastHorizonYear: number,
  energy: {
    lowScenario: ITimestampedEnergyUsageData[],
    midScenario: ITimestampedEnergyUsageData[],
    highScenario: ITimestampedEnergyUsageData[]
  }
  cost: {
    lowScenario: ITimestampedCostData[],
    midScenario: ITimestampedCostData[],
    highScenario: ITimestampedCostData[]
  }
  emissions: {
    lowScenario: ITimestampedEmissionData[],
    midScenario: ITimestampedEmissionData[],
    highScenario: ITimestampedEmissionData[]
  }
}


export interface IFuelMixData extends ITimestampedDataBase {
  data: { [fuelType: string]: number }
}


export interface IResourceMixData {
  emissionMix: IFuelMixData[]
  energyMix: IFuelMixData[]
}


export interface IMockHistoricalChartData {
  startDate: string
  endDate: string
  electricityUsageKwh: number
  gasUsageLiters: number
  intensityLbsCo2PerMwh: number
  totalCostDollars: number
  totalEmissionsTonsCo2: number
  forecast: boolean
}


export interface IMockHistoricalChartResponse {
  baselineFootprint: IMockHistoricalChartData[]
  grossFootprint: IMockHistoricalChartData[]
  utilityFootprint: IMockHistoricalChartData[]
  netFootprint: IMockHistoricalChartData[]
  interventions: ICustomerIntervention[]
}


export interface ICategoryEmissionReduction {
  id: string
  color: string
  readableName: string
  totalEmissionReductionTons: number
}


export interface ICustomerProjection {
  startDate: string
  endDate: string

  electricityUsageKwh: number
  electricityCostPerKwh: number
  intensityLbsCo2PerMwh: number

  grossEmissionsTonsCo2: number
  netEmissionReductionTonsCo2: number
  netEmissionsTonsCo2: number

  grossElectricityCosts: number
  operationalCosts: number
  capitalCosts: number
}


export interface ICustomerInterventionParam {
  paramId: string
  name: string
  description: string
  value: number
  minValue: number
  maxValue: number
  units: string
  userCanModify: boolean
}


export interface ICustomerIntervention {
  id: string
  startDate: string
  endDate: string
  interventionType: string
  name: string
  description: string
  status: string
  params: { [paramId: string]: ICustomerInterventionParam }
  customerId: string
}


export interface ICustomerInterventionImpact {
  addedCapitalCosts: number
  addedOperationalCosts: number
  costSavings: number
  electricityReductionKwh: number
  grossEmissionReductionCo2: number
  netEmissionReductionCo2: number
  type?: string
}


export interface IExecutiveSummaryStats {
  annualEmissionsGoal: number
  gapNetPerformanceAndGoal: number
  cumulativeSavings: number
  estimatedPenalties: number
}


export interface IHistoricalCustomerInterventionImpact {
  readableName: string
  startDate: string
  endDate: string
  netSavingsDollars: number
  totalCostDollars: number
  totalEmissionImpactTonsCo2: number
  totalSavingsDollars: number
}


export interface IRealtimeSummaryStats {
  totalEmissionsToday: number
  totalEnergyUsageToday: number
  totalGasUsageToday: number
}


export interface ICustomerGoals {
  annualEmissionsTons: number
  annualElectricityCost: number
  targetYear: number
}


export interface ICustomerPerformanceSummary {
  annualEmissionsTons: number
  annualElectricityCost: number
}


export interface IBuildingData {
  id: string;
  name: string;
  annualCarbonIntensity: number; // Total CO2 per square foot
  realtimeCarbon: number;
  realtimeKwh: number;
  realtimeGas: number;
  ranking: string;
  annualKwh: number;
  annualCosts: number;
}


export enum TimeResolution {
  MINUTES_15 = '15m',
  HOUR = '1h',
  DAY = '1d',
  MONTH = '1M',
  YEAR = '1Y'
}


// An unfortunate side effect of our camelCase API transformation is that the
// FORECAST_SCENARIO_NAMES become FORECASTSCENARIONAMES.
export enum ProjectionScenario {
  HIGH_RENEWABLE_COST = 'HIGHRENEWABLECOST',
  MID_RENEWABLE_COST = 'MIDRENEWABLECOST',
  LOW_RENEWABLE_COST = 'LOWRENEWABLECOST'
}


// Should have size 12 x 24
export type MonthHourArray = [][]
export type MonthHourArrayByYear = { [year: string | number]: MonthHourArray }

export interface IProjectionMetrics {
  startDate: string
  resolution: TimeResolution

  meanConsumedKw: number
  sumConsumedKwh: number
  minConsumedKw: number
  maxConsumedKw: number
  ciLbsCo2PerMwh: number
  grossEmissionsLbsCo2: number
  netEmissionsLbsCo2: number

  grossEnergyCost: number     // Cost of purchased energy.
  interventionCapex: number   // Cost of physical interventions (e.g installing rooftop solar).
  interventionOpex: number    // Cost of other interventions (e.g buying carbon credits).
}


export interface IProjectionSeries {
  baseline: IProjectionMetrics[]
  planned: IProjectionMetrics[]
  impact: IProjectionMetrics[]
}


export interface ICustomerLoadProfileSum {
  utcOffsetMins: number       // Negative means west of UTC.

  baseline: MonthHourArrayByYear    // a 12x24 array
  planned: MonthHourArrayByYear     // a 12x24 array
}


export interface ICustomerProjectionResponse {
  projections: { [scenario: string]: IProjectionSeries }

  totalImpact: { [scenario: string]: IProjectionMetrics }

  loadProfileForecast: ICustomerLoadProfileSum

  carbonIntensityForecast: { [scenario: string]: MonthHourArrayByYear }

  emissionForecast: { [scenario: string]: MonthHourArrayByYear }

  meta: {
    loadProfileUtcOffsetMins: number
    carbonIntensityUtcOffsetMins: number
  }
}


// This should be kept up to date with projections/types.py.
export enum CustomerInterventionType {
  ROOFTOP_SOLAR = 'rooftop_solar',
  GREEN_POWER_PURCHASE = 'green_power_purchase',
  GENERIC_EFFICIENCY_UPGRADE = 'generic_efficiency_upgrade',
  CARBON_CREDIT_PURCHASE = 'carbon_credit_purchase',
  ENERGY_STORAGE = 'energy_storage',
  XCEL_LIGHTING_UPGRADE_REBATE = 'xcel/lighting_upgrade_rebate',
}


export enum CustomerInterventionStatus {
  PLANNED = 'planned',
  SUGGESTED = 'suggested',
  IMPLEMENTED = 'implemented'
}


export interface IGridStatus {
  startDate: string
  generatedCarbonIntensityLbsCo2PerMwh: number
  generatedFuelMix: { [fuelCategory: string]: number }
}

export interface IPagination {
  first: number,
  prev?: number,
  this: number,
  next?: number,
  last: number,
  total: number,      // total items
  totalItems: number, // user-auth uses total_items instead of total
}

export interface IPaginatedResponse<T> {
  data: T[];
  meta: {
    pagination: IPagination;
  }
}

