import { RootState } from 'modules/store';
import { XCEL_CUSTOMER_IDS } from 'config/resources';
import { DashboardConfigPages } from 'api/types';


interface IPagesConfig {
  availablePages: DashboardConfigPages[];
}

const root = (state: RootState) => state.auth;
export const getCurrentUser = (state: RootState) => root(state).currentUser;
export const getCurrentCustomer = (state: RootState) => root(state).currentCustomer || {};
export const getBootstrapDone = (state: RootState) => root(state).bootstrapDone;

export const getCustomerName = (state: RootState) => getCurrentCustomer(state).name;
const getCustomerId = (state: RootState) => getCurrentCustomer(state).id;
const getCustomerAttributes = (state: RootState) => getCurrentCustomer(state).attributes || [];
const getCustomerAttribute = (state: RootState, attrName: string): {name: string, namespace: string, value: boolean | Record<string, unknown> | number | string} | undefined => getCustomerAttributes(state).find(d => d.name === attrName);
export const getIsDemoCustomer = (state: RootState) => (getCustomerAttribute(state, 'is_demo_customer') || {}).value || false;
export const getIsNotDemoCustomer = (state: RootState) => !getIsDemoCustomer(state);
export const getHasLiveData = (state: RootState) => (getCustomerAttribute(state, 'has_live_data') || {}).value || false;
export const getPhysicalUtcOffsetMins = (state: RootState) => (getCustomerAttribute(state, 'physical_utc_offset_mins') || {}).value as number || 0;
export const getPagesConfig = (state: RootState) => (((getCustomerAttribute(state, 'dashboard_config.pages') || {}).value as unknown) || {availablePages: [DashboardConfigPages.EXECUTIVE_VIEW]}) as IPagesConfig;
export const getIsXcel = (state: RootState) => XCEL_CUSTOMER_IDS.includes(getCustomerId(state));
