import React from 'react';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import SvgIcon from '@mui/material/SvgIcon';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';

import { TimeResolution } from 'api/types';
import { useGetHistoricalSeriesQuery } from 'api/historical';

import './style.css';


interface ITableRow {
  id: string,
  name: string,
  color: string,
  startDate: Date | null,
  emissionReductionTons: number,
  loading: boolean,
}


const ProjectTable = (props: { rows: ITableRow[] }) => {
  return (
    <TableContainer>
      <Table sx={{"& th, & td": {fontSize: 16}}}>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell align="right">Impact</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" size='small'>
                {
                  row.loading
                    ? <Skeleton/>
                    : <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                        <SvgIcon sx={{width: '16px', p: 1, pr: 2}}>
                          <svg id={`${row.name}-circle-icon`} viewBox="0 0 100 100" fill={row.color}>
                            <circle cx="50" cy="50" r="50"/>
                          </svg>
                        </SvgIcon>
                        {row.name}
                      </Box>
                }
              </TableCell>
              <TableCell>
              {
                row.loading
                  ? <Skeleton/>
                  : row.startDate?.toLocaleDateString('default', { month: 'short', year: 'numeric' }) || 'Unknown'
              }
              </TableCell>
              <TableCell align="right">
              {
                row.loading
                  ? <Skeleton/>
                  : row.emissionReductionTons.toLocaleString('default', { maximumFractionDigits: 0 }) + ' tons CO₂'
              }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const placeholderRows = (n: number) => {
  let rows = [];
  for (let i = 0; i < n; ++i) {
    rows.push({
      id: `loading-${i}`,
      name: `loading-${i}`,
      color: '#e6e6e6',
      startDate: new Date(),
      emissionReductionTons: 0,
      loading: true,
    });
  }
  return rows;
}


// For XCEL, we only have projects of one category. So we should break down
// emission reductions by project name instead of category.
const GarysSpecialCumulativeCarbonReducedCard = () => {
  const lookbackHorizonYears = 10;

  // NOTE(milo): We need to fetch a DAILY historical series here, because
  // monthly events are extremely sparse due to data holes and will miss a
  // significant amount of solar generation.
  const { data, isFetching, isLoading, isError } = useGetHistoricalSeriesQuery(
    { lookbackHorizon: lookbackHorizonYears, resolution: TimeResolution.DAY });

  const impactByIntervention = data?.data?.interventions || {};
  const tonsCo2ByCategory: {[category: string]: number} = {};
  const colorOrder = ['#4169E1', '#3DE397', '#B2D3C2', '#028A0F', '#99EDC3', '#0ABAB5']

  let totalEmissionReductionTonsCo2 = 0;
  // The earliest date of a recorded project in each category.
  let startDateByCategory: {[c: string]: Date} = {};
  Object.entries(impactByIntervention).map((value) => {
    const interventionData = value[1];
    const emissionReductionTonsCo2 = interventionData.emissionReductionLbsCo2 / 2000;

    // NOTE(milo): Just use the intervention name and don't map to a category.
    const category = interventionData.name;

    if (!tonsCo2ByCategory[category]) {
      tonsCo2ByCategory[category] = 0;
    }
    tonsCo2ByCategory[category] += emissionReductionTonsCo2;
    totalEmissionReductionTonsCo2 += emissionReductionTonsCo2;

    if (interventionData.startDate) {
      const dt = new Date(interventionData.startDate);
      if (!startDateByCategory[category]) {
        startDateByCategory[category] = dt;
      }
      startDateByCategory[category] = dt < startDateByCategory[category] ? dt : startDateByCategory[category];
    }
  });

  // If no projects were retrieved, don't show this card.
  if (!(isLoading || isFetching) && Object.keys(impactByIntervention).length === 0) {
    return null;
  }

  const makePieChartData = () => {
    return [{
      name: 'Emission reductions',
      type: 'pie',
      colorByPoint: true,
      data: Object.entries(tonsCo2ByCategory).map((value, i) => {
        const categoryName = value[0];
        const emissionReductionTonsCo2 = value[1];
        return {
          name: categoryName,
          y: emissionReductionTonsCo2,
          color: colorOrder[i % colorOrder.length] || '#000000'
        }
      })
    }];
  }

  let rows = placeholderRows(4);
  if (!isFetching && data) {
    rows = Object.entries(tonsCo2ByCategory).map((value, i) => {
      const categoryName = value[0];
      const emissionReductionTonsCo2 = value[1];
      return {
        id: categoryName,
        name: categoryName,
        color: colorOrder[i % colorOrder.length] || '#000000',
        startDate: startDateByCategory[categoryName] || null,
        emissionReductionTons: emissionReductionTonsCo2,
        loading: false,
      }
    });
  }

  // Sort the table in descending order of impact.
  rows.sort((a, b) => b.emissionReductionTons - a.emissionReductionTons);

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Cumulative emission reductions'}/>}
      />
      <CardContent>
      <Alert severity='success'>Nice work! You've reduced your cumulative emissions by <strong>{totalEmissionReductionTonsCo2.toLocaleString('default', { maximumFractionDigits: 0 })} tons CO₂</strong> compared to the baseline.</Alert>
        <Grid container>
          {/* Left side */}
          <Grid item xs={4}>
            <Box display='flex' flexDirection='column' alignItems='center' justifyItems='center' sx={{p: 2}}>
              <ChartBase
                loading={isFetching}
                animated={true}
                chartHeight={240}
                chartContainerId={'cumulative-carbon-pie-chart'}
                chartData={makePieChartData()}
                overrideOptions={{
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      innerSize: '50%',
                      size: '100%',
                      dataLabels: {
                        enabled: false,
                      },
                      tooltip: {
                        valueSuffix: ' tons CO₂',
                        valueDecimals: 0
                      }
                    }
                  }
                }}
              />
            </Box>
          </Grid>
          {/* Right side */}
          <Grid item xs={8} sx={{ p: 2 }}>
            <CardContent sx={{p: 3}}>
              <ProjectTable rows={rows}/>
            </CardContent>
          </Grid>
        </Grid>
      </CardContent>
    </CardBase>
  );
}


export default GarysSpecialCumulativeCarbonReducedCard;
