import React from 'react';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import SvgIcon from '@mui/material/SvgIcon';
import Skeleton from '@mui/material/Skeleton';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';
import { sum } from 'utils/math';
import { ICategoryEmissionReduction } from 'api/types';
import { useGetCumulativeEmissionReductionsQuery, useGetHistoricalInterventionImpactQuery } from 'api/historical';

import './style.css';


interface IEmissionReductionRow {
  id: string,
  name: string,
  color: string,
  emissionReductionTons: number,
  loading: boolean,
}


interface IProjectTableProps {
  rows: IEmissionReductionRow[];
}


const ProjectTable = (props: IProjectTableProps) => {
  return (
    <TableContainer>
      <Table sx={{"& th, & td": {fontSize: 16}}}>
        <TableHead>
          <TableRow>
            <TableCell>Action</TableCell>
            <TableCell align="right">Impact</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" size='small'>
                {row.loading ? <Skeleton/> :
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                  <SvgIcon sx={{width: '16px', p: 1, pr: 2}}>
                    <svg id={`${row.name}-circle-icon`} viewBox="0 0 100 100" fill={row.color}>
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                  </SvgIcon>
                  {row.name}
                </Box>}
              </TableCell>
              <TableCell align="right">{row.loading
                ? <Skeleton/>
                : row.emissionReductionTons.toLocaleString('default', { maximumFractionDigits: 0 }) + ' tons CO₂'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const SummaryNumber = (props: { totalEmissionReductionTons: number, loading: boolean }) => {
  if (props.loading) {
    return <Skeleton variant='text' sx={{mt: 2}} height={45} width={'100%'}/>;
  } else {
    return (
      <Typography sx={{fontSize: '30px'}}>
        {props.totalEmissionReductionTons.toLocaleString('default', { maximumFractionDigits: 0 })}
        <Typography variant='seMutedUnitsText'> tons CO₂</Typography>
      </Typography>
    );
  }
}

const placeholderRows = (n: number) => {
  let rows = [];
  for (let i = 0; i < n; ++i) {
    rows.push({
      id: `loading-${i}`,
      name: `loading-${i}`,
      color: '#e6e6e6',
      emissionReductionTons: 0,
      loading: true,
    });
  }
  return rows;
}


const CumulativeCarbonReducedCard = () => {
  const { data, isFetching } = useGetCumulativeEmissionReductionsQuery();
  const { data: projectData, isFetching: fetchingProjects } = useGetHistoricalInterventionImpactQuery();
  const chartData = data ? data.data : [];

  if (!fetchingProjects && projectData && projectData.data.length === 0) {
    return null;
  }

  const makePieChartData = () => {
    return [{
      name: 'Emission reductions',
      type: 'pie',
      colorByPoint: true,
      data: chartData.map((value: ICategoryEmissionReduction) => {
        return {
          name: value.readableName,
          y: -1 * value.totalEmissionReductionTons,
          color: value.color
        }
      })
    }];
  }

  const totalEmissionReductionTons = sum(chartData.map(v => v.totalEmissionReductionTons));

  let rows = placeholderRows(4);
  if (!isFetching && data) {
    rows = chartData.map((value) => {
      return {
        id: value.id,
        name: value.readableName,
        color: value.color,
        emissionReductionTons: value.totalEmissionReductionTons !== 0 ? -1 * value.totalEmissionReductionTons: 0,
        loading: false,
      }
    });
  }

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Cumulative emission reductions'}/>}
      />
      <Grid container>
        {/* Left side */}
        <Grid item xs={4}>
          <Box display='flex' flexDirection='column' alignItems='center' justifyItems='center' sx={{p: 2}}>
            <ChartBase
              loading={isFetching}
              animated={true}
              chartHeight={240}
              chartContainerId={'cumulative-carbon--pie-chart'}
              chartData={makePieChartData()}
              overrideOptions={{
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    innerSize: '50%',
                    size: '100%',
                    dataLabels: {
                      enabled: false,
                    },
                    tooltip: {
                      valueSuffix: ' tons CO₂',
                      valueDecimals: 0
                    }
                  }
                }
              }}
            />
            <SummaryNumber loading={isFetching} totalEmissionReductionTons={-1 * Math.round(totalEmissionReductionTons)}/>
          </Box>
        </Grid>
        {/* Right side */}
        <Grid item xs={8} sx={{ p: 2 }}>
          <CardContent sx={{p: 3}}>
            <ProjectTable rows={rows}/>
          </CardContent>
        </Grid>
      </Grid>
    </CardBase>
  );
}


export default CumulativeCarbonReducedCard;
