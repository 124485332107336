import React, { useRef, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import CardBase from 'components/CardBase';
import NumberField from 'components/NumberField';
import ModalBase, { IModalBaseProps } from 'components/ModalBase';

import { ICustomerIntervention, ICustomerInterventionParam } from 'api/types';


const NEXT_CALENDAR_YEAR = new Date().getUTCFullYear() + 1;
const DEFAULT_START_DATE = new Date(`${NEXT_CALENDAR_YEAR}-01-01T00:00:00.000`);


interface IEditParamsModalProps {
  // The project information to display. Should have a `params` field.
  interventionDetails: ICustomerIntervention

  // Gets called when the user clicks 'Update' on the modal.
  updateParamsCallback?: (updatedParams?: {[paramId: string]: ICustomerInterventionParam},
                          startDateYear?: number) => void;
}


const EditParamsModal = (props: IModalBaseProps & IEditParamsModalProps) => {
  const modalRef = useRef<ModalBase>(null);

  // Store a temporary set of params in the modal that the user can edit.
  const [editedParams, setEditedParams] = useState(structuredClone(props.interventionDetails.params));
  const [editedStartDate, setEditedStartDate] = useState(
    structuredClone(new Date(props.interventionDetails.startDate).getFullYear() || DEFAULT_START_DATE.getFullYear()));

  // Get all the parameters with the `user_can_modify` flag.
  const editableParameters = Object.entries(props.interventionDetails.params)
    .filter((value: [string, ICustomerInterventionParam]) => value[1].userCanModify === true);

  // Check if every user input is a valid number.
  const validateParamValues = () => {
    return editableParameters.every((value) => {
      const currentValue = editedParams[value[0]].value;
      return currentValue <= value[1].maxValue && currentValue >= value[1].minValue;
    });
  }

  const makeParamField = (paramId: string,
                          name: string,
                          getValue: () => number,
                          setValue: (v: number) => void,
                          defaultValue: number,
                          minValue: number,
                          maxValue: number,
                          units: string,
                          customFormatNumber?: (n: number) => string,
                          decimals: number = 0,
                          isDate: boolean = false) => {
    const userValue = getValue();

    const nicelyFormatNumber = customFormatNumber ? customFormatNumber : (n: number) => {
      return n.toLocaleString('default', { maximumFractionDigits: decimals || 0 });
    }

    let helperText = '';
    if (userValue > maxValue) {
      helperText = `Value must be <= ${nicelyFormatNumber(maxValue)}`;
    } else if (userValue < minValue) {
      helperText = `Value must be >= ${nicelyFormatNumber(minValue)}`;
    }

    return (
      <Grid item xs={12} key={paramId}>
        <Box sx={{display: 'flex', flexDirection: 'column', p: 2}}>
          <Typography sx={{mb: 2, ml: 0}}>{name}</Typography>
          <NumberField
            id={`parameter-input-${paramId}`}
            units={units}
            value={userValue}
            onValueChanged={setValue}
            error={helperText.length > 0}
            helperText={helperText}
            isDate={isDate}
          />
          <Typography variant='seSmallMutedText'>Set to the
            <Button size="small" sx={{minWidth: 30}}
              onClick={() => {
                setValue(defaultValue);
              }}>default value</Button>,
            <Button size="small" sx={{minWidth: 30}}
              onClick={() => {
                setValue(minValue);
              }}>minimum value</Button>,
            <Button size="small" sx={{minWidth: 30}}
              onClick={() => {
                setValue(maxValue);
              }}>maximum value</Button>
          </Typography>
        </Box>
      </Grid>
    );
  }

  const startDateInput = makeParamField(
      'startDate',
      'Start date (year)',
      () => editedStartDate,
      setEditedStartDate,
      DEFAULT_START_DATE.getFullYear(),
      DEFAULT_START_DATE.getFullYear(),
      2040,
      '',
      (year: number) => year.toString(),
      0,
      true
  );

  const parameterInputs = editableParameters.map((value: [string, ICustomerInterventionParam]) => {
    const paramId = value[0];
    const paramInfo = value[1];
    const defaultValue = props.interventionDetails.params[paramId].value;

    // For percentages, we store numbers between 0-1 in the backend, but show
    // them here scaled by 100.
    const numberScaleFactor = paramInfo.units === '%' ? 100 : 1;

    return makeParamField(
      paramId,
      paramInfo.name,
      () => {
        const value = editedParams[paramId].value || 0;
        return numberScaleFactor * value;
      },
      (value: number) => {
        let p = structuredClone(editedParams);
        p[paramId].value = value / numberScaleFactor;
        setEditedParams(p);
      },
      defaultValue * numberScaleFactor,
      paramInfo.minValue * numberScaleFactor,
      paramInfo.maxValue * numberScaleFactor,
      paramInfo.units,
    );
  });

  const paramValuesAreValid = validateParamValues();

  function closeAndUpdateParams() {
    if (!validateParamValues()) {
      modalRef.current.close();
    }

    if (props.updateParamsCallback) {
      props.updateParamsCallback(editedParams, editedStartDate);
      modalRef.current.close();
    }
  }

  return (
    <ModalBase {...props} ref={modalRef}>
      <CardBase width={12}>
        <CardHeader
          title={<Typography gutterBottom variant="seCardTitleText" component="div">{props.interventionDetails.name || 'Project details'}</Typography>}
          action={
            <>
              <Button variant="outlined" startIcon={<CloseIcon/>} sx={{mr: 2}} onClick={() => modalRef.current.close()}>
                Close
              </Button>
              <Button
                variant="contained"
                startIcon={<ShowChartIcon/>}
                disabled={!paramValuesAreValid}
                onClick={paramValuesAreValid ? closeAndUpdateParams : undefined}
              >
                Update
              </Button>
            </>
          }
        />
        <CardContent>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
            <InfoIcon sx={{mr: 2}}/>
            <Typography sx={{mb: 2}}>
              {props.interventionDetails.description || 'This project has no description.'}
            </Typography>
          </Box>
          <Divider orientation="horizontal" flexItem style={{marginBottom: 16}}/>
          <Typography>
            <b>Project Parameters</b>
          </Typography>
          <Grid container>
            {startDateInput}
            {parameterInputs}
          </Grid>
        </CardContent>
      </CardBase>
    </ModalBase>
  );
}


export default EditParamsModal;
