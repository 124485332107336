import React, { useState } from 'react';
import produce from 'immer';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import MuiTooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';

import CardBase from 'components/CardBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import BasicDropdown from 'components/BasicDropdown';
import ChartBase from 'components/ChartBase';
import VerticalDivider from 'components/VerticalDivider';
import CardTitle from 'components/CardTitle';

import { useGetHistoricalSeriesQuery } from 'api/historical';
import { useSearchCustomerInterventionsQuery } from 'api/interventions';

import { ICustomerIntervention, TimeResolution } from 'api/types';

import makeLinearGradient from 'utils/chartGradient';

import Theme from 'config/Theme';
import './style.css';


enum CardMode {
  Emissions = 'Emissions',
  Usage = 'Usage',
  Intensity = 'Intensity'
}


const TWELVE_HOURS_IN_MS = 1000 * 60 * 60 * 12;

type ChartConfigKey = 'showGrossFootprint' | 'showNetFootprint' |
                      'showUtilitySpecificFootprint' | 'showCompanyGoal' |
                      'showBusinessAsUsual';


const HistoricalChartCardV2 = () => {
  const [mode, setMode] = useState(CardMode.Emissions);
  const [resolution, setResolution] = useState(TimeResolution.MONTH);
  const [lookbackPeriod, setLookbackPeriod] = useState('ALL');
  const [chartConfig, setChartConfig] = useState({
    showGrossFootprint: true,
    showNetFootprint: true,
    showUtilitySpecificFootprint: false,
    showCompanyGoal: true,
    showBusinessAsUsual: true
  });

  const lookbackHorizonYears = {
    'ALL': 10,
    '1Y': 1,
    '3Y': 3,
    '5Y': 5,
  }[lookbackPeriod];

  const { data, isFetching, isLoading, isError } = useGetHistoricalSeriesQuery(
    { lookbackHorizon: lookbackHorizonYears, resolution: resolution });

  const historicalInterventionsApi = useSearchCustomerInterventionsQuery({
    status: ['implemented', 'planned', 'suggested'],
    calculateImpact: false
  });

  const checkboxFactory = (
    stateKey: ChartConfigKey,
    label: string,
    hoverText?: string,
    muiColorName?: string
  ) => {
    return (
      <MuiTooltip title={hoverText || 'Click to toggle this series on the chart.'}>
        <FormControlLabel
          control={
            <Checkbox
              checked={chartConfig[stateKey]}
              onChange={(event: any) => {
                // Use immer to apply a partial update to the full state.
                setChartConfig(produce(chartConfig, draftState => {
                  draftState[stateKey] = event.target.checked;
                }));
              }}
              name={stateKey}
              color={muiColorName || 'primary'}
            />
          }
          label={<Typography className={'se--item-has-hover-text'}>{label}</Typography>}
        />
      </MuiTooltip>
    )
  }

  const makeChartData = () => {
    if (!data?.data) {
      return [];
    }

    const opacityHex = '30';

    const tooltipOptions = {
      [CardMode.Emissions]: {
        valueSuffix: ' tons CO₂'
      },
      [CardMode.Usage]: {
        valueSuffix: ' MWh'
      },
      [CardMode.Intensity]: {
        valueSuffix: ' lbs CO₂ per MWh'
      }
    }[mode];

    if (mode == CardMode.Emissions || mode == CardMode.Usage) {
      const chartData = {
        [CardMode.Usage]: data.data.electricityUsage,
        [CardMode.Emissions]: data.data.emissions,
      }[mode as CardMode.Emissions | CardMode.Usage];

      const extractValue = (d: { startDate: string, sumConsumedKwh?: number, lbsCo2?: number }) => {
        return (mode === CardMode.Emissions) ? d.lbsCo2 / 2000 : d.sumConsumedKwh * 1e-3;
      }

      return [
        {
          name: 'Business as usual',
          visible: chartConfig.showBusinessAsUsual,
          type: 'areaspline',
          color: Theme.palette.chartPurpleColor.main,
          fillColor: makeLinearGradient(Theme.palette.chartPurpleColor.main, opacityHex),
          data: chartData.baseline.map((d) => (
            { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: extractValue(d) }
          )),
          tooltip: tooltipOptions
        },
        {
          name: 'Gross footprint',
          visible: chartConfig.showGrossFootprint,
          type: 'areaspline',
          color: Theme.palette.chartRedColor.main,
          fillColor: makeLinearGradient(Theme.palette.chartRedColor.main, opacityHex),
          data: chartData.gross.map((d) => {
            return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: extractValue(d) };
          }),
          tooltip: tooltipOptions
        },
        {
          name: 'Net footprint',
          visible: chartConfig.showNetFootprint,
          type: 'areaspline',
          color: Theme.palette.chartTealColor.main,
          fillColor: makeLinearGradient(Theme.palette.chartTealColor.main, opacityHex),
          data: chartData.net.map((d) => {
            return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: extractValue(d) };
          }),
          tooltip: tooltipOptions
        },
        {
          name: 'Utility-specific footprint',
          visible: chartConfig.showUtilitySpecificFootprint,
          type: 'areaspline',
          color: Theme.palette.chartOrangeColor.main,
          fillColor: makeLinearGradient(Theme.palette.chartOrangeColor.main, opacityHex),
          data: chartData.utility.map((d) => {
            return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: extractValue(d) };
          }),
          tooltip: tooltipOptions
        },
      ];

    // For carbon intensity data, we show a different set of series.
    } else {
      const chartData = data.data.carbonIntensity;

      return [
        {
          name: 'Grid region',
          visible: true,
          type: 'areaspline',
          color: Theme.palette.chartDarkBlueColor.main,
          fillColor: makeLinearGradient(Theme.palette.chartDarkBlueColor.main, opacityHex),
          data: chartData.isoRegion.map((d) => {
            return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: d.lbsCo2PerMwh };
          }),
          tooltip: tooltipOptions
        },
        {
          name: 'Utility-specific',
          visible: chartConfig.showUtilitySpecificFootprint,
          type: 'areaspline',
          color: Theme.palette.chartDarkBlueColor.main,
          fillColor: makeLinearGradient(Theme.palette.chartDarkBlueColor.main, opacityHex),
          data: chartData.utilityRegion.map((d) => {
            return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: d.lbsCo2PerMwh };
          }),
          tooltip: tooltipOptions
        },
      ]
    }
  }

  const makeInterventionLines = () => {
    if (!historicalInterventionsApi.data) {
      return [];
    }

    const pastInterventions = historicalInterventionsApi.data.data;

    return pastInterventions.map((value: ICustomerIntervention) => {
      // Get rid of parenthetical information since it clutters the graph.
      const readableName = value.name.split('(')[0] || value.name;
      const start = new Date(value.startDate);
      start.setDate(1);
      start.setHours(0, 0, 0, 0);
      return {
        color: '#348D48',
        width: 2,
        value: start.valueOf(),
        dashStyle: 'ShortDash',
        label: {
          // Need to add a white background so that the text shows up on top of the plot lines.
          text: `<p style="height: 20px; background-color: white;">${readableName}</p>`,
          align: 'top',
          rotation: 0,
          textAlign: 'center',
          useHTML: true,
        }
      }
    });
  }

  const chartRef = React.createRef<ChartBase>();  // Used for exporting.

  const perWhatTimeInterval = {
    [TimeResolution.DAY]: 'per day',
    [TimeResolution.MONTH]: 'per month',
    [TimeResolution.YEAR]: 'per year'
  }[resolution as TimeResolution.DAY | TimeResolution.MONTH | TimeResolution.YEAR];

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [CardMode.Emissions]: 'tons of CO₂ ' + perWhatTimeInterval,
    [CardMode.Usage]: 'MWh ' + perWhatTimeInterval,
    [CardMode.Intensity]: 'lbs CO₂ per MWh'
  }[mode];

  return (
    <CardBase width={12}>
      <Grid container>
        {/* Left side */}
        <Grid item xs={9}>
          <CardHeader
            title={<CardTitle title={'Performance over time'}/>}
            action={
              <div>
                <CardRadioButtonGroup
                  mode={mode}
                  mapModeToLabel={{
                    [CardMode.Emissions]: 'Emissions',
                    [CardMode.Usage]: 'Grid Use',
                    [CardMode.Intensity]: 'Carbon Intensity'
                  }}
                  setModeCallback={(value) => setMode(value as CardMode)}
                />
                <BasicDropdown
                  id={'time-resolution-dropdown'}
                  value={resolution}
                  mapValueToLabel={{
                    // [TimeResolution.YEAR]: 'Yearly',
                    [TimeResolution.MONTH]: 'Monthly',
                    [TimeResolution.DAY]: 'Daily',
                  }}
                  updateCallback={(value: TimeResolution) => setResolution(value as TimeResolution)}
                  formControlSx={{mt: 1, mr: 2, ml: 1}}
                />
                <BasicDropdown
                  id={'lookback-period-dropdown'}
                  value={lookbackPeriod}
                  mapValueToLabel={{
                    'ALL': 'All time',
                    '1Y': '1 year',
                    '3Y': '3 years',
                    '5Y': '5 years'
                  }}
                  updateCallback={setLookbackPeriod}
                  formControlSx={{mt: 1, mr: 2}}
                />
              </div>
            }
          />
          <CardContent>
            <ChartBase
              ref={chartRef}
              loading={isFetching || isLoading}
              animated={true}
              chartHeight={300}
              chartContainerId={'historical-performance-chart'}
              chartData={makeChartData()}
              dateResolution={'month'}
              downloadFilename={'historical_performance'}
              chartAxisLabelY1={chartAxisLabelY1}
              overrideOptions={{
                legend: {
                  enabled: false
                },
                plotOptions: {
                  column: {
                    groupPadding: 0.2,
                    pointPadding: 0,
                  }
                },
                xAxis: {
                  plotLines: makeInterventionLines(),
                },
              }}
            />
          </CardContent>
        </Grid>

        <VerticalDivider/>

        {/* Right side */}
        <Grid item xs={3} sx={{p: 2}}>
          <Box>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend" disabled={true}>Chart Legend</FormLabel>
              <FormGroup>
                {checkboxFactory('showBusinessAsUsual', 'Business as usual', '', 'chartPurpleColor')}
                {checkboxFactory('showGrossFootprint', 'Gross footprint', 'This is your physical consumption of carbon, costs, or energy.', 'chartRedColor')}
                {checkboxFactory('showUtilitySpecificFootprint', 'Utility-specific footprint', 'This footprint includes actions that the utility takes on your behalf, such as purchasing PPAs or RECs.', 'chartOrangeColor')}
                {checkboxFactory('showNetFootprint', 'Net footprint', 'This footprint includes action that you\'ve taken to reduce usage.', 'chartTealColor')}
              </FormGroup>
              <Box display={'flex'} sx={{pt: 4}}>
                <SvgIcon sx={{width: '16px', padding: '2px'}}>
                  <svg width="27" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line strokeDasharray={"5, 5"} x1="0" y1="10" x2="350" y2="10"></line>
                  </svg>
                </SvgIcon>
                <Typography sx={{paddingLeft: '12px'}}>Project implementation</Typography>
              </Box>
              <Button
                variant="outlined"
                color="neutral"
                sx={{width: '80px', mt: 2}}
                onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() } }
              >Export
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </CardBase>
  );
}


export default HistoricalChartCardV2;
