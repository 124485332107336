import React from 'react';

import { IHistoricalEmissionsData, IMockHistoricalChartData } from 'api/types';
import { useGetCustomerGoalsQuery, useGetEmissionsSummaryQuery } from 'api/summary';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';


import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';

import { makeTodayPlotLine } from 'utils/chartAnnotations';
import AssumptionsModal from './AssumptionsModal';
import GoalSettingModal from './GoalSettingModalV1';

import Theme from 'config/Theme';
import { linearInterpolate } from 'utils/math';
import makeLinearGradient from 'utils/chartGradient';

import './style.css';


export const EmissionsChartCardV1 = () => {
  const goalsApi = useGetCustomerGoalsQuery();
  const goals = goalsApi.data?.data || null;

  const { data, isFetching } = useGetEmissionsSummaryQuery(
    { targetYear: goals ? goals.targetYear : 2040 },
    { skip: !goalsApi.data});

  const todayEpoch = Date.now();
  const chartRef = React.createRef<ChartBase>();

  const chartData: IHistoricalEmissionsData = data?.data || { historicalBaseline: [], historicalActual: [] };

  const makeChartData = () => {
    if (chartData.historicalActual.length === 0 ||
        chartData.historicalBaseline.length === 0 ||
        !goals) {
      return [];
    }

    const opacityHex = '30';
    const earliestDate = new Date(chartData?.historicalBaseline?.at(0).startDate).valueOf();
    const latestDate = new Date(chartData?.historicalBaseline?.at(-1).startDate).valueOf();

    const indexEndOfYear = chartData.historicalActual.findIndex(v => new Date(v.startDate).valueOf() > todayEpoch);
    const indexStartOfYear = indexEndOfYear - 1;
    const epochEndOfYear = new Date(chartData.historicalActual.at(indexEndOfYear).startDate).valueOf();
    const epochStartOfYear = new Date(chartData.historicalActual.at(indexStartOfYear).startDate).valueOf();

    const baselineToday = linearInterpolate(
      todayEpoch, epochStartOfYear, epochEndOfYear,
      chartData.historicalBaseline.at(indexStartOfYear).totalEmissionsTonsCo2,
      chartData.historicalBaseline.at(indexEndOfYear).totalEmissionsTonsCo2);

    const actualToday = linearInterpolate(
      todayEpoch, epochStartOfYear, epochEndOfYear,
      chartData.historicalActual.at(indexStartOfYear).totalEmissionsTonsCo2,
      chartData.historicalActual.at(indexEndOfYear).totalEmissionsTonsCo2);

    return [
      {
        name: 'Historical baseline emissions',
        type: 'areaspline',
        color: Theme.palette.chartOrangeColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartOrangeColor.main, opacityHex),
        data: [
          ...chartData.historicalBaseline
            .filter((d: IMockHistoricalChartData) => !d.forecast)
            .map((d: IMockHistoricalChartData) => {
                return { x: new Date(d.startDate).valueOf(), y: d.totalEmissionsTonsCo2 }
            }),
          { x: todayEpoch, y: baselineToday }
        ]
      },
      {
        name: 'Historical actual emissions',
        type: 'areaspline',
        color: Theme.palette.chartBlueColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
        data: [
          ...chartData.historicalActual
            .filter((d: IMockHistoricalChartData) => !d.forecast)
            .map((d: IMockHistoricalChartData) => {
                return { x: new Date(d.startDate).valueOf(), y: d.totalEmissionsTonsCo2 };
            }),
          { x: todayEpoch, y: actualToday }
        ]
      },
      {
        name: 'Projected baseline emissions',
        type: 'areaspline',
        color: Theme.palette.chartOrangeColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartOrangeColor.main, opacityHex),
        // https://api.highcharts.com/highcharts/series.line.dashStyle
        dashStyle: 'LongDash',
        data: [
          { x: todayEpoch, y: baselineToday },
          ...chartData.historicalBaseline
            .filter((d: IMockHistoricalChartData) => d.forecast)
            .map((d: IMockHistoricalChartData) => {
                return { x: new Date(d.startDate).valueOf(), y: d.totalEmissionsTonsCo2 };
          })
        ]
      },
      {
        name: 'Projected actual emissions',
        type: 'areaspline',
        color: Theme.palette.chartBlueColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
        // https://api.highcharts.com/highcharts/series.line.dashStyle
        dashStyle: 'LongDash',
        data: [
          { x: todayEpoch, y: actualToday },
          ...chartData.historicalActual
            .filter((d: IMockHistoricalChartData) => d.forecast)
            .map((d: IMockHistoricalChartData) => {
                return { x: new Date(d.startDate).valueOf(), y: d.totalEmissionsTonsCo2 };
          })
        ]
      },
      {
        name: 'Company goal',
        type: 'spline',
        color: Theme.palette.chartTealColor.main,
        data: [
          { x: earliestDate, y: chartData.historicalActual[0].totalEmissionsTonsCo2 },
          { x: latestDate, y: goals?.annualEmissionsTons || (chartData.historicalActual[0].totalEmissionsTonsCo2 * 0.8) },
        ],
      },
    ];
  }

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Your emissions over time'}/>}
        action={
          <div>
           <Button
            variant="text"
            color="primary"
            onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() } }
          >
            Export
          </Button>
         </div>
        }
      />
      <CardContent>
        <ChartBase
          ref={chartRef}
          loading={isFetching}
          animated={true}
          chartHeight={300}
          chartContainerId={'emissions-over-time-card--chart'}
          chartAxisLabelY1={'tons CO₂ / year'}
          dateResolution={'month'}
          chartData={makeChartData()}
          downloadFilename={'emissions_summary'}
          overrideOptions={{
            xAxis: {
              plotLines: [makeTodayPlotLine(todayEpoch)],
            },
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' tons CO₂ / year',
              shared: true
            }
          }}
        />
        <Box>
          <AssumptionsModal openButtonId={'open-modal-button--assumptions'}/>
          <GoalSettingModal openButtonId={'open-modal-button--goals'}/>
          <ButtonGroup variant="text" aria-label="text button group" sx={{display: 'flex', justifyContent: 'end'}}>
            <Button variant='text' id={'open-modal-button--assumptions'}>See assumptions</Button>
            <Button variant='text' id={'open-modal-button--goals'} sx={{ml: 1}}>Edit target</Button>
          </ButtonGroup>
        </Box>
      </CardContent>
    </CardBase>
  );
}


export default EmissionsChartCardV1;
