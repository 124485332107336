import React from 'react';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IBuildingData } from 'api/types';
import * as MockAPI from 'api/mock';
import CardTitle from 'components/CardTitle';
import CardBase from 'components/CardBase';
import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import './style.css';


export default function HistoricalBuildingsCard() {
  const buildings: IBuildingData[] = MockAPI.fakeBuildings();
  const isDemoCustomer = useAppSelector(getIsDemoCustomer);

  if (!isDemoCustomer) return null;

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Building', type: 'string', flex: 1, headerClassName: "se--datagrid-header" },
    { field: 'ranking', headerName: 'City Ranking', type: 'string', flex: 0.7, headerAlign: "right", align: "right", headerClassName: "se--datagrid-header" },
    { field: 'annualCarbonIntensity', headerName: 'CUI (lb CO₂ / sq. ft / year)', type: 'number', flex: 1, headerClassName: "se--datagrid-header" },
    { field: 'annualKwh', headerName: 'Grid Use (kWh)', type: 'number', flex: 0.9, headerClassName: "se--datagrid-header" },
    { field: 'annualCosts', headerName: 'Costs', type: 'number', flex: 0.7, headerClassName: "se--datagrid-header",
      renderCell: params => {
        return `-$${params.row.annualCosts.toLocaleString('default')}`
      }}
  ];

  return (
    <CardBase width={12}>
      <CardHeader
          title={<CardTitle title='Building performance' />}
      />
      <CardContent>
        <DataGrid
          columns={columns}
          rows={[...buildings]}
          autoHeight={true}
          pageSize={5}
          rowsPerPageOptions={[5]}
          sx={{border: 0}}
          getRowClassName={(_params) => 'se--datagrid-row'}
        />
      </CardContent>
    </CardBase>
  );
}
