import { api } from "./base";
import { IPaginatedResponse } from "./types";


export interface IAsset {
  id: string;
  name?: string;
  description?: string;
  meta?: Record<string, unknown>;
  createdAt: string;
  updatedAt: string;
  location: Record<string, unknown>;
  // these should match the values in the Python class shared.constants.TimeResolution
  baseResolution?: '5m' | '15m' | '1h' | '1d' | '1M' | '1Y';
  // these should match the values in the Python class shared.constants.CustomerAssetTypes
  assetType: 'solar_panel' | 'electrical_meter' | 'battery';
}

export interface IPatchAsset {
  name: string | null;
  description: string | null;
  asset_type: 'solar_panel' | 'electrical_meter' | 'battery';
  region: string | null;
  base_resolution: '5m' | '15m' | '1h' | '1d' | '1M' | '1Y';
}

const assetPage = { type: 'Asset' as const, id: 'PARTIAL_LIST'};

export const assetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchAssetsPage: build.query<IPaginatedResponse<IAsset>, {page: number, per_page: number}>({
      query: ({page, per_page}) => {
        const qs = new URLSearchParams({page: page.toString(), per_page: per_page.toString()});
        return {
          url: `/v2/assets?${qs}`,
          method: 'GET'
        }
      },
      providesTags: (result) => [
        assetPage,
        ...result ? result.data.map(asset => ({type: 'Asset' as const, id: asset.id})) : [],
      ],
    }),

    fetchAsset: build.query<{data: IAsset}, {id: string}>({
      query: ({id}) => ({url: `/v2/assets/${id}`, method: 'GET'}),
      providesTags: (result) => result ? [{type: 'Asset' as const, id: result.data.id}]: [],
    }),

    patchAsset: build.mutation<{data: IAsset}, {id: string, payload: IPatchAsset}>({
      query: ({id, payload}) => ({url: `/v2/assets/${id}`, method: 'PATCH', body: payload}),
      invalidatesTags: (res) => [assetPage, {type: 'Asset' as const, id: res.data.id}],
    }),

    createAsset: build.mutation<{data: IAsset}, {payload: IPatchAsset}>({
      query: ({payload}) => ({url: `/v2/assets/`, method: 'POST', body: payload}),
      invalidatesTags: (res) => [assetPage],
    }),
  })
});


export const {
  useFetchAssetsPageQuery,
  useLazyFetchAssetsPageQuery,
  usePrefetch,
  useFetchAssetQuery,
  usePatchAssetMutation,
  useCreateAssetMutation,
} = assetsApi;
