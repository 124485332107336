import React, { useState } from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';
import RealTimeIndicator from 'components/RealTimeIndicator';

import makeIntensityIconFormatter from 'utils/makeIntensityIconFormatter';
import { useGetRealtimeChartDataV2Query } from 'api/realtime';
import { useAppSelector } from 'modules/store';
import { getPhysicalUtcOffsetMins } from 'modules/auth/selectors';
import { mapOffsetToReadableTimezone } from 'utils/timezone';
import { hexToRGB } from 'utils/math';
import { getAxisScaling } from 'utils/dataUtils';

import Theme from 'config/Theme';
import './style.css';


const RealtimeIntensityCard = () => {
  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);
  const timezoneName = mapOffsetToReadableTimezone(physicalUtcOffsetMins);

  const params = { timezone: timezoneName };
  // NOTE(milo): Use this if we want to re-enable auto refresh.
  // const optios = { pollingInterval: 60*1000 }
  const { data, isLoading, isFetching } = useGetRealtimeChartDataV2Query(params);

  const makeChartData = () => {
    if (!data) {
      return [];
    }

    // First get the current date in local time, then set the hours to the end of the day.
    const endOfTodayLocal = new Date(new Date().getTime() + physicalUtcOffsetMins * 60 * 1000);
    endOfTodayLocal.setUTCHours(23, 59, 59, 999);
    const endOfTodayLocalEpoch = endOfTodayLocal.valueOf() - (physicalUtcOffsetMins * 60 * 1e3);

    const pastCarbonIntensity = data.hourlyGridStatus
      .map(d => {
        return { x: new Date(d.startDate).valueOf(), y: d.generatedCarbonIntensityLbsCo2PerMwh };
      })
      .sort((d1, d2) => d1.x - d2.x);

    const futureCarbonIntensity = data.forecastedHourlyGridStatus
      .map(d => {
        return { x: new Date(d.startDate).valueOf(), y: d.generatedCarbonIntensityLbsCo2PerMwh };
      })
      // Make the forecast line connect to the past data line.
      .concat(pastCarbonIntensity.slice(-1))
      .filter(d => d.x < endOfTodayLocalEpoch)
      .sort((d1, d2) => d1.x - d2.x);

    const hourlyUsage = data.hourlyConsumption
      .map(d => {
        return { x: new Date(d.startDate).valueOf(), y: d.data.sumConsumedKwh * 1e-3 };
      })
      .sort((d1, d2) => d1.x - d2.x);

    const futureUsage = data.forecastedHourlyConsumption
      .map(d => {
        return { x: new Date(d.startDate).valueOf(), y: d.data.sumConsumedKwh * 1e-3 };
      })
      .sort((d1, d2) => d1.x - d2.x);

    const forecastGridUsageColor = hexToRGB(Theme.palette.chartBlueColor.main);

    return [
      {
        name: 'Grid usage',
        type: 'column',
        yAxis: 1,
        color: Theme.palette.chartBlueColor.main,
        data: hourlyUsage,
        tooltip: {
          valueSuffix: ' MWh',
        }
      },
      {
        name: 'Forecasted grid usage',
        type: 'column',
        yAxis: 1,
        color: `rgba(${forecastGridUsageColor.r}, ${forecastGridUsageColor.g}, ${forecastGridUsageColor.b}, 0.5)`,
        data: futureUsage,
        tooltip: {
          valueSuffix: ' MWh',
        }
      },
      {
        name: 'Actual carbon intensity',
        type: 'line',
        step: 'left',
        yAxis: 0,
        color: Theme.palette.chartOrangeColor.main,
        data: pastCarbonIntensity,
        tooltip: {
          valueSuffix: ' lbs CO₂ / MWh'
        }
      },
      {
        name: 'Forecasted carbon intensity',
        type: 'line',
        step: 'left',
        yAxis: 0,
        color: Theme.palette.chartGrayColor.main,
        dashStyle: 'ShortDash',
        data: futureCarbonIntensity,
        tooltip: {
          valueSuffix: ' lbs CO₂ / MWh',
        }
      },
    ];
  }

  const chartData = makeChartData();

  let xAxisOptions: any = {
    labels: {
      format: '{value:%l%P}'
    }
  };

  let minUsageY = undefined;
  let maxUsageY = undefined;
  if (chartData.length > 0) {
    const usageAxisScaling = getAxisScaling(chartData[0].data.concat(chartData[1].data));
    minUsageY = usageAxisScaling.minY;
    maxUsageY = usageAxisScaling.maxY;
  }

  // Once intensity data is loaded, show icons under the x axis.
  if (chartData.length > 0) {
    const fullCarbonIntensity = chartData[2].data.concat(chartData[3].data);
    const epochValues = fullCarbonIntensity.map(v => v.x);
    const intensityValues = fullCarbonIntensity.map(v => v.y);

    const xAxisFormatter = makeIntensityIconFormatter(epochValues, intensityValues, timezoneName);
    xAxisOptions = {
    labels: {
        useHTML: true,
        formatter: xAxisFormatter
      }
    }
  }

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Real-time carbon intensity'}/>}
        action={
          <>
            <RealTimeIndicator loading={isFetching || isLoading}/>
            <Button variant="outlined" color="neutral" sx={{m: 1}}>Export</Button>
          </>
        }
      />
      <CardContent>
        <ChartBase
          loading={isLoading}
          refreshing={isFetching}
          animated={true}
          chartHeight={350}
          chartContainerId={'real-time-intensity-chart'}
          chartAxisLabelY1={'carbon intensity (lbs CO₂ / MWh)'}
          dateResolution={'hour'}
          chartData={chartData}
          overrideOptions={
            {
              time: {
                // We use a "negative means west", whereas highchart uses the opposite.
                timezoneOffset: -physicalUtcOffsetMins,
                useUTC: true
              },
              xAxis: xAxisOptions,
              yAxis: [
                {
                  title: {
                    text: 'carbon intensity (lbs CO₂ / MWh)',
                    style: {
                      color: Theme.palette.chartOrangeColor.main
                    }
                  },
                  labels: { style: { color: Theme.palette.chartOrangeColor.main } },
                  opposite: true,
                },
                {
                  title: {
                    text: 'grid usage (MWh)',
                    style: {
                      color: Theme.palette.chartBlueColor.main
                    }
                  },
                  labels: { style: { color: Theme.palette.chartBlueColor.main } },
                  min: minUsageY,
                  max: maxUsageY,
                }
              ],
              legend: {
                enabled: true
              }
            }
          }
        />
      </CardContent>
    </CardBase>
  );
}


export default RealtimeIntensityCard;
