import React, { Component } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


export interface IModalBaseProps {
  // The ID of a DOM element that should open this modal when clicked.
  // TODO(milo): Consider using a ref or some other system instead?
  openButtonId?: string

  // Components that get rendered inside of the modal.
  children?: any
}


export interface IModalBaseState {
  open: boolean;
}


export default class ModalBase extends Component<IModalBaseProps, IModalBaseState> {
  constructor(props: IModalBaseProps) {
    super(props);
    this.state = {
      open: false,
    }
  }

  // Opens this modal. Can be used by the parent component by passing a ref.
  open() {
    this.setState({ open: true });
  }

  // Closes this modal. Can be used by the parent component by passing a ref.
  close() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.close.bind(this)}
        >
          <Box style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              minWidth: '300px',
              maxHeight: '75%',
              overflowY: 'auto'
            }}
          >
            {this.props.children}
          </Box>
        </Modal>
      </div>
    );
  }

  componentDidMount(): void {
    if (this.props.openButtonId) {
      const btn = document.getElementById(this.props.openButtonId);
      if (btn) {
        btn.onclick = this.open.bind(this);
      }
    }
  }
}
