import React, { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';


interface ISummaryStatisticProps {
  loading?: boolean
  labelText: ReactNode,
  labelColor?: string
  valueText: ReactNode
  unitsText?: string | null
  labelHeight?: string
  sx?: any
}

export const SummaryStatSubValue = ({
  value,
  label,
}: {value: React.ReactNode, label: React.ReactNode}) => {
  return (
    <div className="summary-stat-sub-value--container">
      <div className="summary-stat-sub-value--value">{value}</div>
      <div className="summary-stat-sub-value--label">{label}</div>
    </div>
  );
}

export const LargeSummaryStatSubValue = ({
   value,
   label,
   unitText,
}: {value: React.ReactNode, label: React.ReactNode, unitText?: React.ReactNode}) => {
  return (
    <div className="summary-stat-sub-value-l--container">
      <div className="summary-stat-sub-value-l--value">
        <Typography variant='seBigNumberText'>{value}</Typography>
        {unitText && <div className="summary-stat-sub-value-l--units">{unitText}</div>}
      </div>
        {label ? <div className="summary-stat-sub-value--label">{label}</div> : ''}
    </div>
  );
}


// Shows a label with a number below (and optionally units). If `loading` is true,
// this component will display a skeleton where the text goes.
const SummaryStatistic = ({
  loading,
  labelText,
  labelColor,
  labelHeight,
  valueText,
  unitsText,
  sx,
}: ISummaryStatisticProps) => {
  const numberOrSkeleton = loading ?
    <Skeleton variant='text' sx={{mt: 1}}/> :
    <Typography variant='seBigNumberText'>
      {valueText}
      {unitsText ? <Typography variant='seMutedUnitsText'>{unitsText}</Typography> : ''}
    </Typography>;

  const sxOverride = {...{pl: 2, pr: 2}, ...(sx || {})};

  return (
    <Grid item xs sx={sxOverride}>
      <Box sx={{height: labelHeight || '50px', paddingBottom: '12px'}}>
        <Typography variant='seCardLabelText' color={labelColor || 'inherit'}>
        {labelText}
        </Typography>
      </Box>
      {numberOrSkeleton}
    </Grid>
  );
}


export default SummaryStatistic;
