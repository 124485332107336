import React from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
// import UserAssetDropdown from 'components/UserAssetDropdown';
import CardTitle from 'components/CardTitle';
import makeIntensityIconFormatter from 'utils/makeIntensityIconFormatter';
import { useGetRealtimeChartDataQuery } from 'api/realtime';
import {
  ITimestampedIntensityData,
  ITimestampedEnergyUsageData,
  IRealtimeChartData
} from 'api/types';

import Theme from 'config/Theme';
import './style.css';


const RealtimeIntensityCard = () => {
  const { data, isFetching } = useGetRealtimeChartDataQuery();
  const chartData: IRealtimeChartData = data ? data.data : { emissionIntensity: [], energyUsage: [] };

  const makeChartData = () => {
    if (!data) {
      return [];
    }

    const actualCarbonIntensity = chartData.emissionIntensity
      .filter((d: ITimestampedIntensityData) => !d.forecast)
      .map((d: ITimestampedIntensityData) => {
          return { x: new Date(d.startDate).valueOf(), y: d.intensityLbsCo2PerMwh };
      })
      .sort((d1, d2) => d1.x - d2.x);

    const forecastedCarbonIntensity = chartData.emissionIntensity
      .filter((d: ITimestampedIntensityData) => d.forecast)
      .map((d: ITimestampedIntensityData) => {
          return { x: new Date(d.startDate).valueOf(), y: d.intensityLbsCo2PerMwh };
      })
      .concat(actualCarbonIntensity.slice(-1))
      .sort((d1, d2) => d1.x - d2.x);

    return [
      {
        name: 'Grid usage',
        type: 'column',
        yAxis: 1,
        color: Theme.palette.chartBlueColor.main,
        data: chartData.energyUsage.map((d: ITimestampedEnergyUsageData) => {
          return { x: new Date(d.startDate).valueOf(), y: d.energyUsageKwh };
        }).sort((d1, d2) => d1.x - d2.x),
        tooltip: {
          valueSuffix: ' kWh'
        }
      },
      {
        name: 'Actual carbon intensity',
        type: 'line',
        step: 'left',
        yAxis: 0,
        color: Theme.palette.chartOrangeColor.main,
        data: actualCarbonIntensity,
        tooltip: {
          valueSuffix: ' lbs CO₂ / MWh'
        }
      },
      {
        name: 'Forecasted carbon intensity',
        type: 'line',
        step: 'left',
        yAxis: 0,
        color: Theme.palette.chartGrayColor.main,
        dashStyle: 'LongDash',
        data: forecastedCarbonIntensity,
        tooltip: {
          valueSuffix: ' lbs CO₂ / MWh',
        }
      },
    ];
  }

  const epochValues = chartData.emissionIntensity.map(v => { return new Date(v.startDate).valueOf() });
  const intensityValues = chartData.emissionIntensity.map(v => { return v.intensityLbsCo2PerMwh });
  const xAxisFormatter = makeIntensityIconFormatter(epochValues, intensityValues, 'US/Central');

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Real-time carbon intensity'}/>}
        action={
          <div>
            {/* <UserAssetDropdown
              dropdownId={'realtime-intensity--dropdown'}
              onChange={(selectedAssetId: string, selectedAsset: IUserAsset) => {
                setSelectedAsset(selectedAssetId);
              }}
            /> */}
            {/* <Button variant="outlined" color="neutral" sx={{m: 1}}>Export</Button> */}
          </div>
        }
      />
      <CardContent>
        <ChartBase
            loading={isFetching}
            animated={true}
            chartHeight={300}
            chartContainerId={'realtime-chart-card--chart'}
            chartAxisLabelY1={'carbon intensity (lbs CO₂ per MWh)'}
            dateResolution={'hour'}
            chartData={makeChartData()}
            overrideOptions={
              {
                xAxis: {
                  labels: {
                    useHTML: true,
                    formatter: xAxisFormatter
                  },
                },
                yAxis: [
                  // Carbon intensity axis
                  {
                    title: {
                      text: 'carbon intensity (lbs per MWh)',
                      style: {
                        color: Theme.palette.chartOrangeColor.main
                      }
                    },
                    labels: { style: { color: Theme.palette.chartOrangeColor.main } },
                    opposite: true
                  },
                  // Grid usage axis
                  {
                    title: {
                      text: 'grid usage (kWh)',
                      style: {
                        color: Theme.palette.chartBlueColor.main
                      }
                    },
                    labels: { style: { color: Theme.palette.chartBlueColor.main } },
                  }
                ],
                legend: {
                  enabled: true
                },
                time: {
                  useUTC: true,
                  timezoneOffset: 300,
                }
              }
            }
        />
      </CardContent>
    </CardBase>
  );
}


export default RealtimeIntensityCard;
