import { api } from "./base";
import { IUser, IPagination } from "./types";

export interface IGetUsersResponse {
  data: IUser[]
  meta: {
    pagination: IPagination
  }
}

export interface IInviteUserParams {
  email: string
  name: string
  role: string
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchMyTeammates: build.query<IGetUsersResponse, {page: number, per_page: number}>({
      query: ({page, per_page}) => {
        const qs = new URLSearchParams({page: page.toString(), per_page: per_page.toString()});
        return {
          url: `/v1/users/my-teammates?${qs}`,
          method: 'GET'
        }
      },
      providesTags: (result) => [
        { type: 'User', id: 'PARTIAL_LIST'},
        ...result ? result.data.map(user => ({type: 'User' as const, id: user.id})) : [],
      ],
    }),
    inviteUser: build.mutation<void, IInviteUserParams>({
      query: (params: IInviteUserParams) => {
        return {
          url: '/v1/users/invite',
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: [{ type: 'User', id: 'PARTIAL_LIST'}],
    }),
    deleteUser: build.mutation<void, string>({
      query: (id) => {
        return {
          url: `/v1/users/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (res, err, arg) => [{ type: 'User', id: 'PARTIAL_LIST'}, { type: 'User', id: arg}],
    }),
  }),
});


export const {
  useFetchMyTeammatesQuery,
  useInviteUserMutation,
  useDeleteUserMutation,
} = usersApi;