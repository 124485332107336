import React from 'react';
import Typography from "@mui/material/Typography";


interface ICardTitleProps {
  title: string
}


export default function CardTitle(props: ICardTitleProps) {
  return <Typography
      variant="seCardTitleText"
      component="div"
      sx={{p: 1}}>
    {props.title}
  </Typography>;
}
