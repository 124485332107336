import React, { useState } from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import {
  makeInterventionPlotPoints,
  makeTodayPlotLine
} from 'utils/chartAnnotations';

import CardBase from 'components/CardBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import BasicDropdown from 'components/BasicDropdown';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';

import {
  ICustomerProjection
} from 'api/types';
import {
  useSearchCustomerInterventionsQuery,
  useSimulateProjectionQuery
} from 'api/interventions';
import { useGetCustomerGoalsQuery } from 'api/summary';

import Theme from 'config/Theme';
import './style.css';


enum ProjectionsChartCardMode {
  Emissions = 'emissions',
  Costs = 'costs',
  Energy = 'energy'
}

const TWELVE_HOURS_IN_MS = 1000 * 60 * 60 * 12;

interface IProjectionsChartCardProps {
  horizon: number;
  setHorizon: (horizon: number) => void;
}


function getToolTipOptions(mode: ProjectionsChartCardMode) {
  return {
    [ProjectionsChartCardMode.Emissions]: {
      valueSuffix: ' tons CO₂'
    },
    [ProjectionsChartCardMode.Costs]: {
      valuePrefix: '$'
    },
    [ProjectionsChartCardMode.Energy]: {
      valueSuffix: ' kWh'
    }
  }[mode];
}


function makeUncertaintyCone(
    series: { x: number, y: number }[],
    maxPlusOrMinusPercent: number) {
  const xmin = series.at(0).x;
  const xmax = series.at(-1).x;
  return series.map((value) => {
    const t = Math.abs(value.x - xmin) / Math.abs(xmax - xmin);
    return maxPlusOrMinusPercent * t;
  });
}


function makeConfidenceInterval(series: { x: number, y: number }[],
                                plusOrMinusPercents: number[]) {
  return {
    lowerBound: series.map((value, i) => {
      return {
        x: value.x, y: value.y + value.y * plusOrMinusPercents.at(i)
      };
    }),
    upperBound: series.map((value, i) => {
      return {
        x: value.x, y: value.y - value.y * plusOrMinusPercents.at(i)
      };
    }),
  }
}


const ProjectionsChartCard = (props: IProjectionsChartCardProps) => {
  const [mode, setMode] = useState(ProjectionsChartCardMode.Emissions);
  const goalsApi = useGetCustomerGoalsQuery();
  const userGoals = goalsApi.data ? goalsApi.data.data : null;

  // Don't request a projection until there are input interventions to pass.
  const plannedInterventionsApi = useSearchCustomerInterventionsQuery({
    status: ['planned'],
    calculateImpact: true
  });
  const projectionApi = useSimulateProjectionQuery(
    plannedInterventionsApi.data ? plannedInterventionsApi.data.data : [],
    { skip: !plannedInterventionsApi.data }
  );

  const perWhatTimeInterval = 'per year';

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [ProjectionsChartCardMode.Emissions]: 'tons of CO₂ ' + perWhatTimeInterval,
    [ProjectionsChartCardMode.Costs]: 'cost ($) ' + perWhatTimeInterval,
    [ProjectionsChartCardMode.Energy]: 'kWh ' + perWhatTimeInterval
  }[mode];

  const todayEpoch = new Date('2022-01-01T00:00:00.000').valueOf();
  const chartRef = React.createRef<ChartBase>();

  const chartDataFactory = () => {
    if (!projectionApi.data) {
      return [];
    }

    const makePointFromCustomerProjection = (value: ICustomerProjection, mode: ProjectionsChartCardMode) => {
      return {
        x: new Date(value.startDate).valueOf() + TWELVE_HOURS_IN_MS,
        y: {
          [ProjectionsChartCardMode.Emissions]: value.netEmissionsTonsCo2,
          [ProjectionsChartCardMode.Costs]: value.grossElectricityCosts,
          [ProjectionsChartCardMode.Energy]: value.electricityUsageKwh,
        }[mode]
      }
    }
    const baselineData = projectionApi.data.data.baselineProjections
        .filter((value: ICustomerProjection) => new Date(value.startDate).getFullYear() <= props.horizon)
        .map((value: ICustomerProjection) => {
      return makePointFromCustomerProjection(value, mode);
    });
    const scenarioData = projectionApi.data.data.scenarioProjections
        .filter((value: ICustomerProjection) => new Date(value.startDate).getFullYear() <= props.horizon)
        .map((value: ICustomerProjection) => {
      return makePointFromCustomerProjection(value, mode);
    });

    if (!baselineData || !scenarioData || baselineData.length === 0 || scenarioData.length === 0) {
      return [];
    }

    const tooltipOptions = getToolTipOptions(mode);
    const epochToday = (baselineData[0] || {x:0}).x;
    const valueThisYear = (baselineData[0] || {y:0}).y;

    const plusOrMinusPercents = makeUncertaintyCone(scenarioData, 0.4);
    const lowAndHighSeries = makeConfidenceInterval(scenarioData, plusOrMinusPercents);

    const chartData = [
      {
        name: 'Historical baseline',
        type: 'line',
        color: Theme.palette.chartBlueColor.main,
        data: [
          { x: new Date('2018-01-01T00:00:00.000').valueOf(), y: valueThisYear * 1.0 },
          { x: new Date('2019-01-01T00:00:00.000').valueOf(), y: valueThisYear * 1.0 },
          { x: new Date('2020-01-01T00:00:00.000').valueOf(), y: valueThisYear * 1.0 },
          { x: new Date('2021-01-01T00:00:00.000').valueOf(), y: valueThisYear * 1.0 },
          { x: new Date('2022-01-01T00:00:00.000').valueOf(), y: valueThisYear * 1.0 },
          { x: epochToday, y: valueThisYear },
        ].sort((d1, d2) => d1.x - d2.x),
        tooltip: tooltipOptions
      },
      {
        name: 'High cost renewable scenario',
        type: 'line',
        color: Theme.palette.chartPurpleColor.main,
        dashStyle: 'LongDash',
        data: lowAndHighSeries.lowerBound,
        tooltip: tooltipOptions
      },
      {
        name: 'Mid-case projection',
        type: 'line',
        color: Theme.palette.chartBlueColor.main,
        dashStyle: 'LongDash',
        data: scenarioData,
        tooltip: tooltipOptions
      },
      {
        name: 'Low cost renewable scenario',
        type: 'line',
        color: Theme.palette.chartOrangeColor.main,
        dashStyle: 'LongDash',
        data: lowAndHighSeries.upperBound,
        tooltip: tooltipOptions
      },
    ];

    const plannedInterventions = plannedInterventionsApi.data.data.filter(value => new Date(value.startDate).getFullYear() <= props.horizon);
    const plannedAnnotations = makeInterventionPlotPoints(plannedInterventions, scenarioData, 'Planned projects');
    if (plannedAnnotations && plannedAnnotations.data.length > 0) {
      chartData.push(plannedAnnotations);
    }

    if (mode == ProjectionsChartCardMode.Costs || mode == ProjectionsChartCardMode.Emissions) {
      const goalMetricValue = {
        [ProjectionsChartCardMode.Emissions]: userGoals ? userGoals.annualEmissionsTons : 0,
        [ProjectionsChartCardMode.Costs]: userGoals ? userGoals.annualElectricityCost : 0
      }[mode];
      chartData.push({
        name: 'Goal',
        type: 'line',
        color: Theme.palette.chartTealColor.main,
        data: [{x: chartData[0].data[0].x, y: chartData[0].data[0].y}, {x: scenarioData.at(-1).x, y: goalMetricValue}],
        tooltip: tooltipOptions,
      });
    }

    return chartData;
  }

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Projections'}/>}
        action={
          <div>
            <CardRadioButtonGroup
              mode={mode}
              mapModeToLabel={{
                [ProjectionsChartCardMode.Emissions]: 'Carbon (tons of CO₂)',
                [ProjectionsChartCardMode.Costs]: 'Costs ($)',
                [ProjectionsChartCardMode.Energy]: 'Energy (kWh)'
              }}
              setModeCallback={(value: string) => setMode(value as ProjectionsChartCardMode)}
            />
            <BasicDropdown
              id={'forecast-horizon-dropdown'}
              value={props.horizon.toString()}
              mapValueToLabel={{
                2025: '2025',
                2030: '2030',
                2035: '2035',
                2040: '2040',
              }}
              updateCallback={(value: string) => props.setHorizon(parseInt(value))}
              formControlSx={{mt: 1, mr: 1}}
            />
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() }}
            >
              Export
            </Button>
          </div>
        }
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          ref={chartRef}
          loading={projectionApi.isFetching}
          animated={true}
          chartHeight={300}
          chartContainerId={'projections-chart-card--chart'}
          chartData={chartDataFactory()}
          chartAxisLabelY1={chartAxisLabelY1}
          dateResolution={'year'}
          downloadFilename={`projected_${mode}`}
          overrideOptions={{
            xAxis: {
              plotLines: [makeTodayPlotLine(todayEpoch, 'Current Year')],
            },
          }}
        />
      </CardContent>
    </CardBase>
  );
}


export default ProjectionsChartCard;
