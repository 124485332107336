import * as React from 'react';

import ViewContainerBase from 'components/ViewContainerBase';
import CardGridBase from 'components/CardGridBase';

import ChartCardV1 from './HistoricalChartCardV1';
import ChartCardV2 from './HistoricalChartCardV2';
import ImpactByProjectCardV1 from './ImpactByProjectCardV1';
import CumulativeCarbonReducedCardV1 from './CumulativeCarbonReducedCardV1';
import CumulativeCarbonReducedCardV2 from './CumulativeCarbonReducedCardV2';
import HistoricalBuildingsCard from './HistoricalBuildingsCard';
import { useAppSelector } from 'modules/store';
import { getHasLiveData, getIsDemoCustomer, getIsXcel } from 'modules/auth/selectors';

import './style.css';


const GarysSpecialHistoricalView = () => {

  return (
    <ViewContainerBase>
      <CardGridBase>
        <ChartCardV2/>
        <CumulativeCarbonReducedCardV2/>
      </CardGridBase>
    </ViewContainerBase>
  );
}


const HistoricalView = () => {
  const isDemo = useAppSelector(getIsDemoCustomer);
  const hasLiveData = useAppSelector(getHasLiveData);
  const isXcel = useAppSelector(getIsXcel);

  if (isXcel) {
    return <GarysSpecialHistoricalView/>
  }

  // For customers without live data, we show mock data (V1 card).
  // For non -demo customers WITH live data, we show the real data (V2 card).
  const showCardV1 = !hasLiveData;
  const showCardV2 = hasLiveData && !isDemo;

  return (
    <ViewContainerBase>
      <CardGridBase>
        {showCardV1 && <ChartCardV1/>}
        {showCardV2 && <ChartCardV2/>}
        {showCardV1 && <ImpactByProjectCardV1/>}
        {showCardV1 && <CumulativeCarbonReducedCardV1/>}
        {showCardV2 && <CumulativeCarbonReducedCardV2/>}
        <HistoricalBuildingsCard/>
      </CardGridBase>
    </ViewContainerBase>
  )
}


export default HistoricalView;
