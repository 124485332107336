import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from 'modules/store';
import { receiveGenerationData, setGenerationLoading } from 'modules/demo/slice';
import { generationDataLoading, generationDataNeverLoaded, getGeneratorList } from 'modules/demo/selectors';
import loadGeneration from 'demo/data/duke/generation';


const columns = [
  {
    field: 'plant_id_eia',
    headerName: 'Plant ID',
    width: 120,
    sortable: false,
    type: 'string',
  }, {
    field: 'ba_code',
    headerName: 'BA Code',
    width: 120,
    sortable: false,
    type: 'string',
  }, {
    field: 'fuel_category',
    headerName: 'Fuel',
    width: 200,
    sortable: false,
    type: 'string',
  }, {
    field: 'state',
    headerName: 'State',
    width: 120,
    sortable: false,
    type: 'string',
  }, {
    field: 'type',
    headerName: 'Type',
    width: 120,
    sortable: false,
    type: 'string',
  }, {
    field: 'source',
    headerName: 'Source',
    width: 120,
    sortable: false,
    type: 'string',
  }
  // {
  //   field: 'programId',
  //   headerName: 'Program',
  //   width: 200,
  //   sortable: false,
  //   editable: true,
  //   type: 'singleSelect',
  //   valueOptions: [...staticPrograms.map(program => ({value: program.id, label: program.name})),
  //     {value: '', label: 'Standard Ratepayer'},
  //   ],
  //   valueGetter: (params: GridCellParams) => staticPrograms.find(program => program.id === params.row.programId)?.name || 'Standard Ratepayer',
  // }
]

// TODO(brooke): consolidate this with the other generators table under ProgramView/generators
const GeneratorsTable = () => {
  const dispatch = useAppDispatch();
  const generators = useAppSelector(getGeneratorList);
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const loading = useAppSelector(generationDataLoading);
  const nav = useNavigate();

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  return (
    <DataGrid
      loading={loading}
      rowCount={generators.length}
      rows={generators.filter(g => g.source === 'owned' || g.type === 'specified' || g.type === 'unbundled')}
      columns={columns}
      pageSize={40}
      rowHeight={30}
      autoHeight
      paginationMode="client"
      onRowClick={(row) => nav(`/inventory/generators/${row.id}`)}
    />
  )
};

export default GeneratorsTable;