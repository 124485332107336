import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProgramsTable from 'components/ProgramsTable';
import ProgramView from 'components/ProgramView';
import EditProgramView from 'components/ProgramView/edit';

const ProgramsView = () => {
  return <Routes>
    <Route path="/" element={<ProgramsTable />} />
    <Route path=":id">
      <Route index element={<ProgramView />} />
      <Route path="edit/*" element={<EditProgramView />} />
    </Route>

  </Routes>
};

export default ProgramsView;