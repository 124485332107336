import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import { IRealtimeChartData, ITimestampedIntensityData } from 'api/types';
import { useGetRealtimeChartDataQuery } from 'api/realtime';

import CardTitle from 'components/CardTitle';
import CardBase from 'components/CardBase';
import CarbonIntensityIcon from 'components/CarbonIntensityIcon';
import ExpandArrow from 'components/ExpandArrow';
import { percentile } from 'utils/math';
import { Alert } from '@mui/material';


interface IRealtimeIntensityCardV1Props {
  forecastWindowHours?: number
}


const RealtimeIntensityCardV1 = (props: IRealtimeIntensityCardV1Props) => {
  const { data } = useGetRealtimeChartDataQuery();
  const chartData: IRealtimeChartData = data ? data.data : { emissionIntensity: [], energyUsage: [] };

  const forecastData = chartData.emissionIntensity.filter((value: ITimestampedIntensityData) => value.forecast).sort((a, b) => new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf());

  return <RealtimeIntensityCardV1Content
    forecastData={forecastData}
    forecastWindowHours={props.forecastWindowHours}
  />;

};


export const RealtimeIntensityCardV1Content = ({
  forecastData,
  forecastWindowHours=5,
}: {forecastData: ITimestampedIntensityData[], forecastWindowHours?: number}) => {
  const navigate = useNavigate();
  const goToRealtimePage = () => navigate('/realtime');

  const forecastDataWindow = forecastData.slice(0, forecastWindowHours);
  const percentWidthEachItem = 100 / Math.max(forecastDataWindow.length, 1);
  const intensityValues = forecastData.map((value: ITimestampedIntensityData) => value.intensityLbsCo2PerMwh);
  const allHighIntensities = forecastDataWindow.length !== 0 && forecastDataWindow.every(({ intensityLbsCo2PerMwh }: ITimestampedIntensityData) => intensityLbsCo2PerMwh >= percentile(67, intensityValues));
  const allLowIntensities = forecastDataWindow.length !== 0 && forecastDataWindow.every(({ intensityLbsCo2PerMwh }: ITimestampedIntensityData) => intensityLbsCo2PerMwh <= percentile(34, intensityValues));

  const hourlyItems = forecastDataWindow.map((value: ITimestampedIntensityData) => {
    return (
      <Grid item key={value.startDate} width={percentWidthEachItem.toString() + '%'}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyItems='center'>
          <CarbonIntensityIcon
            carbonIntensityLbsPerMwh={value.intensityLbsCo2PerMwh}
            cleanThresholdLbsPerMwh={percentile(34, intensityValues)}
            dirtyThresholdLbsPerMwh={percentile(67, intensityValues)}
            width={30}
            height={30}
          />
          <Typography paddingTop={1}>{new Date(value.startDate).toLocaleTimeString('default', {hour: 'numeric'})}</Typography>
          <Typography
            fontSize={8}
            variant='seMutedUnitsText'>
              {value.intensityLbsCo2PerMwh.toFixed(0)} lbs CO₂ / MWh
          </Typography>
        </Box>
      </Grid>
    );
  });

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Carbon intensity forecast'}/>}
        action={<ExpandArrow onClick={goToRealtimePage}/>}
      />
      <CardContent>
        <Typography>You can reduce carbon emissions by aligning your grid consumption with the cleanest grid conditions.</Typography>
        <Grid container width={'100%'} sx={{mt: 3}}>
          {hourlyItems}
        </Grid>
      </CardContent>
      {allHighIntensities && <Alert severity='warning'>Carbon intensity is high on the grid for the next {forecastWindowHours} hours, avoid using more energy than necessary!</Alert>}
      {allLowIntensities && <Alert severity='success'>Carbon intensity is low for the next {forecastWindowHours} hours, now is a great time to use more energy!</Alert>}
    </CardBase>
  )
}


export default RealtimeIntensityCardV1;
