import React, { useRef } from 'react';

import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import CardBase from 'components/CardBase';
import CardTitle from 'components/CardTitle';
import ModalBase, { IModalBaseProps } from 'components/ModalBase';
import ProjectionDetailsText from 'components/ProjectionDetailsText';



export default function EmissionsOverTimeModal(props: IModalBaseProps) {
  const modalRef = useRef<ModalBase>(null);

  return (
    <ModalBase {...props} ref={modalRef}>
      <CardBase sx={{border: '2px solid #3F9594'}} width={12}>
        <CardHeader
          title={<CardTitle title={'Emission Forecasts'}/>}
        />
        <CardContent sx={{pl: 2, pb: 1}}>
          <ProjectionDetailsText/>
          <Button variant='contained' sx={{mt: 3}} onClick={() => {
            if (modalRef && modalRef.current) {
              modalRef.current.close()
            }
          }}>Close</Button>
        </CardContent>
      </CardBase>
    </ModalBase>
  );
}
