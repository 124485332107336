import React from 'react';

import { getIsXcel } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';


const NrelForecastDescription = () => (
  <p>
    Our grid projections are derived from the National Renewable Energy Laboratory's <a href="https://www.nrel.gov/docs/fy22osti/81611.pdf">Cambium model</a>.
    Cambium models the evolution and operation of the electrical grid until 2040 under a range of scenarios.
    The "Mid-case scenario" represents default assumptions for variables describing the evolution of the electric grid,
    including demand, costs, and technology evolution. Around this Mid-case, Cambium models a range of alternative futures,
    from a pessimistic scenario with high renewable energy costs to an optimistic scenario with low renewable costs.
  </p>
);


const XcelForecastDescription = () => (
  <p>
    Grid projections are based on representative carbon intensity forecasts from your utility's region.
  </p>
);


const ProjectionDetailsText = () => {
  const isXcel = useAppSelector(getIsXcel);

  return (
    <>
      <h3>Forecasting Grid Carbon Intensity</h3>
      {
        isXcel ? <XcelForecastDescription/> : <NrelForecastDescription/>
      }
      <h3 style={{paddingTop: '4px'}}>Forecasting Your Emissions</h3>
      <p>
        We use up to two years of your historical hourly energy consumption data to model your future load profile, which
        captures your patterns of grid usage across months and hours in the day. Your future load profile is then
        combined with forecasted grid carbon intensity to estimate future emissions.
      </p>

      <h3 style={{paddingTop: '4px'}}>Modeling Planned Projects</h3>
      <p>
        On the <strong>Scenarios</strong> page, you can plan projects that will reduce grid usage and emissions. Our
        model takes these projects into account when forecasting your future load profile and emissions.
      </p>
      <p>
        Estimates of project impacts vary from project-to-project, informed by the technology involved and the project sponsor. Ask us for the
        assumptions underlying specific project projections.
      </p>
    </>
  )
}


export default ProjectionDetailsText;
