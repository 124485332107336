export const mapOffsetToReadableTimezone = (utcOffsetMins: number): string => {
  switch (utcOffsetMins) {
    case -300:
      return 'US/Eastern';
    case -360:
      return 'US/Central';
    case -420:
      return 'US/Mountain';
    case -480:
      return 'US/Pacific';
    default:
      return 'UTC';
  }
}
