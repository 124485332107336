import { camelToSnake } from './strings';


// https://stackoverflow.com/questions/12931828/convert-returned-json-object-properties-to-lower-first-camelcase
export default function snakifyKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(v => snakifyKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelToSnake(key)]: snakifyKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};
