import * as React from 'react';
import { Grid } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import cx from 'classnames';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CustomersTable from 'demo/components/CustomerTable';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  generationDataLoading,
  generationDataNeverLoaded,
  getAllocationOverviewExcessMetrics,
  getCertificateFlowData,
  getCustomerAccountList,
  getGeneratorList,
  getOverallProgramCustomerLoadMatching,
  getProgramCertificateAllocation,
  getProgramsList,
  getResidualMixCarbonIntensity,
  getTotalCustomerLoadKWh,
  getTotalGenerationMWh,
  getProgramTotalMWh,
  getTotalCertValues,
} from 'modules/demo/selectors';
import { IProgram, receiveGenerationData, setGenerationLoading } from 'modules/demo/slice';
import loadGeneration from 'demo/data/duke/generation';

import ToplineMetrics from 'demo/components/ToplineMetrics';
import InventoryAllocation from 'components/InventoryAllocation';
import UtilityToplineMetrics from 'demo/components/UtilityToplineMetrics';
import './style.css';


const allocationTableCols: GridColDef[] = [
  {
    field: 'priority',
    headerName: 'Priority',
    minWidth: 80,
    flex: 0.1,
    type: 'number',
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    sortable: false,
    type: 'string',
    flex: 0.5,
  },
  {
    field: 'numCustomers',
    headerName: 'Enrollments',
    description: 'The number of customers who are enrolled in the program.',
    minWidth: 120,
    sortable: false,
    type: 'number',
    flex: 0.2,
  },
  {
    field: 'numGenerators',
    headerName: 'Inventory Sources',
    description: 'The number of inventory sources contributing certificates to the program.',
    minWidth: 120,
    sortable: false,
    type: 'number',
    flex: 0.2,
  },
  {
    field: 'loadMatched',
    headerName: 'Percent Load Matched',
    width: 200,
    sortable: false,
    type: 'number',
    flex: 0.2,
    renderCell: (params: GridRenderCellParams<number, IProgram>) => {
      const loadMatched = useAppSelector(s => getOverallProgramCustomerLoadMatching(s, params.row.id));

      if (isNaN(loadMatched) || params.row.id === 'Standard Ratepayer') {
        return <em className="program-allocation-row-coverage--na">N/A</em>
      }

      const threshold = params.row.is247Program ? 80 : 100;
      const classModifier: Record<string, boolean> = {};
      if ((loadMatched < threshold) && (loadMatched >= (threshold * 0.9))) {
        classModifier['medium'] = true;
      } else if (loadMatched < (threshold * 0.9)) {
        classModifier['bad'] = true;
      }

      return (
        <div className={cx("program-allocation-row--coverage", classModifier)}>{Math.floor(loadMatched)} %</div>
      )
    },
  },
  {
    field: 'totalCerts',
    headerName: 'Total Certificates',
    minWidth: 120,
    sortable: false,
    type: 'number',
    flex: 0.2,
  },
]


const AllocationProgramsTable = () => {
  const generationLoading = useAppSelector(generationDataLoading);
  const programs = useAppSelector(getProgramsList);
  const customers = useAppSelector(getCustomerAccountList);
  const generatorList = useAppSelector(getGeneratorList);
  const totalStandardRatepayerCerts = Math.floor(useAppSelector(s => getProgramTotalMWh(s, 'Standard Ratepayer')));

  const pagePrograms = [
    ...programs,
    {
      id: 'Standard Ratepayer',
      priority: Infinity,
      name: 'Standard Ratepayer',
      description: 'The default ratepayer who is not enrolled in a program.',
      is247Program: false,
      generatorCertificateDistribution: 'pooled',
      totalCerts: totalStandardRatepayerCerts,
    }
  ].sort((a, b) => a.priority - b.priority).map(program => {
    return {
      ...program, ...{
        numCustomers: customers.filter(customer => customer.programId === program.id).length,
        numGenerators: generatorList.filter(generator => generator.programId == program.id).length,
        loadMatched: 0,
      }
    }
  });


  return (
    <DataGrid
      loading={generationLoading}
      rows={pagePrograms}
      columns={allocationTableCols}
      autoHeight
      paginationMode="client"
      hideFooter
    />
  )
};


const renderPercentCell = (num: number) => {
  return (num * 100).toFixed(2) + '%';
}

const ProgramReleaseHeatmap = () => {
  const programs = useAppSelector(getProgramsList);
  const programReleaseRows = useAppSelector(getProgramCertificateAllocation)

  const programReleaseCols: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      minWidth: 140,
      flex: 0.1,
      type: 'string',
      sortable: false,
    },
  ];

  programs.forEach(program => {
    programReleaseCols.push({
      field: program.id,
      headerName: program.name,
      minWidth: 140,
      flex: 0.1,
      type: 'number',
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return params.value ? renderPercentCell(params.value) : <span style={{ opacity: 0.6 }}>n/a</span>;
      }
    });
  });

  return (
    <DataGrid
      rows={programReleaseRows}
      columns={programReleaseCols}
      autoHeight
      hideFooter
    />
  );
}

const CertificateFlowSankeyChart = () => {
  const loading = useAppSelector(generationDataLoading);
  const sankeyCertFlowData = useAppSelector(getCertificateFlowData);

  const chartRef = React.createRef<ChartBase>();

  const makeChartData = () => {

    return [{
      nodes: sankeyCertFlowData.nodes,
      data: sankeyCertFlowData.points,
      type: 'sankey',
      name: 'Certificate Flow'
    }];
  }

  return (
    <ChartBase
      ref={chartRef}
      loading={loading}
      animated={true}
      chartHeight={600}
      chartContainerId={'certificate-flow-sankey'}
      chartData={makeChartData()}
      downloadFilename={'certificate_flow_results'}
      overrideOptions={{
        plotOptions: {
          sankey: {
            nodePadding: 17, // spreads the nodes apart  if there's room
            curveFactor: 0.2,
            tooltip: {
              headerFormat: null,
              pointFormat: '{point.fromNode.name} \u2192 {point.toNode.name}: <b>{point.realWeight:.0f}</b> Certificates</br>{point.tooltipInfo}',
              nodeFormat: '{point.name}: <b>{point.tooltipValue}</b> Certificates </br> {point.tooltipInfo}',
            }
          }
        },
      }}
    />
  );
}


const AllocationOverview = () => {
  const totalLoadKWh = useAppSelector(getTotalCustomerLoadKWh);
  const totalGenerationMWh = useAppSelector(getTotalGenerationMWh);
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const carbonIntensity = useAppSelector(getResidualMixCarbonIntensity);
  const excess = useAppSelector(getAllocationOverviewExcessMetrics);
  const certValue = useAppSelector(getTotalCertValues);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({ data })));
    }
  }, [generationNeverLoaded]);


  return <div className="allocation-overview--container">
    <Grid container spacing={4} sx={{ mt: 0, mb: 2 }}>
      <ToplineMetrics
        cfe={{ programPct: null, gridPct: carbonIntensity.fullInventoryCFEPct }}
        totals={{ consumptionMWh: totalLoadKWh / 1000, generationMWh: totalGenerationMWh }}
        excess={{ generationMWh: Math.max(0, excess.generationMWh), consumptionMWh: Math.max(0, excess.consumptionMWh) }}
      />

      <UtilityToplineMetrics
        certificateValue={certValue}
      />

      <CardBase width={12}>
        <h3>Certificate Flow</h3>
        <CertificateFlowSankeyChart />
      </CardBase>

      <InventoryAllocation />

      <CardBase width={12}>
        <h3>Program Certificate Allocation</h3>
        <h5>Visualization of what percent of certificates in a program were released to each other program.</h5>
        <div className="allocation-overview-heatmap--to-label">To:</div>
        <div className="allocation-overview-heatmap--chart-container">
          <div className="allocation-overview-heatmap--from-label">From:</div>
          <ProgramReleaseHeatmap />
        </div>
      </CardBase>

      <CardBase width={12}>
        <h3>Customers</h3>
        <CustomersTable withLoadMatching />
      </CardBase>
      <CardBase width={12}>
        <h3>Programs</h3>
        <AllocationProgramsTable />
      </CardBase>
    </Grid>
  </div>
};

export default AllocationOverview;