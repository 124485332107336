import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import loadGeneration from 'demo/data/duke/generation';
import { generationDataNeverLoaded, getInventoryAllocationSeriesData, getPrograms } from 'modules/demo/selectors';
import { IProgram, receiveGenerationData, setGenerationLoading } from 'modules/demo/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { makeChartOptions } from 'demo/chart_helpers';
import DateRangeHeader from 'components/DateRangeHeader';

import './style.css';

const defaultStartStr = '2021-01-01T00:00:00-05:00';
const defaultEndStr = '2021-12-31T23:59:59.9999-05:00';


const InventoryAllocation = () => {
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const programs = useAppSelector(getPrograms);
  const [startDate, setStartDate] = useState<null | Date>(new Date(defaultStartStr));
  const [endDate, setEndDate] = useState<null | Date>(new Date(defaultEndStr));
  const [interval, setInterval] = useState<'year' | 'month' | 'day' | 'hour'>('day');
  const data = useAppSelector(s => getInventoryAllocationSeriesData(s, interval, startDate, endDate));
  const programsById: Record<string, IProgram> = {};
  programs.forEach(program => {
    programsById[program.id] = program;
  });
  const dispatch = useAppDispatch();

  const setDates = (start: Date | null, end: Date | null, interval: string) => {
    setStartDate(start);
    setEndDate(end);
    setInterval(interval as 'year' | 'month' | 'day' | 'hour');
  }

  useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  const options = makeChartOptions({
    dateResolution: interval,
    chartData: data,
    chartType: 'column',
    chartAxisLabelY1: 'MWh Generation',
    animated: true,
  })

  return <div className="inventory-allocation--container">
    <div className="inventory-allocation-header--container">
      <DateRangeHeader onChange={setDates} defaultInterval='day' defaultStart={defaultStartStr} defaultEnd={defaultEndStr} />
    </div>
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  </div>
};

export default InventoryAllocation;