import React from 'react';

import ExpandArrowIcon from 'images/expand_arrow.svg';
import IconButton from '@mui/material/IconButton';


interface IExpandArrowProps {
  width?: string | number
  height?: string | number
  onClick?: () => void;
}


export default function ExpandArrow(props: IExpandArrowProps) {
  return (
    <IconButton onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
    >
      <img src={ExpandArrowIcon} width={props.width || 10} height={props.height || 10}/>
    </IconButton>
  );
}
