import axios, { AxiosError, AxiosResponse } from 'axios';

import { getToken } from 'utils/auth';
import { API_URL, AUTH_URL } from 'config/resources';


export interface IHTTPConfig {
  useToken: boolean;
  useCSRF: boolean;
}

const defaultConfig: IHTTPConfig = {
  useToken: true,
  useCSRF: true,
};

export const bootToLogin = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: 'carbon-dashboard',
  });

  window.location.href = `${AUTH_URL}/?${params}`;
}

export const bootToLogout = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: 'carbon-dashboard',
  });

  window.location.href = `${AUTH_URL}/logout?${params}`;
}

export const API = axios.create({
  baseURL: API_URL,
  timeout: 30000, // 30 second timeout
  headers: {
    'X-App-Version': `carbon-dashboard[${ENVIRONMENT}]`,
  },
  withCredentials: true,
});


API.interceptors.response.use((x) => x,
  (err: Error | AxiosError) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      bootToLogin();
    } else {
      return err;
    }
  },
);


class HTTP {

  public constructor() {}

  private async checkStatus(res: Response) {
    if (!res.ok) {
      let badRes;
      try {
        badRes = await res.json();
      } catch (e) {
        badRes = res;
      }
      throw badRes;
    }
    // NOTE(milo): The API returns a 204 response with no body. In that case,
    // we want to indicate success without parsing as json.
    if (res.status === 204) {
      return res;
    }
    return await res.json();
  };

  private checkAxiosStatus(res: AxiosResponse<any>) {
    if (res.status > 299 || res.status < 200) {
      let badRes;
      try {
        badRes = res.data;
      } catch (e) {
        badRes = res;
      }
      throw badRes;
    }

    return res.data;
  }

  public async get(path: string, queryParams: any = {}, config: IHTTPConfig = defaultConfig): Promise<any> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (config.useToken && getToken()) {
      headers['Authorization'] = `Bearer ${getToken()}`;
    }

    let res = await API.get(`${API_URL}${path}`, {
      method: 'GET',
      headers: headers,
      params: queryParams
    });

    return this.checkAxiosStatus(res);
  }
}

const instance = new HTTP();

export const getInstance = () => instance
