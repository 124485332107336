import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useAppSelector } from 'modules/store';
import { getDailyGenDataGroupedByFuelSource } from 'modules/demo/selectors';
import DateRangeHeader from 'components/DateRangeHeader';
import { makeChartOptions } from 'demo/chart_helpers';
import { CardContent, CardHeader } from '@mui/material';
import CardTitle from 'components/CardTitle';

const defaultStartStr = '2021-01-01T00:00:00-05:00';
const defaultEndStr = '2021-12-31T23:59:59.9999-05:00';

const GenerationChart = () => {
  const [startDate, setStartDate] = useState<null | Date>(new Date(defaultStartStr));
  const [endDate, setEndDate] = useState<null | Date>(new Date(defaultEndStr));
  const [interval, setInterval] = useState<'year' | 'month' | 'day' | 'hour'>('day');
  // TODO: user start, end and interval
  const dailyGenDataGroupedByFuelSource = useAppSelector(s => getDailyGenDataGroupedByFuelSource(s, startDate, endDate, interval));

  const setDates = (start: Date | null, end: Date | null, interval: string) => {
    setStartDate(start);
    setEndDate(end);
    setInterval(interval as 'year' | 'month' | 'day' | 'hour');
  }

  const options = makeChartOptions({
    dateResolution: interval,
    chartData: dailyGenDataGroupedByFuelSource,
    chartType: 'column',
    chartAxisLabelY1: 'MWh Generation',
    animated: true,
  })

  return <div>
    <div className="inventory-generation-chart--header">
      <CardTitle title="Generation over 2021" />
      <DateRangeHeader onChange={setDates} defaultInterval='day' defaultStart={defaultStartStr} defaultEnd={defaultEndStr} />
    </div>

    <CardContent>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </CardContent>
  </div>
}

export default GenerationChart;