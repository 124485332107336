import * as React from 'react';
import Container from '@mui/material/Container';

import './style.css';


interface IHasChildrenProps {
  children: any
}


// This outer container simply displays any children that are nested inside of its JSX.
export default class ViewContainerBase extends React.Component<IHasChildrenProps> {
  render() {
    return (
      // No maximum width for this container. This keeps the scrollbar on the far right.
      <Container maxWidth={false} className="se--base-view-container">
        {/* The inner container keeps the contents at a reasonable width. */}
        <Container maxWidth={"lg"}>
          {this.props.children}
        </Container>
      </Container>
    );
  }
}
