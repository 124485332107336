import * as React from 'react';
import { NavLink } from "react-router-dom";
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import SvgIcon from '@mui/material/SvgIcon';

import { bootToLogout } from 'api/http';
import { getPagesConfig, getCurrentUser } from 'modules/auth/selectors';
import poweredBySingularity from 'images/singularity.svg';
import amerenLogo from 'images/ameren.svg';
import nypaLogo from 'images/nypa.svg';
import washuLogo from 'images/washu.svg';
import singularityLogo from 'images/singularity.svg';
import apsLogo from 'images/aps_logo.png';
import socoLogo from 'images/soco.png';
import engieLogo from 'images/engie.png';
import hbsLogo from 'images/hbs.png';
import { useAppSelector } from 'modules/store';
import { DashboardConfigPages, IUser } from 'api/types';

import './style.css';
import { usePrefetch } from 'api/assets';


const MemoizedLogo = React.memo<{ src: string, id?: string }>(function Image({ src, id }) {
  return <img src={src} id={id} />;
});


const NavLinkTab = (props: {
  to: string,
  children: React.ReactNode,
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>,
}) => {
  return (
    <li>
      <NavLink
        to={props.to}
        className={({ isActive }) =>
          isActive ? "sidebar-nav-link-selected" : ""
        }
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
      >
        <div className={"sidebar-tab"}>
          <div className={"sidebar-tab--indicator"}></div>
          <div className={"sidebar-tab--label"}>
            {props.children}
          </div>
        </div>
      </NavLink>
    </li>
  );
}


const Sidebar = () => {
  const user: null | IUser = useAppSelector(getCurrentUser);

  let logo = singularityLogo;
  if (user.email.toLowerCase().includes('ameren')) {
    logo = amerenLogo;
  } else if (user.email.toLowerCase().includes('nypa')) {
    logo = nypaLogo
  } else if (user.email.toLowerCase().includes('wustl') || user.email.toLowerCase().includes('washu')) {
    logo = washuLogo
  } else if (user.email.toLowerCase().includes('aps_')) {
    logo = apsLogo
  } else if (user.email.toLowerCase().includes('soco_')) {
    logo = singularityLogo;
  } else if (user.email.toLowerCase().includes('hbs.edu')) {
    logo = hbsLogo
  } else if (user.email.toLowerCase().includes('acme') || user.email.toLowerCase().includes('engie_')) {
    logo = singularityLogo;
  }

  const pagesConfig = useAppSelector(getPagesConfig);
  const pages = pagesConfig.availablePages
  const prefetchAssets = usePrefetch('fetchAssetsPage');

  return (
    <nav className="sidebar-nav">
      <Box className="sidebar-logo">
        <MemoizedLogo src={logo} />
      </Box>
      <div className="thin-section-divider"></div>
      <ul className="sidebar-ul">
        {pages.includes(DashboardConfigPages.EXECUTIVE_VIEW) &&
          <NavLinkTab to="/summary">
            Executive view
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.PROGRAM_SUMMARY) &&
          <NavLinkTab to="/program-summary">
            CFE Report
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.REC_OVERVIEW) &&
          <NavLinkTab to="/overview">
            Overview
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.REC_INVENTORY) &&
          <NavLinkTab to="/inventory">
            Inventory
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.REC_PROGRAMS) &&
          <NavLinkTab to="/programs">
            Console
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.REC_ALLOCATION) &&
          <NavLinkTab to="/allocation">
            Allocation
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.REAL_TIME) &&
          <NavLinkTab to="/realtime">
            Real-time
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.HISTORICAL) &&
          <NavLinkTab to="/historical">
            Historical
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.PROJECTIONS) &&
          <NavLinkTab to="/projections">
            Projections
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.SCENARIOS) &&
          <NavLinkTab to="/scenarios">
            Scenarios
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.CUSTOMERS) &&
          <NavLinkTab to="/customers">
            Customers
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.ASSET_MGMT) &&
          <NavLinkTab to="/assets" onMouseEnter={() => prefetchAssets({ page: 1, per_page: 5 })}>
            Assets
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.SOLAR_BATTERY_INT) &&
          <NavLinkTab to="/solar-integration">
            HBS Real-time
          </NavLinkTab>
        }
        {pages.includes(DashboardConfigPages.SOLAR_BATTERY_INT_HISTORICAL) &&
          <NavLinkTab to="/solar-integration-historical">
            HBS Historical
          </NavLinkTab>
        }
        <Box className="sidebar-ul" sx={{ flexDirection: "column-reverse", position: "absolute", bottom: "16px" }}>
          {/* <NavLinkTab to="/settings">
            <SvgIcon sx={{ mr: 2 }}>
              <SettingsIcon fontSize='small'/>
            </SvgIcon>
            Settings
          </NavLinkTab> */}
          <NavLinkTab to="/teammates">
            Teammates
          </NavLinkTab>
          <NavLinkTab to={"/login"} onClick={bootToLogout}>
            <SvgIcon sx={{ mr: 2 }}>
              <LogoutIcon fontSize='small' />
            </SvgIcon>
            Logout
          </NavLinkTab>
          <Box className='sidebar-logo'>
            <MemoizedLogo src={poweredBySingularity} />
          </Box>
        </Box>
      </ul>
    </nav>
  );
}


export default Sidebar;
