import * as React from 'react';
import CustomerAllocation from "demo/components/APSCustomerAllocation"
import { receiveGenerationData, setGenerationLoading, updateAccountProgramAssignment, updateGeneratorProgramAssignment } from 'modules/demo/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import loadGeneration from 'demo/data/duke/generation';
import { generationDataLoading, generationDataNeverLoaded } from 'modules/demo/selectors';
import { Box, CircularProgress } from '@mui/material';

// Example 24/7 CFE Program
const PROGRAM_ID = "dac8adc4-6858-458a-9510-7b8eaa5d3a29";
// University of Georgia
const ACCOUNT_ID = 39488892;

const ProgramSummaryView = () => {
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const loading = useAppSelector(generationDataLoading);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);
  React.useEffect(() => {
    dispatch(updateAccountProgramAssignment({
      programId: PROGRAM_ID,
      accountId: ACCOUNT_ID
    }));
    dispatch(updateGeneratorProgramAssignment({
      generatorId: '59897', // 269MWh solar plant
      programId: PROGRAM_ID
    }));
    dispatch(updateGeneratorProgramAssignment({
      generatorId: '6131', // 365k MWh hydro plant
      programId: PROGRAM_ID
    }));
  }, []);

  if (loading || generationNeverLoaded) {
    return <Box className='se--loading-indicator-container'>
      <CircularProgress />
    </Box>
  }

  return <CustomerAllocation accountId={ACCOUNT_ID} />
};

export default ProgramSummaryView;