import { Button, CalendarIcon, Group } from "evergreen-ui";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { IconButton, Tooltip } from "@mui/material";

import './style.css';

type MaybeDate = Moment | null;

const DateRangeHeader = ({
  onChange,
  hideInterval,
  defaultStart='2022-12-25',
  defaultEnd='2022-12-30T23:59:59.9999',
  defaultInterval='day',
  minStart='2021-01-01',
  maxEnd='2021-12-31',
}: {onChange?: (start: Date | null, end: Date | null, interval: string) => void, hideInterval?: boolean, defaultStart?: string, defaultEnd?: string, defaultInterval?: string, minStart?: string, maxEnd?: string}) => {
  const [startDate, setStartDate] = useState<MaybeDate>(moment(defaultStart));
  const [endDate, setEndDate] = useState<MaybeDate>(moment(defaultEnd));
  const [focusOn, setFocusOn] = useState<null | FocusedInputShape>(null);
  const [interval, setSelectedInterval] = useState(defaultInterval);
  const options = useMemo(
    () => [
      { label: 'Hourly', value: 'hour' },
      { label: 'Daily', value: 'day' },
      { label: 'Monthly', value: 'month' },
      // TODO: enable this when we have more than 1 year of data
      // { label: 'Annually', value: 'year' },
    ],
    []
  )

  const onChangeDate = ({startDate: newStartDate, endDate: newEndDate}: {startDate: MaybeDate, endDate: MaybeDate}) => {
    const start = newStartDate || startDate || moment('2021-02-03');
    const end = newEndDate || endDate || moment('2022-12-31');
    // @ts-ignore
    const diffInHours = (end - start) / (1000 * 60 * 60);
    const diffInDays = diffInHours / 24;

    if (interval === 'hour') {
      if (diffInDays > 400) {
        setSelectedInterval('month');
      } else if (diffInHours > 1000) {
        setSelectedInterval('day');
      }
    } else if (interval === 'day' && diffInDays > 400) {
      setSelectedInterval('month');
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    if (onChange) {
      let start = startDate?.toDate();
      if (start) {
        start.setHours(0, 0, 0, 0);
        start = new Date(start.valueOf());
      }
      let end = endDate?.toDate();
      if (end) {
        end.setHours(23, 59, 59, 999);
        end = new Date(end.valueOf());
      }
      onChange(start || null, end || null, interval);
    }
  }, [startDate, endDate, interval]);

  const setToAllTime = () => {
    onChangeDate({
      startDate: moment('2021-01-01'),
      endDate: moment('2021-12-31'),
    });
  }

  return (
    <div className="date-header--container">
      <div className="date-header-range-picker--container">
        <Tooltip title="Set to entirety of 2021">
          <IconButton onClick={setToAllTime}>
            <CalendarIcon />
          </IconButton>
        </Tooltip>
        <DateRangePicker
          startDate={startDate}
          minimumNights={0}
          startDateId={startDate ? startDate.toISOString() : 'none'}
          endDate={endDate}
          endDateId={endDate ? endDate.toISOString() : 'none'}
          onDatesChange={onChangeDate}
          focusedInput={focusOn}
          onFocusChange={setFocusOn}
          withPortal
          small
          enableOutsideDays={false}
          isOutsideRange={(d) => d >= moment(maxEnd) || d < moment(minStart)}
        />
      </div>
      {!hideInterval && <Group>
        {options.map(({ label, value }) => (
          <Button key={label} isActive={interval === value} onClick={() => setSelectedInterval(value)}>
            {label}
          </Button>
        ))}
      </Group>}
    </div>
  );
};

export default DateRangeHeader;