import * as genData from './generators.json';
import * as genLocations from './gen_locations.json';

type FuelCategory = 'petroleum' | 'natural_gas' | 'hydro' | 'coal' | 'nuclear' | 'biomass' | 'waste' | 'other' | 'solar';
type EIAFuelCategory = 'petroleum' | 'natural_gas' | 'hydro' | 'coal' | 'nuclear' | 'other' | 'solar';

export interface IGenerator {
  plant_id_eia: string,
  plant_primary_fuel: string,
  data_availability: "eia_only" | "cems_and_eia",
  ba_code: string,
  ba_code_physical: string,
  state: string,
  distribution_flag: "False" | "True",
  fuel_category: FuelCategory,
  fuel_category_eia930: EIAFuelCategory,
  timezone: string,
  shaped_plant_id: string,
  location?: {
    lat: number,
    lng: number,
  }
};

interface ILocationData {
  eia_id: string,
  lat: number,
  lng: number,
}

const findLocationData = (genId: string): ILocationData | undefined => {
  return (genLocations.default as ILocationData[]).find(loc => loc.eia_id === genId);
}

export const generators = (genData.default as IGenerator[]).map(gen => ({
  ...gen,
  location: findLocationData(gen.plant_id_eia),
}));
