import * as loadData from './customers.json';

export interface ILoadData {
  start_date: string,
  consumed_kwh: number,
}

export interface ICustomerAccountProfile {
  id: number,
  name: string,
  load: ILoadData[],
  rootCustomerId: string,
};

export default (loadData.default.map(c => ({...c, rootCustomerId: c.name.split('.')[0]}))) as ICustomerAccountProfile[];