import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Alert from '@mui/material/Alert';

import CardTitle from 'components/CardTitle';
import CardBase from 'components/CardBase';
import CarbonIntensityIcon from 'components/CarbonIntensityIcon';
import ExpandArrow from 'components/ExpandArrow';
import RealTimeIndicator from 'components/RealTimeIndicator';

import { useGetRealtimeChartDataV2Query } from 'api/realtime';
import { IGridStatus } from 'api/types';

import { percentile } from 'utils/math';
import { mapOffsetToReadableTimezone } from 'utils/timezone';

import { useAppSelector } from 'modules/store';
import { getPhysicalUtcOffsetMins } from 'modules/auth/selectors';


const RealtimeIntensityCardV2 = (props: { forecastWindowHours?: number }) => {
  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);
  const timezoneName = mapOffsetToReadableTimezone(physicalUtcOffsetMins);

  const params = { timezone: timezoneName };
  // NOTE(milo): Use this if we want to re-enable auto refresh.
  // const optios = { pollingInterval: 60*1000 }
  const { data, isLoading, isFetching, isError } = useGetRealtimeChartDataV2Query(params);

  const forecastData = data?.forecastedHourlyGridStatus || [];

  return (
    <RealtimeIntensityCardV2Content
      loading={isLoading|| isFetching}
      forecastData={forecastData}
      forecastWindowHours={props.forecastWindowHours}
      timeZoneName={timezoneName}
    />
  );

};


export const RealtimeIntensityCardV2Content = ({
  loading,
  forecastData,
  forecastWindowHours=5,
  timeZoneName='US/Central',
}: {loading: boolean, forecastData: IGridStatus[], forecastWindowHours?: number, timeZoneName?: string}) => {
  const navigate = useNavigate();
  const goToRealtimePage = () => navigate('/realtime');

  const forecastDataWindow = forecastData.slice(0, forecastWindowHours);
  const percentWidthEachItem = 100 / Math.max(forecastDataWindow.length, 1);
  const intensityValues = forecastData.map((value: IGridStatus) => value.generatedCarbonIntensityLbsCo2PerMwh);
  const allHighIntensities = forecastDataWindow.length !== 0 && forecastDataWindow
    .every(({ generatedCarbonIntensityLbsCo2PerMwh }: IGridStatus) => generatedCarbonIntensityLbsCo2PerMwh >= percentile(67, intensityValues));
  const allLowIntensities = forecastDataWindow.length !== 0 && forecastDataWindow
    .every(({ generatedCarbonIntensityLbsCo2PerMwh }: IGridStatus) => generatedCarbonIntensityLbsCo2PerMwh <= percentile(34, intensityValues));

  const hourlyItems = forecastDataWindow.map((value: IGridStatus) => {
    return (
      <Grid item key={value.startDate} width={percentWidthEachItem.toString() + '%'}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyItems='center'>
          <CarbonIntensityIcon
            carbonIntensityLbsPerMwh={value.generatedCarbonIntensityLbsCo2PerMwh}
            cleanThresholdLbsPerMwh={percentile(34, intensityValues)}
            dirtyThresholdLbsPerMwh={percentile(67, intensityValues)}
            width={30}
            height={30}
          />
          <Typography paddingTop={1}>{new Date(value.startDate).toLocaleTimeString('default', {hour: 'numeric', timeZone: timeZoneName})}</Typography>
          <Typography
            fontSize={8}
            variant='seMutedUnitsText'>
              {value.generatedCarbonIntensityLbsCo2PerMwh.toFixed(0)} lbs CO₂ / MWh
          </Typography>
        </Box>
      </Grid>
    );
  });

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Carbon intensity forecast'}/>}
        action={
          <>
            <RealTimeIndicator loading={loading}/>
            <ExpandArrow onClick={goToRealtimePage}/>
          </>
        }
      />
      <CardContent>
        <Typography>You can reduce carbon emissions by aligning your grid consumption with the cleanest grid conditions.</Typography>
        <Grid container width={'100%'} sx={{mt: 3}}>
          {hourlyItems}
        </Grid>
      </CardContent>
      {allHighIntensities && <Alert severity='warning'>Carbon intensity is high on the grid for the next {forecastWindowHours} hours, avoid using more energy than necessary!</Alert>}
      {allLowIntensities && <Alert severity='success'>Carbon intensity is low for the next {forecastWindowHours} hours, now is a great time to use more energy!</Alert>}
    </CardBase>
  )
}


export default RealtimeIntensityCardV2;
