import React, { useState } from 'react';

import ViewContainerBase from 'components/ViewContainerBase';
import CardGridBase from 'components/CardGridBase';
import { useAppSelector } from 'modules/store';
import { getHasLiveData, getIsDemoCustomer, getIsXcel } from 'modules/auth/selectors';

import ProjectionsChartCardV1 from './ProjectionsChartCardV1';
import ProjectionsChartCardV2 from './ProjectionsChartCardV2';
import AssumptionsAccordionCard from './AssumptionsAccordionCard';
import ProjectedCostsCard from './ProjectedCostsCard';
import ProjectionsToplineCardV1 from './ProjectionsToplineCard1';
import ProjectionsToplineCardV2 from './ProjectionsToplineCard2';
import ProjectedEnergyConsumptionCard from './ProjectedEnergyConsumptionCard';
import DailyComparisonCard from './DailyComparisonCard';

import './style.css';


// projections view to show just for Gary, out most special customer.
const GarysSpecialProjectionsView = () => {
  const [horizon, setHorizon] = useState(2030);
  const horizonCallback = (newHorizon: number) => setHorizon(newHorizon);

  // I can't describe how much I hate this
  return (
    <ViewContainerBase>
      <CardGridBase>
        <ProjectionsToplineCardV2 horizon={horizon}/>
        <ProjectionsChartCardV2 horizon={horizon} setHorizon={horizonCallback}/>
        <DailyComparisonCard/>
        <AssumptionsAccordionCard/>
      </CardGridBase>
    </ViewContainerBase>
  )
};


const ProjectionsView = () => {
  const isXcel = useAppSelector(getIsXcel)
  const [horizon, setHorizon] = useState(2040);
  const horizonCallback = (newHorizon: number) => setHorizon(newHorizon);

  const isDemo = useAppSelector(getIsDemoCustomer);
  const hasLiveData = useAppSelector(getHasLiveData);

  // Xcel has a weird mix of data, so we special case them
  if (isXcel) return <GarysSpecialProjectionsView />;

  // For 'demo' customers, we don't show either topline card.
  // For non-demo customers without live data, we show mock data (V1 card).
  // For non -demo customers WITH live data, we show the real data (V2 card).
  const showCardV1 = !isDemo && !hasLiveData;
  const showCardV2 = hasLiveData && !isDemo;

  return (
    <ViewContainerBase>
      <CardGridBase>
        {showCardV1 && <ProjectionsToplineCardV1 horizon={horizon}/>}
        {showCardV2 && <ProjectionsToplineCardV2 horizon={horizon}/>}
        {showCardV1 && <ProjectionsChartCardV1 horizon={horizon} setHorizon={horizonCallback}/>}
        {showCardV2 && <ProjectionsChartCardV2 horizon={horizon} setHorizon={horizonCallback}/>}
        {showCardV2 && <DailyComparisonCard/>}
        <AssumptionsAccordionCard/>
        {isDemo && <>
          <ProjectedCostsCard horizon={horizon}/>
          <ProjectedEnergyConsumptionCard horizon={horizon}/>
        </>}
      </CardGridBase>
    </ViewContainerBase>
  )
}


export default ProjectionsView;
