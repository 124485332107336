import * as React from 'react';
import { Card, Divider, Grid } from '@mui/material';

import SummaryStatistic, { LargeSummaryStatSubValue } from 'components/SummaryStatistic';
import { numberToShortString } from 'utils/strings';

import './style.css';

export interface IToplineMetrics {
  totals: {
    consumptionMWh: number,
    generationMWh: number,
  },
  cfe: {
    programPct: number | null,
    gridPct: number,
  },
  excess: {
    consumptionMWh: number,
    generationMWh: number,
  },
}

const ToplineMetrics = ({
  totals,
  cfe,
  excess
}: IToplineMetrics) => {
  return <Grid item sx={{mt: 2, mb: 2}} xs={12}>
    <Card variant="outlined" className="se--card-base" sx={{p: 2 }}>
      <Grid container>
        <SummaryStatistic
          labelText={'Total'}
          labelHeight={"32px"}
          valueText={<div className="program-allocation-multi-metric--container">
              <LargeSummaryStatSubValue label={"Consumption"} value={numberToShortString(totals.consumptionMWh)} unitText="MWh" />
              <LargeSummaryStatSubValue label={"Generation"} value={numberToShortString(totals.generationMWh)} unitText="MWh" />
            </div>}
        />
        <Divider orientation="vertical" flexItem/>
        <SummaryStatistic
          labelText={'CFE Percentage'}
          labelHeight={"32px"}
          valueText={<div className="program-allocation-multi-metric--container">
              <LargeSummaryStatSubValue label={"Total"} value={numberToShortString(Math.min(100, cfe.gridPct + (cfe.programPct || 0)))} unitText="%" />
              {Number.isFinite(cfe.programPct) && <LargeSummaryStatSubValue label={"Program"} value={numberToShortString(cfe.programPct)} unitText="%" />}
              {Number.isFinite(cfe.programPct) && <LargeSummaryStatSubValue label={"Grid"} value={numberToShortString(cfe.gridPct)} unitText="%" />}
          </div>}
        />
        <Divider orientation="vertical" flexItem/>
        <SummaryStatistic
          labelText='Generation Matching'
          labelHeight={"32px"}
          valueText={<div className="program-allocation-multi-metric--container">
              <LargeSummaryStatSubValue label={"Excess Generation"} value={numberToShortString(excess.generationMWh)} unitText="MWh" />
              <LargeSummaryStatSubValue label={"Unmatched Consumption"} value={numberToShortString(excess.consumptionMWh)} unitText="MWh" />
            </div>}
        />
      </Grid>
    </Card>
  </Grid>
}

export default ToplineMetrics;