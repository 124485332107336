import React from 'react';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import * as MockAPI from 'api/mock';
import { IBuildingData } from 'api/types';
import CardTitle from 'components/CardTitle';
import CardBase from 'components/CardBase';
import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import './style.css';


const RealtimeBuildingsCard = () => {
  const buildings: IBuildingData[] = MockAPI.fakeBuildings();
  const isDemoCustomer = useAppSelector(getIsDemoCustomer);

  if (!isDemoCustomer) return null;

  const customRender = (field: string, value: number, color: string, isTotal: boolean) => {
    if (isTotal) {
      return (
        <Box sx={{width: 1}}>{value.toFixed(1)}</Box>
      );
    }
    const minCarbon = Math.min(...buildings.map((x: {[index: string]: number}) => x[field])) * 0.95;
    const maxCarbon = Math.max(...buildings.map((x: {[index: string]: number}) => x[field]));
    return (
      <Box sx={{width: 1, height: 1, display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: 50}}>{value.toFixed(1)}</Box>
        <Box
          sx={{
            width: 0.6 * (value - minCarbon) / (maxCarbon - minCarbon),
            height: 1 / 8,
            bgcolor: color,
            ml: 1
          }}
        />
      </Box>
    );
  }

  const totalCarbon = buildings.reduce((x: number, b: IBuildingData) => x + b.realtimeCarbon, 0);
  const totalKwh = buildings.reduce((x: number, b: IBuildingData) => x + b.realtimeKwh, 0);
  const totalGas = buildings.reduce((x: number, b: IBuildingData) => x + b.realtimeGas, 0);
  const totalsId = '\0totals-row-id';

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Building', type: 'string', flex: 1, headerClassName: "se--datagrid-header", headerAlign: "left"  },
    { field: 'realtimeCarbon', headerName: 'Carbon (lbs of CO₂)', type: 'number', flex: 1, headerClassName: "se--datagrid-header", headerAlign: "left",
      renderCell: params => customRender('realtimeCarbon', params.row.realtimeCarbon, '#C99547', params.id === totalsId)
    },
    { field: 'realtimeKwh', headerName: 'Grid Use (kWh)', type: 'number', flex: 1, headerClassName: "se--datagrid-header", headerAlign: "left",
      renderCell: params => customRender('realtimeKwh', params.row.realtimeKwh, '#6E49FF', params.id === totalsId)
    },
    { field: 'realtimeGas', headerName: 'Gas Use (MMBtu)', type: 'number', flex: 1, headerClassName: "se--datagrid-header", headerAlign: "left",
        renderCell: params => customRender('realtimeGas', params.row.realtimeGas, '#11A9BD', params.id === totalsId)
    }
  ];

  buildings.sort((x: IBuildingData, y: IBuildingData) => y.realtimeCarbon - x.realtimeCarbon);
  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title='Real-time building performance' />}
      />
      <CardContent>
        <DataGrid
          columns={columns}
          rows={[{
            id: totalsId,
            name: 'TOTALS',
            realtimeCarbon: totalCarbon,
            realtimeKwh: totalKwh,
            realtimeGas: totalGas
          }, ...buildings]}
          autoHeight={true}
          pageSize={5}
          rowsPerPageOptions={[5]}
          sx={{border: 0}}
          getRowClassName={(params) => `se--datagrid-row${params.id === totalsId ? '-totals' : ''}`}
        />
      </CardContent>
    </CardBase>
  );
}


export default RealtimeBuildingsCard;
