import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';


interface IBasicDropdownProps {
  id: string;
  value: string | number;
  mapValueToLabel: {[value: string | number]: string};
  placeholder?: string;
  dropdownLabel?: string;
  updateCallback?: (value: string | number) => void;
  formControlSx?: any
}


export default function BasicDropdown(props: IBasicDropdownProps) {
  const labelId = `${props.id}--label`;

  const items = Object.entries(props.mapValueToLabel).map((keyValuePair: [string, string]) => {
    const [value, label] = keyValuePair;
    return (
      <MenuItem value={value} key={value}>{label}</MenuItem>
    );
  });

  return (
    <FormControl sx={{ m: 0, minWidth: '100px', ...(props.formControlSx || {}) }} size="small">
      {props.dropdownLabel ? <FormHelperText>{props.dropdownLabel}</FormHelperText> : ''}
      <Select
        labelId={labelId}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder || ''}
        onChange={(event: any) => { if (props.updateCallback) props.updateCallback(event.target.value)}}
      >
        {items}
      </Select>
    </FormControl>
  );
}
