import React, { useState } from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { makeInterventionPlotPoints } from 'utils/chartAnnotations';

import CardBase from 'components/CardBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import BasicDropdown from 'components/BasicDropdown';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';

import { CustomerInterventionStatus, IProjectionMetrics, IProjectionSeries, ProjectionScenario, TimeResolution } from 'api/types';
import { useGetProjectionSeriesQuery, useGetProjectionToplineMetricsQuery } from 'api/projections';
import { useGetCustomerGoalsQuery } from 'api/summary';
import { useSearchCustomerInterventionsQuery } from 'api/interventions';
import { makeYearlyPlotData } from 'utils/rollup';

import { useAppSelector } from 'modules/store';
import { getIsXcel, getPhysicalUtcOffsetMins } from 'modules/auth/selectors';

import Theme from 'config/Theme';

import './style.css';


enum ProjectionsChartCardMode {
  Emissions = 'emissions',
  Costs = 'costs',
  Energy = 'energy',
  Intensity = 'intensity'
}


const TONS_PER_LB = 1.0 / 2000;
const MWH_PER_KWH = 1e-3;


interface IProjectionsChartCardProps {
  horizon: number;
  setHorizon: (horizon: number) => void;
}


function getToolTipOptions(mode: ProjectionsChartCardMode, resolution: TimeResolution) {
  return {
    [ProjectionsChartCardMode.Emissions]: {
      valueSuffix: ' tons CO₂',
      xDateFormat: (resolution === TimeResolution.YEAR) ? '%Y' : '%b %Y'
    },
    [ProjectionsChartCardMode.Costs]: {
      valuePrefix: '$',
      xDateFormat: (resolution === TimeResolution.YEAR) ? '%Y' : '%b %Y'
    },
    [ProjectionsChartCardMode.Energy]: {
      valueSuffix: ' MWh',
      xDateFormat: (resolution === TimeResolution.YEAR) ? '%Y' : '%b %Y'
    },
    [ProjectionsChartCardMode.Intensity]: {
      valueSuffix: ' lbs CO₂ / MWh',
      xDateFormat: (resolution === TimeResolution.YEAR) ? '%Y' : '%b %Y'
    }
  }[mode];
}


const ProjectionsChartCardV2 = (props: IProjectionsChartCardProps) => {
  const [resolution, setResolution] = useState(TimeResolution.MONTH);
  const [mode, setMode] = useState(ProjectionsChartCardMode.Emissions);
  const isXcel = useAppSelector(getIsXcel);

  const goalsApi = useGetCustomerGoalsQuery();
  const customerGoals = goalsApi.data ? goalsApi.data.data : null;
  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);

  // Query the topline metrics so that we can ensure the projections chart is consistent.
  const toplineApi = useGetProjectionToplineMetricsQuery(
    { forecastHorizon: 2030, applyInterventions: true });
  const currentConsumedMwh = toplineApi.data?.data?.totalConsumedKwh?.currentYear * 1e-3;
  const currentEmissionsTonsCo2 = toplineApi.data?.data?.totalEmissionsLbsCo2.currentYear / 2000;

  const { data, isFetching, isLoading, isError } = useGetProjectionSeriesQuery(
    { forecastHorizon: 2040, applyInterventions: true });

  // Don't request a projection until there are input interventions to pass.
  const plannedInterventionsApi = useSearchCustomerInterventionsQuery({
    status: [CustomerInterventionStatus.IMPLEMENTED, CustomerInterventionStatus.PLANNED],
    calculateImpact: true
  });

  const perWhatTimeInterval = {
    [TimeResolution.YEAR]: 'per year',
    [TimeResolution.MONTH]: 'per month',
  }[resolution as TimeResolution.YEAR | TimeResolution.MONTH];

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [ProjectionsChartCardMode.Emissions]: 'tons of CO₂ ' + perWhatTimeInterval,
    [ProjectionsChartCardMode.Costs]: 'cost ($) ' + perWhatTimeInterval,
    [ProjectionsChartCardMode.Energy]: 'MWh ' + perWhatTimeInterval,
    [ProjectionsChartCardMode.Intensity]: 'lbs CO₂ / MWh'
  }[mode];

  const chartRef = React.createRef<ChartBase>();  // For the export button.

  const chartDataFactory = () => {
    if (isFetching || isLoading || isError || !data || !currentConsumedMwh || !currentEmissionsTonsCo2) {
      return [];
    }

    const extractDataPoint = (value: IProjectionMetrics, mode: ProjectionsChartCardMode) => {
      return {
        x: new Date(value.startDate).valueOf(),
        y: {
          [ProjectionsChartCardMode.Emissions]: value.netEmissionsLbsCo2 * TONS_PER_LB,
          [ProjectionsChartCardMode.Costs]: value.grossEnergyCost,
          [ProjectionsChartCardMode.Energy]: value.sumConsumedKwh * MWH_PER_KWH,
          [ProjectionsChartCardMode.Intensity]: value.ciLbsCo2PerMwh,
        }[mode]
      }
    }

    const getMidCostSeriesLabelName = () => {
      if (!isXcel) return 'Mid-case scenario';

      switch (mode) {
        case ProjectionsChartCardMode.Emissions:
          return 'Projected emissions in grid territory';
        case ProjectionsChartCardMode.Energy:
          return 'Projected grid consumption';
        default:
          return 'Utility regional forecast';
      }
    }

    const tooltipOptions = getToolTipOptions(mode, resolution);
    const chartData: any[] = [];

    Object.entries(data.projections).forEach((value: [string, IProjectionSeries]) => {
      const scenarioName = value[0];
      const series = value[1];

      const midCostName = getMidCostSeriesLabelName();

      const readableScenarioName = {
        [ProjectionScenario.LOW_RENEWABLE_COST]: 'Low renewable cost scenario',
        [ProjectionScenario.MID_RENEWABLE_COST]: midCostName,
        [ProjectionScenario.HIGH_RENEWABLE_COST]: 'High renewable cost scenario'
      }[scenarioName];

      const scenarioColor = {
        [ProjectionScenario.LOW_RENEWABLE_COST]: Theme.palette.chartPurpleColor.main,
        [ProjectionScenario.MID_RENEWABLE_COST]: Theme.palette.chartBlueColor.main,
        [ProjectionScenario.HIGH_RENEWABLE_COST]: Theme.palette.chartOrangeColor.main
      }[scenarioName];

      const seriesData = series.planned
        .filter((value: IProjectionMetrics) => new Date(value.startDate) < new Date(props.horizon + 1, 0, 1))
        .map((value: IProjectionMetrics) => extractDataPoint(value, mode));

      const yearlyData = makeYearlyPlotData(seriesData, 12, mode === ProjectionsChartCardMode.Intensity)
        .filter(value => new Date(value.x).getFullYear() <= props.horizon);

      const scaleFactorToMatchTopline = {
        [ProjectionsChartCardMode.Costs]: 1,
        [ProjectionsChartCardMode.Emissions]: yearlyData.length > 0 ? currentEmissionsTonsCo2 / yearlyData.at(0).y : 1,
        [ProjectionsChartCardMode.Energy]: yearlyData.length > 0 ? currentConsumedMwh / yearlyData.at(0).y : 1,
        [ProjectionsChartCardMode.Intensity]: 1,
      }[mode];

      chartData.push({
        name: readableScenarioName,
        type: 'spline',
        color: scenarioColor,
        // dashStyle: 'ShortDash',
        data: (resolution === TimeResolution.YEAR ? yearlyData : seriesData)
                .map(v => ({ x: v.x, y: v.y * scaleFactorToMatchTopline})),
        tooltip: tooltipOptions,
      });
    });

    const indexOfMidCase = Math.max(0, chartData.findIndex(value => value.name === 'Mid-case scenario'));

    // Add annotations at points in time where the customer plans to implement an intervention.
    if (plannedInterventionsApi.data?.data) {
      const plannedInterventions = plannedInterventionsApi.data.data
        .filter(value => new Date(value.startDate).getFullYear() <= props.horizon)
        .filter(value => new Date(value.startDate).getFullYear() >= new Date().getFullYear());
      const plannedAnnotations = makeInterventionPlotPoints(
        // NOTE(milo): Hacky way to use the mid-case scenario if it exists.
        plannedInterventions, chartData.at(indexOfMidCase)?.data, 'Planned Projects');
      if (plannedAnnotations && plannedAnnotations.data.length > 0 && mode !== ProjectionsChartCardMode.Intensity) {
        chartData.push(plannedAnnotations);
      }
    }

    // Add the customer goal line.
    if (mode == ProjectionsChartCardMode.Costs || mode == ProjectionsChartCardMode.Emissions) {
      const goalMetricValue = {
        [ProjectionsChartCardMode.Emissions]: customerGoals ? customerGoals.annualEmissionsTons : 0,
        [ProjectionsChartCardMode.Costs]: customerGoals ? customerGoals.annualElectricityCost : 0
      }[mode];

      // The customer sets a yearly goal, which we may need to translate to a monthly goal.
      const goalMultiplier = (resolution === TimeResolution.MONTH) ? (1 / 12.0) : 1;

      const goalSeries = chartData[0].data.map((value: { x: number, y: number }) =>
        ({ x: value.x, y: goalMetricValue * goalMultiplier }));

      chartData.push({
        name: 'Goal',
        type: 'line',
        color: Theme.palette.chartTealColor.main,
        data: goalSeries,
        tooltip: tooltipOptions,
      });
    }

    return chartData;
  }

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Projections'}/>}
        action={
          <div>
            <CardRadioButtonGroup
              mode={resolution}
              mapModeToLabel={{
                [TimeResolution.MONTH]: 'Monthly',
                [TimeResolution.YEAR]: 'Yearly'
              }}
              setModeCallback={(value: TimeResolution) => setResolution(value as TimeResolution)}
            />
            <CardRadioButtonGroup
              mode={mode}
              mapModeToLabel={{
                [ProjectionsChartCardMode.Emissions]: 'Emissions',
                [ProjectionsChartCardMode.Energy]: 'Grid Use',
                [ProjectionsChartCardMode.Intensity]: 'Intensity'
              }}
              setModeCallback={(value: string) => setMode(value as ProjectionsChartCardMode)}
            />
            <BasicDropdown
              id={'forecast-horizon-dropdown'}
              value={props.horizon.toString()}
              mapValueToLabel={isXcel ? {2025: '2025', 2030: '2030'} : {
                2025: '2025',
                2030: '2030',
                2035: '2035',
                2040: '2040',
              }}
              updateCallback={(value: string) => props.setHorizon(parseInt(value))}
              formControlSx={{mt: 1, mr: 1}}
            />
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() }}
            >
              Export
            </Button>
          </div>
        }
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          ref={chartRef}
          loading={isFetching || isLoading}
          animated={true}
          chartHeight={300}
          chartContainerId={'projections-chart-series'}
          chartData={chartDataFactory()}
          chartAxisLabelY1={chartAxisLabelY1}
          dateResolution={resolution === TimeResolution.YEAR ? 'year' : 'month'}
          downloadFilename={`projected_${mode}`}
          overrideOptions={{
            time: {
              useUTC: true,
              timezoneOffset: -physicalUtcOffsetMins
            },
            yAxis: {
              min: mode === ProjectionsChartCardMode.Energy ? 0 : undefined
            }
          }}
        />
      </CardContent>
    </CardBase>
  );
}


export default ProjectionsChartCardV2;
