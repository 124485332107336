import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";

import { CardHeader, CardContent, Button, Typography, Box, Skeleton, SvgIcon } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

import CardBase from 'components/CardBase';
import CardTitle from 'components/CardTitle';
import ExpandArrow from 'components/ExpandArrow';


const ProjectTeaserCard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    potentialEmissionReductionTons: 544,
    potentialCostSavingsDollars: 45100,
    numberOfPotentialProjects: 3,
  });

  setTimeout(() => setLoading(false), 500);

  const maybeText = loading ?
    <>
      <Skeleton/>
      <Skeleton/>
    </> :
    <Typography>
      Reduce your carbon footprint by <b>{data.potentialEmissionReductionTons.toLocaleString('default')} tons CO₂</b> {' '}
      and energy costs by <b>{data.potentialCostSavingsDollars.toLocaleString(
        'default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}</b> by implementing {' '}
      {data.numberOfPotentialProjects || 0} projects.
    </Typography>;

  const navigate = useNavigate();
  const goToScenariosPage = () => navigate('/scenarios');

  return (
    <CardBase width={12}>
      <CardHeader
        title={
          <Box display='flex' flexDirection='row' justifyItems='center' alignItems='center'>
            <SvgIcon color='success'>
              <LightbulbOutlinedIcon/>
            </SvgIcon>
            <CardTitle title={'Explore projects to reduce your footprint'}/>
          </Box>
        }
        action={
          <ExpandArrow onClick={goToScenariosPage}/>
        }
      />
      <CardContent>
        <Box>
          {maybeText}
        </Box>
        <Button
          variant='outlined'
          color='primary'
          sx={{mt: 3}}
          onClick={goToScenariosPage}
        >Explore Scenarios</Button>
      </CardContent>
    </CardBase>
  )
}


export default ProjectTeaserCard;
