export function parseNumberBounded(v: string, minValue?: number, maxValue?: number) {
  let raw = v.replaceAll(',', '');
  let parsed = parseFloat(raw);
  if (isNaN(parsed)) {
    return null;
  }
  if ((minValue && parsed < minValue) || (maxValue && parsed >= maxValue)) {
    return null;
  }
  return parsed;
}
