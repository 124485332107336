/**
 * This module defines the global style for Material UI components.
 * https://mui.com/material-ui/customization/how-to-customize/
 **/
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { createTheme, SimplePaletteColorOptions } from '@mui/material/styles';
import React from 'react';

import '../index.css';


// Helper function for retrieving a CSS variable as a string.
const getCssVariable = (varname: string) => {
  const bodyStyles = window.getComputedStyle(document.body);
  return bodyStyles.getPropertyValue(varname).replace(' ', '');
}


// Augment the MUI default theme with new properties.

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutral: SimplePaletteColorOptions;
    chartRedColor: SimplePaletteColorOptions;
    chartTealColor: SimplePaletteColorOptions;
    chartOrangeColor: SimplePaletteColorOptions;
    chartBlueColor: SimplePaletteColorOptions;
    chartPurpleColor: SimplePaletteColorOptions;
    chartGrayColor: SimplePaletteColorOptions;
    chartLightGreenColor: SimplePaletteColorOptions;
    chartDarkGreenColor: SimplePaletteColorOptions;
    chartLightBlueColor: SimplePaletteColorOptions;
    chartDarkBlueColor: SimplePaletteColorOptions;
  }

  interface Palette {
    neutral: SimplePaletteColorOptions;
    chartRedColor: SimplePaletteColorOptions;
    chartTealColor: SimplePaletteColorOptions;
    chartOrangeColor: SimplePaletteColorOptions;
    chartBlueColor: SimplePaletteColorOptions;
    chartPurpleColor: SimplePaletteColorOptions;
    chartGrayColor: SimplePaletteColorOptions;
    chartLightGreenColor: SimplePaletteColorOptions;
    chartDarkGreenColor: SimplePaletteColorOptions;
    chartLightBlueColor: SimplePaletteColorOptions;
    chartDarkBlueColor: SimplePaletteColorOptions;
  }
}


declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    seViewHeaderMutedText: true;
    seViewHeaderTitleText: true;
    seCardTitleText: true;
    seCardSubtitleText: true;
    seCardLabelText: true;
    seBigNumberText: true;
    seMedNumberText: true;
    seMutedUnitsText: true;
    seSmallMutedText: true;
    seReportCardTitle: true;
    seListSubheaderText: true;
  }
}


declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    neutral: true;
  }

  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}


declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    chartRedColor: true
  }
}


interface ExtendedTypographyOptions extends TypographyOptions {
  seViewHeaderMutedText: React.CSSProperties;
  seViewHeaderTitleText: React.CSSProperties;
  seCardTitleText: React.CSSProperties;
  seCardSubtitleText: React.CSSProperties;
  seCardLabelText: React.CSSProperties;
  seBigNumberText: React.CSSProperties;
  seMedNumberText: React.CSSProperties;
  seMutedUnitsText: React.CSSProperties;
  seSmallMutedText: React.CSSProperties;
  seReportCardTitle: React.CSSProperties;
  seListSubheaderText: React.CSSProperties;
}


export const GlobalPalette = {
  // Basic colors.
  White: '#FFFFFF',
  Black: '#000000',

  // Custom theme colors.
  Teal: getCssVariable('--color-teal'),
  Navy: getCssVariable('--color-navy'),
}


const theme = createTheme({
  /*
   * GLOBAL THEME CONFIGURATION
   */
  palette: {
    primary: {
      main: GlobalPalette.Teal
    },
    neutral: {
      light: '#eeeeee',
      main: '#9e9e9e',
      dark: '#000000',
    },
    chartRedColor: {
      main: '#C8644D',
      dark: '#C8644D',
      light: '#F3D6D0'
    },
    chartTealColor: {
      main: '#3BD9BB',
      dark: '#3BD9BB',
      light: '#8CE1D11A'
    },
    chartOrangeColor: {
      main: '#C99547',
      dark: '#C99547',
      light: '#FBF7E5'
    },
    chartBlueColor: {
      main: '#6090E6',
      dark: '#6090E6',
      light: '#F1F5FD'
    },
    chartPurpleColor: {
      main: '#A837DD',
      dark: '#A837DD',
      light: '#A837DD1A'
    },
    chartGrayColor: {
      main: '#757575',
      dark: '#757575',
      light: '#e0e0e0'
    },
    chartLightGreenColor: {
      main: '#8ABC38',
      dark: '#8ABC38',
      light: '#8ABC38'
    },
    chartDarkGreenColor: {
      main: '#348D48',
      dark: '#348D48',
      light: '#348D48'
    },
    chartLightBlueColor: {
      main: '#8CB0F2',
      dark: '#8CB0F2',
      light: '#8CB0F2'
    },
    chartDarkBlueColor: {
      main: '#0C409E',
      dark: '#0C409E',
      light: '#0C409E',
    },
  },

  typography: {
    fontFamily: [
      'Inter',
      '"Helvetica Neue"',
      '"Segoe UI"',
      'Roboto',
      '-apple-system',
      'sans-serif',
    ].join(','),
    subtitle2: {
      fontSize: '12px',
    },
    seViewHeaderMutedText: {
      fontSize: '12px',
      opacity: 0.5,
    },
    seViewHeaderTitleText: {
      fontSize: '20px',
    },
    seCardTitleText: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '27px',
    },
    seCardSubtitleText: {
      fontSize: '16px',
      fontWeight: 400,
    },
    seCardLabelText: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px'
    },
    seBigNumberText: {
      fontWeight: 400,
      fontSize: '30px',
      lineHeight: '36px',
    },
    seMedNumberText: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '20px',
    },
    seMutedUnitsText: {
      fontWeight: 400,
      fontSize: '16px',
      opacity: 0.5
    },
    seSmallMutedText: {
      fontWeight: 400,
      fontSize: '14px',
      opacity: 0.5
    },
    seReportCardTitle: {
      fontSize: '20px',
      fontWeight: 'bold'
    },
    seListSubheaderText: {
      fontSize: '16px',
      fontWeight: 700
    }
  } as ExtendedTypographyOptions,

  /*
   * COMPONENT THEME CONFIGURATION
   */
  components: {
    MuiTypography: {
      styleOverrides: {
        // This is the default variant used for <Typography/> elements.
        body1: {
          fontSize: '14px',
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'neutral' },
          style: {
            textTransform: 'none',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: '6px',
          paddingBottom: '6px'
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        // Slightly adjust padding so that dropdowns are the same height as other buttons.
        select: {
          paddingTop: '8px',
          paddingBottom: '8px'
        },
        outlined: {
          fontSize: '0.8125rem',
          fontWeight: '500',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: 'gray'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '8px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          // For some reason, MUI adds extra padding below the card content. Get rid of that here.
          ":last-child": {
            paddingBottom: '8px'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingBottom: '32px',
          paddingLeft: '32px',
          paddingRight: '32px'
        }
      }
    }
  },
});


export default theme;
