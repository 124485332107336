import * as React from 'react';
import { Card, Divider, Grid, Tooltip } from '@mui/material';

import SummaryStatistic, { LargeSummaryStatSubValue } from 'components/SummaryStatistic';
import { numberToShortString, moneyToString } from 'utils/strings';

import './style.css';

export interface IUtilityToplineMetrics {
  certificateValue: {
    total: number,
    matched: number,
    excess: number,
  }
  carbonIntensity?: {
    locationBased: number,
    marketBased: number,
  },
}

const createCIValue = (ci: number) => {
  const ciValue = numberToShortString(ci);
  return (
    <Tooltip title={`${ciValue} lbs CO2 per MWh`}>
      <>{ciValue}</>
    </Tooltip>
  )
}

const UtilityToplineMetrics = ({
  certificateValue,
  carbonIntensity,
}: IUtilityToplineMetrics) => {
  return <Grid item sx={{mt: 2, mb: 2}} xs={12}>
    <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
      <Grid container>
        <SummaryStatistic
          labelText='Total Certificate Value'
          valueText={moneyToString(certificateValue.total)}
        />
        <Divider orientation="vertical" flexItem/>
        <SummaryStatistic
          labelText='Matched Certificate Value'
          valueText={moneyToString(certificateValue.matched)}
        />
        <Divider orientation="vertical" flexItem/>
        <SummaryStatistic
          labelText='Excess Certificate Value'
          valueText={moneyToString(certificateValue.excess)}
        />
        {carbonIntensity &&
          <>
            <Divider orientation="vertical" flexItem/>
            <SummaryStatistic
              labelText={<div>Carbon Intensity <small className="summary-statistic-metric-title--small">lbs CO<sub>2</sub> per MWh</small></div>}
              valueText={<div className="program-allocation-multi-metric--container">
                  <LargeSummaryStatSubValue label={"Location-Based"} value={createCIValue(carbonIntensity.locationBased)} />
                  <LargeSummaryStatSubValue label={"Market-Based"} value={createCIValue(carbonIntensity.marketBased)} />
                </div>}
            />
          </>
        }
      </Grid>
    </Card>
  </Grid>
}

export default UtilityToplineMetrics;