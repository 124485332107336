import React from 'react';
import { useState } from "react";

import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import CardBase from "components/CardBase";
import CardTitle from "components/CardTitle";
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import BasicDropdown from 'components/BasicDropdown';
import ChartBase from 'components/ChartBase';

import { useGetProjectionSeriesQuery } from 'api/projections';
import { ProjectionScenario } from 'api/types';

import Theme from 'config/Theme';
import { mod, sum } from 'utils/math';
import { useAppSelector } from 'modules/store';
import { getIsXcel } from 'modules/auth/selectors';
import SummaryStatistic from 'components/SummaryStatistic';


enum CardMode {
  Intensity = 'Grid Intensity',
  Emissions = 'Emissions',
  Usage = 'Grid Use'
}


interface IMonthYearSelectorProps {
  years: number[]
  // Months should be 0-indexed.
  month: number
  year: number
  updateMonth?: (m: number) => void
  updateYear?: (y: number) => void
}


const MonthYearSelector = (props: IMonthYearSelectorProps) => {
  const mapMonthIndexToLabel: {[m: number]: string} = {};
  const monthIndexes = [...Array(12).keys()];
  monthIndexes.forEach((m) => mapMonthIndexToLabel[m] = new Date(2020, m, 1).toLocaleString('default', { month: 'short' }));

  const mapYearToLabel: {[y: number]: string} = {};
  props.years.forEach((y: number) => mapYearToLabel[y] = y.toString());

  return (
    <>
      <BasicDropdown
        id={'month-dropdown'}
        value={props.month}
        mapValueToLabel={mapMonthIndexToLabel}
        updateCallback={(m: number) => props.updateMonth && props.updateMonth(m)}
        formControlSx={{mt: 1, mr: 1}}
      />
      <BasicDropdown
        id={'year-dropdown'}
        value={props.year}
        mapValueToLabel={mapYearToLabel}
        updateCallback={(m: number) => props.updateYear && props.updateYear(m)}
        formControlSx={{mt: 1, mr: 1}}
      />
    </>
  );
}


function getToolTipOptions(mode: CardMode) {
  return {
    [CardMode.Emissions]: {
      valueSuffix: ' lbs CO₂',
      xDateFormat: '%l %P',
    },
    [CardMode.Intensity]: {
      valueSuffix: ' lbs CO₂ per kWh',
      xDateFormat: '%l %P',
    },
    [CardMode.Usage]: {
      valueSuffix: ' kWh',
      xDateFormat: '%l %P',
    }
  }[mode];
}


const DailyComparisonCard = () => {
  const [mode, setMode] = useState(CardMode.Intensity);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(2030);
  const [scenario, setScenario] = useState(ProjectionScenario.MID_RENEWABLE_COST);
  const isXcel = useAppSelector(getIsXcel);

  const { data, isFetching, isLoading, isError } = useGetProjectionSeriesQuery(
    { forecastHorizon: 2040, applyInterventions: true });

  // Figure out what scenarios we should show in the dropdown.
  const availableScenarios = Object.keys(data?.carbonIntensityForecast|| {[ProjectionScenario.MID_RENEWABLE_COST]: {}});

  const mapScenarioToLabel = {
    [ProjectionScenario.LOW_RENEWABLE_COST]: 'Optimistic',
    [ProjectionScenario.MID_RENEWABLE_COST]: isXcel ? 'Utility-based carbon intensity' : 'Mid-case',
    [ProjectionScenario.HIGH_RENEWABLE_COST]: 'Pessimistic'
  };

  const mapAvailableToLabel: {[s: string]: string} = {};
  availableScenarios.forEach((s: ProjectionScenario) => mapAvailableToLabel[s] = mapScenarioToLabel[s]);

  const yearRange = [];
  const currentYear = new Date().getFullYear();
  const maxForecastYear = isXcel ? 2032 : 2040;
  for (let y = currentYear + 1; y <= maxForecastYear; ++y) {
    yearRange.push(y);
  }

  const tooltipOptions = getToolTipOptions(mode);

  const chartRef = React.createRef<ChartBase>();  // For the export button.

  // The customer's load profile and carbon intensity could be in a different
  // timezone, depending on the configuration of their customer properties.
  const utcOffsetMins = {
    [CardMode.Emissions]: data?.meta.loadProfileUtcOffsetMins || 0,
    [CardMode.Usage]: data?.meta.loadProfileUtcOffsetMins || 0,
    [CardMode.Intensity]: data?.meta.carbonIntensityUtcOffsetMins || 0,
  }[mode];

  const utcOffsetHours = Math.trunc(utcOffsetMins / 60);

  const refYear = currentYear;

  const makeChartData = () => {
    if (!data) {
      return [];
    }

    const cmpYear = year;

    const monthHourDataByYear: {[year: string]: [][]} = {
      [CardMode.Intensity]: data.carbonIntensityForecast[scenario],
      [CardMode.Emissions]: data.emissionForecast[scenario],
      [CardMode.Usage]: data.loadProfileForecast.planned,
    }[mode];

    const conversionFactor = {
      // Convert lbs per MWh to lbs per kWh
      [CardMode.Intensity]: 1e-3,
      [CardMode.Emissions]: 1,
      [CardMode.Usage]: 1
    }[mode];

    const getSeriesForYear = (whichYear: number) => monthHourDataByYear[whichYear][month]
      .map((value: number, utcHourIndex: number) => {
        return {
          // The only relevant part of the x value is the hour. We map each hour
          // into the customer's local timezone, but then tell Date that it's
          // a date in UTC. Then we also tell highchart to show dates in UTC
          // below. The net result is that the chart hours match up with the
          // customer's physical location.
          x: Date.UTC(2020, month, 15, mod(utcHourIndex + utcOffsetHours, 24)),
          y: value * conversionFactor
        };
      })
      .sort((a, b) => a.x - b.x);

    // might need to add 1 year to the Xcel projections
    const refYearSeries = getSeriesForYear(refYear in monthHourDataByYear ? refYear : refYear + 1);
    const cmpYearSeries = getSeriesForYear(cmpYear);

    const cmpYearGreaterSeries: [number, number, number][] = [];
    const refYearGreaterSeries: [number, number, number][] = [];
    refYearSeries.forEach((refValue: { x: number, y: number }, i) => {
      const refY = refValue.y;
      const cmpY = cmpYearSeries.at(i).y;
      if (refY >= cmpY) {
        refYearGreaterSeries.push([refValue.x, cmpY, refY]);
        cmpYearGreaterSeries.push([refValue.x, refY, refY]);
      } else {
        cmpYearGreaterSeries.push([refValue.x, refY, cmpY]);
        refYearGreaterSeries.push([refValue.x, refY, refY]);
      }
    });

    return [
      {
        name: refYear,
        type: 'line',
        color: Theme.palette.chartBlueColor.main,
        step: 'left',
        data: refYearSeries,
        tooltip: tooltipOptions,
      },
      {
        name: cmpYear,
        type: 'line',
        step: 'left',
        color: Theme.palette.chartOrangeColor.main,
        dashStyle: 'ShortDash',
        data: cmpYearSeries,
        tooltip: tooltipOptions,
      },
      {
        name: 'Greater in the future',
        showInLegend: false,
        type: 'arearange',
        step: 'left',
        color: '#ff000020',
        data: cmpYearGreaterSeries,
        tooltip: {
          // Don't show on tooltip.
          pointFormat: null as null
        }
      },
      {
        name: 'Greater today',
        showInLegend: false,
        type: 'arearange',
        step: 'left',
        color: '#00ff0020',
        data: refYearGreaterSeries,
        tooltip: {
          // Don't show on tooltip.
          pointFormat: null as null
        }
      }
    ];
  }

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [CardMode.Emissions]: 'lbs CO₂',
    [CardMode.Intensity]: 'lbs CO₂ per kWh',
    [CardMode.Usage]: 'kWh'
  }[mode];

  const monthName = new Date(2020, month, 1).toLocaleString('default', { month: 'long' });
  const monthNameShort = new Date(2020, month, 1).toLocaleString('default', { month: 'short' });

  const chartData = makeChartData();

  // Calculations for the summary card on the right side.
  const refAvgLbsPerKwh = chartData.length === 0
    ? 1 : sum(chartData[0].data.map(d => (d as {x: number, y: number}).y)) / chartData[0].data.length;
  const futAvgLbsPerKwh = chartData.length === 0
    ? 1 : sum(chartData[1].data.map(d => (d as {x: number, y: number}).y)) / chartData[1].data.length;
  const percentChangeFromRefToFut = 100 * (futAvgLbsPerKwh - refAvgLbsPerKwh) / refAvgLbsPerKwh;
  const ciIsLowerOrEqualInFuture = percentChangeFromRefToFut <= 0;
  const reductionOrIncrease = ciIsLowerOrEqualInFuture ? 'reduction' : 'increase';
  const metricDecimals = (mode === CardMode.Intensity) ? 2 : 0;
  const metricBeingCompared = {
    [CardMode.Intensity]: 'grid carbon intensity',
    [CardMode.Emissions]: 'grid-related emissions',
    [CardMode.Usage]: 'grid usage',
  }[mode];

  const basedOnText = isXcel
    ? '* Based on utility forecasts.'
    : '* Based on NREL forecasts.';

  return (
    <CardBase width={12}>
      <CardHeader
        title={
          <Tooltip title={<p>Compares an average <strong>{monthName}</strong> day in <strong>{refYear}</strong> and <strong>{year}</strong>.</p>}>
            <div>
              <CardTitle title={'Daily Comparison'}/>
            </div>
          </Tooltip>
        }
        action={
          <div>
            <CardRadioButtonGroup
              mode={mode}
              mapModeToLabel={{
                [CardMode.Intensity]: 'Intensity',
                [CardMode.Emissions]: 'Emissions',
                [CardMode.Usage]: 'Grid Use'
              }}
              setModeCallback={(value: string) => setMode(value as CardMode)}
            />
            <MonthYearSelector
              years={yearRange}
              month={month}
              year={year}
              updateMonth={setMonth}
              updateYear={setYear}
            />
            {!isXcel && <BasicDropdown
              id={'scenario-dropdown'}
              value={scenario}
              mapValueToLabel={{
                [ProjectionScenario.LOW_RENEWABLE_COST]: 'Low renewable cost',
                [ProjectionScenario.MID_RENEWABLE_COST]: 'Mid-case scenario',
                [ProjectionScenario.HIGH_RENEWABLE_COST]: 'High renewable cost'
              }}
              updateCallback={(s: string) => setScenario(s as ProjectionScenario)}
              formControlSx={{mt: 1, mr: 1}}
            />}
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() }}
            >
              Export
            </Button>
          </div>
        }
      />
      <CardContent sx={{p: 2}}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <ChartBase
              ref={chartRef}
              loading={isFetching || isLoading}
              animated={true}
              chartHeight={300}
              chartContainerId={'daily-comparison-chart'}
              chartData={chartData}
              chartAxisLabelY1={chartAxisLabelY1}
              dateResolution={'hour'}
              downloadFilename={`daily_comparison_${mode}`}
              overrideOptions={{
                time: {
                  useUTC: true
                },
                xAxis: {
                  labels: {
                    format: '{value:%l%P}'
                  }
                },
                tooltip: {
                  valueDecimals: 2,
                  // enabled: false
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Card variant='outlined'>
              <CardHeader title={
                <Typography variant='seCardLabelText'>{`${monthNameShort} ${year}`}</Typography>
              }/>
              <Divider/>
              <CardContent>
                <SummaryStatistic
                  loading={isLoading || isFetching}
                  labelText={<strong>{`Average daily ${metricBeingCompared}`}</strong>}
                  valueText={futAvgLbsPerKwh.toLocaleString('default', { maximumFractionDigits: metricDecimals})}
                  unitsText={` ${chartAxisLabelY1}`}
                  sx={{pl: 0, pr: 0}}
                />
                {
                  (isLoading || isFetching) ?
                    <Box sx={{mt: 2}}>
                      <Skeleton/>
                      <Skeleton/>
                    </Box>:
                  <p style={{color: ciIsLowerOrEqualInFuture ? Theme.palette.success.main : Theme.palette.error.main}}>
                    {percentChangeFromRefToFut.toLocaleString('default', { maximumFractionDigits: 1})}% {metricBeingCompared} {reductionOrIncrease} vs. {monthNameShort} {refYear}*
                  </p>
                }
              </CardContent>
              <Divider/>
              <CardActions>
                <Typography variant='seMutedUnitsText' sx={{fontSize: 12}}>{basedOnText}</Typography>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default DailyComparisonCard;
