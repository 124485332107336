import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'

import loadGeneration from 'demo/data/duke/generation';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { generationDataLoading, generationDataNeverLoaded, getGenerationData } from 'modules/demo/selectors';
import { receiveGenerationData, setGenerationLoading } from 'modules/demo/slice';

const columns = [
  {
    field: 'timestamp',
    headerName: 'Hour',
    width: 300,
    sortable: false,
    type: 'string',
  },
  {
    field: 'totalCerts',
    headerName: 'Total Certs',
    width: 150,
    sortable: false,
    type: 'number',
  },
]

const CertsByGeneratorTable = (props: { plant_id_eia: string }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [dataRows, setDataRows] = React.useState([]);

  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const generationLoading = useAppSelector(generationDataLoading);
  const rawGenerationData = useAppSelector(getGenerationData);
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  React.useEffect(() => {
    if (rawGenerationData) {
      const generationByHour: Record<string, number> = {};
      const allData = rawGenerationData.sampled
        .concat(rawGenerationData.purchased_specified)
        .concat(rawGenerationData.purchased_unspecified)
        .concat(rawGenerationData.unbundled_recs);

      allData.forEach(sampledGeneration => {
        if (sampledGeneration.plant_id_eia === props.plant_id_eia) {
          if (generationByHour[sampledGeneration.datetime_utc]) {
            generationByHour[sampledGeneration.datetime_utc] += sampledGeneration.net_generation_mwh;
          } else {
            generationByHour[sampledGeneration.datetime_utc] = sampledGeneration.net_generation_mwh;
          }
        }
      });

      const tableRows: { id: string, timestamp: string, totalCerts: number }[] = [];
      Object.entries(generationByHour).forEach(([hour, generation]) => {
          tableRows.push({ id: hour, timestamp: hour, totalCerts: Math.floor(generation) });
      });
      setDataRows(tableRows);
    }
  }, [rawGenerationData]);

  const startIdx = (page - 1) * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;
  return (
    <DataGrid
      loading={generationLoading}
      rowCount={(dataRows.length || 0)}
      pagination={true}
      rows={dataRows.slice(startIdx, endIdx)}
      rowHeight={25} // this is half the default of 50
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[5, 10, 20]}
      onPageSizeChange={setRowsPerPage}
      onPageChange={(p) => setPage(p + 1)}
      paginationMode="server"
      autoHeight={true}
      onRowClick={(row) => nav(`/inventory/generators/${props.plant_id_eia}/certificates/${row.row.timestamp}`)}
    />
  )
};

export default CertsByGeneratorTable;