import React from 'react';

import { useGetCustomerGoalsQuery } from 'api/summary';
import { useGetExecutiveSeriesQuery } from 'api/executive';
import { TimeResolution } from 'api/types';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CardTitle from 'components/CardTitle';

import { makeTodayPlotLine } from 'utils/chartAnnotations';
import AssumptionsModal from './AssumptionsModal';
import GoalSettingModal from './GoalSettingModalV2';

import makeLinearGradient from 'utils/chartGradient';

import Theme from 'config/Theme';
import { useAppSelector } from 'modules/store';
import { getPhysicalUtcOffsetMins } from 'modules/auth/selectors';

import './style.css';


const makeYearlyData = (monthlyData: { startDate: string, lbsCo2: number }[]) => {
  const totalByYear: {[year: string]: number} = {};
  const countByYear: {[year: string]: number} = {};
  const twelveHoursInMs = 1e3 * 3600 * 12;
  monthlyData.forEach(v => {
    const year = new Date(new Date(v.startDate).valueOf() + twelveHoursInMs).getFullYear();
    if (!totalByYear[year]) {
      totalByYear[year] = 0;
      countByYear[year] = 0;
    }
    totalByYear[year] += v.lbsCo2;
    countByYear[year] += 1;
  });

  const years = Object.keys(totalByYear).sort();
  return years.map(y => ({
    startDate: new Date(parseInt(y), 0, 1).toISOString(),
    lbsCo2: totalByYear[y] * 12 / countByYear[y]
  }));
}


export const EmissionsChartCardV2 = () => {
  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);

  const goalsApi = useGetCustomerGoalsQuery();
  const goalAnnualEmissionsTons = goalsApi.data?.data.annualEmissionsTons || null;

  const params = { applyInterventions: true, lookbackHorizon: 5, forecastHorizon: 8 }
  const { data, isFetching, isLoading, isError } = useGetExecutiveSeriesQuery(params);

  const todayEpoch = Date.now();
  const chartRef = React.createRef<ChartBase>();

  const resolution = TimeResolution.YEAR;

  const makeChartData = () => {
    if (!data) {
      return [];
    }

    let historicalBaseline = data.data.emissions.historical.baseline;
    let historicalNet = data.data.emissions.historical.net;
    let forecastBaseline = data.data.emissions.projected.baseline;
    let forecastNet = data.data.emissions.projected.net;

    if (resolution === TimeResolution.YEAR) {
      historicalBaseline = makeYearlyData(historicalBaseline);
      historicalNet = makeYearlyData(historicalNet);
      forecastBaseline = makeYearlyData(forecastBaseline);
      forecastNet = makeYearlyData(forecastNet);
    }

    const opacityHex = '30';
    let earliestEpochTime = new Date().valueOf();
    let latestEpochTime = new Date().valueOf();

    if (historicalBaseline.length > 0) {
      earliestEpochTime = new Date(historicalBaseline[0].startDate).valueOf();
    }
    if (forecastBaseline.length > 0) {
      latestEpochTime = new Date(forecastBaseline.at(-1).startDate).valueOf();
    }

    // Normalize the projections so that they values match up to present day.
    // This is a somewhat hacky way to make the series line up. Otherwise, there
    // can be a big jump between the historical emissions and forecasted emissions
    // due to different carbon intensities.
    const joinValueHistoricalBaseline = historicalBaseline.at(-1)?.lbsCo2 || 1;
    const joinValueProjectedBaseline = forecastBaseline.at(0)?.lbsCo2 || 1;
    const multiplyProjectedBaselineBy = joinValueHistoricalBaseline / joinValueProjectedBaseline;

    const joinValueHistoricalNet = historicalNet.at(-1)?.lbsCo2 || 1;
    const joinValueProjectedNet = forecastNet.at(0)?.lbsCo2 || 1;
    const multiplyProjectedNetBy = joinValueHistoricalNet / joinValueProjectedNet;

    return [
      {
        name: 'Baseline emissions',
        type: 'areaspline',
        color: Theme.palette.chartOrangeColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartOrangeColor.main, opacityHex),
        data: historicalBaseline.map(v => ({ x: new Date(v.startDate).valueOf(), y: v.lbsCo2 / 2000 }))
      },
      {
        name: 'Actual emissions',
        type: 'areaspline',
        color: Theme.palette.chartBlueColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
        data: historicalNet.map(v => ({ x: new Date(v.startDate).valueOf(), y: v.lbsCo2 / 2000 }))
      },
      {
        name: 'Projected baseline emissions',
        type: 'areaspline',
        showInLegend: false,
        color: Theme.palette.chartOrangeColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartOrangeColor.main, opacityHex),
        // https://api.highcharts.com/highcharts/series.line.dashStyle
        dashStyle: 'LongDash',
        data: forecastBaseline.map(v => ({ x: new Date(v.startDate).valueOf(), y: v.lbsCo2 / 2000 * multiplyProjectedBaselineBy }))
      },
      {
        name: 'Projected actual emissions',
        type: 'areaspline',
        showInLegend: false,
        color: Theme.palette.chartBlueColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
        // https://api.highcharts.com/highcharts/series.line.dashStyle
        dashStyle: 'LongDash',
        data: forecastNet.map(v => ({ x: new Date(v.startDate).valueOf(), y: v.lbsCo2 / 2000 * multiplyProjectedNetBy }))
      },
      {
        name: 'Company goal',
        type: 'spline',
        color: Theme.palette.chartTealColor.main,
        data: [
          { x: earliestEpochTime, y: goalAnnualEmissionsTons },
          { x: latestEpochTime, y: goalAnnualEmissionsTons },
        ],
      },
    ];
  }

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Your emissions over time'}/>}
        action={
          <div>
           <Button
            variant="outlined"
            color="neutral"
            onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() } }
          >
            Export
          </Button>
         </div>
        }
      />
      <CardContent>
        <ChartBase
          ref={chartRef}
          loading={isFetching || isLoading}
          animated={true}
          chartHeight={300}
          chartContainerId={'executive-emissions-chart'}
          chartAxisLabelY1={'tons CO₂ / year'}
          dateResolution={'month'}
          chartData={makeChartData()}
          downloadFilename={'emissions_summary'}
          overrideOptions={{
            xAxis: {
              plotLines: [makeTodayPlotLine(todayEpoch)],
            },
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' tons CO₂ / year',
              shared: true
            },
            time: {
              useUTC: true,
              timezoneOffset: -physicalUtcOffsetMins,
            }
          }}
        />
        <Box>
          <AssumptionsModal openButtonId={'open-modal-button-assumptions'}/>
          <GoalSettingModal openButtonId={'open-modal-button-goals'}/>
          <Box display={'flex'} justifyContent={'end'}>
            <Button variant='outlined' color="neutral" id={'open-modal-button-assumptions'}>See assumptions</Button>
            <Button variant='outlined' color="neutral" id={'open-modal-button-goals'} sx={{ml: 1}}>Edit target</Button>
          </Box>
        </Box>
      </CardContent>
    </CardBase>
  );
}


export default EmissionsChartCardV2;
