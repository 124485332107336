// Defines the mapping from fuel types to colors. Adpted from the MISO dashboard.
export const fuelPalette: Record<string, string> = {
  storage: '#B2292E',
  batteries: '#B2292E',

  nuclear: '#EE7623',
  wind: '#80BC00',
  hydro: '#54C0E8',
  solar: '#FFC844',

  natural_gas: '#0082CA',

  oil: '#004D71',
  petroleum: '#004D71',
  coal: '#636569',

  other: '#A7A8AA',

  geothermal: '#FFC0CB',
  unknown: '#A7A8AA',
  waste: '#483C32',
  refuse: '#483C32',
  biomass: '#A0522D',
  wood: '#D2B48C',
  landfill_gas: '#EFDECD',
  gas_scope_1: '#000000'
}
