import * as React from 'react';
import { Route, Routes } from "react-router-dom";

import AssetTable from 'components/AssetTable';
import AssetView from 'components/AssetView';
import EditAssetView from 'components/AssetView/edit';

import './style.css';


const AssetsView = () => {
  return (
    <Routes>
      <Route path="/" element={<AssetTable />} />
      <Route path=":id">
        <Route index element={<AssetView />} />
        <Route path="edit" element={<EditAssetView />} />
      </Route>
    </Routes>
  )
};

export default AssetsView;