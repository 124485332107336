import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid';

import CardBase from 'components/CardBase';
import CardSaveButton from 'components/CardSaveButton';
import CardTitle from 'components/CardTitle';
import EditParamsModal from './EditParamsModal';
import AdjustIcon from '@mui/icons-material/CandlestickChart';

import { ICustomerIntervention, ICustomerInterventionImpact } from 'api/types';
import { Chip } from '@mui/material';


interface IInterventionTableProps {
  loading: boolean
  loadingError: boolean
  saving: boolean
  savingError: boolean
  selectedInterventionIds: (string | number)[]
  editedInterventions: (ICustomerInterventionImpact & ICustomerIntervention)[]

  setSelectedInterventionIds?: (update: (string | number)[]) => void
  updateInterventionParams?: (update: (ICustomerInterventionImpact & ICustomerIntervention)[]) => void
  saveInterventionsCallback?: () => void
}


const renderTitleCell = (val: {row: ICustomerInterventionImpact & ICustomerIntervention}) => {
  const getsXcelTag = val.row.type === 'xcel/lighting_upgrade_rebate';
  if (!getsXcelTag) {
    return val.row.name;
  } else {
    return <div>{val.row.name} <Chip size="small" label="Utility program" style={{backgroundColor: '#da1020', color: 'white', fontWeight: 600, marginLeft: '8px'}} /></div>;
  }
};


export default function InterventionTable(props: IInterventionTableProps) {

  const renderDetailsButton = (params: GridRenderCellParams) => {
    const editButtonId = `edit-button-${params.id}`;
    const interventionDetails = props.editedInterventions.find((value) => value.id === params.id);
    return (
      <React.Fragment>
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%'}}>
          <Button
            id={editButtonId}
            variant="text"
            color="primary"
            size="small"
            startIcon={<AdjustIcon/>}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Edit
          </Button>
        </Box>
        <EditParamsModal
          openButtonId={editButtonId}
          interventionDetails={interventionDetails}
          updateParamsCallback={(updatedParams, updatedStartYear) => {
            let editedInterventions = structuredClone(props.editedInterventions);
            let interventionToUpdate = editedInterventions.find((value) => value.id === params.id);

            Object.entries(updatedParams).forEach((value) => {
              const paramId = value[0];
              interventionToUpdate.params[paramId].value = value[1].value;
            });
            if (updatedStartYear > 2022 && updatedStartYear < 2100) {
              interventionToUpdate.startDate = new Date(updatedStartYear, 1, 1, 0, 0, 0, 0).toISOString();
            }
            if (props.updateInterventionParams) {
              props.updateInterventionParams(editedInterventions);
            }
          }}/>
      </React.Fragment>
    );
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 340, headerClassName: "se--datagrid-header", renderCell: renderTitleCell, valueFormatter: ({ value }) => value },
    { field: 'lifetimeEmissionReduction', headerName: 'Emission Reduction (tons CO₂)', type: 'number', width: 250, headerClassName: "se--datagrid-header" },
    { field: 'lifetimeCostSavings', headerName: 'Lifetime Savings ($)', type: 'number', width: 180, headerClassName: "se--datagrid-header" },
    { field: 'lifetimeCost', headerName: 'Lifetime Cost ($)', type: 'number', width: 180, headerClassName: "se--datagrid-header" },
    { field: 'settingsButton', headerName: 'Project Details', width: 150,
      renderCell: renderDetailsButton.bind(this), headerClassName: "se--datagrid-header",
      disableReorder: true, disableColumnMenu: true, headerAlign: 'right' }
  ]

  const rows = props.editedInterventions.map((value: ICustomerInterventionImpact & ICustomerIntervention) => {
    return {
      id: value.id,
      name: value.name,
      type: value.interventionType,
      description: value.description,
      lifetimeCost: Math.round(value.addedCapitalCosts + value.addedOperationalCosts),
      lifetimeEmissionReduction: Math.round(value.netEmissionReductionCo2),
      lifetimeCostSavings: Math.round(value.costSavings),
      status: value.status,
    };
  });

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={`Available projects (${rows.length})`}/>}
        action={
          // The onClick callback needs to resolve to a boolean indicating
          // whether the save succeeded.
          <CardSaveButton
            text={'Save Plan'}
            loading={props.saving}
            error={props.savingError}
            onClick={props.saveInterventionsCallback}
          />
        }
      />
      <CardContent>
        <Typography mb={3}>
          Try adding these projects to your decarbonization plan to see their impact.
        </Typography>
        <Box sx={{ height: '500px'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight={true}
            autoPageSize={true}
            pageSize={8}
            rowsPerPageOptions={[8]}
            checkboxSelection
            loading={props.loading}
            error={props.loadingError || undefined}
            selectionModel={props.selectedInterventionIds}
            onSelectionModelChange={(selectedIds: GridSelectionModel) => {
              // NOTE(milo): Need to prevent this from firing when there are now rows.
              if (rows.length === 0) {
                return;
              }
              if (props.setSelectedInterventionIds) {
                props.setSelectedInterventionIds(selectedIds);
              }
            }}
          />
        </Box>
      </CardContent>
    </CardBase>
  );
}
