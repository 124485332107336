import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

import CardBase from 'components/CardBase';
import SummaryStatistic from 'components/SummaryStatistic';

import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import { useGetProjectionToplineMetricsQuery } from 'api/projections';

import './style.css';


const KWH_PER_MWH = 1e-3;
const TONS_PER_LB = 1.0 / 2000;


// Format numbers to the right number of decimals, and also return a placeholder
// string if the data is null or undefined.
const formatToplineMetric = (v: number | null | undefined): string => {
  if (v === null || v === undefined) {
    return 'Unavailable';
  }
  return v.toLocaleString('default', { maximumFractionDigits: 0 });
}


const ProjectionsToplineCard2 = ({ horizon }: { horizon: number }) => {
  // We don't show this card to demo customers (i.e customers without real data).
  const isDemo = useAppSelector(getIsDemoCustomer);
  if (isDemo) return null;

  const desiredForecastHorizon = 2040;
  const { data, isFetching, isLoading, isError } = useGetProjectionToplineMetricsQuery(
    { forecastHorizon: desiredForecastHorizon, applyInterventions: true });

  const currentConsumedMwh = formatToplineMetric(data?.data?.totalConsumedKwh?.currentYear * KWH_PER_MWH);
  const currentEmissionsTonsCo2 = formatToplineMetric(data?.data?.totalEmissionsLbsCo2.currentYear * TONS_PER_LB);

  // The API endpoint gives back summaries at 5Y intervals. We want to choose the
  // latest of those, which is usually 2040.
  const forecastYears = Object.entries(data?.data?.totalConsumedKwh || {}).map(value => value[0]).filter(value => value != 'currentYear');
  const maxForecastYear = forecastYears.length > 0
    ? Math.max(...forecastYears.map(y => parseInt(y))).toString()
    : desiredForecastHorizon.toString();

  // @ts-ignore
  const futureConsumedMwh = formatToplineMetric(data?.data?.totalConsumedKwh[maxForecastYear] * KWH_PER_MWH);
  // @ts-ignore
  const futureEmissionsTonsCo2 = formatToplineMetric(data?.data?.totalEmissionsLbsCo2[maxForecastYear] * TONS_PER_LB);

  return (
    <CardBase width={12}>
      <CardContent>
        <Grid container>
          <SummaryStatistic
            labelText={'Current annual emissions'}
            valueText={currentEmissionsTonsCo2}
            unitsText={currentEmissionsTonsCo2 === 'Unavailable' ? '' : ' tons CO₂'}
            loading={isFetching || isLoading}
            labelHeight={'30px'}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={`Projected emissions by ${maxForecastYear}`}
            valueText={futureEmissionsTonsCo2}
            unitsText={futureEmissionsTonsCo2 === 'Unavailable' ? '' : ' tons CO₂'}
            loading={isLoading || isFetching}
            labelHeight={'30px'}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Current annual grid usage'}
            valueText={currentConsumedMwh}
            unitsText={currentConsumedMwh === 'Unavailable' ? '' : ' MWh'}
            loading={isLoading || isFetching}
            labelHeight={'30px'}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={`Projected usage by ${maxForecastYear}`}
            valueText={futureConsumedMwh}
            unitsText={futureConsumedMwh === 'Unavailable' ? '' : ' MWh'}
            loading={isLoading || isFetching}
            labelHeight={'30px'}
          />
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default ProjectionsToplineCard2;
