import * as React from 'react';

import ViewContainerBase from 'components/ViewContainerBase';
import CardGridBase from 'components/CardGridBase';

import ScenarioChartCardsV1 from './ScenarioChartCardsV1';
import ScenarioChartCardsV2 from './ScenarioChartCardsV2';
import GoalSettingCardV1 from 'components/GoalSettingCardV1';
import GoalSettingCardV2 from 'components/GoalSettingCardV2';

import { useAppSelector } from 'modules/store';
import { getHasLiveData, getIsXcel } from 'modules/auth/selectors';

import './style.css';


const ScenariosView = () => {
  const hasLiveData = useAppSelector(getHasLiveData);
  const isXcel = useAppSelector(getIsXcel);
  const showCardV2 = hasLiveData || isXcel;
  const showCardV1 = !showCardV2;

  return (
    <ViewContainerBase>
      <CardGridBase>
        {showCardV1 && <GoalSettingCardV1 inputLayout={'wide'} showDividers={true}/>}
        {showCardV2 && <GoalSettingCardV2 inputLayout={'wide'} showDividers={true}/>}
        {showCardV1 && <ScenarioChartCardsV1/>}
        {showCardV2 && <ScenarioChartCardsV2/>}
      </CardGridBase>
    </ViewContainerBase>
  )
}


export default ScenariosView;
