import { fromPairs, groupBy, indexBy, mapObjIndexed, prop, sortBy } from "ramda";

import { IAccountProgramAssignment, IProgram, IProgramGeneratorAssignment } from "modules/demo/slice";
import { ICustomerAccountProfile } from "demo/data/duke/customers";
import { IFullGenerationData, IGenerationData } from "demo/data/duke/generation";
import { sum } from "utils/math";
import { timer } from "utils/timer";

export interface IHourlyAllocationResult {
  hour: string;
  overageMWh: number;
  shortFallMWh: number;
  loadMWh: number;
  genMWh: number,
  bySource: Record<string, number>,
}


interface IRunAllocationInput {
  programs: IProgram[],
  generatorAssignment: IProgramGeneratorAssignment[],
  customerAccountAssignment: IAccountProgramAssignment[],
  generation: IFullGenerationData,
  customerAccounts: ICustomerAccountProfile[],
  optimizeFor: 'minimalShortfall' | 'minimalOverage',
}

export interface IAnnualPooledAllocationResult {
  programId: string;
  shortFallMWh: number;
  overageMWh: number;
  totalGenMWh: number;
  totalLoadMWh: number;
  bySource: Record<string, number>,
  hourlyResults: IHourlyAllocationResult[];
}

interface IAnnualDedicatedAllocationResult {
  programId: string;
  customerResults: {
    accountId: number;
    rootCustomerId: string;
    generatorId: string;
    shortFallMWh: number;
    overageMWh: number;
    totalGenMWh: number;
    totalLoadMWh: number;
    bySource: Record<string, number>,
    hourlyResults: IHourlyAllocationResult[];
  }[];
}

interface I247PooledAllocationResult {
  programId: string;
  hourlyResults: IHourlyAllocationResult[];
}

interface ICustomerHourlyGeneratorMatch {
  rootCustomerId: string;
  accountId: number;
  generatorId: string;
  hourlyResults: IHourlyAllocationResult[];
}

interface I247DedicatedAllocationResult {
  programId: string;
  results: ICustomerHourlyGeneratorMatch[];
}

export type ProgramAllocationResult = IAnnualDedicatedAllocationResult | IAnnualPooledAllocationResult | I247PooledAllocationResult | I247DedicatedAllocationResult;


export const instanceOfAnnualDedicatedResult = (result: ProgramAllocationResult): result is IAnnualDedicatedAllocationResult => {
  return 'customerResults' in result;
};

export const instanceOfAnnualPooledResult = (result: ProgramAllocationResult): result is IAnnualPooledAllocationResult => {
  return 'shortFallMWh' in result;
};

export const instanceOf247PooledResult = (result: ProgramAllocationResult): result is I247PooledAllocationResult => {
  return 'hourlyResults' in result && !('bySource' in result);
}

export const instanceOf247DedicatedResult = (result: ProgramAllocationResult): result is I247DedicatedAllocationResult => {
  return 'results' in result;
}

export interface IRunAllocationResults {
  allocationResults: ProgramAllocationResult[];
  certReleaseResults: ICertReleasableResult[],
  residualMixResults: {
    generation: IGenerationData[];
  },
  lastRunState: {
    generatorProgramsByGeneratorId: Record<string, IProgramGeneratorAssignment>,
    customerProgramsByAccountId: Record<number, IAccountProgramAssignment>,
    programs: IProgram[],
  }
}


// TODO: this logic will change when the customer load data changes
const getSummedLoad = (customer: ICustomerAccountProfile) => {
  return sum(customer.load.map(day => day.consumed_kwh)) / 1000
};

interface IGenDataWithSource extends IGenerationData {
  source: string;
}

const getHourlyAllocationResults = (gen: IGenDataWithSource[], customerAccounts: ICustomerAccountProfile[]): IHourlyAllocationResult[] => {
  const generationsByHour = groupBy(prop('dateTime'), gen.map(d => ({ dateTime: new Date(d.datetime_utc).getTime().toString(), ...d})))
  const generationByHour = fromPairs(
    Object.entries(generationsByHour)
      .map(([dateTime, gens]) => {
        const totalGenMWh = sum(gens.map(prop('net_generation_mwh')));
        const groupedBySource = groupBy(prop('source'), gens);
        const genBySource = mapObjIndexed((gensInSource) => sum(gensInSource.map(g => g.net_generation_mwh)), groupedBySource);
        return [dateTime, {genMWh: totalGenMWh, bySource: genBySource}]
      })
  )
  const loadByHour: Record<string, number> = {};
  customerAccounts.forEach(acc => {
    acc.load.forEach(hour => {
      const dateTime = new Date(hour.start_date).getTime().toString();
      if (!loadByHour[dateTime]) {
        loadByHour[dateTime] = 0;
      }
      loadByHour[dateTime] = loadByHour[dateTime] + (hour.consumed_kwh / 1000);
    });
  });
  return Object.entries(generationByHour).map(([hourEpoch, {genMWh, bySource}]) => ({
    hour: new Date(parseInt(hourEpoch)).toISOString(),
    overageMWh: Math.max(0, genMWh - (loadByHour[hourEpoch] || 0)),
    shortFallMWh: Math.max(0, (loadByHour[hourEpoch] || 0) - genMWh),
    loadMWh: loadByHour[hourEpoch] || 0,
    genMWh,
    bySource,
  }));
}


const chooseGeneratorForCustomerAccounts = (programAccounts: ICustomerAccountProfile[], relevantGeneration: IGenDataWithSource[]) => {
  const generatorWithTotalGeneration = Object.entries(groupBy(prop('plant_id_eia'), relevantGeneration)).map(([generatorId, generation]) => {
    return {
      generatorId,
      generation,
      summedGenerationMWh: sum(generation.map(gen => gen.net_generation_mwh)),
    };
  });

  const customerAccountWithLoad = programAccounts.map(acc => ({summedLoadMWh: getSummedLoad(acc), customerAccount: acc}));
  const dedicatedGeneratorIds = new Set();
  const customerGeneratorPairs: {shortFallMWh: number, overageMWh: number, rootCustomerId: string, accountId: number, generatorId: string, totalGenMWh: number, totalLoadMWh: number, bySource: Record<string, number>, hourlyResults: IHourlyAllocationResult[]}[] = [];

  customerAccountWithLoad.forEach((customerAccount) => {
    const minGenerator = generatorWithTotalGeneration.reduce((soFar, nextUp) => {
      if (dedicatedGeneratorIds.has(nextUp.generatorId)) return soFar;
      if (soFar === null) return nextUp;

      const currentDiff = Math.abs(soFar.summedGenerationMWh - customerAccount.summedLoadMWh);
      const nextDiff = Math.abs(nextUp.summedGenerationMWh - customerAccount.summedLoadMWh);

      // TODO: take optimizeFor into account
      return currentDiff < nextDiff ? soFar : nextUp;
    }, null);

    const bySource: Record<string, number> = {rec: 0, owned: 0, 'purchased.specified': 0, 'purchased.unspecified': 0};
    bySource[minGenerator.generation[0].source] = minGenerator.summedGenerationMWh;

    dedicatedGeneratorIds.add(minGenerator.generatorId);
    customerGeneratorPairs.push({
      overageMWh: Math.max(0, minGenerator.summedGenerationMWh - customerAccount.summedLoadMWh),
      shortFallMWh: Math.max(0, customerAccount.summedLoadMWh - minGenerator.summedGenerationMWh),
      rootCustomerId: customerAccount.customerAccount.rootCustomerId,
      accountId: customerAccount.customerAccount.id,
      generatorId: minGenerator.generatorId,
      totalGenMWh: minGenerator.summedGenerationMWh,
      totalLoadMWh: customerAccount.summedLoadMWh,
      hourlyResults: getHourlyAllocationResults(minGenerator.generation, [customerAccount.customerAccount]),
      bySource,
    });
  });

  return customerGeneratorPairs;
}

const run247PooledAllocation = ({
  relevantGeneration,
  programAccounts,
}: {
  programAccounts: ICustomerAccountProfile[],
  relevantGeneration: IGenDataWithSource[],
}) => {
  // TODO: this might be different in the future
  return getHourlyAllocationResults(relevantGeneration, programAccounts);
};


const run247DedicatedAllocation = ({
  customerAccounts,
  programAccounts,
  relevantGeneration,
  allGeneration,
}: {
  generatorAssignment: IProgramGeneratorAssignment[],
  programAccounts: ICustomerAccountProfile[],
  relevantGeneration: IGenDataWithSource[],
  allGeneration: IGenDataWithSource[],
  customerAccounts: ICustomerAccountProfile[],
}) => {
  const pairs = chooseGeneratorForCustomerAccounts(programAccounts, relevantGeneration);
  const customerHourlyGeneratorMatch: ICustomerHourlyGeneratorMatch[] = [];
  pairs.forEach(p => {
    const customerAccountId = p.accountId;
    const generatorId = p.generatorId;
    const loadAndGenByHour: Record<number, {loadMWh: number, genMWh: number, bySource: Record<string, number>}> = {};
    customerAccounts.find(cust => cust.id === customerAccountId).load.forEach(load => {
      const hour = new Date(load.start_date).getTime();
      loadAndGenByHour[hour] = {loadMWh: load.consumed_kwh / 1000, genMWh: 0, bySource: {rec: 0, owned: 0, 'purchased.specified': 0, 'purchased.unspecified': 0}};
    });
    allGeneration.forEach(gen => {
      const hour = new Date(gen.datetime_utc).getTime();
      if (loadAndGenByHour[hour] && gen.plant_id_eia === generatorId) {
        loadAndGenByHour[hour].genMWh = gen.net_generation_mwh;
        loadAndGenByHour[hour].bySource[gen.source] = loadAndGenByHour[hour].bySource[gen.source] + gen.net_generation_mwh;
      }
    });
    customerHourlyGeneratorMatch.push({
      accountId: customerAccountId,
      rootCustomerId: p.rootCustomerId,
      generatorId: generatorId,
      hourlyResults: Object.entries(loadAndGenByHour).map(([hourEpoch, {loadMWh, genMWh, bySource}]) => ({
        hour: new Date(parseInt(hourEpoch)).toISOString(),
        overageMWh: Math.max(0, genMWh - loadMWh),
        shortFallMWh: Math.max(0, loadMWh - genMWh),
        loadMWh,
        genMWh,
        bySource,
      })),
    })
  });
  return customerHourlyGeneratorMatch;
};

interface ICertReleasableResult {
  hour: string,
  fromProgramId: string,
  certsClaimable: {
    unclaimed: number,
    claimed: Record<string, number>, // map of program ID -> number of certs claimed
  }
}

const applyCertificatesReleased = (releases: ICertReleasableResult[], programAllocations: ProgramAllocationResult[]) => {
  const releasesByProgramId = groupBy(prop('fromProgramId'), releases);
  return programAllocations.map(allocation => {
    const releasesForProgram = releasesByProgramId[allocation.programId];

    // no adjustment needed
    if (!releasesForProgram || releasesForProgram.length === 0) {
      return allocation;
    }

    const releasesByHour = indexBy(prop('hour'), releasesForProgram);

    if (instanceOf247DedicatedResult(allocation)) {
      const newResults = allocation.results.map(result => {
        const newHourlyResults = result.hourlyResults.map(hour => {
          const release = releasesByHour[hour.hour];
          if (!release) {
            return hour;
          }

          const claimedFromProgram = sum(Object.values(release.certsClaimable.claimed));
          const claimedFromOverage = Math.min(hour.overageMWh, claimedFromProgram);
          // TODO: make sure to subtract the amount claimed from the release, so that other gens don't release it as well
          // if (claimedFromProgram > hour.overageMWh) {
          //   releasesByHour[hour.hour].certsClaimable... = claimedFromProgram - hour.overageMWh;
          // }
          return {
            ...hour,
            genMWh: hour.genMWh - claimedFromOverage,
            overageMWh: hour.overageMWh - claimedFromOverage,
          };
        });

        return {
          ...result,
          hourlyResults: newHourlyResults,
        };
      });

      return {
        ...allocation,
        results: newResults,
      }
    }

    if (instanceOf247PooledResult(allocation)) {
      const newHourlyResults = allocation.hourlyResults.map(hour => {
        const release = releasesByHour[hour.hour];
        if (!release) {
          return hour;
        }

        const claimedFromHour = sum(Object.values(release.certsClaimable.claimed));
        const claimedFromOverage = Math.min(hour.overageMWh, claimedFromHour);
        return {
          ...hour,
          genMWh: hour.genMWh - claimedFromOverage,
          overageMWh: hour.overageMWh - claimedFromOverage,
        };
      });

      return {
        ...allocation,
        hourlyResults: newHourlyResults,
      }
    }

    if (instanceOfAnnualPooledResult(allocation)) {
      let genMWhSubtracted = 0;
      const newHourlyResults = allocation.hourlyResults.map(hour => {
        const release = releasesByHour[hour.hour];
        if (!release) {
          return hour;
        }

        const claimedFromHour = sum(Object.values(release.certsClaimable.claimed));
        const claimedFromOverage = Math.min(hour.overageMWh, claimedFromHour);

        genMWhSubtracted += claimedFromOverage;

        return {
          ...hour,
          genMWh: hour.genMWh - claimedFromOverage,
          overageMWh: hour.overageMWh - claimedFromOverage,
        }
      });

      return {
        ...allocation,
        overageMWh: allocation.overageMWh - genMWhSubtracted,
        totalGenMWh: allocation.totalGenMWh - genMWhSubtracted,
        hourlyResults: newHourlyResults,
      }
    }

    if (instanceOfAnnualDedicatedResult(allocation)) {
      const newCustomerResults = allocation.customerResults.map(custResult => {
        let genMWhSubtracted = 0;
        const newHourlyResults = custResult.hourlyResults.map(hour => {
          const release = releasesByHour[hour.hour];
          if (!release) {
            return hour;
          }

          const claimedFromProgram = sum(Object.values(release.certsClaimable.claimed));
          const claimedFromOverage = Math.min(hour.overageMWh, claimedFromProgram);
          // TODO: make sure to subtract the amount claimed from the release, so that other gens don't release it as well
          // if (claimedFromProgram > hour.overageMWh) {
          //   releasesByHour[hour.hour].certsClaimable... = claimedFromProgram - hour.overageMWh;
          // }
          genMWhSubtracted += claimedFromOverage;
          return {
            ...hour,
            genMWh: hour.genMWh - claimedFromOverage,
            overageMWh: hour.overageMWh - claimedFromOverage,
          };
        });

        return {
          ...custResult,
          hourlyResults: newHourlyResults,
          totalGenMWh: custResult.totalGenMWh - genMWhSubtracted,
          overageMWh: custResult.overageMWh - genMWhSubtracted,
        }
      });

      return {
        ...allocation,
        customerResults: newCustomerResults,
      };
    }
  });
};


const reallocateWithCertClaimsForAnnualDedicatedResult = (allocationResult: IAnnualDedicatedAllocationResult, certsReleasable: ICertReleasableResult[], programReleaseMap: string[]): {
  newCertsReleasable: ICertReleasableResult[],
  newAllocationResult: IAnnualDedicatedAllocationResult,
} => {

  return {
    newCertsReleasable: certsReleasable, // TODO:
    newAllocationResult: allocationResult, // TODO
  }
};

const reallocateWithCertClaimsForAnnualPooledResult = (allocationResult: IAnnualPooledAllocationResult, certsReleasable: ICertReleasableResult[], programsClaimable: string[]): {
  newCertsReleasable: ICertReleasableResult[],
  newAllocationResult: IAnnualPooledAllocationResult,
} => {
  const programId = allocationResult.programId;
  const certsReleasableWithId = [...certsReleasable].map(c => ({...c, id: `${c.fromProgramId}.${c.hour}`}));

  const certsReleasableByHour = groupBy(prop('hour'), certsReleasableWithId);

  const modifiedCertsById: Record<string, typeof certsReleasableWithId[0]> = {};

  const certIsClaimable = (cert: typeof certsReleasableWithId[0]) => programsClaimable.includes(cert.fromProgramId);

  let claimedGenMWh = 0;
  let totalShortFallMWh = allocationResult.shortFallMWh;

  const newHourlyResults = allocationResult.hourlyResults.map(hourlyResult => {
    let shortFallMWh = hourlyResult.shortFallMWh;
    let newGen = hourlyResult.genMWh;

    certsReleasableByHour[hourlyResult.hour]?.filter(certIsClaimable).forEach(cert => {
      if (totalShortFallMWh > 0) {
        const toClaim = Math.min(cert.certsClaimable.unclaimed, totalShortFallMWh);
        totalShortFallMWh = totalShortFallMWh - toClaim;
        shortFallMWh -= toClaim;
        newGen += toClaim;
        claimedGenMWh += toClaim;
        modifiedCertsById[cert.id] = {
          ...cert,
          certsClaimable: {
            claimed: {...cert.certsClaimable.claimed, [programId]: toClaim},
            unclaimed: cert.certsClaimable.unclaimed - toClaim,
          }
        };
      }
    });

    if (totalShortFallMWh < 0) {
      console.warn(`shortFall is less than 0 program(${programId})`);
    }

    return {
      ...hourlyResult,
      shortFallMWh: Math.max(0, shortFallMWh),
      genMWh: newGen,
    }
  });

  const newAllocationResult: IAnnualPooledAllocationResult = {
    totalGenMWh: allocationResult.totalGenMWh + claimedGenMWh,
    overageMWh: allocationResult.overageMWh,
    programId,
    shortFallMWh: totalShortFallMWh,
    bySource: allocationResult.bySource, // TODO: account for claimed gen
    totalLoadMWh: allocationResult.totalLoadMWh,
    hourlyResults: newHourlyResults,
  };

  return {
    newCertsReleasable: certsReleasableWithId.map(cr => {
      const modifiedCr = modifiedCertsById[cr.id];
      if (modifiedCr) {
        return modifiedCr;
      } else {
        return cr;
      }
    }),
    newAllocationResult,
  };
};

const reallocateWithCertClaimsFor247PooledResult = (allocationResult: I247PooledAllocationResult, certsReleasable: ICertReleasableResult[], programsClaimable: string[]): {
  newCertsReleasable: ICertReleasableResult[],
  newAllocationResult: I247PooledAllocationResult,
} => {
  const programId = allocationResult.programId;
  const certsReleasableWithId = [...certsReleasable].map(c => ({...c, id: `${c.fromProgramId}.${c.hour}`}));

  const certsReleasableByHour = groupBy(prop('hour'), certsReleasableWithId);

  const modifiedCertsById: Record<string, typeof certsReleasableWithId[0]> = {};

  const certIsClaimable = (cert: typeof certsReleasableWithId[0]) => programsClaimable.includes(cert.fromProgramId);

  const newHourlyResults = allocationResult.hourlyResults.map((hourlyResult) => {
    let shortFallMWh = hourlyResult.shortFallMWh;
    let newGen = hourlyResult.genMWh;

    certsReleasableByHour[hourlyResult.hour]?.filter(certIsClaimable).forEach(cert => {
      if (shortFallMWh > 0) {
        const toClaim = Math.min(cert.certsClaimable.unclaimed, shortFallMWh);
        shortFallMWh = shortFallMWh - toClaim;
        newGen = newGen + toClaim;
        modifiedCertsById[cert.id] = {
          ...cert,
          certsClaimable: {
            claimed: {...cert.certsClaimable.claimed, [programId]: toClaim},
            unclaimed: cert.certsClaimable.unclaimed - toClaim,
          }
        };
      }
    });

    if (shortFallMWh < 0) {
      console.warn(`shortFall is less than 0 program(${programId})`);
    }

    return {
      ...hourlyResult,
      shortFallMWh: Math.max(0, shortFallMWh),
      genMWh: newGen,
    }
  });

  return {
    newCertsReleasable: certsReleasableWithId.map(cr => {
      const modifiedCr = modifiedCertsById[cr.id];
      if (modifiedCr) {
        return modifiedCr;
      } else {
        return cr;
      }
    }),
    newAllocationResult: {
      ...allocationResult,
      hourlyResults: newHourlyResults,
    },
  }
};

const reallocateWithCertClaimsFor247DedicatedResult = (allocationResult: I247DedicatedAllocationResult, certsReleasable: ICertReleasableResult[], programsClaimable: string[]): {
  newCertsReleasable: ICertReleasableResult[],
  newAllocationResult: I247DedicatedAllocationResult,
} => {
  const programId = allocationResult.programId;
  const certsReleasableWithId = [...certsReleasable].map(c => ({...c, id: `${c.fromProgramId}.${c.hour}`}));

  const certsReleasableByHour = groupBy(prop('hour'), certsReleasableWithId);

  const modifiedCertsById: Record<string, typeof certsReleasableWithId[0]> = {};
  const certIsClaimable = (cert: typeof certsReleasableWithId[0]) => programsClaimable.includes(cert.fromProgramId);

  const newResults = allocationResult.results.map((result) => {

    const newHourlyResults = result.hourlyResults.map(hourlyResult => {
      let shortFall = hourlyResult.shortFallMWh;
      let newGen = hourlyResult.genMWh;

      // claim the MWh from certs if possible
      certsReleasableByHour[hourlyResult.hour]?.filter(certIsClaimable).forEach(cert => {
        // this just takes the first claimable hour, perhaps that's not the best approach
        if (shortFall > 0) {
          const toClaim = Math.min(cert.certsClaimable.unclaimed, shortFall);
          shortFall = shortFall - toClaim;
          newGen = newGen + toClaim;
          modifiedCertsById[cert.id] = {
            ...cert,
            certsClaimable: {
              claimed: {...cert.certsClaimable.claimed, [programId]: toClaim},
              unclaimed: cert.certsClaimable.unclaimed - toClaim,
            }
          };
        }
      });

      if (shortFall < 0) {
        console.warn(`shortFall is less than 0 program(${programId})`);
      }

      return {
        ...hourlyResult,
        shortFallMWh: Math.max(0, shortFall),
        genMWh: newGen,
      }

    });

    return {
      ...result,
      hourlyResults: newHourlyResults,
    }
  })

  return {
    newCertsReleasable: certsReleasableWithId.map(cr => {
      const modifiedCr = modifiedCertsById[cr.id];
      if (modifiedCr) {
        return modifiedCr;
      } else {
        return cr;
      }
    }),
    newAllocationResult: {
      programId,
      results: newResults,
    },
  }
}

export const runAllocation = async ({
  programs,
  generatorAssignment,
  customerAccountAssignment,
  generation,
  customerAccounts,
  optimizeFor,
}: IRunAllocationInput): Promise<IRunAllocationResults> => {
  timer.start('runAllocation');
  const sortedPrograms = sortBy(prop('priority'), programs);
  const usedGeneratorIds = new Set();
  const customerAccountssById = fromPairs(customerAccounts.map(c => ([c.id, c])));
  const allocationResults: ProgramAllocationResult[] = [];
  const makeWithSource = (source: string) => (gen: IGenerationData) => ({...gen, source})
  const allGeneration = generation.sampled.map(makeWithSource('owned'))
    .concat(generation.purchased_specified.map(makeWithSource('purchased.specified')))
    .concat(generation.purchased_unspecified.map(makeWithSource('purchased.unspecified')))
    .concat(generation.unbundled_recs.map(makeWithSource('rec')));
  let certReleasableResults: ICertReleasableResult[] = [];

  while (sortedPrograms.length > 0) {
    const nextProgram = sortedPrograms.shift();
    const programGeneratorIds = new Set(generatorAssignment.filter(a => a.programId === nextProgram.id).map(prop('generatorId')));
    const programAccounts = customerAccountAssignment.filter(a => a.programId === nextProgram.id).map(c => customerAccountssById[c.accountId]);
    const isRelevant = (g: IGenerationData) => programGeneratorIds.has(g.plant_id_eia) && !usedGeneratorIds.has(g.plant_id_eia);
    const relevantGeneration = allGeneration.filter(isRelevant);
    relevantGeneration.forEach(gen => usedGeneratorIds.add(gen.plant_id_eia));

    if (nextProgram.is247Program) {
      // TODO: round down to the nearest MWh for each of generation/load in the program
      if (nextProgram.generatorCertificateDistribution === 'dedicated') {
        const results = run247DedicatedAllocation({
          allGeneration,
          customerAccounts,
          programAccounts,
          relevantGeneration,
          generatorAssignment,
        });
        results.forEach(result => {
          result.hourlyResults.forEach(hourResult => {
            if (hourResult.overageMWh > 0) {
              certReleasableResults.push({
                hour: hourResult.hour,
                fromProgramId: nextProgram.id,
                certsClaimable: {
                  claimed: {},
                  unclaimed: hourResult.overageMWh,
                }
              });
            }
          });
        });
        allocationResults.push({
          programId: nextProgram.id,
          results,
        })
      } else {
        // 24/7 pooled program
        const hourlyResults = run247PooledAllocation({programAccounts, relevantGeneration});
        hourlyResults.forEach(hourResult => {
          if (hourResult.overageMWh > 0) {
            certReleasableResults.push({
              hour: hourResult.hour,
              fromProgramId: nextProgram.id,
              certsClaimable: {
                claimed: {},
                unclaimed: hourResult.overageMWh,
              }
            })
          }
        });
        allocationResults.push({
          programId: nextProgram.id,
          hourlyResults,
        });
      }
    } else {
      if (nextProgram.generatorCertificateDistribution === 'dedicated') {
        // annual dedicated program
        const customerResults = chooseGeneratorForCustomerAccounts(programAccounts, relevantGeneration)
        customerResults.forEach(result => {
          result.hourlyResults.forEach(hourResult => {
            if (hourResult.overageMWh > 0) {
              certReleasableResults.push({
                hour: hourResult.hour,
                fromProgramId: nextProgram.id,
                certsClaimable: {
                  claimed: {},
                  unclaimed: hourResult.overageMWh,
                }
              })
            }
          })
        });
        allocationResults.push({
          programId: nextProgram.id,
          customerResults,
        });
      } else {
        // annual pooled program
        const summedGenerationMWh = sum(relevantGeneration.map(prop('net_generation_mwh')));
        const groupedBySource = groupBy(prop('source'), relevantGeneration);
        const genBySource = mapObjIndexed((gensInSource) => sum(gensInSource.map(g => g.net_generation_mwh)), groupedBySource);
        const summedLoadMWh = sum(programAccounts.map(acc => getSummedLoad(acc)));
        const overage = Math.max(0, summedGenerationMWh - summedLoadMWh);
        const shortFall = Math.max(0, summedLoadMWh - summedGenerationMWh);
        const hourlyResults = getHourlyAllocationResults(relevantGeneration, programAccounts);
        hourlyResults.forEach(hourResult => {
          if (hourResult.overageMWh > 0) {
            certReleasableResults.push({
              hour: hourResult.hour,
              fromProgramId: nextProgram.id,
              certsClaimable: {
                claimed: {},
                unclaimed: hourResult.overageMWh,
              }
            })
          }
        });

        const hourlyOverage = hourlyResults.reduce((soFar, nextUp) => soFar + nextUp.overageMWh, 0);
        if (hourlyOverage !== overage) {
          console.warn(`annual pooled program: hourly and overall overage do not line up: hourlyMWh: ${hourlyOverage}, overallMWh: ${overage}`);
        }

        allocationResults.push({
          programId: nextProgram.id,
          shortFallMWh: shortFall,
          overageMWh: overage,
          totalGenMWh: summedGenerationMWh,
          totalLoadMWh: summedLoadMWh,
          bySource: genBySource,
          hourlyResults,
        });
      }
    }
  }

  const generatorNotUsed = (d: IGenerationData) => !usedGeneratorIds.has(d.plant_id_eia)

  // map of program ID => list of program IDs they can claim certs from
  const programReleaseMap: Record<string, string[]> = {};
  let postCertReleaseAllocationResults = allocationResults;
  programs.forEach(program => {
    programReleaseMap[program.id] = program.canReceiveFromProgramIds.filter(canTakeFromId => (
      programs.find(p => p.id === canTakeFromId)?.canReleaseToProgramIds.includes(program.id)
    ));
  });

  // going through prioritized order
  // TODO: finish for the other program types
  postCertReleaseAllocationResults = allocationResults.map(allocationResult => {
    if (instanceOf247DedicatedResult(allocationResult)) {
      const {
        newAllocationResult,
        newCertsReleasable,
      } = reallocateWithCertClaimsFor247DedicatedResult(allocationResult, certReleasableResults, programReleaseMap[allocationResult.programId]);
      certReleasableResults = newCertsReleasable;
      return newAllocationResult;
    } else if (instanceOf247PooledResult(allocationResult)) {
      const {
        newAllocationResult,
        newCertsReleasable,
      } = reallocateWithCertClaimsFor247PooledResult(allocationResult, certReleasableResults, programReleaseMap[allocationResult.programId])
      certReleasableResults = newCertsReleasable;
      return newAllocationResult;
    } else if (instanceOfAnnualDedicatedResult(allocationResult)) {
      const {
        newAllocationResult,
        newCertsReleasable,
      } = reallocateWithCertClaimsForAnnualDedicatedResult(allocationResult, certReleasableResults, programReleaseMap[allocationResult.programId])
      certReleasableResults = newCertsReleasable;
      return newAllocationResult;
    } else if (instanceOfAnnualPooledResult(allocationResult)) {
      const {
        newAllocationResult,
        newCertsReleasable,
      } = reallocateWithCertClaimsForAnnualPooledResult(allocationResult as IAnnualPooledAllocationResult, certReleasableResults, programReleaseMap[(allocationResult as IAnnualPooledAllocationResult).programId])
      certReleasableResults = newCertsReleasable;
      return newAllocationResult;
    } else {
      return allocationResult;
    }
  });


  // remove the generation that was "released" from each program's allocation
  const postReleaseAppliedResults = applyCertificatesReleased(certReleasableResults, postCertReleaseAllocationResults);

  timer.stop('runAllocation');
  return Promise.resolve({
    allocationResults: postReleaseAppliedResults,
    certReleaseResults: certReleasableResults,
    residualMixResults: {
      generation: allGeneration.filter(generatorNotUsed)
    },
    lastRunState: {
      generatorProgramsByGeneratorId: indexBy(prop('generatorId'), generatorAssignment),
      customerProgramsByAccountId: indexBy(prop('accountId'), customerAccountAssignment),
      programs,
    }
  });
};