import React, { useEffect } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Sidebar from 'components/Sidebar';
import { getInstance } from 'api/http';
import ExecutiveSummaryView from 'layouts/ExecutiveSummary';
import RealtimeView from 'layouts/Realtime';
import HistoricalView from 'layouts/Historical';
import ProjectionsView from 'layouts/Projections';
import ScenariosView from 'layouts/Scenarios';
import ReportsView from 'layouts/Reports';
import SettingsView from 'layouts/Settings';
import AssetsView from 'layouts/Assets';
import TeammatesView from 'layouts/Teammates';
import OverviewView from 'layouts/Overview';
import InventoryView from 'layouts/Inventory';
import AllocationView from 'layouts/Allocation';
import ProgramsView from 'layouts/Programs';
import SolarControlRealtimeView from 'layouts/SolarBatteryControl/realtime';
import SolarControlHistorical from 'layouts/SolarBatteryControl/historical';
import ProgramSummaryView from 'layouts/ProgramSummary';
import { DashboardConfigPages } from 'api/types';
import { getBootstrapDone, getPagesConfig } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { receiveCustomer, receiveUser, setBootstrapDone } from 'modules/auth/slice';
import camelizeKeys from 'utils/camelizeKeys';

import './App.css';


const SidebarComponent = (props: { children: any }) => {
  return (
    <React.Fragment>
      <Sidebar />
      {props.children}
    </React.Fragment>
  );
}

const getRootPage = (availablePages: DashboardConfigPages[]) => {
  if (availablePages.includes(DashboardConfigPages.EXECUTIVE_VIEW)) {
    return '/summary';
  } else if (availablePages.includes(DashboardConfigPages.PROGRAM_SUMMARY)) {
    return '/program-summary';
  } else if (availablePages.includes(DashboardConfigPages.REC_INVENTORY)) {
    return '/inventory';
  } else if (availablePages.includes(DashboardConfigPages.ASSET_MGMT)) {
    return '/assets/'
  } else {
    // this may not be the correct default in the long term
    return '/summary';
  }
};


const App = () => {
  const dispatch = useAppDispatch();
  const bootstrapDone = useAppSelector(getBootstrapDone);
  const pagesConfig = useAppSelector(getPagesConfig);
  const pages = pagesConfig.availablePages;

  const handleGetMeSuccess = (response: any) => {
    dispatch(receiveUser(camelizeKeys(response.data)));
  }
  const handleGetCustomerSuccess = (response: any) => {
    dispatch(receiveCustomer(camelizeKeys(response.data)));
  }

  const bootstrap = async (): Promise<void> => {
    const API = getInstance();

    await Promise.all([
      API.get('/api/v1/users/me'),
      API.get('/api/v1/customers/me'),
    ])
      .then(([
        meUserResponse,
        meCustomerResponse
      ]) => {
        handleGetMeSuccess(meUserResponse);
        handleGetCustomerSuccess(meCustomerResponse);
        dispatch(setBootstrapDone(true));
      })
      .catch(errs => {
        console.warn(errs);
        dispatch(setBootstrapDone(true));
      });
  }

  // Run bootstrap when the component mounts.
  useEffect(() => {
    bootstrap();
  }, []);

  if (!bootstrapDone) {
    return <Box className='se--loading-indicator-container'>
      <CircularProgress />
    </Box>
  }

  const rootRoute = getRootPage(pages);

  // TODO: make this cleaner, it causes the page to whitescreen for about half a second
  if (window.location.pathname === '/summary' && rootRoute !== '/summary') {
    return window.location.pathname = rootRoute;
  }

  return (
    <BrowserRouter>
      <div className="se--app-container">
        <Routes>
          <Route path="/summary" element={<SidebarComponent><ExecutiveSummaryView /></SidebarComponent>} />
          <Route path="/program-summary" element={<SidebarComponent><ProgramSummaryView /></SidebarComponent>} />
          <Route path="/realtime" element={<SidebarComponent><RealtimeView /></SidebarComponent>} />
          <Route path="/historical" element={<SidebarComponent><HistoricalView /></SidebarComponent>} />
          <Route path="/projections" element={<SidebarComponent><ProjectionsView /></SidebarComponent>} />
          <Route path="/scenarios" element={<SidebarComponent><ScenariosView /></SidebarComponent>} />
          <Route path="/reports" element={<SidebarComponent><ReportsView /></SidebarComponent>} />
          <Route path="/settings" element={<SidebarComponent><SettingsView /></SidebarComponent>} />
          <Route path="/login" element={<Navigate to={rootRoute} replace />} />
          <Route path="/assets/*" element={<SidebarComponent><AssetsView /></SidebarComponent>} />
          <Route path="/teammates" element={<SidebarComponent><TeammatesView /></SidebarComponent>} />
          <Route path="/overview" element={<SidebarComponent><OverviewView /></SidebarComponent>} />
          <Route path="/inventory/*" element={<SidebarComponent><InventoryView /></SidebarComponent>} />
          <Route path="/allocation/*" element={<SidebarComponent><AllocationView /></SidebarComponent>} />
          <Route path="/programs/*" element={<SidebarComponent><ProgramsView /></SidebarComponent>} />
          <Route path="/solar-integration" element={<SidebarComponent><SolarControlRealtimeView></SolarControlRealtimeView></SidebarComponent>} />
          <Route path="/solar-integration-historical" element={<SidebarComponent><SolarControlRealtimeView></SolarControlRealtimeView></SidebarComponent>} />
          <Route path="/*" element={<Navigate to={rootRoute} replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}


export default App;