import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Alert from "@mui/material/Alert";

import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  generationDataLoading,
  generationDataNeverLoaded,
  getCustomerAccountList,
  getGeneratorList,
  getPrograms,
  getAccountProgramAssignment,
  getGeneratorProgramAssignment,
  getGenerationData,
  getHasAllocationBeenEdited,
  getCertificateFlowData,
} from 'modules/demo/selectors';
import { IGenerator } from 'demo/data/generators';
import loadGeneration from "demo/data/duke/generation";
import { runAllocation } from 'demo/rec_allocation';
import {
  CertificateDistribution,
  receiveGenerationData,
  setGenerationLoading,
  updateProgramPriority,
  receiveAllocationResults,
  IAccountProgramAssignment,
  IProgramGeneratorAssignment,
  discardConsoleChanges,
} from 'modules/demo/slice';

import './style.css';
import CustomersTable from 'demo/components/CustomerTable';
import GeneratorsTable from 'demo/components/GeneratorTable';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const columns: GridColDef[] = [
  {
    field: 'priority',
    headerName: 'Priority',
    minWidth: 80,
    flex: 0.1,
    type: 'number',
    sortable: false,
    renderCell: (params: GridRenderCellParams<IGenerator>) => {
      const dispatch = useAppDispatch();

      const changePriority = (event: React.MouseEvent, newPriority: number) => {
        event.stopPropagation();
        dispatch(updateProgramPriority({programId: params.row.id, priority: newPriority}));
      };

      if (!isFinite(params.row.priority)) return <div />;

      return <div className="programs-list-page-row-priority--container">
        <div className="programs-list-row-priority-icons--container">
          <KeyboardArrowUpIcon className="programs-list-row-priority--icon" onClick={(e) => changePriority(e, params.row.priority - 1)} color={params.row.priority === 1 ? 'disabled' : 'inherit'}/>
          {/* TODO: don't hard-code the disabled attribute to priority 4 */}
          <KeyboardArrowDownIcon  className="programs-list-row-priority--icon" onClick={(e) => changePriority(e, params.row.priority + 1)} color={params.row.priority === 4 ? 'disabled' : 'inherit'}/>
        </div>
        <div className="programs-list-page-row-priority--value">{params.row.priority}</div>
      </div>
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    sortable: false,
    type: 'string',
    flex: 0.5,
  },
  {
    field: 'is247Program',
    headerName: 'Accounting Period',
    description: 'The granularity for which the certificates from the generation sources are assigned to the customer or reserved to the program. This will either be "hourly" for 24/7 load matching programs, or "annual" for traditional REC programs.',
    minWidth: 60,
    sortable: false,
    type: 'string',
    valueGetter: ({value}: GridValueGetterParams<boolean>) => value ? 'hourly' : 'annual',
    flex: 0.2,
  },
  {
    field: 'generatorCertificateDistribution',
    headerName: 'Cert Assignment',
    description: 'Determines whether the certificates from the energy sources are assigned to the customer or reserved to the program. This is based on whether or not the program reserves specific plants or generators to specific customers or whether generation is shared between all customers enrolled in the program.',
    minWidth: 120,
    sortable: false,
    type: 'string',
    valueGetter: ({value}: GridValueGetterParams<CertificateDistribution>) => value === 'dedicated' ? 'customer' : 'program',
    flex: 0.2,
  },
  {
    field: 'numCustomers',
    headerName: 'Enrollments',
    description: 'The number of customers who are enrolled in the program.',
    minWidth: 120,
    sortable: false,
    type: 'number',
    flex: 0.2,
  },
  {
    field: 'numGenerators',
    headerName: 'Inventory Sources',
    description: 'The number of inventory sources contributing certificates to the program.',
    minWidth: 120,
    sortable: false,
    type: 'number',
    flex: 0.2,
  }
]


const ProgramsTable = () => {
  const programs = useAppSelector(getPrograms);
  const customerAccounts = useAppSelector(getCustomerAccountList);
  const customerAccountAssignment: IAccountProgramAssignment[] = Object.values(useAppSelector(getAccountProgramAssignment));
  const generatorAssignment: IProgramGeneratorAssignment[] = Object.values(useAppSelector(getGeneratorProgramAssignment));
  const dispatch = useAppDispatch();
  const generation = useAppSelector(getGenerationData);
  // selecting this to "pre-load" the allocation overview page
  useAppSelector(getCertificateFlowData);
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const nav = useNavigate();
  const generatorList = useAppSelector(getGeneratorList);
  const generationLoading = useAppSelector(generationDataLoading);
  const [allocationLoading, setAllocationLoading] = React.useState(false);
  const allocationEdited = useAppSelector(getHasAllocationBeenEdited);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);

  const pagePrograms = [
    ...programs,
    {
      id: 'Standard Ratepayer',
      priority: Infinity,
      name: 'Standard Ratepayer',
      description: 'The default ratepayer who is not enrolled in a program.',
      is247Program: false,
      generatorCertificateDistribution: 'pooled',
    }
  ].sort((a, b) => a.priority - b.priority).map(program => {
    return {
      ...program, ...{
        numCustomers: customerAccounts.filter(customerAccount => customerAccount.programId === program.id).length,
        numGenerators: generatorList.filter(generator => generator.programId == program.id).length
      }
    }
  });

  const allocationDisabled = pagePrograms.filter(program => {
    return program.generatorCertificateDistribution === 'dedicated' && program.numCustomers > program.numGenerators
  }).length > 0;

  const onRunAllocationClick = () => {
    setAllocationLoading(true);
    setDialogOpen(false);
    setTimeout(() => runAllocation({
      programs,
      customerAccounts,
      customerAccountAssignment,
      generatorAssignment,
      generation,
      // TODO: make this configurable
      optimizeFor: 'minimalShortfall',
    }).then(data => {
      dispatch(receiveAllocationResults({data}));
      setAllocationLoading(false);
    }).catch(err => {
      console.warn('Allocation run failed', err);
      setAllocationLoading(false);
    }), 10);
  };

  return (
    <div className='programs-list-page--container'>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirm Run Allocation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you would like to run allocation with the current program settings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={onRunAllocationClick} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
      <div className="programs-list-page-header--container">
        <h1>Programs</h1>
        <div className='programs-list-page-allocation-button--container'>
          {allocationDisabled &&
            <Alert className='programs-list-page-allocation-warning' severity="warning">
              One or more customer certificate assigned programs have more customers than generators.
            </Alert>
          }
          {allocationEdited && <Button onClick={() => dispatch(discardConsoleChanges())} className="programs-list-page-discard--button">Discard Changes</Button>}
          <LoadingButton variant="contained" loading={allocationLoading} disabled={allocationDisabled} onClick={() => setDialogOpen(true)}>Run Allocation</LoadingButton>
        </div>
      </div>
      <DataGrid
        loading={generationLoading || allocationLoading}
        rows={pagePrograms}
        columns={columns}
        autoHeight
        paginationMode="client"
        hideFooter
        getRowClassName={(row) => row.id ? 'programs-list-page--row' : ''}
        onRowClick={(row) => row.id !== 'Standard Ratepayer' ? nav(`/programs/${row.id}/edit`) : undefined}
      />
      <h2 className="programs-list-page--subheader">Customers</h2>
      <CustomersTable />
      <h2 className="programs-list-page--subheader">Inventory</h2>
      <GeneratorsTable />
    </div>
  )
};

export default ProgramsTable;