import React, { useState } from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import CardTitle from 'components/CardTitle';

import { useGetRealtimeChartDataV2Query } from 'api/realtime';
import { fuelPalette } from 'utils/fuelPalette';
import { camelToSnake } from 'utils/strings';

import { useAppSelector } from 'modules/store';
import { getPhysicalUtcOffsetMins } from 'modules/auth/selectors';
import { mapOffsetToReadableTimezone } from 'utils/timezone';
import { sum } from 'utils/math';

import './style.css';
import RealTimeIndicator from 'components/RealTimeIndicator';


enum CardMode {
  Emissions = 'Emissions',
  Electricity = 'Electricity'
}


const normalizeGeneratedFuelMix = (mix: {[fuelCategory: string]: number}) => {
  const totalGenerationMw = sum(Object.entries(mix).map(v => v[1]));
  const normalizedMix = structuredClone(mix);
  Object.keys(normalizedMix).forEach((fuelType: string) => {
    if (totalGenerationMw > 0) {
      normalizedMix[fuelType] /= totalGenerationMw;
    }
  });
  return normalizedMix;
}


const stripSuffixFromFuelNames = (mix: {[fuelCategory: string]: number}) => {
  const output: {[fuelCategory: string]: number} = {};
  Object.entries(mix).forEach(value => {
    if (!value[0].toLowerCase().includes('horizon')) {
      const name = value[0].replace('Mw', '');
      output[name] = value[1];
    }
  });
  return output;
}


const sanitizeFuelType = (fuelType: string): string => {
  return camelToSnake(fuelType).replaceAll('_', ' ');
}


// Breaks a customer's consumption during each hour into a "usage mix".
const calculateUsageMix = (
    hourlyConsumption: { startDate: string, data: Partial<{ sumConsumedKwh: number, minConsumedKw: number, maxConsumedKw: number, meanConsumedKw: number }> }[],
    normalizedFuelMix: { startDate: number, generatedFuelMix: {[fuelType: string]: number} }[],
    forecast: boolean
  ) => {
  return hourlyConsumption.map(value => {
    const startDate = new Date(value.startDate).valueOf();
    const genFuelMixAtTime = normalizedFuelMix.find(v => v.startDate === startDate)?.generatedFuelMix || {};
    const consumedMwhAtTime = value.data.sumConsumedKwh * 1e-3;

    const usageMixAtTime = structuredClone(genFuelMixAtTime);
    Object.keys(usageMixAtTime).forEach(fuelType => {
      usageMixAtTime[fuelType] *= consumedMwhAtTime;
    });
    return { startDate: startDate, mix: usageMixAtTime, forecast };
  });
}


const ResourceMixCard = () => {
  const [mode, setMode] = useState(CardMode.Electricity);

  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);
  const timezoneName = mapOffsetToReadableTimezone(physicalUtcOffsetMins);

  const params = { timezone: timezoneName };
  // NOTE(milo): Use this if we want to re-enable auto refresh.
  // const optios = { pollingInterval: 60*1000 }
  const { data, isLoading, isFetching } = useGetRealtimeChartDataV2Query(params);

  const chartRef = React.createRef<ChartBase>();  // For the export button.

  const makeSeriesChartData = () => {
    if (!data || !data.hourlyGridStatus || data.hourlyGridStatus.length === 0) {
      return {series: [], pie: []};
    }

    // TODO(milo): Need to find an 'other' emission factor!
    const lbsPerMwhByFuelType: {[fuelType: string]: { source: string, value: number }} = {
      ...data.emissionFactors,
      solar: { source: 'UI', value: 0 },
      wind: { source: 'UI', value: 0 },
      hydro: { source: 'UI', value: 0 },
      nuclear: { source: 'UI', value: 0 }
    };

    // First get the current date in local time, then set the hours to the end of the day.
    const endOfTodayLocal = new Date(new Date().getTime() + physicalUtcOffsetMins * 60 * 1000);
    endOfTodayLocal.setUTCHours(23, 59, 59, 999);
    const endOfTodayLocalEpoch = endOfTodayLocal.valueOf() - (physicalUtcOffsetMins * 60 * 1e3);

    // Normalize the generated fuel mix into fractions from 0 to 1.
    const genFuelMix = data.hourlyGridStatus.map(value => ({
      startDate: new Date(value.startDate).valueOf(),
      generatedFuelMix: stripSuffixFromFuelNames(normalizeGeneratedFuelMix(value.generatedFuelMix))
    }));

    // Get the forecasted fuel mix, but limit to the end of today.
    const forecastGenFuelMix = data.forecastedHourlyGridStatus.map(value => ({
      startDate: new Date(value.startDate).valueOf(),
      generatedFuelMix: stripSuffixFromFuelNames(normalizeGeneratedFuelMix(value.generatedFuelMix))
    }))
    .filter(d => d.startDate < endOfTodayLocalEpoch);

    // Multiply the normalized fuel mix by consumed kWh in each hour to get the kWh breakdown.
    const consumedMwhMix = calculateUsageMix(data.hourlyConsumption, genFuelMix, false);
    const forecastConsumedMwhMix = calculateUsageMix(data.forecastedHourlyConsumption, forecastGenFuelMix, true);

    // Multiply the kWh breakdown by fuel emission factors to get the emission mix.
    const consumedTonsCo2Mix = consumedMwhMix.map(value => {
      const emissionMixAtTime = structuredClone(value.mix);
      Object.keys(emissionMixAtTime).forEach(fuelType => {
        // Use an emission factor of zero if fuel type isn't found.
        const lbsCo2PerMwh: number = lbsPerMwhByFuelType[fuelType]?.value || 0;
        emissionMixAtTime[fuelType] *= (lbsCo2PerMwh / 2000);
      });
      return { startDate: value.startDate, mix: emissionMixAtTime, forecast: false };
    });

    const forecastConsumedTonsCo2Mix = forecastConsumedMwhMix.map(value => {
      const emissionMixAtTime = structuredClone(value.mix);
      Object.keys(emissionMixAtTime).forEach(fuelType => {
        // Use an emission factor of zero if fuel type isn't found.
        const lbsCo2PerMwh: number = lbsPerMwhByFuelType[fuelType]?.value || 0;
        emissionMixAtTime[fuelType] *= (lbsCo2PerMwh / 2000);
      });
      return { startDate: value.startDate, mix: emissionMixAtTime, forecast: true };
    });

    const seriesByFuelType: {
      [fuelType: string]: {
        data: { x: number, y: number, forecast: boolean, opacity?: number }[],
        name: string,
        type: string,
        color: string,
        showInLegend: boolean,
      }
    } = {};

    // Convert the series of fuel mixes to a plotable format.
    const selectedFuelMix = {
      [CardMode.Electricity]: consumedMwhMix.concat(forecastConsumedMwhMix),
      [CardMode.Emissions]: consumedTonsCo2Mix.concat(forecastConsumedTonsCo2Mix),
    }[mode];

    selectedFuelMix.forEach(value => {
      Object.entries(value.mix).map((fuelAndValue: [string, number]) => {
        const fuelType = fuelAndValue[0];
        const fuelValue = fuelAndValue[1];
        if (!seriesByFuelType[fuelType]) {
          seriesByFuelType[fuelType] = {
            name: sanitizeFuelType(fuelType),
            type: 'column',
            color: fuelPalette[camelToSnake(fuelType)] || '#000000',
            showInLegend: false,
            data: [],
          };
        }
        seriesByFuelType[fuelType].data.push({
          x: value.startDate,
          y: fuelValue,
          forecast: value.forecast,
          opacity: value.forecast ? 0.3 : 1.0,
        });

        if (fuelValue > 0) {
          seriesByFuelType[fuelType].showInLegend = true;
        }
      })
    });

    const units = {
      [CardMode.Emissions]: 'lbs CO₂',
      [CardMode.Electricity]: 'kWh'
    }[mode];

    const pieChartData = [{
      name: units,
      type: 'pie',
      colorByPoint: true,
      data: Object.entries(seriesByFuelType).map((value) => {
        return {
          name: sanitizeFuelType(value[0]),
          y: sum(value[1].data.filter(v => !v.forecast).map(v => v.y)),
          color: fuelPalette[camelToSnake(value[0])] || '#000000'
        };
      })
    }];

    // Flatten the mapping into a list of items.
    return {
      series: Object.entries(seriesByFuelType).map((v) => v[1]),
      pie: pieChartData,
    };
  }

  const chartData = makeSeriesChartData();

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [CardMode.Emissions]: 'tons of CO₂ consumed',
    [CardMode.Electricity]: 'MWh consumed'
  }[mode];

  const tooltipValueSuffix = {
    [CardMode.Emissions]: ' tons CO₂',
    [CardMode.Electricity]: ' MWh'
  }[mode];

  return (
    <CardBase width={12}>
      <CardHeader
        title={<CardTitle title={'Breakdown of your grid and emission sources'}/>}
        action={
          <div>
            <RealTimeIndicator loading={isLoading || isFetching}/>
            <CardRadioButtonGroup
              mode={mode}
              mapModeToLabel={{
                [CardMode.Electricity]: 'Grid Use',
                [CardMode.Emissions]: 'Emissions',
              }}
              setModeCallback={(value) => setMode(value as CardMode)}
            />
            <Button
              variant="outlined"
              color="neutral"
              sx={{m: 1}}
              onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() }}
            >Export</Button>
          </div>
        }
      />
      <CardContent>
        <Grid container>
          {/* Left side */}
          <Grid item xs={3}>
            <ChartBase
              loading={isLoading}
              refreshing={isFetching}
              animated={true}
              chartHeight={350}
              chartContainerId={'resource-mix-pie-chart'}
              chartData={chartData.pie}
              overrideOptions={{
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    innerSize: '50%',
                    dataLabels: {
                      enabled: false,
                    }
                  }
                }
              }}
            />
          </Grid>
          {/* Right side */}
          <Grid item xs={9}>
            <ChartBase
              ref={chartRef}
              loading={isLoading}
              refreshing={isFetching}
              animated={true}
              chartHeight={350}
              chartContainerId={'resource-mix-hourly-chart'}
              chartAxisLabelY1={chartAxisLabelY1}
              chartData={chartData.series}
              dateResolution={'hour'}
              overrideOptions={{
                plotOptions: {
                  series: {
                    stacking: 'normal'
                  },
                },
                time: {
                  // We use a "negative means west", whereas highchart uses the opposite.
                  timezoneOffset: -physicalUtcOffsetMins,
                  useUTC: true
                },
                tooltip: {
                  shared: false,
                  valueDecimals: 1,
                  valueSuffix: tooltipValueSuffix,
                },
                xAxis: {
                  labels: {
                    format: '{value:%l%P}'
                  },
                },
                yAxis: {
                  min: 0,
                }
              }}
          />
          </Grid>
        </Grid>
      </CardContent>
    </CardBase>
  );
}


export default ResourceMixCard;
