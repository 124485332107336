import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Card, Divider, Grid } from '@mui/material';
import { sum } from 'ramda';

import { useFetchAssetsPageQuery, IAsset } from 'api/assets';
import { useSearchAssetEventsQuery } from 'api/asset_events';
import CardGridBase from 'components/CardGridBase';
import SummaryStatistic, { LargeSummaryStatSubValue } from 'components/SummaryStatistic';
import ViewContainerBase from 'components/ViewContainerBase';
import SolarGenerationChart from './SolarGenerationChart';
import BatteryChart from './BatteryChart';

const extractValue = (d: { sumGeneratedKwh?: number }) => {
  return d.sumGeneratedKwh * 1e-3;
}

const SolarToplineMetrics = ({ solarAssetId }:
  { solarAssetId: string }) => {
  const { data: dayOfGeneration, isFetching, isLoading } = useSearchAssetEventsQuery({
    start: moment().startOf('day').toString(),
    end: moment().toString(),
    assetId: solarAssetId,
    eventTypes: ['energy_generation'],
    resolution: '1d',
  }, { skip: !solarAssetId });

  const dailyMWh = dayOfGeneration ? sum(
    dayOfGeneration.data.filter(event => event.assetId === solarAssetId).map(event => extractValue(event.data))
  ) : 0;

  const batteryIsCharging = false; // TODO

  return (
    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
      <Card variant='outlined' className='se--card-base' sx={{ p: 2 }}>
        <Grid container>
          <SummaryStatistic
            labelText='Daily Solar Generation'
            valueText={dailyMWh / 1000.0}
            unitsText={'kWh'}
            loading={isFetching || isLoading}
          />
          <Divider orientation="vertical" flexItem />
          <SummaryStatistic
            labelText='Battery State'
            valueText={batteryIsCharging ? 'Charging' : 'Idle'}
            loading={false}
          />
          <Divider orientation="vertical" flexItem />
          <SummaryStatistic
            labelText='Battery Charge'
            valueText={66.2}
            unitsText='%'
            loading={false}
          />
        </Grid>
      </Card>
    </Grid>
  );
}

const getAssetId = (customerAssets: IAsset[], assetType: string, assetName: string) => {
  const customerSolarAssets = customerAssets.filter((asset: IAsset) => asset.assetType === assetType);
  const hbsBattenSolar = customerSolarAssets.find(a => a.name === assetName);
  return hbsBattenSolar?.id;
}

const SolarView = () => {
  const [solarAssetId, setSolarAssetId] = useState<null | string>(null);
  const [batteryAssetId, setBatteryAssetId] = useState<null | string>(null);

  const { data } = useFetchAssetsPageQuery({ page: 1, per_page: 200 });

  useEffect(() => {
    if (data) {
      setSolarAssetId(getAssetId(data.data, 'solar_panel', 'HBS Batten Hall Solar'));
      setBatteryAssetId(getAssetId(data.data, 'battery', 'HBS Batten Hall Battery'));
    }
  }, [data]);

  return (
    <ViewContainerBase>
      <CardGridBase>
        <SolarToplineMetrics solarAssetId={solarAssetId} />
        <SolarGenerationChart solarAssetId={solarAssetId} />
        <BatteryChart batteryAssetId={batteryAssetId} />
      </CardGridBase>
    </ViewContainerBase>
  );
}


export default SolarView;