import Theme from 'config/Theme';
import { IConsumptionGenerationData, IGenDataBySource } from 'modules/demo/selectors';
import { groupBy, mapAccum } from 'ramda';
import makeLinearGradient from 'utils/chartGradient';
import { mean, sum } from 'utils/math';
import { ILoadData } from './data/duke/customers';


export const dukeOGE2021FactorsLbsPerMWH: Record<string, number> = {
  'biomass': 133.129,
  'natural_gas': 979.562,
  'solar': 0.0,
  'wind': 0.0,
  'hydro': 0.0,
  'nuclear': 0.0,
  'coal': 2062.817,
  'petroleum': 2463.714,
  'other': 25.46,
};

export const gridCFEFuels = new Set([
  'solar',
  'wind',
  'hydro',
  'nuclear',
]);

const gridNonCFEFuels = new Set([
  'natural_gas',
  'petroleum',
  'biomass',
  'wood',
  'coal',
  'oil',
  'renewables',
  'multi_fuel',
  'other',
]);


export const fuelToColor: Record<string, string> = {
  coal: '#7D2828',
  petroleum: '#A73636',
  natural_gas: '#FFB020',
  renewables: '#996A13',
  solar: '#0F9F73',
  wood: 'brown',
  multi_fuel: 'burgundy',
  other: 'terracotta',
  hydro: 'cornflowerblue',
  nuclear: 'yellowgreen',
  wind: 'lightgreen',
}

const fuelToName: Record<string, string> = {
  coal: 'Coal',
  natural_gas: 'Natural Gas',
  petroleum: 'Oil',
  renewables: 'Biomass',
  wood: 'Wood',
  multi_fuel: 'Multi-fuel',
  other: 'Other',
  hydro: 'Hydro',
  wind: 'Wind',
  nuclear: 'Nuclear',
  solar: 'Solar',
}



export interface IConsumptionGenerationRow {
  datetime: Date,  // every hour is represented
  generation_kwh: number,
  consumption_kwh: number,
  generated_ci_lbs_mwh: number,
  marginal_ci_lbs_mwh: number,
  marginal_fuel_mix: Record<string, number>, // map of { fuel_name -> pct_fuel_mix }
  generated_fuel_mix: Record<string, number>, // map of { fuel_name -> mw }
}


export const groupByInterval = (data: Record<number, number>, interval: 'year' | 'month' | 'day' | 'hour') => {
  const groupFn = (row: number[]) => {
    const dateCopy = new Date(row[0]);
    switch (interval) {
      case 'year':
        dateCopy.setMonth(0);
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'month':
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'day':
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'hour':
      default:
        dateCopy.setMinutes(0, 0, 0);
        break;
    }
    return dateCopy.toISOString();
  };
  return groupBy(groupFn, Object.entries(data).map(([x, y]) => ([parseInt(x), y])));
};



const groupProgramGenerationByInterval = (data: {epoch: number, genBySource: Record<'program' | 'gridCFE' | 'gridNonCFE', number>}[], interval: 'year' | 'month' | 'day' | 'hour') => {
  const groupFn = (row: {epoch: number, genBySource: Record<'program' | 'gridCFE' | 'gridNonCFE', number>}) => {
    const dateCopy = new Date(row.epoch);
    switch (interval) {
      case 'year':
        dateCopy.setMonth(0);
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'month':
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'day':
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'hour':
      default:
        dateCopy.setMinutes(0, 0, 0);
        break;
    }
    return dateCopy.toISOString();
  };
  return groupBy(groupFn, data);
};


const groupLoadDataByInterval = (data: ILoadData[], interval: 'year' | 'month' | 'day' | 'hour') => {
  const groupFn = (row: ILoadData) => {
    const dateCopy = new Date(row.start_date);
    switch (interval) {
      case 'year':
        dateCopy.setMonth(0);
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'month':
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'day':
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'hour':
      default:
        dateCopy.setMinutes(0, 0, 0);
        break;
    }
    return dateCopy.toISOString();
  };
  return groupBy(groupFn, data);
};


const groupConsumptionGenerationByInterval = (data: IConsumptionGenerationData[], interval: 'year' | 'month' | 'day' | 'hour') => {
  const groupFn = (row: IConsumptionGenerationData) => {
    const dateCopy = new Date(row.date.toISOString());
    switch (interval) {
      case 'year':
        dateCopy.setMonth(0);
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'month':
        dateCopy.setDate(1);
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'day':
        dateCopy.setHours(0, 0, 0, 0);
        break;
      case 'hour':
      default:
        dateCopy.setMinutes(0, 0, 0);
        break;
    }
    return dateCopy.toISOString();
  };
  return groupBy(groupFn, data);
};


export function makeXDateFormat(dateResolution: 'hour' | 'day' | 'month' | 'year') {
  // https://github.com/highcharts/highcharts/issues/6737
  return {
    'hour': '%I:%M %p (%a, %b %e)',
    'day': '%a %b %e, %Y',
    'month': '%b %Y',
    'year': '%Y'
  }[dateResolution];
}



export const makeChartOptions = ({
  dateResolution,
  animated=false,
  chartAxisLabelY1,
  chartData,
  chartType,
  title,
}: {animated: boolean, dateResolution?: string, chartAxisLabelY1: string, chartData: any, chartType: string, title?: string}) => {
  let xDateFormat: string | boolean = false;
  if (dateResolution) {
    xDateFormat = makeXDateFormat(dateResolution as 'hour' | 'day' | 'month' | 'year');
  }

  let chartOptions: Record<string, unknown> = {
    chart: {
      type: chartType,
      style: {
        fontFamily: "'Inter', 'Helvetica Neue', 'Segoe UI', 'Roboto', '-apple-system', 'sans-serif'",
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
      },
      animation: animated
    },
    time: {
      // Positive offset means WEST of GMT, negative means EAST.
      timezoneOffset: new Date().getTimezoneOffset()
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        animation: animated
      },
      scatter: {
        marker: {
          enabled: true
        }
      },
      column: {
        stacking: 'normal',
      },
      area: {
        stacking: 'percent',
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '14px',
        },
        xDateFormat: xDateFormat
      },
    },
    yAxis: {
      title: {
        text: chartAxisLabelY1,
        useHTML: true,
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      min: 0,
    },
    title: {
      text: title || '',
    },
    tooltip: {
      valueDecimals: 1,
      shared: true,
      crosshairs: true,
      // useHTML: true
      xDateFormat,
    },
    legend: {
      enabled: true,
      symbolWidth: 40,
      itemStyle: {
        fontSize: '14px',
        lineHeight: '21px',
        opacity: 0.9,
        fontWeight: 'normal',
      },
      itemMarginTop: 8,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      description: 'This is a default chart description that will be replaced in future versions.',
      enabled: false
    },
    series: chartData,
  };

  return chartOptions;
}

export const getCustomerCumulativeProgramMatchSeries = (start: Date | null, end: Date | null, interval: 'hour' | 'day' | 'month' | 'year', data: IConsumptionGenerationData[]) => {
  const grouped = groupConsumptionGenerationByInterval(data, interval);

  const [_total, genSeriesData] = mapAccum((accum, [dtstr, groupData]) => {
    const genMWh = sum(groupData.map(d => d.generatedKwh)) / 1000;
    return [
      accum + genMWh,
      [new Date(dtstr).valueOf(), genMWh + accum]
    ]
  }, 0, Object.entries(grouped));

  const [_t, loadSeriesData] = mapAccum((accum, [dtstr, groupData]) => {
    const loadMWh = sum(groupData.map(d => d.consumedKwh)) / 1000;
    return [
      accum + loadMWh,
      [new Date(dtstr).valueOf(), loadMWh + accum]
    ]
  }, 0, Object.entries(grouped));

  const startFilter = start?.valueOf() || 0;
  const endFilter = end?.valueOf() || Infinity;

  return [
    {
      name: 'Generation',
      color: Theme.palette.chartBlueColor.main,
      fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, '60'),
      type: 'areaspline',
      data: genSeriesData.filter(([x]) => x >= startFilter && x <= endFilter),
    },
    {
      name: 'Consumption',
      color: Theme.palette.chartOrangeColor.main,
      type: 'line',
      data: loadSeriesData.filter(([x]) => x >= startFilter && x <= endFilter),
    }
  ];

};

export const getCFEVsNonCFESeries = (start: Date | null, end: Date | null, interval: 'hour' | 'day' | 'month' | 'year', data: IConsumptionGenerationData[]) => {
  const grouped = groupConsumptionGenerationByInterval(data, interval);

  const series = [
    {
      name: 'Load',
      type: 'line',
      color: '#D14343',
      lineWidth: 2,
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ({x: new Date(dtstr).valueOf(), y: sum(groupData.map(d => d.consumedKwh)) / 1000})),
      zIndex: 4,
    },
    {
      name: 'Excess Program Certificates',
      color: '#0F2128',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ({x: new Date(dtstr).valueOf(), y: sum(groupData.map(d => Math.max(0, d.generatedKwh - d.consumedKwh))) / 1000}))
    },
    {
      name: 'Grid Non-CFE',
      color: '#EE9191',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ({
          x: new Date(dtstr).valueOf(),
          // the Y value is:
          // * mean of %s of generated fuel mix for non-carbon free fuels times
          // * sum of the grid electicity consumed (max[0, consumption - generation])
          y: (
            mean(groupData.map(d => sum(Object.entries(d.residualMix).map(([fuel, val], _idx, arr) => gridNonCFEFuels.has(fuel) ? val/sum(arr.map(i => i[1])) : 0)))) *
            sum(groupData.map(d => Math.max(0, d.consumedKwh - d.generatedKwh)))
          ) / 1000
        }))
    },
    {
      name: 'Grid CFE',
      color: '#2f785d',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ({
          x: new Date(dtstr).valueOf(),
          // the Y value is:
          // * mean of %s of generated fuel mix for carbon free fuels times
          // * sum of the grid electicity consumed (max[0, consumption - generation])
          y: (
            mean(groupData.map(d => sum(Object.entries(d.residualMix).map(([fuel, val], _idx, arr) => gridCFEFuels.has(fuel) ? val/sum(arr.map(i => i[1])) : 0)))) *
            sum(groupData.map(d => Math.max(0, d.consumedKwh - d.generatedKwh)))
          ) / 1000
        }))
    },
    {
      name: 'Matched Program Certificates',
      color: '#04b565',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ({x: new Date(dtstr).valueOf(), y: sum(groupData.map(d => Math.min(d.consumedKwh, d.generatedKwh))) / 1000}))
    },
  ];

  return series;
};

export const getProgramByGenSourceSeries = (start: Date | null, end: Date | null, interval: 'hour' | 'day' | 'month' | 'year', data: {epoch: number, genBySource: Record<'program' | 'gridCFE' | 'gridNonCFE', number>}[], loadData: ILoadData[], isStandardRatepayerProgram: boolean) => {
  const grouped = groupProgramGenerationByInterval(data, interval);
  const groupedLoad = groupLoadDataByInterval(loadData || [], interval);
  const startFilter = start?.valueOf() || 0;
  const endFilter = end?.valueOf() || Infinity;

  const series = [
    {
      name: 'Grid non-CFE',
      color: '#242424',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ([new Date(dtstr).valueOf(), sum(groupData.map(d => d.genBySource.gridNonCFE))]))
        .filter(([x]) => x >= startFilter && x <= endFilter)
    },
    {
      name: 'Grid CFE',
      color: 'var(--color-green-5)',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ([new Date(dtstr).valueOf(), sum(groupData.map(d => d.genBySource.gridCFE))]))
        .filter(([x]) => x >= startFilter && x <= endFilter)
    },
    {
      name: 'Program Generation',
      color: '#2f785d',
      type: 'column',
      data: Object.entries(grouped)
        .map(([dtstr, groupData]) => ([new Date(dtstr).valueOf(), sum(groupData.map(d => d.genBySource.program))]))
        .filter(([x]) => x >= startFilter && x <= endFilter)
    },
    {
      name: 'Program Load',
      color: '#D14343',
      lineWidth: 4,
      type: 'line',
      data: Object.entries(groupedLoad)
        .map(([dtstr, groupData]) => ([new Date(dtstr).valueOf(), sum(groupData.map(d => d.consumed_kwh / 1000))]))
        .filter(([x]) => x >= startFilter && x <= endFilter)
    },
  ];

  return isStandardRatepayerProgram ? series.slice(0, 2) : series;
};


export const getProgramCumulativeGenSourceSeries = (start: Date | null, end: Date | null, interval: 'hour' | 'day' | 'month' | 'year', data: {epoch: number, genBySource: Record<'program' | 'gridCFE' | 'gridNonCFE', number>}[], loadData: ILoadData[]) => {
  const grouped = groupProgramGenerationByInterval(data, interval);
  const loadGrouped = groupLoadDataByInterval(loadData, interval);
  const [_total, genSeriesData] = mapAccum((accum, [dtstr, groupData]) => {
    const genMWh = sum(groupData.map(d => d.genBySource.program));
    return [
      accum + genMWh,
      [new Date(dtstr).valueOf(), genMWh + accum]
    ]
  }, 0, Object.entries(grouped));

  const [_t, loadSeriesData] = mapAccum((accum, [dtstr, groupData]) => {
    const loadMWh = sum(groupData.map(d => d.consumed_kwh / 1000));
    return [
      accum + loadMWh,
      [new Date(dtstr).valueOf(), loadMWh + accum]
    ]
  }, 0, Object.entries(loadGrouped));

  const startFilter = start?.valueOf() || 0;
  const endFilter = end?.valueOf() || Infinity;

  return [
    {
      name: 'Generation',
      color: Theme.palette.chartBlueColor.main,
      fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, '60'),
      type: 'areaspline',
      data: genSeriesData.filter(([x]) => x >= startFilter && x <= endFilter),
    },
    {
      name: 'Consumption',
      color: Theme.palette.chartOrangeColor.main,
      type: 'line',
      data: loadSeriesData.filter(([x]) => x >= startFilter && x <= endFilter),
    }
  ];
};