import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import CardBase from 'components/CardBase';
import SummaryStatistic from 'components/SummaryStatistic';
import { useGetCustomerGoalsQuery, useGetSummaryStatsQuery } from 'api/summary';
import { useAppSelector } from 'modules/store';
import { getIsDemoCustomer } from 'modules/auth/selectors';
import { useGetHistoricalPerformanceQuery } from 'api/historical';
import { getLastYearEmissionsTonsCo2 } from 'utils/dataUtils';

import './style.css';


const ToplineMetricsCardV1 = () => {
  const goalsApi = useGetCustomerGoalsQuery();
  const dataApi = useGetHistoricalPerformanceQuery();
  const currentAnnualEmissionsTons = getLastYearEmissionsTonsCo2(dataApi?.data?.data?.netFootprint || []);

  // If the customer has already hit their goal, don't give them a negative reduction!
  const goalAnnualEmissionsTons = goalsApi?.data?.data?.annualEmissionsTons;
  const neededReductionTonsClipped = Math.max(0, currentAnnualEmissionsTons - goalAnnualEmissionsTons);
  const neededReductionTons = isNaN(neededReductionTonsClipped) ? 0 : neededReductionTonsClipped;

  // Currently, the mock API returns strings instead of numbers, which breaks
  // the locale formatting to $ below.
  const { data, isFetching } = useGetSummaryStatsQuery();
  const parsedData = structuredClone(data);
  let estimatedPenalties: number | undefined = undefined;
  if (data) {
    estimatedPenalties = parseFloat(data.data.estimatedPenalties.toString().replace(',', ''));
  }

  return <ToplineMetricsCardV1Content
    goalAnnualEmissionsTons={goalAnnualEmissionsTons}
    neededReductionTons={neededReductionTons}
    loading={isFetching || goalsApi.isFetching}
    estimatedPenalties={estimatedPenalties}
    cumulativeSavings={parsedData?.data?.cumulativeSavings}
  />
}

export const ToplineMetricsCardV1Content = ({
  goalAnnualEmissionsTons,
  neededReductionTons,
  loading,
  estimatedPenalties,
  cumulativeSavings,
}: {goalAnnualEmissionsTons?: number, neededReductionTons?: number, loading: boolean, estimatedPenalties?: number, cumulativeSavings?: number}) => {
  const isDemoCustomer = useAppSelector(getIsDemoCustomer);

  return (
    <CardBase width={12}>
      <CardContent>
        <Grid container>
          <SummaryStatistic
            labelText={'Goal for annual carbon emissions'}
            valueText={goalAnnualEmissionsTons?.toLocaleString('default', { maximumFractionDigits: 0 }) || ''}
            unitsText={' tons CO₂'}
            loading={loading}/>
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Reduction needed to meet goal'}
            valueText={neededReductionTons?.toLocaleString('default', { maximumFractionDigits: 1 }) || ''}
            unitsText={' tons CO₂'}
            loading={loading}/>
          {isDemoCustomer &&
            <>
              <Divider orientation="vertical" flexItem/>
              <SummaryStatistic
              labelText={'Cumulative savings'}
              valueText={cumulativeSavings?.toLocaleString(
                'default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }) || ''}
              unitsText={null}
              loading={loading}/>
            <Divider orientation="vertical" flexItem/>
            <SummaryStatistic
              labelText={'Estimated penalties'}
              labelColor={'#C8644D'}
              valueText={estimatedPenalties?.toLocaleString(
                'default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }) || ''}
              unitsText={null}
              loading={loading}/>
            </>}
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default ToplineMetricsCardV1;
