import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { getToken } from 'utils/auth';
import camelizeKeys from 'utils/camelizeKeys';
import snakifyKeys from 'utils/snakifyKeys';

import * as Resources from 'config/resources';


const baseQuery = fetchBaseQuery({
  baseUrl: Resources.API_URL + '/api',
  prepareHeaders: (headers) => {
    const token = getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers
  },
  credentials: 'include',
});


// Objects are sent to the backend in snake_case, and sent to the frontend in
// camelCase so that they're consistent with the language being used. This
// function performs this translation by wrapping every query. Note that only
// the request param and body keys are translated, and the values are unaffected.
const translateCaseQuery = async (args: FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  let snakeArgs = args;
  if (args.body) {
    args.body = snakifyKeys(args.body);
  }
  if (args.params) {
    args.params = snakifyKeys(args.params);
  }

  const result = await baseQuery(snakeArgs, api, extraOptions);

  if (result.error) {
    return { error: camelizeKeys(result.error) }
  } else {
    return { data: camelizeKeys(result.data || {}) }
  }
}


export const api = createApi({
  // All endpoints are defined relative to the baseUrl below.
  baseQuery: translateCaseQuery,
  // All tag types for injected endpoints must be defined here.
  tagTypes: [
    'CustomerSummaryStats',
    'CustomerEmissionsSummary',
    'CustomerHistoricalPerformance',
    'CustomerProjectedPerformance',
    'CumulativeEmissionReductions',
    'PeerComparison',
    'RealtimeUsage',            // Tagged with an aggregate ID or building I.
    'RealtimeCarbonIntensity',
    'RealtimeFuelMix',
    'RealtimeSummary',
    'HistoricalInterventionImpact',
    'TotalLongTermProjection',
    'BuildingLongTermProjection',
    'CustomerIntervention',
    'CustomerInterventionWithImpact',
    'CustomerGoals',
    'CustomerPerformanceSummary',
    'Projections',
    'Executive',
    'Asset',
    'User',
    'Certificate',
  ],

  // Endpoints are injected from adjacent files.
  endpoints: () => ({})
});
