import * as React from 'react';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  IProgram,
  receiveGenerationData,
  setGenerationLoading,
  updateGeneratorProgramAssignment
} from 'modules/demo/slice';
import {
  generationDataLoading,
  generationDataNeverLoaded,
  getPrograms,
  getGeneratorListWithSummedGen
} from 'modules/demo/selectors';
import loadGeneration from 'demo/data/duke/generation';


const columns = (programs: IProgram[]) => [
  {
    field: 'fuel_category',
    headerName: 'Fuel',
    width: 200,
    sortable: true,
    type: 'string',
    flex: 1,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 120,
    sortable: false,
    type: 'string',
    flex: 1,
  },
  {
    field: 'plant_id_eia',
    headerName: 'Plant ID',
    width: 120,
    sortable: true,
    type: 'string',
    flex: 1,
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 120,
    sortable: true,
    type: 'string',
    flex: 1,
    description: 'The origin of the power in the full inventory. This will either be "owned", "purchased", or "REC".',
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 120,
    sortable: true,
    type: 'string',
    flex: 1,
    description: 'The type of origin of the power. This attribute further specifies the "source" of the inventory.',
  },
  {
    field: 'programId',
    headerName: 'Program',
    width: 200,
    sortable: true,
    editable: true,
    type: 'singleSelect',
    valueOptions: [...programs.map(program => ({value: program.id, label: program.name})),
      {value: '', label: 'Standard Ratepayer'},
    ],
    valueGetter: (params: GridCellParams) => programs.find(program => program.id === params.row.programId)?.name || 'Standard Ratepayer',
    flex: 1,
  },
  {
    field: 'totalGenerationMWh',
    headerName: 'Total 2021 Generation (MWh)',
    minWidth: 240,
    sortable: true,
    type: 'number',
    flex: 1,
  }
]


const GeneratorsTable = () => {
  const dispatch = useAppDispatch();
  const generationNeverLoaded = useAppSelector(generationDataNeverLoaded);
  const generators = useAppSelector(getGeneratorListWithSummedGen)
  const loading = useAppSelector(generationDataLoading);
  const programs = useAppSelector(getPrograms);

  React.useEffect(() => {
    if (generationNeverLoaded) {
      dispatch(setGenerationLoading());
      loadGeneration().then(data => dispatch(receiveGenerationData({data})));
    }
  }, [generationNeverLoaded]);


  return (
    <DataGrid
      loading={loading}
      rowCount={generators.length}
      rows={generators}
      columns={columns(programs)}
      pageSize={10}
      rowsPerPageOptions={[10]}
      experimentalFeatures={{ newEditingApi: true }}
      processRowUpdate={(newRow: typeof generators[0]) => {
        dispatch(updateGeneratorProgramAssignment({generatorId: newRow.id, programId: newRow.programId}));
        return newRow;
      }}
      autoHeight
      paginationMode="client"
    />
  )
};

export default GeneratorsTable;