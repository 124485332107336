import * as React from 'react';

import ViewContainerBase from 'components/ViewContainerBase';
import CardGridBase from 'components/CardGridBase';
import ToplineCardV1 from './ToplineCardV1';
import ToplineCardV2 from './ToplineCardV2';
import RealtimeIntensityCardV1 from './RealtimeIntensityCardV1';
import RealtimeIntensityCardV2 from './RealtimeIntensityCardV2';
import ResourceMixCardV1 from './ResourceMixCardV1';
import ResourceMixCardV2 from './ResourceMixCardV2';
import RealtimeBuildingsCard from '../Realtime/RealtimeBuildingsCard';

import { useAppSelector } from 'modules/store';
import { getHasLiveData } from 'modules/auth/selectors';

import './style.css';


const RealtimeView = () => {
  const hasLiveData = useAppSelector(getHasLiveData);

  const showCardV1 = !hasLiveData;
  const showCardV2 = hasLiveData;

  return (
    <ViewContainerBase>
      <CardGridBase>
        {showCardV1 && <ToplineCardV1/>}
        {showCardV2 && <ToplineCardV2/>}
        {showCardV1 && <RealtimeIntensityCardV1/>}
        {showCardV2 && <RealtimeIntensityCardV2/>}
        {showCardV1 && <ResourceMixCardV1/>}
        {showCardV2 && <ResourceMixCardV2/>}
        <RealtimeBuildingsCard/>
      </CardGridBase>
    </ViewContainerBase>
  )
}


export default RealtimeView;
