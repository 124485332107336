import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import GeneratorsTable from 'components/GeneratorsTable';
import GeneratorView from 'components/GeneratorView';
import CertificatesTable from 'components/CertificatesTable';
import InventoryOverview from 'components/InventoryOverview';
import ViewContainerBase from 'components/ViewContainerBase';

import './style.css';

const BaseInventoryView = () => {
  const [tab, setTab] = React.useState("Overview");

  return (
    <ViewContainerBase >
      <h1 className="certificate-inventory--title">Generation Data for 2021</h1>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(_e, newTab) => setTab(newTab)}>
            <Tab label="Overview" value="Overview" />
            <Tab label="Detail" value="Detail" />
            <Tab label="RPS Tracking" value="RPS Tracking" disabled className="certificate-intentory--tab-disabled" />
            <Tab label="Voluntary Target Tracking" value="Voluntary Target Tracking" disabled  className="certificate-intentory--tab-disabled"/>
          </TabList>
        </Box>
        <TabPanel value="Overview">
          <InventoryOverview />
        </TabPanel>
        <TabPanel value="Detail">
          <GeneratorsTable />
        </TabPanel>
        {/* <TabPanel value="RPS Tracking">
          <RPSOverview />
        </TabPanel>
        <TabPanel value="Voluntary Target Tracking">
          <VoluntaryOverview />
        </TabPanel> */}
      </TabContext>
    </ViewContainerBase>
  );
}

const InventoryView = () => {
  return (
    <Routes>
      <Route path="/" element={<BaseInventoryView />} />
      <Route path="generators/:id" element={<GeneratorView />} />
      <Route path="generators/:id/certificates/:timestamp" element={<CertificatesTable />} />
    </Routes>
  );
}

export default InventoryView;