import React from 'react';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';

import CardBase from 'components/CardBase';
import CardTitle from 'components/CardTitle';

import { useGetHistoricalInterventionImpactQuery } from 'api/historical';

import './style.css';
import EmptyIcon from 'components/icons/empty';
import { useAppSelector } from 'modules/store';
import { getCurrentCustomer } from 'modules/auth/selectors';


interface IProjectTableRow {
  name: string
  date: Date
  avoidedEmissions: number
  projectCost: number
  costSavings: number
  loading: boolean
}


interface IProjectTableProps {
  rows: IProjectTableRow[];
}


function ProjectTable(props: IProjectTableProps) {
  return (
    <TableContainer>
      <Table sx={{"& th, & td": {fontSize: 16}}}>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell align="right">Date Implemented</TableCell>
            <TableCell align="right">Emissions Reductions</TableCell>
            <TableCell align="right">Cost</TableCell>
            <TableCell align="right">Net Savings</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row.loading
                ? <Skeleton/>
                : row.name}
              </TableCell>
              <TableCell align="right">{row.loading
                ? <Skeleton/>
                : row.date.toLocaleDateString()}
              </TableCell>
              <TableCell align="right">{row.loading
                ? <Skeleton/>
                : row.avoidedEmissions.toLocaleString('default', { maximumFractionDigits: 0 }) + ' lbs CO₂'}
              </TableCell>
              <TableCell align="right">{row.loading
                ? <Skeleton/>
                : row.projectCost.toLocaleString('default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </TableCell>
              <TableCell align="right">{row.loading
                ? <Skeleton/>
                : row.costSavings.toLocaleString('default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


function makePlaceholderRows(n: number) {
  let rows = [];
  for (let i = 0; i < n; ++i) {
    rows.push({
      name: `loading-row-${i}`,
      date: new Date('2020-01-01T00:00:00.000'),
      avoidedEmissions: 0,
      projectCost: 0,
      costSavings: 0,
      loading: true
    });
  }
  return rows;
}


const ImpactByProjectCard = () => {
  const { data, isFetching } = useGetHistoricalInterventionImpactQuery();
  const customer = useAppSelector(getCurrentCustomer);

  let rows = makePlaceholderRows(3);
  let totalCostSavings = 0;
  if (data) {
    rows = data.data.map(value => {
      // NOTE(milo): Negative emissions/dollars means reduced, positive means increased.
      totalCostSavings += -1 * value.netSavingsDollars;
      return {
        name: value.readableName,
        date: new Date(value.startDate),
        costSavings: -1 * value.netSavingsDollars,
        avoidedEmissions: -1 * value.totalEmissionImpactTonsCo2,
        projectCost: value.totalCostDollars,
        loading: false
      };
    });
  }

  const hasNoProjects = !isFetching && data && rows.length === 0;

  return (
    <CardBase width={12}>
      <Grid container>
          {/* Left side */}
          <Grid item xs={9}>
            <CardHeader
              title={<CardTitle title={'Impact by project'}/>}
            />
            {hasNoProjects ?
              <div className="empty-state--container">
                <EmptyIcon height={200} width={240} className="empty-state--icon"/>
                <div className="empty-state-text--container">
                  <div className='empty-state--title'>You have no projects!</div>
                  <p className="empty-state--description">Reach out to your contact at {customer.name} in order to start saving money and carbon emissions with a project tailored just for your needs.</p>
                </div>
              </div>
              :
              <CardContent sx={{p: 3}}>
                <ProjectTable
                  rows={rows}
                />
              </CardContent>
            }
          </Grid>
          {/* Right side */}
          {!hasNoProjects && <Grid item xs={3} sx={{ p: 2 }} display='flex' alignItems={'center'}>
            <Box sx={{
              backgroundColor: '#D8EDD080',
              borderRadius: '3px',
              border: '1px solid #348D48',
              p: 4,
              textAlign: 'left'
            }}>
              <Typography sx={{mt: 1}}>Nice work!</Typography>
              <Typography sx={{mt: 1}}>Cumulative savings compared to baseline:</Typography>
              <Typography sx={{mt: 1, fontSize: '30px'}}>{isFetching
                  ? <Skeleton/>
                  : totalCostSavings.toLocaleString('default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Typography>
            </Box>
          </Grid>}
      </Grid>
    </CardBase>
  )
}


export default ImpactByProjectCard;
