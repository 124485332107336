
/**
 * percentile function takes a percentile and a series of numbers and returns
 * the number at the given percentile
 * @param p the value at the start of that percentile to return
 * @param series a list of numbers (unordered)
 * @returns the number at the start of the given percentile
 */
export const percentile = (p: number, series: number[]) => {
  if (series.length === 0) {
    return 0;
  }
  const sorted = [...series].sort((a, b) => b - a);
  const percentiles = sorted.map((num, i) => ({ num, percentile: (+((sorted.length - i) / sorted.length * 100).toFixed(2)) }));
  const val = percentiles.sort((a, b) => a.percentile - b.percentile).find(place => place.percentile >= p);
  return val ? val.num : percentiles[percentiles.length - 1].num;
};

/**
 * A function that takes a hex color string
 * and converts it to the values used in the
 * RGB color scale
 * @param hexColor the hex value of the color
 * @returns an object describing the rgb color value
 */
export const hexToRGB = (hexColor: string) => {
  const colorString = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
  if (colorString.length !== 6) {
    return { r: 0, g: 0, b: 0 };
  } else {
    return {
      r: parseInt(`${colorString[0]}${colorString[1]}`, 16),
      g: parseInt(`${colorString[2]}${colorString[3]}`, 16),
      b: parseInt(`${colorString[4]}${colorString[5]}`, 16),
    }
  }
};


export const linearInterpolate = (x: number, x0: number, x1: number, y0: number, y1: number) => {
  const t = (x - x0) / (x1 - x0);
  return t * y1 + (1 - t) * y0;
}


export const sum = (nums: number[]) => nums.filter(n => !isNaN(n)).reduce((s, n) => s + n, 0);

export const mean = (nums: number[]) => sum(nums) / (nums.length || 1);

// Returns n mod m.
export const mod = function (n: number, m: number) {
  return ((n % m) + m) % m;
};


export function sample<T>(population: T[], seed?: number): T {
  const idx = Math.floor(population.length * (seed !== undefined ? seed : Math.random()));
  return population[idx];
};

type Range = { floor: number, ceiling: number }

// Takes in a range to normalize from and a range to normalize to.
// returns a function that takes a single number and scales it.
//   e.g. const func = makeScalingFunction([50, 100], [1, 10]);
//        func(50) -> 1
//        func(75) -> 5.5
export const makeScalingFunction = (from: Range, to: Range) => {
  const fromDiff = from.ceiling - from.floor;
  const toDiff = to.ceiling - to.floor;
  return (scaleMe: number) => ((scaleMe - from.floor) / fromDiff) * toDiff + to.floor;
}
