// Makes a linear gradient that goes from `fillColor` at max y to transparent at min y.
// You can optionally set `opacity` to a two-letter hex value that gets appended to the `fillColor`.
export default function makeLinearGradient(fillColor: string, opacity: string) {
  if (opacity.length > 0 && fillColor.length === 7) {
    fillColor = `${fillColor}${opacity}`;
  }
  return {
    linearGradient: {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: [
      [0, fillColor],
      [1, '#FFFFFF00']
    ]
  }
}
