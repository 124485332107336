import React, { useState } from 'react';
import produce from 'immer';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import MuiTooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';

import CardBase from 'components/CardBase';
import CardRadioButtonGroup from 'components/CardRadioButtonGroup';
import BasicDropdown from 'components/BasicDropdown';
import ChartBase from 'components/ChartBase';
import VerticalDivider from 'components/VerticalDivider';
import CardTitle from 'components/CardTitle';

import { useGetHistoricalPerformanceQuery } from 'api/historical';
import {
  ICustomerIntervention,
  IMockHistoricalChartData,
  IMockHistoricalChartResponse
} from 'api/types';

import makeLinearGradient from 'utils/chartGradient';

import Theme from 'config/Theme';
import './style.css';


enum HistoricalPerformanceCardMode {
  Emissions = 'Emissions',
  Costs = 'Cost',
  Energy = 'kWh'
}


const TWELVE_HOURS_IN_MS = 1000 * 60 * 60 * 12;

type ChartConfigKey = 'showGrossFootprint' | 'showNetFootprint' |
                      'showUtilitySpecificFootprint' | 'showCompanyGoal' |
                      'showBusinessAsUsual';


const HistoricalPerformanceCard = () => {
  const [mode, setMode] = useState(HistoricalPerformanceCardMode.Emissions);
  const [lookbackPeriod, setLookbackPeriod] = useState('all');
  const [chartConfig, setChartConfig] = useState({
    showGrossFootprint: false,
    showNetFootprint: true,
    showUtilitySpecificFootprint: false,
    showCompanyGoal: true,
    showBusinessAsUsual: true
  });

  const { data, isFetching } = useGetHistoricalPerformanceQuery();

  const checkboxFactory = (
    stateKey: ChartConfigKey,
    label: string,
    hoverText?: string,
    muiColorName?: string
  ) => {
    return (
      <MuiTooltip title={hoverText || 'Click to toggle this series on the chart.'}>
        <FormControlLabel
          control={
            <Checkbox
              checked={chartConfig[stateKey]}
              onChange={(event: any) => {
                // Use immer to apply a partial update to the full state.
                setChartConfig(produce(chartConfig, draftState => {
                  draftState[stateKey] = event.target.checked;
                }));
              }}
              name={stateKey}
              color={muiColorName || 'primary'}
            />
          }
          label={<Typography className={'se--item-has-hover-text'}>{label}</Typography>}
        />
      </MuiTooltip>
    )
  }

  const makeChartData = () => {
    if (!data) {
      return [];
    }
    const chartData: IMockHistoricalChartResponse = data.data;
    const opacityHex = '30';
    const dataKey: keyof IMockHistoricalChartData = {
      [HistoricalPerformanceCardMode.Emissions]: 'totalEmissionsTonsCo2' as const,
      [HistoricalPerformanceCardMode.Costs]: 'totalCostDollars' as const,
      [HistoricalPerformanceCardMode.Energy]: 'electricityUsageKwh' as const
    }[mode];

    const tooltipOptions = {
      [HistoricalPerformanceCardMode.Emissions]: {
        valueSuffix: ' tons CO₂'
      },
      [HistoricalPerformanceCardMode.Costs]: {
        valuePrefix: '$'
      },
      [HistoricalPerformanceCardMode.Energy]: {
        valueSuffix: ' kWh'
      }
    }[mode];

    return [
      {
        name: 'Business as usual',
        visible: chartConfig.showBusinessAsUsual,
        type: 'area',
        color: Theme.palette.chartPurpleColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartPurpleColor.main, opacityHex),
        data: chartData.baselineFootprint.map((d: IMockHistoricalChartData) => (
          { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: d[dataKey] }
        )),
        tooltip: tooltipOptions
      },
      {
        name: 'Gross footprint',
        visible: chartConfig.showGrossFootprint,
        type: 'area',
        color: Theme.palette.chartRedColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartRedColor.main, opacityHex),
        data: chartData.grossFootprint.map((d: IMockHistoricalChartData) => {
          return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: d[dataKey] };
        }),
        tooltip: tooltipOptions
      },
      {
        name: 'Net footprint',
        visible: chartConfig.showNetFootprint,
        type: 'area',
        color: Theme.palette.chartTealColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartTealColor.main, opacityHex),
        data: chartData.netFootprint.map((d: IMockHistoricalChartData) => {
          return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: d[dataKey] };
        }),
        tooltip: tooltipOptions
      },
      {
        name: 'Utility-specific footprint',
        visible: chartConfig.showUtilitySpecificFootprint,
        type: 'area',
        color: Theme.palette.chartOrangeColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartOrangeColor.main, opacityHex),
        data: chartData.utilityFootprint.map((d: IMockHistoricalChartData) => {
          return { x: new Date(d.startDate).valueOf() + TWELVE_HOURS_IN_MS, y: d[dataKey] };
        }),
        tooltip: tooltipOptions
      },
    ];
  }

  const makeInterventionLines = () => {
    if (!data) {
      return [];
    }

    return data.data.interventions.map((value: ICustomerIntervention) => {
      // Get rid of parenthetical information since it clutters the graph.
      const readableName = value.name.split('(')[0] || value.name;
      const start = new Date(value.startDate);
      start.setDate(1);
      start.setHours(0, 0, 0, 0);
      return {
        color: '#348D48',
        width: 2,
        value: start.valueOf(),
        dashStyle: 'ShortDash',
        label: {
          // Need to add a white background so that the text shows up on top of the plot lines.
          text: `<p style="height: 20px; background-color: white;">${readableName}</p>`,
          align: 'top',
          rotation: 0,
          textAlign: 'center',
          useHTML: true,
        }
      }
    });
  }

  const chartRef = React.createRef<ChartBase>();
  // TODO: stopgap to shim days into the historical chart
  const isShowingDays = data?.data.baselineFootprint.length > 300;
  const perWhatTimeInterval = isShowingDays ? 'per day' : 'per month';

  // Set the label of the Y axis based on the chart mode.
  const chartAxisLabelY1 = {
    [HistoricalPerformanceCardMode.Emissions]: 'tons of CO₂ ' + perWhatTimeInterval,
    [HistoricalPerformanceCardMode.Costs]: 'cost ($) ' + perWhatTimeInterval,
    [HistoricalPerformanceCardMode.Energy]: 'kWh ' + perWhatTimeInterval
  }[mode];

  return (
    <CardBase width={12}>
      <Grid container>
        {/* Left side */}
        <Grid item xs={9}>
          <CardHeader
            title={<CardTitle title={'Performance over time'}/>}
            action={
              <div>
                <CardRadioButtonGroup
                  mode={mode}
                  mapModeToLabel={{
                    [HistoricalPerformanceCardMode.Emissions]: 'Carbon (tons of CO₂)',
                    [HistoricalPerformanceCardMode.Costs]: 'Costs ($)',
                    [HistoricalPerformanceCardMode.Energy]: 'Energy (kWh)'
                  }}
                  setModeCallback={(value) => setMode(value as HistoricalPerformanceCardMode)}
                />
                <BasicDropdown
                  id={'lookback-period-dropdown'}
                  value={lookbackPeriod}
                  mapValueToLabel={{
                    'all': 'All time',
                    '6m': '6 months',
                    '1y': '1 year',
                    '3y': '3 years',
                    // '5Y': '5 years'
                  }}
                  updateCallback={setLookbackPeriod}
                  formControlSx={{mt: 1, mr: 2}}
                />
              </div>
            }
          />
          <CardContent>
            <ChartBase
              ref={chartRef}
              loading={isFetching}
              animated={true}
              chartHeight={300}
              chartContainerId={'historical-performance-card--chart'}
              chartData={makeChartData()}
              dateResolution={'month'}
              downloadFilename={'historical_performance'}
              chartAxisLabelY1={chartAxisLabelY1}
              overrideOptions={{
                legend: {
                  enabled: false
                },
                plotOptions: {
                  column: {
                    groupPadding: 0.2,
                    pointPadding: 0,
                  }
                },
                xAxis: {
                  plotLines: makeInterventionLines(),
                },
              }}
            />
          </CardContent>
        </Grid>

        <VerticalDivider/>

        {/* Right side */}
        <Grid item xs={3} sx={{p: 2}}>
          <Box>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend" disabled={true}>Chart Legend</FormLabel>
              <FormGroup>
                {checkboxFactory('showBusinessAsUsual', 'Business as usual', '', 'chartPurpleColor')}
                {checkboxFactory('showGrossFootprint', 'Gross footprint', 'This is your physical consumption of carbon, costs, or energy.', 'chartRedColor')}
                {checkboxFactory('showUtilitySpecificFootprint', 'Utility-specific footprint', 'This footprint includes actions that the utility takes on your behalf, such as purchasing PPAs or RECs.', 'chartOrangeColor')}
                {checkboxFactory('showNetFootprint', 'Net footprint', 'This footprint includes action that you\'ve taken to reduce usage.', 'chartTealColor')}
                {/* {this.checkboxFactory('showCompanyGoal', 'Company goal', 'This is your company\'s long-term goal.', 'chartBlueColor')} */}
              </FormGroup>
              <Box display={'flex'} sx={{pt: 4}}>
                <SvgIcon sx={{width: '16px', padding: '2px'}}>
                  {/* <svg id="triangle" viewBox="0 0 100 100" fill={projectColor}>
                    <polygon points="50 15, 100 100, 0 100"/>
                  </svg> */}
                  <svg width="27" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line strokeDasharray={"5, 5"} x1="0" y1="10" x2="350" y2="10"></line>
                  </svg>
                </SvgIcon>
                <Typography sx={{paddingLeft: '12px'}}>Project implementation</Typography>
              </Box>
              <Button
                variant="outlined"
                color="neutral"
                sx={{width: '80px', mt: 2}}
                onClick={() => { chartRef && chartRef.current && chartRef.current.exportChart() } }
              >Export
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </CardBase>
  );
}


export default HistoricalPerformanceCard;
