import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CardBase from 'components/CardBase';
import ChartBase from 'components/ChartBase';
import InterventionTable from './InterventionTableV2';
import CumulativeEmissionsChart from './CumulativeEmissionsChart';

import { CustomerInterventionStatus, ICustomer, ICustomerIntervention, ICustomerInterventionImpact, IProjectionMetrics, TimeResolution } from 'api/types';
import { useCalculateCustomerInterventionImpactQuery, useGetProjectionToplineMetricsQuery, useGetScenarioProjectionQuery, useListCustomerInterventionsQuery, useSaveCustomerInterventionsMutation } from 'api/projections';

import makeLinearGradient from 'utils/chartGradient';
import { makeYearlyPlotData } from 'utils/rollup';
import { makeInterventionPlotPoints } from 'utils/chartAnnotations';
import { getIsXcel, getPhysicalUtcOffsetMins } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';
import { useGetCustomerGoalsQuery } from 'api/summary';

import Theme from 'config/Theme';
import './style.css';


enum CardMode {
  Emissions = 'Emissions',
  Usage = 'Usage'
}


const ScenarioChartCards = () => {
  const resolution = TimeResolution.YEAR;
  const [forecastHorizon, setForecastHorizon] = useState(2040);

  const [editedInterventions, setEditedInterventions] = useState<(ICustomerIntervention)[]>([]);
  const [editedImpact, setEditedImpact] = useState<(ICustomerIntervention & ICustomerInterventionImpact)[]>([]);

  const physicalUtcOffsetMins = useAppSelector(getPhysicalUtcOffsetMins);
  const isXcel = useAppSelector(getIsXcel);

  // Get customer goals.
  const goalsApi = useGetCustomerGoalsQuery();
  const goalEmissionsTons = goalsApi.data?.data?.annualEmissionsTons || null;
  const goalYear = goalsApi.data?.data?.targetYear || 2040;

  // Get a list of suggested and planned interventions for the customer.
  const interventionApi = useListCustomerInterventionsQuery(
    { forecastHorizon: forecastHorizon, status: [CustomerInterventionStatus.IMPLEMENTED, CustomerInterventionStatus.PLANNED, CustomerInterventionStatus.SUGGESTED] });

  // Once the list of interventions is fetched, calculate their marginal impact.
  const impactApi = useCalculateCustomerInterventionImpactQuery(
    { forecastHorizon: forecastHorizon, scenario: editedInterventions.filter(v => v.status !== CustomerInterventionStatus.IMPLEMENTED) },
    { skip: editedInterventions.length === 0 });

  const [saveCustomerInterventions, saveCustomerInterventionsApi] = useSaveCustomerInterventionsMutation();

  // Query the topline metrics so that we can ensure the projections chart is consistent.
  const toplineApi = useGetProjectionToplineMetricsQuery(
    { forecastHorizon: 2030, applyInterventions: true });
  const currentConsumedMwh = toplineApi.data?.data?.totalConsumedKwh?.currentYear * 1e-3;
  const currentEmissionsTonsCo2 = toplineApi.data?.data?.totalEmissionsLbsCo2.currentYear / 2000;

  // Don't request a projection until there are input interventions to pass.
  const params = {
    forecastHorizon: forecastHorizon,
    scenario: editedInterventions,
  };
  const { data, isFetching, isLoading, isError } = useGetScenarioProjectionQuery(params, { skip: !interventionApi.data });

  useEffect(() => {
    if (!interventionApi.data) {
      return;
    }
    setEditedInterventions(interventionApi.data);
  }, [interventionApi.data]);

  // Once impact metrics arrive, update the table data again.
  useEffect(() => {
    if (!impactApi.data) {
      return;
    }
    const withImpact = impactApi.data.map(value => {
      const metrics = value.projectedImpact || null;
      // TODO(milo): Update this from the legacy type used below.
      return {
        ...value,
        addedCapitalCosts: metrics?.interventionCapex || 0,
        addedOperationalCosts: metrics?.interventionOpex || 0,
        costSavings: -metrics?.grossEnergyCost || 0,
        electricityReductionKwh: -metrics?.sumConsumedKwh || 0,
        grossEmissionReductionCo2: (-metrics?.grossEmissionsLbsCo2 || 0) / 2000,
        netEmissionReductionCo2: (-metrics?.netEmissionsLbsCo2 || 0) / 2000
      };
    });

    setEditedImpact(structuredClone(withImpact))
  }, [impactApi.data]);

  // Saves the current state of the customer intervention table to the backend.
  const onSaveInterventions = () => {
    saveCustomerInterventions(editedInterventions);
  }

  const selectedInterventionIds = editedInterventions
    .filter(value => value.status === CustomerInterventionStatus.PLANNED)
    .map(value => value.id);

  // Helper function to prepare data for the emissions/costs chart. These charts have identical
  // series, but different units ($ vs tons CO₂).
  const makeChartData = (mode: CardMode) => {
    if (!data || !data.data || !currentConsumedMwh || !currentEmissionsTonsCo2) {
      return [];
    }

    // The endpoint should only return a single scenario (mid-case).
    const midCaseScenario = Object.keys(data.data.projections).at(0);
    if (!midCaseScenario) {
      return [];
    }

    const baselineProjections = data.data.projections[midCaseScenario].baseline;
    const scenarioProjections = data.data.projections[midCaseScenario].planned;

    const opacityHex = '30';

    const whichData = {
      [CardMode.Emissions]: 'emissions',
      [CardMode.Usage]: 'grid use',
    }[mode];

    const tooltipOptions = {
      [CardMode.Emissions]: { valueSuffix: ' tons CO₂' },
      [CardMode.Usage]: { valueSuffix: ' MWh' }
    }[mode];

    const goalMetricValue = (mode === CardMode.Emissions) ? goalEmissionsTons : null;

    const valueGetter = (m: IProjectionMetrics) => {
      return {
        [CardMode.Emissions]: m.netEmissionsLbsCo2 / 2000,
        [CardMode.Usage]: m.sumConsumedKwh * 1e-3,
      }[mode];
    }

    let baselineSeries = baselineProjections.map((value: IProjectionMetrics) => {
      return { x: new Date(value.startDate).valueOf(), y: valueGetter(value) };
    });
    let scenarioSeries = scenarioProjections.map((value: IProjectionMetrics) => {
      return { x: new Date(value.startDate).valueOf(), y: valueGetter(value) };
    });

    if (resolution === TimeResolution.YEAR) {
      baselineSeries = makeYearlyPlotData(baselineSeries);
      scenarioSeries = makeYearlyPlotData(scenarioSeries);
    }

    const scaleFactorToMatchTopline = {
      [CardMode.Emissions]: baselineSeries.length > 0 ? currentEmissionsTonsCo2 / baselineSeries.at(0).y : 1,
      [CardMode.Usage]: baselineSeries.length > 0 ? currentConsumedMwh / baselineSeries.at(0).y : 1,
    }[mode];

    const goalYearEpoch = new Date(goalYear, 1, 1, 0, 0, 0, 0).valueOf();

    const plotData: any[] = [
      {
        name: `Baseline ${whichData}`,
        visible: true,
        type: 'area',
        color: Theme.palette.chartRedColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartRedColor.main, opacityHex),
        data: baselineSeries
          .filter((value) => value.x <= goalYearEpoch)
          .map(value => ({ x: value.x, y: value.y * scaleFactorToMatchTopline })),
        tooltip: tooltipOptions
      },
      {
        name: `Scenario ${whichData}`,
        visible: true,
        type: 'area',
        color: Theme.palette.chartBlueColor.main,
        fillColor: makeLinearGradient(Theme.palette.chartBlueColor.main, opacityHex),
        data: scenarioSeries
          .filter((value) => value.x <= goalYearEpoch)
          .map(value => ({ x: value.x, y: value.y * scaleFactorToMatchTopline })),
        tooltip: tooltipOptions
      }
    ];

    if (goalMetricValue) {
      plotData.push({
        name: 'Goal',
        visible: true,
        type: 'line',
        color: Theme.palette.chartTealColor.main,
        data: baselineSeries
          .map((value) => { return { x: value.x, y: goalMetricValue } })
          .filter((value) => value.x <= goalYearEpoch),
        tooltip: tooltipOptions,
        fillColor: '#FFFFFF',
      })
    }

    const plannedInterventions = editedInterventions.filter(value => value.status === 'planned');
    const plannedAnnotations = makeInterventionPlotPoints(plannedInterventions, plotData.at(1).data, 'Planned projects');
    if (plannedAnnotations && plannedAnnotations.data.length > 0) {
      plotData.push(plannedAnnotations);
    }

    return plotData;
  }

  // Translate API state into component rendering state here.
  const emissionsPlotData = makeChartData(CardMode.Emissions);
  const usagePlotData = makeChartData(CardMode.Usage);

  const tableLoading = interventionApi.isFetching || interventionApi.isLoading;
  const chartsLoading = isFetching || isLoading || tableLoading;
  const tableError = interventionApi.isError;
  const savingLoading = saveCustomerInterventionsApi.isLoading;
  const savingError = saveCustomerInterventionsApi.isError;

  // Need to sort rows or they will change location in the table. If we want
  // planned projects to float to the top, then just use rows in unsorted form
  // from the API.
  // NOTE(milo): This will NOT contain implemented projects.
  const rowsSorted = structuredClone(editedImpact);
  rowsSorted.sort((a, b) => (a.id < b.id ? -1 : 1));

  const perWhatTimeInterval = {
    [TimeResolution.YEAR]: 'per year',
    [TimeResolution.MONTH]: 'per month',
  }[resolution as TimeResolution.YEAR | TimeResolution.MONTH];

  return (
    <>
    <CardBase width={6}>
      <CardHeader
        title={<Typography gutterBottom variant="seCardTitleText" component="div">Projected emissions</Typography>}
        action={<Button variant="outlined" color="neutral">Export</Button>}
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          loading={chartsLoading}
          animated={true}
          chartHeight={300}
          chartContainerId={'scenario-chart-emissions'}
          chartData={emissionsPlotData}
          chartAxisLabelY1={'tons of CO₂ ' + perWhatTimeInterval}
          dateResolution={'year'}
          overrideOptions={{
            yAxis: {
              min: 0
            },
            time: {
              // note: this will cause an issue if the user isn't in the customer's timezone
              timezoneOffset: resolution === TimeResolution.YEAR ? undefined : -physicalUtcOffsetMins,
              useUTC:  resolution === TimeResolution.YEAR ? undefined : true,
            }
          }}
        />
      </CardContent>
    </CardBase>
    <CardBase width={6}>
      <CardHeader
        title={<Typography gutterBottom variant="seCardTitleText" component="div">Projected grid use</Typography>}
        action={<Button variant="outlined" color="neutral">Export</Button>}
      />
      <CardContent sx={{p: 2}}>
        <ChartBase
          loading={chartsLoading}
          animated={true}
          chartHeight={300}
          chartContainerId={'scenario-chart-usage'}
          chartData={usagePlotData}
          chartAxisLabelY1={'MWh ' + perWhatTimeInterval}
          dateResolution={'year'}
          overrideOptions={{
            yAxis: {
              min: 0
            },
            time: {
              // note: this will cause an issue if the user isn't in the customer's timezone
              timezoneOffset: resolution === TimeResolution.YEAR ? undefined : -physicalUtcOffsetMins,
              useUTC:  resolution === TimeResolution.YEAR ? undefined : true,
            }
          }}
        />
      </CardContent>
    </CardBase>
    {
      !isXcel ?
        <CumulativeEmissionsChart
          loading={chartsLoading}
          goalYear={goalYear}
          data={data?.data}
        /> : ''
    }
    <InterventionTable
      loading={tableLoading}
      loadingError={tableError}
      saving={savingLoading}
      savingError={savingError}
      selectedInterventionIds={selectedInterventionIds}
      editedInterventions={rowsSorted}
      saveInterventionsCallback={onSaveInterventions}
      setSelectedInterventionIds={(selectedIds: (string | number)[]) => {
        if (selectedIds.length === selectedInterventionIds.length) {
          return;
        }
        // Don't change the status of 'implemented' projects; they aren't shown in the table.
        const updatedWithStatus = editedInterventions.map((value) => {
          if (value.status === CustomerInterventionStatus.IMPLEMENTED) {
            return value;
          }
          const statusInTable = selectedIds.includes(value.id)
            ? CustomerInterventionStatus.PLANNED
            : CustomerInterventionStatus.SUGGESTED;
          return { ...value, status: statusInTable };
        });
        setEditedInterventions(updatedWithStatus);
      }}
      // Update some or all of the interventions with new values.
      updateInterventionParams={(updatedInterventions: ICustomerIntervention[]) => {
        const mergedInterventions = editedInterventions.map(existingVersion => {
          const updatedVersion = updatedInterventions.find(updatedValue => updatedValue.id === existingVersion.id);
          return updatedVersion ? updatedVersion : existingVersion;
        })
        setEditedInterventions(mergedInterventions);
      }}
    />
    </>
  );
}


export default ScenarioChartCards;
