import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';


const RealTimeIndicator = (props: { loading: boolean }) => {
  const text = props.loading ? 'Fetching latest data' : 'Real-time';
  return (
    <LoadingButton
      loading={props.loading}
      loadingPosition="start"
      startIcon={<CheckIcon/>}
      variant="text"
      disabled={true}
    >
      {text}
    </LoadingButton>

  )
}


export default RealTimeIndicator;
