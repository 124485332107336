import { api } from './base';

import {
  ICustomerIntervention,
  ICustomerInterventionImpact,
  ICustomerProjection
} from './types';


interface ISearchCustomerInterventionsParams {
  status: string[]
  calculateImpact: boolean
}


interface ISearchCustomerInterventionsResponse {
  data: (ICustomerIntervention & ICustomerInterventionImpact)[]
}


type ISimulateProjectionParams = (ICustomerIntervention)[];


interface ISimulateProjectionResponse {
  data: {
    baselineProjections: ICustomerProjection[]
    scenarioProjections: ICustomerProjection[]
    interventionImpact: (ICustomerIntervention & ICustomerInterventionImpact)[]
  }
}


type ISaveCustomerInterventionsResponse = void;


type ISaveCustomerInterventionsParams = ICustomerIntervention[];


export const customerInterventionApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchCustomerInterventions: build.query<ISearchCustomerInterventionsResponse, ISearchCustomerInterventionsParams>({
      query: (params: ISearchCustomerInterventionsParams) => {
        return {
          url: '/v1/mock/customer_interventions/search',
          method: 'GET',
          params: {
            status: params.status.join(','),
            calculateImpact: params.calculateImpact
          }
        }
      },
      providesTags: (result, error, _args) => {
        return error ? [] : result.data.map(value => ({ type: 'CustomerIntervention', id: value.id }))
      }
    }),
    simulateProjection: build.query<ISimulateProjectionResponse, ISimulateProjectionParams>({
      query: (params: ISimulateProjectionParams) => {
        return {
          url: '/v1/mock/customer_interventions/projection',
          method: 'POST',
          body: {
            data: params
          }
        }
      },
    }),
    saveCustomerInterventions: build.mutation<ISaveCustomerInterventionsResponse, ISaveCustomerInterventionsParams>({
      query: (params: ISaveCustomerInterventionsParams) => {
        return {
          url: '/v1/mock/customer_interventions/save',
          method: 'POST',
          body: {
            data: params
          }
        };
      },
      invalidatesTags: (_result, error, args: ICustomerIntervention[]) => {
        return error ? [] : args.map(value => ({ type: 'CustomerIntervention', id: value.id }));
      }
    })
  })
});


export const {
  useSearchCustomerInterventionsQuery,
  useSimulateProjectionQuery,
  useSaveCustomerInterventionsMutation
} = customerInterventionApi;
