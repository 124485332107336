import React from 'react';
import { createRoot } from "react-dom/client";

import { store } from 'modules/store';
import { Provider } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { ThemeProvider } from '@mui/material';
import Theme from 'config/Theme';

import { DEBUG } from 'config/resources';

import App from './App.tsx';
import './index.css';


if (DEBUG) {
  window.store = store;
}


const mountSentry = () => {
  if (!DEBUG) {
    Sentry.init({ dsn: 'https://cd6ea6cdfb274147bb25337a61f8b508@sentry.io/1447705' });
  }
};
setTimeout(mountSentry, 0);


createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <App/>
    </ThemeProvider>
  </Provider>
);
