import { api } from './base';
import { CustomerInterventionStatus, ICustomerIntervention, ICustomerProjectionResponse, IProjectionMetrics, TimeResolution } from './types';


// We don't know exactly which keys will exist; it depends on the forecast availability.
export type MaybeEveryFifthYear = {
  '2025'?: number
  '2030'?: number
  '2035'?: number
  '2040'?: number
  '2045'?: number
  '2050'?: number
  '2055'?: number
  '2060'?: number
}


interface IGetProjectionToplineMetricsResponse {
  data: {
    totalEmissionsLbsCo2: { 'currentYear': number } & MaybeEveryFifthYear
    totalConsumedKwh: { 'currentYear': number } & MaybeEveryFifthYear
  }
  meta: {
    // These tell us the latest year that we can grab from the objects in `data`.
    minForecastYear: number
    maxForecastYear: number
    latestConsumptionEmissionsEvent: {
      rollupCoverage: number
      rollupResolution: TimeResolution
    }
  }
}


interface IGetProjectionToplineMetricsParams {
  applyInterventions?: boolean
  forecastHorizon?: number
}


interface IGetProjectionSeriesParams {
  applyInterventions?: boolean
  forecastHorizon?: number
}


type IListCustomerInterventionsResponse = (ICustomerIntervention & { projectedImpact: IProjectionMetrics })[];


interface IListCustomerInterventionsParams {
  forecastHorizon?: number
  status?: CustomerInterventionStatus[]
}


interface IGetScenarioResponse {
  data: ICustomerProjectionResponse
  scenario: ICustomerIntervention[]
}


interface IGetScenarioParams {
  forecastHorizon?: number
  scenario: ICustomerIntervention[]
}


export const projectionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectionToplineMetrics: build.query<IGetProjectionToplineMetricsResponse, IGetProjectionToplineMetricsParams>({
      query: (params: IGetProjectionToplineMetricsParams) => {
        return {
          url: '/v2/dashboard/projections/topline',
          method: 'GET',
          params: params
        };
      },
      providesTags: [{ type: 'Projections', id: 'ToplineMetrics' }]
    }),
    getProjectionSeries: build.query<ICustomerProjectionResponse, IGetProjectionSeriesParams>({
      query: (params: IGetProjectionSeriesParams) => {
        return {
          url: '/v2/dashboard/projections/series',
          method: 'GET',
          params: params
        };
      }
    }),
    listCustomerInterventions: build.query<IListCustomerInterventionsResponse, IListCustomerInterventionsParams>({
      query: (params: IListCustomerInterventionsParams) => {
        return {
          url: '/v2/dashboard/customer_interventions',
          method: 'GET',
          params: params
        }
      },
      providesTags: [{ type: 'CustomerIntervention', id: 'LIST' }]
    }),
    calculateCustomerInterventionImpact: build.query<IListCustomerInterventionsResponse, IGetScenarioParams>({
      query: (params: IGetScenarioParams) => {
        return {
          url: '/v2/dashboard/customer_interventions/impact',
          method: 'POST',
          body: params
        }
      }
    }),
    getScenarioProjection: build.query<IGetScenarioResponse, IGetScenarioParams>({
      query: (params: IGetScenarioParams) => {
        return {
          url: '/v2/dashboard/scenario',
          method: 'POST',
          body: params
        }
      }
    }),
    saveCustomerInterventions: build.mutation<void, ICustomerIntervention[]>({
      query: (params: ICustomerIntervention[]) => {
        return {
          url: '/v2/dashboard/customer_interventions/save',
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: [{ type: 'CustomerIntervention', id: 'LIST' }]
    })
  })
});


export const {
  useGetProjectionToplineMetricsQuery,
  useGetProjectionSeriesQuery,
  useListCustomerInterventionsQuery,
  useGetScenarioProjectionQuery,
  useSaveCustomerInterventionsMutation,
  useCalculateCustomerInterventionImpactQuery
} = projectionsApi;
