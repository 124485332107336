export interface IGenerationData {
  plant_id_eia: string;
  datetime_utc: string;
  net_generation_mwh: number;
}


interface RawGenerationData {
  sampled_generation: IGenerationData[];
  rec_generation: IGenerationData[];
  specified_purchased: IGenerationData[];
  unspecified_purchased: IGenerationData[];
}


export interface IAggregatedGenerationData {
  state: string;
  hourUtc: string;
  generationMWh: number;
  fuel_category: string;
}

export interface IFullGenerationData {
  sampled: IGenerationData[];
  unbundled_recs: IGenerationData[];
  purchased_specified: IGenerationData[];
  purchased_unspecified: IGenerationData[];
}

const loadGeneration = async (): Promise<IFullGenerationData> => {
  const data: RawGenerationData = (await import('./generation.json')).default as RawGenerationData;

  return {
    sampled: data.sampled_generation,
    unbundled_recs: data.rec_generation,
    purchased_specified: data.specified_purchased,
    purchased_unspecified: data.unspecified_purchased,
  }
};

export default loadGeneration;