import React, { useRef } from 'react';

import ModalBase, { IModalBaseProps } from 'components/ModalBase';
import GoalSettingCard from 'components/GoalSettingCardV1';


export default function GoalSettingModal(props: IModalBaseProps) {
  const modalRef = useRef<ModalBase>(null);

  return (
    <ModalBase {...props} ref={modalRef}>
      <GoalSettingCard
        inputLayout={'narrow'}
        showDividers={false}
        showSaveButton={true}
        sx={{border: '2px solid #3F9594'}}
      />
    </ModalBase>
  );
}
