import * as React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button, CircularProgress, Fade, Typography } from '@mui/material';

import { useFetchAssetQuery } from 'api/assets';
import { assetTypeToName, getRegionName, timeResolutionToName } from 'constants/app';

import './style.css';


const AssetView = () => {
  const {id=''} = useParams<{id: string}>();
  const {isLoading, isFetching, isError, data} = useFetchAssetQuery({id});
  const nav = useNavigate();

  const loading = isLoading || isFetching;

  return <div className='asset-page--container'>
    <Breadcrumbs>
      <Link to="/assets">All assets</Link>
      <Typography color="text.primary">{data?.data.name || 'this asset'}</Typography>
    </Breadcrumbs>
    {isError && <Typography color="error">Failed to load asset</Typography>}
    {data &&
      <div>
        <div className="asset-page-header--container">
          <h1>{data.data.name || 'Unnamed asset'}</h1>
          <Button onClick={() => nav(`/assets/${id}/edit`)}>Edit</Button>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>ID</label>
          <p>{data.data.id}</p>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Description</label>
          <p>{data.data.description || 'no description'}</p>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Region</label>
          <p>{getRegionName(data.data.location.region as string)}</p>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Type</label>
          <p>{assetTypeToName[data.data.assetType] || `unknown ("${data.data.assetType}")`}</p>
        </div>
        <div className='asset-page-info--container'>
          <label className='asset-page-info--label'>Base Resolution</label>
          <p>{data.data.baseResolution ?  timeResolutionToName[data.data.baseResolution] : 'no resolution'}</p>
        </div>
      </div>
    }
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '100ms' : '0ms',
      }}
      unmountOnExit
    >
      <CircularProgress />
    </Fade>
  </div>
};

export default AssetView;