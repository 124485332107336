import * as React from 'react';
import Grid from '@mui/material/Grid';

import './style.css';


interface IHasChildrenProps {
  children: any
}


// This outer container simply display any children that are nested inside of its JSX.
export default class CardGridBase extends React.Component<IHasChildrenProps> {
  render() {
    return (
      <Grid container spacing={4} sx={{mt: 2, mb: 2}}>
        {this.props.children}
      </Grid>
    );
  }
}
