import React from 'react';

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardTitle from "components/CardTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

import Money from "images/money.svg";
import CardBase from 'components/CardBase';
import { IBuildingData } from 'api/types';
import * as MockAPI from 'api/mock';

import CostsTeaserCard from "./CostsTeaserCard";

import './style.css';


interface IProjectedCostsProps {
  horizon: number;
}


const ProjectedCostsCard = (props: IProjectedCostsProps) => {
  const buildings: IBuildingData[] = MockAPI.fakeBuildings();

  buildings.sort((x: IBuildingData, y: IBuildingData) => y.annualCosts - x.annualCosts);
  const average = buildings.length > 0 ? (buildings.map((x: IBuildingData) => x.annualCosts).reduce((x: number, y: number) => x + y) / buildings.length) : 0;

  return (
    <CardBase width={6}>
      <Grid container>
        <Grid item xs={12}>
        <CardHeader
          title={<Stack direction="row" spacing={1}><Box component="img" src={Money} /><CardTitle title='Projected Costs ($)' /></Stack>}
        />
        <CardContent>
          <Stack direction="row" spacing={10} sx={{mb: 2}}>
          <div>
            <Typography variant="seCardSubtitleText">Avg. annual cost</Typography>
            <Typography sx={{fontSize: 30}}>{average.toLocaleString('default', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})}</Typography>
          </div>
          <div>
            <Typography variant="seCardSubtitleText">Avg. annual costs by {props.horizon}</Typography>
            <Typography sx={{fontSize: 30}}>{(average * 0.85).toLocaleString('default', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})}</Typography>
          </div>
          </Stack>
          <Typography variant="seListSubheaderText">Biggest contributors</Typography>
          <Table sx={{ml: 0, "& th, & td": {fontSize: 18, pl: 0, borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}}>
            <TableHead sx={{"& th": {pb: 0}}}>
              <TableRow>
                <TableCell>What</TableCell>
                <TableCell>Why</TableCell>
                <TableCell align="right">How much</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buildings.slice(0, 3).map((building: IBuildingData) => (
                <TableRow key={building.id}>
                  <TableCell>{building.name}</TableCell>
                  <TableCell>Energy costs</TableCell>
                  <TableCell align="right">{building.annualCosts.toLocaleString('default', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        </Grid>
        {/* At bottom of card */}
        <Grid item xs={12} sx={{ p: 2 }} alignItems={'center'}>
          <CostsTeaserCard/>
        </Grid>
      </Grid>
    </CardBase>
  );
}


export default ProjectedCostsCard;
