import { api } from './base';


interface IGetToplineMetricsResponse {
  goalAnnualTonsCo2: number
  currentAnnualTonsCo2: number
  requiredReductionAnnualTonsCo2: number
}


interface IGetExecutiveSeriesResponse {
  data: {
    emissions: {
      historical: {
        baseline: { startDate: string, lbsCo2: number }[]
        net: { startDate: string, lbsCo2: number }[]
      },
      projected: {
        baseline: { startDate: string, lbsCo2: number }[]
        net: { startDate: string, lbsCo2: number }[]
      }
    }
  }
}


interface IGetExecutiveSeriesParams {
  lookbackHorizon: number
  forecastHorizon: number
  applyInterventions: boolean
}


export const executiveApi = api.injectEndpoints({
  endpoints: (build) => ({
    getToplineMetrics: build.query<IGetToplineMetricsResponse, void>({
      query: () => {
        return {
          url: '/v2/dashboard/executive/topline',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'Executive', id: 'Topline' }]
    }),
    getExecutiveSeries: build.query<IGetExecutiveSeriesResponse, IGetExecutiveSeriesParams>({
      query: (params: IGetExecutiveSeriesParams) => {
        return {
          url: '/v2/dashboard/executive/series',
          method: 'GET',
          params: params
        }
      }
    })
  })
});


export const {
  useGetToplineMetricsQuery,
  useGetExecutiveSeriesQuery
} = executiveApi;
