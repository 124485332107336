import * as React from 'react';

import Grid from "@mui/material/Grid";

import { useAppSelector } from 'modules/store';
import { getHasLiveData, getIsDemoCustomer } from 'modules/auth/selectors';

import SummaryBuildingsCard from 'layouts/ExecutiveSummary/SummaryBuildingsCard';
import ViewContainerBase from 'components/ViewContainerBase';
import CardGridBase from 'components/CardGridBase';

import ToplineMetricsCardV1 from './ToplineMetricsCardV1';
import ToplineMetricsCardV2 from './ToplineMetricsCardV2';
import EmissionsChartCardV1 from './EmissionsChartCardV1';
import EmissionsChartCardV2 from './EmissionsChartCardV2';
import RealtimeIntensityCardV1 from './RealtimeIntensityCardV1';
import RealtimeIntensityCardV2 from './RealtimeIntensityCardV2';
import PeerComparisonCard from './PeerComparisonCard';
import ProjectTeaserCardV1 from './ProjectsTeaserCardV1';
import ProjectsTeaserCardV2 from './ProjectsTeaserCardV2';

import './style.css';


const LiveDataExecutiveSummaryView = () => {
  return (
    <ViewContainerBase>
      <CardGridBase>
        <ToplineMetricsCardV2/>
        <EmissionsChartCardV2/>
        <Grid item container xs={6} spacing={4} alignContent='start'>
          <RealtimeIntensityCardV2/>
        </Grid>
        <Grid item container xs={6} spacing={4} alignContent='start'>
          <ProjectsTeaserCardV2/>
        </Grid>
      </CardGridBase>
    </ViewContainerBase>
  )
};

// TODO: Try to use masonry for the summary cards (https://mui.com/material-ui/react-masonry/)
const ExecutiveSummaryView = () => {
  const isDemoCustomer = useAppSelector(getIsDemoCustomer);
  const hasLiveData = useAppSelector(getHasLiveData);

  if (hasLiveData) {
    return <LiveDataExecutiveSummaryView />;
  }

  return (
    <ViewContainerBase>
      <CardGridBase>
        <ToplineMetricsCardV1/>
        <EmissionsChartCardV1/>
        {/* NOTE(milo): alignContent is what makes cards float to the top. */}
        <Grid item container xs={6} spacing={4} alignContent='start'>
          <RealtimeIntensityCardV1/>
          <SummaryBuildingsCard/>
        </Grid>
        <Grid item container xs={6} spacing={4} alignContent='start'>
          {isDemoCustomer && <PeerComparisonCard/>}
          <ProjectTeaserCardV1/>
        </Grid>
      </CardGridBase>
    </ViewContainerBase>
  )
}


export default ExecutiveSummaryView;
