import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Typography, Link, Box, Skeleton } from '@mui/material';
import Stack from "@mui/material/Stack";

import Lightbulb from "images/lightbulb.svg"


const CostsTeaserCard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    potentialCostSavingsDollars: 45100,
    numberOfPotentialProjects: 3,
  });

  useEffect(() => {
    if (loading) {
      const timeoutId = setTimeout(() => setLoading(false), 500);

      return () => {
        clearTimeout(timeoutId);
      }
    }
  }, [loading]);

  const navigate = useNavigate();
  const goToScenariosPage = () => navigate('/scenarios');

  const maybeText = loading ?
    <>
      <Skeleton/>
      <Skeleton/>
    </> :
    <>
      <Typography sx={{mt: 1}}>You could save <b>{
        data.potentialCostSavingsDollars.toLocaleString('default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
        }</b> over the next 40 years <br></br></Typography>
      <Link onClick={goToScenariosPage}><span className='link'>Explore Scenario</span> </Link>
    </>;

  return (
    <Box sx={{
        backgroundColor: '#D8EDD080',
        borderRadius: '3px',
        border: '1px solid #348D48',
        p: 4,
        textAlign: 'left'
      }}>
      <Stack direction="row" spacing={4}>
        <Box component="img" src={Lightbulb} />
        <Box>{maybeText}</Box>
      </Stack>
    </Box>
  );
}


export default CostsTeaserCard;
