import React, { useState } from 'react';
import cx from 'classnames';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardTitle from 'components/CardTitle';
import CardBase from 'components/CardBase';
import ChartBase, { IChartBaseProps } from 'components/ChartBase';

import Theme from 'config/Theme';


interface IPeerComparisonCardProps {
  forecastWindowHours?: number
}


interface IPeerComparisonData {
  yourEmissionsTonsPerSquareFoot: number
  bestPeersEmissionsTonsPerSquareFoot: number
  avgPeersEmissionsTonsPerSquareFoot: number
}


export default function PeerComparisonCard(props: IPeerComparisonCardProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    yourEmissionsTonsPerSquareFoot: 14.3,
    bestPeersEmissionsTonsPerSquareFoot: 10.2,
    avgPeersEmissionsTonsPerSquareFoot: 16.7
  });

  setTimeout(() => setLoading(false), 500);

  const makeChartData = (data: IPeerComparisonData) => {
    return [
      {
        type: 'bar',
        name: 'CUI (tons CO₂ / sq. ft / yr)',
        data: [
          data.yourEmissionsTonsPerSquareFoot,
          data.bestPeersEmissionsTonsPerSquareFoot,
          data.avgPeersEmissionsTonsPerSquareFoot
        ]
      }
    ]
  }

  const percentMoreOrLessThanAvg = 100 * ((data.yourEmissionsTonsPerSquareFoot - data.avgPeersEmissionsTonsPerSquareFoot) / data.avgPeersEmissionsTonsPerSquareFoot)
  const moreOrLessThanAvg = percentMoreOrLessThanAvg > 0 ? 'worse' : 'better';

  const percentMoreOrLessThanBest = 100 * ((data.yourEmissionsTonsPerSquareFoot - data.bestPeersEmissionsTonsPerSquareFoot) / data.bestPeersEmissionsTonsPerSquareFoot)
  const moreOrLessThanBest = percentMoreOrLessThanBest > 0 ? 'worse' : 'better';

  const maybeSummaryText = loading ?
    <>
      <Skeleton variant='text'/>
      <Skeleton variant='text'/>
    </> :
    <Typography>
      Your carbon intensity is <strong className={cx('text-metric', {good: percentMoreOrLessThanAvg < 0, bad: percentMoreOrLessThanAvg > 0})}>{Math.abs(percentMoreOrLessThanAvg).toFixed(0)}% {moreOrLessThanAvg}</strong> than average, and <strong className={cx('text-metric', {good: percentMoreOrLessThanBest < 0, bad: percentMoreOrLessThanBest > 0})}>{Math.abs(percentMoreOrLessThanBest).toFixed(0)}% {moreOrLessThanBest}</strong> than your most efficient peers.
    </Typography>

  return (
    <CardBase width={12}>
      <CardHeader title={<CardTitle title={'Compared to peers'}/>}/>
      <CardContent>
        {maybeSummaryText}
        <ChartBase
          chartContainerId={'peer-comparison-chart'}
          loading={loading}
          chartHeight={300}
          animated={true}
          chartData={makeChartData(data)}
          overrideOptions={{
            plotOptions: {
              bar: {
                colorByPoint: true,
                colors: [
                  Theme.palette.chartLightGreenColor.main,
                  Theme.palette.chartDarkGreenColor.main,
                  Theme.palette.chartBlueColor.main
                ],
                borderRadius: 3,
                pointPadding: 0.05,
              }
            },
            xAxis: {
              categories: ['You', 'Most efficient', 'Average'],
              labels: {style: {fontSize: '16px', color: 'black'}}
            }
          }}
          renderSkeleton={(chartProps: IChartBaseProps) => {
            return (
              <>
                <Skeleton variant='rectangular' height={50} sx={{mt: 4}}/>
                <Skeleton variant='rectangular' height={50} sx={{mt: 4}}/>
                <Skeleton variant='rectangular' height={50} sx={{mt: 4}}/>
              </>
            );
          }}
        />
      </CardContent>
    </CardBase>
  )
}
