import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

import CardTitle from 'components/CardTitle';
import ProjectionDetailsText from 'components/ProjectionDetailsText';


export default function AssumptionsAccordionCard() {
  return (
    <Grid item xs={12}>
      <Accordion disableGutters={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          id="projections-accordion"
        >
          <CardTitle title={'Our Assumptions'}/>
        </AccordionSummary>
        <AccordionDetails>
          <ProjectionDetailsText/>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
