import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, CardContent, CardHeader, TextField, Typography } from '@mui/material';

import { useInviteUserMutation } from 'api/users';
import BasicDropdown from 'components/BasicDropdown';
import CardBase from 'components/CardBase';
import CardTitle from 'components/CardTitle';
import ModalBase, { IModalBaseProps } from 'components/ModalBase';
import { validateName, validateEmail } from 'utils/validation';

const InviteTeammateModal = (modalProps: IModalBaseProps) => {
  const [inviteUser, inviteUserApi] = useInviteUserMutation();
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState('user');

  const onSubmit = (e: any) => {
    e.preventDefault();
    const lowerCaseEmail = email.toLowerCase();
    inviteUser({ name, email: lowerCaseEmail, role: selectedRole});
  }

  const submitEnabled = validateName(name) && validateEmail(email)

  return (
    <ModalBase {...modalProps}>
      <CardBase sx={{border: '2px solid #3F9594'}} width={12}>
        <CardHeader title={<CardTitle title={'Invite a new teammate'}/>} />
        <CardContent sx={{pl: 2, pb: 1}}>
          <Box className="invite-user-modal--form">
            <TextField
              required
              id="invite-user-modal--name"
              label="Name"
              placeholder="My Friend"
              sx={{mt: 2}}
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete={"name"}
              error={!validateName(name)}
              helperText={validateName(name) ? '' : 'Please enter a name (at least 5 characters).'}
            />
            <TextField
              required
              id="invite-user-modal--email"
              label="Email"
              placeholder="friend@email.com"
              sx={{mt: 4}}
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete={"email"}
              error={!validateEmail(email)}
              helperText={validateEmail(email) ? '' : 'Please use a valid email address.'}
            />
            <Typography sx={{mt: 2}}>Role</Typography>
            <BasicDropdown
              id={'invite-teammate-modal--dropdown'}
              value={selectedRole}
              updateCallback={(value: string) => setSelectedRole(value)}
              mapValueToLabel={{
                user: 'User',
                admin: 'Admin',
              }} />
            {inviteUserApi.isError && <div className="invite-user--error">Failed to send invitation</div>}
            {inviteUserApi.isSuccess && <div className="invite-user--success">Invitation sent successfully!</div>}
            <LoadingButton disabled={!submitEnabled} loading={inviteUserApi.isLoading} variant="text" onClick={onSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </CardContent>
      </CardBase>
    </ModalBase>
  );
}

export default InviteTeammateModal;