import * as React from 'react';

import './style.css';


const SettingsView = () => {
  return (
    <div className="se--base-view-container">
    </div>
  )
}


export default SettingsView;
