import { api } from './base';
import {
  ICategoryEmissionReduction,
  IHistoricalCustomerInterventionImpact,
  IMockHistoricalChartResponse,
  TimeResolution
} from './types';


interface IGetCumulativeEmissionReductionsResponse {
  data: ICategoryEmissionReduction[]
}



interface IGetHistoricalPerformanceResponse {
  data: IMockHistoricalChartResponse
}


interface IGetHistoricalProjectImpact {
  data: IHistoricalCustomerInterventionImpact[];
}

export interface IGetHistoricalSeriesResponse {
  data: {
    carbonIntensity: {
      isoRegion: { startDate: string, lbsCo2PerMwh: number }[]
      utilityRegion: { startDate: string, lbsCo2PerMwh: number }[]
    }
    emissions: {
      baseline: { startDate: string, lbsCo2: number }[]
      net: { startDate: string, lbsCo2: number }[]
      gross: { startDate: string, lbsCo2: number }[]
      utility: { startDate: string, lbsCo2: number }[]
    }
    electricityUsage: {
      baseline: { startDate: string, sumConsumedKwh: number }[]
      net: { startDate: string, sumConsumedKwh: number }[]
      gross: { startDate: string, sumConsumedKwh: number }[]
      utility: { startDate: string, sumConsumedKwh: number }[]
    }
    interventions: {[id: string]: {
      name: string,
      description: string,
      startDate: string,
      emissionReductionLbsCo2: number,
      interventionType: string
    }}
  }
}


interface IGetHistoricalSeriesParams {
  resolution: TimeResolution
  lookbackHorizon: number
}


export const historicalApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCumulativeEmissionReductions: build.query<IGetCumulativeEmissionReductionsResponse, void>({
      query: () => {
        return {
          url: '/v1/mock/historical/cumulative-emission-reductions',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'CumulativeEmissionReductions' }]
    }),
    getHistoricalPerformance: build.query<IGetHistoricalPerformanceResponse, void>({
      query: () => {
        return {
          url: '/v1/mock/historical',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'CustomerHistoricalPerformance' }]
    }),
    getHistoricalInterventionImpact: build.query<IGetHistoricalProjectImpact, void>({
      query: () => {
        return {
          url: '/v1/mock/historical/project-impact',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'HistoricalInterventionImpact' }]
    }),
    getHistoricalSeries: build.query<IGetHistoricalSeriesResponse, IGetHistoricalSeriesParams>({
      query: (params: IGetHistoricalSeriesParams) => {
        return {
          url: '/v2/dashboard/historical/series',
          method: 'GET',
          params: params
        }
      }
    })
  })
});


export const {
  useGetCumulativeEmissionReductionsQuery,
  useGetHistoricalPerformanceQuery,
  useGetHistoricalInterventionImpactQuery,
  useGetHistoricalSeriesQuery
} = historicalApi;
