import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Button, TextField, Switch, FormHelperText, Select, MenuItem, OutlinedInput } from '@mui/material';

import { getOtherPrograms, getProgram } from 'modules/demo/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CertificateDistribution, IProgram, updateProgram} from "modules/demo/slice";
import './style.css';


const EditProgramView = () => {
  const {id=''} = useParams<{id: string}>();
  const program: IProgram = useAppSelector((s) => getProgram(s, id));
  const otherPrograms: IProgram[] = useAppSelector(s => getOtherPrograms(s, id));
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [is247, set247] = useState(program.is247Program);
  const [selectedReleaseTo, setSelectedReleaseTo] = useState(program.canReleaseToProgramIds);
  const [selectedReceiveFrom, setSelectedReceiveFrom] = useState(program.canReceiveFromProgramIds);
  const [dedicatedGeneration, setDedicatedGeneration] = useState(program.generatorCertificateDistribution === "dedicated");

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    // this method relies on the "name" property of the form inputs
    // aligning with the key names in the request payload
    const form = new FormData(e.currentTarget);
    dispatch(updateProgram({
      program: {
        ...program, ...Object.fromEntries(form), ...{
          is247Program: is247,
          generatorCertificateDistribution: (dedicatedGeneration ? "dedicated" : "pooled") as CertificateDistribution,
          canReleaseToProgramIds: selectedReleaseTo,
          canReceiveFromProgramIds: selectedReceiveFrom,
        }
      }
    }));
    nav(`/programs`);
  };

  return <div className='asset-page--container'>
    <Breadcrumbs>
      <Link to="/programs">All programs</Link>
      <Link to={`/programs/${id}`}>{program.name}</Link>
      <Typography color="text.primary">edit</Typography>
    </Breadcrumbs>
    <form onSubmit={onSubmit}>
      <div className="asset-page-info--container asset-page-header--container">
        <TextField className="asset-page-edit-input--header" name="name" label="Name" variant="outlined" defaultValue={program.name || ''} placeholder='Name'/>
        <div>
          <Button variant="contained" style={{marginRight: '16px'}} type='submit'>Save</Button>
          <Button onClick={() => nav(`/programs`)} variant="outlined" >Cancel</Button>
        </div>
      </div>
      <div className='asset-page-info--container'>
        <label className='asset-page-info--label'>ID</label>
        <p>{program.id}</p>
      </div>
      <div className='asset-page-info--container'>
        <label className='asset-page-info--label'>Description</label>
        <TextField label="description" variant="outlined" className="asset-page-edit-input--field asset-page-edit-input--description" name="description" defaultValue={program.description || ''}/>
      </div>
      <div className='asset-page-info--container'>
        <label className='asset-page-info--label'>Hourly Accounting</label>
        <Switch checked={is247} onChange={(event) => set247(event.target.checked)} />
        <FormHelperText className='program-page-info--helptext'>Enable this to have 24/7 load matching in this program</FormHelperText>
      </div>
      <div className='asset-page-info--container'>
        <label className='asset-page-info--label'>Customer Dedicated Cert Assignment</label>
        <Switch checked={dedicatedGeneration} onChange={(event) => setDedicatedGeneration(event.target.checked)}/>
        <FormHelperText className='program-page-info--helptext'>Enable this to dedicate generators in a program to only one customer in the program.</FormHelperText>
      </div>
      <div className='asset-page-info--container'>
        <label className='asset-page-info--label'>Allow Certificate Release</label>
        <Select multiple value={selectedReleaseTo} className="program-page-select--input" onChange={(e) => setSelectedReleaseTo(Array.isArray(e.target.value) ? e.target.value : [e.target.value])}>
          {otherPrograms.map(prog => <MenuItem key={prog.id} value={prog.id}>{prog.name}</MenuItem>)}
        </Select>
        <FormHelperText className='program-page-info--helptext'>Allow excess certificates from this program to release to the following programs. Certificates will be released in order of next highest priority after this program.</FormHelperText>
      </div>
      <div className='asset-page-info--container'>
        <label className='asset-page-info--label'>Allow Certificate Receipt</label>
        <Select multiple value={selectedReceiveFrom} className="program-page-select--input" onChange={(e) => setSelectedReceiveFrom(Array.isArray(e.target.value) ? e.target.value : [e.target.value])}>
          {otherPrograms.map(prog => <MenuItem key={prog.id} value={prog.id}>{prog.name}</MenuItem>)}
        </Select>
        <FormHelperText className='program-page-info--helptext'>Allow excess certificates from specified programs to release to this program. This setting must be enabled for a excess certificates to be released to this program, irrespective of other programs' "release" setting.</FormHelperText>
      </div>
    </form>
  </div>
};

export default EditProgramView;