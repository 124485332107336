import React from 'react';
import Highcharts from 'highcharts';
import ExtendWithExportModule from 'highcharts/modules/exporting';
import ExtendWithAnnotationsModule from 'highcharts/modules/annotations';
import ExtendWithMoreModule from 'highcharts/highcharts-more';
import ExtendWithPatternModule from 'highcharts/modules/pattern-fill';
import Sankey from 'highcharts/modules/sankey';
import { mergeDeepRight } from 'ramda';
ExtendWithExportModule(Highcharts);
ExtendWithAnnotationsModule(Highcharts);
ExtendWithMoreModule(Highcharts);
ExtendWithPatternModule(Highcharts);
Sankey(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  },
});

Highcharts.AST.allowedAttributes.push('data-reactroot');

import Skeleton from '@mui/material/Skeleton';

import './style.css';


export function makeXDateFormat(dateResolution: 'hour' | 'day' | 'month' | 'year') {
  // https://github.com/highcharts/highcharts/issues/6737
  return {
    'hour': '%I:%M %p (%a, %b %e)',
    'day': '%a %b %e, %Y',
    'month': '%b %Y',
    'year': '%Y'
  }[dateResolution];
}


export interface IChartBaseProps {
  loading: boolean
  refreshing?: boolean
  animated: boolean

  chartWidth?: string
  chartHeight: number
  chartAxisLabelY1?: string
  dateResolution?: 'hour' | 'day' | 'month' | 'year'

  chartContainerId: string
  overrideOptions?: Highcharts.Options

  chartCallback?: (chart: Highcharts.Chart) => void

  chartData?: any

  // Optionally pass in a custom skeleton that should be rendered while loading.
  renderSkeleton?: (thisProps: IChartBaseProps) => JSX.Element;

  downloadFilename?: string
}


interface IChartBaseState {
  chart: Highcharts.Chart
}


export default class ChartBase extends React.Component<IChartBaseProps, IChartBaseState> {
  constructor(props: IChartBaseProps) {
    super(props);
    this.state = {
      chart: null
    };
  }

  // This function builds the chart and places it in the `chartContainerId` container.
  updateChart() {
    if (this.props.loading) {
      return;
    }

    // https://github.com/highcharts/highcharts/issues/6737
    let xDateFormat: string | boolean = false;
    if (this.props.dateResolution) {
      xDateFormat = makeXDateFormat(this.props.dateResolution);
    }

    let chartOptions: Record<string, unknown> = {
      chart: {
        type: 'area',
        style: {
          fontFamily: "'Inter', 'Helvetica Neue', 'Segoe UI', 'Roboto', '-apple-system', 'sans-serif'",
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '17px',
        },
        animation: this.props.animated
      },
      // time: {
      //   // Positive offset means WEST of GMT, negative means EAST.
      //   timezoneOffset: -1 * dayjs().utcOffset()
      // },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          animation: this.props.animated
        },
        scatter: {
          marker: {
            enabled: true
          }
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            fontSize: '14px',
          },
          xDateFormat: xDateFormat
        },
      },
      yAxis: {
        title: {
          text: this.props.chartAxisLabelY1
        },
        labels: {
          style: {
            fontSize: '14px',
          },
        }
      },
      title: {
        text: '',
      },
      tooltip: {
        valueDecimals: 1,
        shared: true,
        crosshairs: true,
        // useHTML: true
        xDateFormat,
      },
      legend: {
        enabled: true,
        symbolWidth: 40,
        itemStyle: {
          fontSize: "14px",
          lineHeight: "21px",
          opacity: 0.9,
          fontWeight: "normal",
        },
        itemMarginTop: 8,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        description: 'This is a default chart description that will be replaced in future versions.',
        enabled: false
      },
      series: this.props.chartData
    };

    // Override any chart options with those that are passed in via props.
    if (this.props.overrideOptions) {
      chartOptions = mergeDeepRight(chartOptions, this.props.overrideOptions);
    }

    const chart = Highcharts.chart(
      this.props.chartContainerId,
      chartOptions,
      this.props.chartCallback ? this.props.chartCallback : (chart: Highcharts.Chart) => { });

    this.setState({ chart: chart });
  }

  // Change the visibility of a series with `name` to `visible`.
  toggleSeries(chart: Highcharts.Chart, name: string, visible: boolean) {
    if (!chart || !chart.series || chart.series.length === 0) {
      return;
    }
    const series = chart.series.find((series: Highcharts.Series) => { return series.name == name });
    if (series) {
      series.setVisible(visible, visible);
    }
  }

  componentDidUpdate(): void {
    this.updateChart();
  }

  // Important! When this chart re-mounts, we need to run update again to
  // re-initialize highcharts in the current DOM container. Otherwise you'll
  // just see blank containers when switching between tabs.
  componentDidMount(): void {
    this.updateChart();
  }

  // Only re-render if the loading prop or data prop changed.
  shouldComponentUpdate(nextProps: Readonly<IChartBaseProps>): boolean {
    if (!nextProps.chartData) {
      return false;
    }
    const seriesLengthChanged = (nextProps.chartData.length !== this.props.chartData.length);
    const seriesFirstElementChanged = nextProps.chartData.length && this.props.chartData.length && nextProps.chartData[0] != this.props.chartData[0]
    const seriesAddedOrRemoved = seriesLengthChanged || seriesFirstElementChanged;
    const seriesDataChanged = seriesAddedOrRemoved || !nextProps.chartData.every((series: any, i: number) => {
      if (!(series.data.length === this.props.chartData[i].data.length &&
        series.name == this.props.chartData[i].name)) {
        return false;
      }
      return series.data.every((data: any, j: number) => {
        return data.y === this.props.chartData[i].data[j].y &&
          data.x === this.props.chartData[i].data[j].x;
      });
    });
    const chartOptionsChanged = JSON.stringify(nextProps.overrideOptions)?.length !== JSON.stringify(this.props.overrideOptions)?.length;

    // If the visibility of a series changed, toggle that series but don't do a React re-render.
    nextProps.chartData.forEach((series: any) => {
      if (series.visible !== null && series.visible !== undefined) {
        this.toggleSeries(this.state.chart, series.name, series.visible);
      }
    });

    const noLongerLoading = (nextProps.loading !== this.props.loading) || (nextProps.refreshing !== this.props.refreshing && !this.props.refreshing);

    return (noLongerLoading || seriesDataChanged || chartOptionsChanged);
  }

  exportChart(filetype: 'image/svg+xml' | 'image/png' | 'image/jpeg' | 'application/pdf' = 'image/svg+xml') {
    if (!this.state.chart) {
      return;
    }
    const downloadFilename = this.props.downloadFilename || 'chart';
    this.state.chart.exportChart({
      fallbackToExportServer: false,
      type: filetype,
      filename: downloadFilename
    }, {});
  }

  renderSkeleton() {
    if (this.props.renderSkeleton) {
      return this.props.renderSkeleton(this.props);
    } else {
      // Default skeleton (box with x-axis below).
      return (
        <>
          <Skeleton variant='rectangular' height={this.props.chartHeight - 30 - 2 * 4} />
          <Skeleton variant='text' height={30} sx={{ mt: 1 }} />
        </>
      );
    }
  }

  render() {
    const skeletonIfLoading = this.props.loading ? this.renderSkeleton() : '';
    return (
      <div id={this.props.chartContainerId} style={{ height: this.props.chartHeight, width: '100%', paddingTop: '10px' }}>
        {skeletonIfLoading}
      </div>
    )
  }
}
