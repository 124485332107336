import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Link } from 'react-router-dom';
import { Card, Divider, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch } from '@mui/material';
import cx from 'classnames';
import { CaretDownIcon, CaretUpIcon } from 'evergreen-ui';

import DateRangeHeader from 'components/DateRangeHeader';
import { useAppSelector } from 'modules/store';
import { getAllocationNotRun, getConsumptionAndGenerationData, getCustomerAccountList, getCustomerSummaryResults, getProgram, getResidualMixCarbonIntensity, getAccountToplineMetrics, getAccountCertValues, getCustomerCarbonIntensity } from 'modules/demo/selectors';
import { IProgram } from 'modules/demo/slice';
import { getCFEVsNonCFESeries, getCustomerCumulativeProgramMatchSeries, makeChartOptions } from 'demo/chart_helpers';
import SummaryStatistic, { LargeSummaryStatSubValue } from 'components/SummaryStatistic';
import ToplineMetrics from 'demo/components/ToplineMetrics';
import { numberToShortString } from 'utils/strings';

import './style.css';
import UtilityToplineMetrics from 'demo/components/UtilityToplineMetrics';

const getProgramDescription = (program?: IProgram) => {
  if (!program) {
    return <em>Customer is not subscribed to any program, standard ratepayer information is shown.</em>
  }

  if (program.is247Program) {
    return `A 24/7 program with ${program.generatorCertificateDistribution} generators for subscribers guaranteeing 80% load matching.`
  } else {
    return `An annual program with ${program.generatorCertificateDistribution} generators for subscribers guaranteeing 100% load matching.`
  }
}

const getAccountName = (longFormName: string) => {
  const names = longFormName.split('.');
  return names[1] || names[0];
}

const CustomerAllocationSummary = ({
  selectedCustomer,
  startDate,
  endDate,
}: { selectedCustomer: string, startDate: Date, endDate: Date }) => {
  const locationCI = useAppSelector(getResidualMixCarbonIntensity).locationBased;
  const results = useAppSelector(s => getCustomerSummaryResults(s, selectedCustomer, startDate, endDate));
  const { totalDiff, totalGenMWh, certsBySource, avgMatchedPercent, avgAccountCI } = results;

  const overallDiffValue = <div className={cx('annual-allocation-result--diff', {good: totalDiff >= 0, bad: totalDiff < 0})}>
    {totalDiff > 0 ? <CaretUpIcon /> : <CaretDownIcon />}
    {numberToShortString(Math.abs(totalDiff))}
  </div>

  return <>
    <Grid item sx={{mt: 2, mb: 2}} xs={12}>
      <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
        <Grid container>
          <SummaryStatistic
            labelText='Load Covered by Program'
            valueText={avgMatchedPercent ? numberToShortString(avgMatchedPercent) : <em style={{fontSize: 14}}>N/A</em>}
            unitsText={'%'}
            loading={false}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText='Total Generation'
            valueText={numberToShortString(totalGenMWh)}
            unitsText={'MWh'}
            loading={false}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText='Shortfall or Overage'
            valueText={overallDiffValue}
            unitsText='MWh'
            loading={false}
          />
        </Grid>
      </Card>
    </Grid>
    <Grid item sx={{mt: 2, mb: 2}} xs={12}>
      <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
        <Grid container>
          <SummaryStatistic
            labelText={'Annual Market-based Carbon Intensity'}
            valueText={avgAccountCI ? numberToShortString(avgAccountCI) : <em style={{fontSize: 14}}>N/A</em>}
            unitsText={'lbs / MWh'}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Annual Location-based Carbon intensity'}
            valueText={numberToShortString(locationCI)}
            unitsText={'lbs / MWh'}
          />
          <Divider orientation="vertical" flexItem/>
          <SummaryStatistic
            labelText={'Certs by Source'}
            valueText={<div className="program-allocation-multi-metric--container">
              <LargeSummaryStatSubValue label={"Owned"} value={numberToShortString(certsBySource.owned || 0)} />
              <LargeSummaryStatSubValue label={"Purchased"} value={numberToShortString(certsBySource['purchased.specified'] || 0 + certsBySource['purchased.unspecified'] || 0)} />
              <LargeSummaryStatSubValue label={"RECs"} value={numberToShortString(certsBySource.rec || 0)} />
              </div>}
          />
        </Grid>
      </Card>
    </Grid>
  </>;
}


const defaultStartStr = '2021-01-01T00:00:00-05:00';
const defaultEndStr = '2021-12-31T23:59:59.9999-05:00';


const CustomerAllocation = () => {
  const [startDate, setStartDate] = React.useState<null | Date>(new Date(defaultStartStr));
  const [endDate, setEndDate] = React.useState<null | Date>(new Date(defaultEndStr));
  const [interval, setInterval] = React.useState('day');
  const [show247Cumulative, setShow247Cumulative] = React.useState(false);
  const accounts = useAppSelector(getCustomerAccountList);
  const topLevelCustomers = [...new Set(accounts.map(c => c.rootCustomerId))];
  const [selectedCustomer, setSelectedCustomer] = React.useState(topLevelCustomers[0]);
  const customerAccounts = accounts.filter(c => c.rootCustomerId === selectedCustomer);
  const [selectedAccount, setSelectedAccount] = React.useState(customerAccounts[0]);
  const consumptionAndGenerationData = useAppSelector((s) => getConsumptionAndGenerationData(s, selectedAccount.id, startDate, endDate));
  const allocationNotRun = useAppSelector(getAllocationNotRun);
  const data = getCFEVsNonCFESeries(startDate, endDate, interval as 'hour' | 'day' | 'month' | 'year', consumptionAndGenerationData || []);
  const cumulativeData = getCustomerCumulativeProgramMatchSeries(startDate, endDate, interval as 'hour' | 'day' | 'month' | 'year', consumptionAndGenerationData || []);
  const program = useAppSelector(s => getProgram(s, selectedAccount.programId));
  const is247Program = !program || program.is247Program;
  const toplineMetrics = useAppSelector(s => getAccountToplineMetrics(s, selectedAccount.id, null, null));
  const certValues = useAppSelector(s => getAccountCertValues(s, selectedAccount.id, null, null));
  const ci = useAppSelector(s => getCustomerCarbonIntensity(s, selectedAccount.id));
  const residualCI = useAppSelector(getResidualMixCarbonIntensity);

  const setDates = (start: Date | null, end: Date | null, interval: string) => {
    setStartDate(start);
    setEndDate(end);
    setInterval(interval)
  }

  const setCustomer = (customerName: string) => {
    setSelectedCustomer(customerName);
    setSelectedAccount(accounts.find(act => act.rootCustomerId === customerName));
  }

  const cumulativeChartOptions = {dateResolution: interval, animated: true, chartAxisLabelY1: 'Electricity (MWh)', chartData: cumulativeData, chartType: 'areaspline', title: 'Program Trending'};
  const intervalChartOptions = {dateResolution: interval, animated: true, chartAxisLabelY1: `Eiectricity (MWh)`, chartData: data, chartType: 'column', title: 'Program Matching'};

  return <div className="customer-allocation-results--container">
    <div className="customer-allocation-results--header">
      <FormControl sx={{ m: 0, minWidth: '150px' }} size="small">
        <Select
          value={selectedCustomer}
          onChange={(event: any) => setCustomer(event.target.value)}
          className="customer-allocation--select"
        >
          {topLevelCustomers.map(cName => <MenuItem value={cName} key={cName}>{cName}</MenuItem>)}
        </Select>
      </FormControl>
    </div>

    {customerAccounts.length > 1 && <>
      {!allocationNotRun && <CustomerAllocationSummary selectedCustomer={selectedCustomer} startDate={startDate} endDate={endDate} />}
      <FormControl sx={{ m: 0, minWidth: '100px' }} size="small">
        <Select
          value={selectedAccount.id}
          onChange={(event: any) => setSelectedAccount(accounts.find((a) => a.id === event.target.value))}
          className="customer-allocation--select"
        >
          {customerAccounts.map(act => <MenuItem value={act.id} key={act.id}>{getAccountName(act.name)}</MenuItem>)}
        </Select>
      </FormControl>
    </>}

    <div className="customer-allocation--description">
      <strong>{program?.name || 'Standard Ratepayer'}</strong>
      <p>{getProgramDescription(program)}</p>
    </div>

    {!allocationNotRun && <ToplineMetrics {...toplineMetrics} />}

    {!allocationNotRun && <UtilityToplineMetrics {...certValues} carbonIntensity={{marketBased: isNaN(ci) ? residualCI.marketBased : ci, locationBased: residualCI.locationBased}}/>}

    <Grid container spacing={2} sx={{mt: 0, mb: 2, mr: 2 }}>
      <Grid item xs={12}>
        <Card variant='outlined' className='se--card-base' sx={{p: 2 }}>
          {!allocationNotRun && <div className="customer-allocation-chart-header--options">
              {program?.is247Program ? <FormControlLabel control={<Switch checked={show247Cumulative} onChange={(e) => setShow247Cumulative(e.target.checked)} />} label="Cumulative" /> : <div />}
              <DateRangeHeader onChange={setDates} defaultStart={defaultStartStr} defaultEnd={defaultEndStr} defaultInterval='day'/>
            </div>
          }
          {allocationNotRun && <div>Inventory allocation has not been run. Go to the <Link to="/programs">Program Settings</Link> page to configure and run inventory allocation based on your program setup.</div>}
          {!allocationNotRun &&
            <HighchartsReact
              highcharts={Highcharts}
              options={makeChartOptions(!is247Program || show247Cumulative ? cumulativeChartOptions : intervalChartOptions)}
            />
          }
        </Card>
      </Grid>
    </Grid>
  </div>
};


export default CustomerAllocation;