import * as React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { useFetchMyTeammatesQuery, useDeleteUserMutation } from 'api/users';
import { IUser } from 'api/types';
import ConfirmDialog from 'components/ConfirmDialog';
import { userHasAccess } from 'constants/types';
import InviteUserModal  from 'layouts/Teammates/inviteTeammateModal';
import { getCustomerName, getCurrentUser } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';

import './style.css';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    type: 'string',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 500,
    sortable: false,
    type: 'string',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    sortable: false,
    type: 'string',
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 100,
    sortable: false,
    type: 'string',
  },
]

const TeammatesView = () => {
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const {data, isError, isFetching, isLoading} = useFetchMyTeammatesQuery({page, per_page: rowsPerPage});
  const [deleteUser, deleteUserApi] = useDeleteUserMutation();
  const loading = isLoading || isFetching;
  const companyName = useAppSelector(getCustomerName);
  const user: null | IUser = useAppSelector(getCurrentUser);

  const onDeleteConfirm = () => {
    const idToDelete = selectedRows[0]
    deleteUser(idToDelete);
    setConfirmDeleteDialogOpen(false);
  }

  return (
    <div className="teammates-page--container">
      <h1>Your teammates</h1>
      <div className="teammates-page--description">
        <p>The list of all users under {companyName || 'your company'}</p>
        {userHasAccess(user, 'admin') &&
          <div className="teammates-page--action-buttons">
            <Button id="teammates-page--invite-user-button" variant='contained' startIcon={<AddCircleOutlineIcon />}>
              Invite a new user
            </Button>
            <LoadingButton
              color={deleteUserApi.isError ? 'error' : 'primary'}
              loading={deleteUserApi.isLoading}
              loadingPosition="start"
              variant='contained'
              disabled={!selectedRows.length}
              startIcon={<DeleteOutline />}
              onClick={() => setConfirmDeleteDialogOpen(true)}
            >
              Delete User
            </LoadingButton>
          </div>
        }
      </div>
      <InviteUserModal openButtonId="teammates-page--invite-user-button" />
      <ConfirmDialog
        open={confirmDeleteDialogOpen}
        setOpen={setConfirmDeleteDialogOpen}
        title={'Are you sure?'}
        content={'To delete , type in \'delete\' below.'}
        requiredInput={'delete'}
        onConfirm={onDeleteConfirm}
      />
      <DataGrid
        loading={loading}
        getRowClassName={() => 'teammates-page--row'}
        rowCount={(data?.meta?.pagination?.totalItems || 0)}
        pagination={true}
        rows={data?.data || []}
        isRowSelectable={params => params.row?.role === 'user'}
        error={isError || undefined}
        columns={columns}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={setRowsPerPage}
        onPageChange={(p) => setPage(p + 1)}
        paginationMode="server"
        autoHeight={true}
        onSelectionModelChange={setSelectedRows}
      />
    </div>
  )
};

export default TeammatesView;