import { ICustomerIntervention } from 'api/types';
import Theme from 'config/Theme';

import { hexToRGB } from './math';


// Makes a vertical line that indicates the current time on a chart.
//
// To use this, you would change the Highchart xAxis configuration as follows:
//
// xAxis: {
//  plotLines: [
//    ...,
//    makeTodayPlotLine(t)
//  ],
// type: 'datetime' <-- // Important! Since we're overriding the xAxis, we need to make sure it's still datetime.
// }
export function makeTodayPlotLine(todayEpochTime: number, text?: string) {
  const rgb = hexToRGB(Theme.palette.chartDarkBlueColor.main);
  return {
    color: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`,
    width: 1,
    zIndex: 1,
    value: todayEpochTime,
    label: {
      text: text || 'Today',
      x: 4,
      style: {color: 'rgba(0,0,0,0.5)'},
      y: 24,
      rotation: 0,
    }
  }
}


// Find the nearest point to `epoch` in `plotData` and return its y-value.
function getScenarioValueAtTime(plotData: {x: number, y: number}[], epoch: number): number {
  const firstIndexEqualOrAfter = plotData.findIndex((xy) => (xy.x >= epoch));
  if (firstIndexEqualOrAfter >= 0) {
    const xBefore = plotData[Math.max(0, firstIndexEqualOrAfter - 1)].x;
    const xAfter = plotData[firstIndexEqualOrAfter].x;
    if (Math.abs(xBefore - epoch) < Math.abs(xAfter - epoch)) {
      return plotData[Math.max(0, firstIndexEqualOrAfter - 1)].y;
    } else {
      return plotData[firstIndexEqualOrAfter].y;
    }
  } else {
    return 0;
  }
}


// Make a scatter plot that represents when projects were implemented (or are planned).
export function makeInterventionPlotPoints(
    customerInterventions: ICustomerIntervention[],
    seriesToAnnotate: { x: number, y: number }[],
    seriesName?: string) {
  if (!customerInterventions || customerInterventions.length === 0) {
    return null;
  }
  let interventionsAtEachDate: {[epoch: number]: string[]} = {};
  customerInterventions.forEach((value) => {
    const epoch = new Date(value.startDate).valueOf();
    if (!interventionsAtEachDate[epoch]) {
      interventionsAtEachDate[epoch] = [];
    }
    interventionsAtEachDate[epoch].push(value.name);
  });

  return {
    name: seriesName || 'Your Projects',
    visible: true,
    type: 'scatter',
    color: Theme.palette.chartBlueColor.main,
    marker: {
      radius: 5,
      symbol: 'diamond'
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '<div>{point.interventions}</div>',
    },
    data: Object.entries(interventionsAtEachDate).map((value) => {
      const epoch = parseInt(value[0]);
      const scenarioValueY = getScenarioValueAtTime(seriesToAnnotate, epoch);
      const names = value[1].map((s) => `${s}<br/>`).join('');
      return { x: epoch, y: scenarioValueY, interventions: names };
    })
  };
}
