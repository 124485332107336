import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';


interface ICardSaveButtonProps {
  loading: boolean
  error: boolean
  text: string
  icon?: any
  onClick?: () => void;
}


export default function CardSaveButton(props: ICardSaveButtonProps) {
  let maybeHelpText: string | React.ReactNode = '';
  if (!props.loading) {
    if (!props.error) {
      maybeHelpText = <Alert severity="success" icon={<CheckIcon fontSize="inherit"/>} sx={{mr: 2, pt: 0, pb: 0}}>Saved!</Alert>;
    } else {
      maybeHelpText = <Alert severity="error" icon={<ErrorIcon fontSize="inherit"/>} sx={{mr: 2, pt: 0, pb: 0}}>Something went wrong</Alert>;
    }
  }

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
      {maybeHelpText}
      <LoadingButton
        loading={props.loading}
        loadingPosition="start"
        startIcon={props.icon || <SaveIcon/>}
        variant="contained"
        onClick={props.onClick}
      >
        {props.text}
      </LoadingButton>
    </Box>
  );
}
