import React, { useEffect, useState } from 'react';

import {
  Button, Typography, Box,
  Grid, CardHeader, CardContent
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';

import CardBase from 'components/CardBase';
import BasicDropdown from 'components/BasicDropdown';
import VerticalDivider from 'components/VerticalDivider';

import NumberField from 'components/NumberField';
import { useGetCustomerGoalsQuery, useGetCustomerPerformanceSummaryQuery, useSaveCustomerGoalsMutation } from 'api/summary';
import { ICustomerGoals } from 'api/types';
import { useGetHistoricalPerformanceQuery } from 'api/historical';
import { getLastYearCost, getLastYearEmissionsTonsCo2 } from 'utils/dataUtils';


interface IGoalSettingCardProps {
  inputLayout: 'wide' | 'narrow'
  showDividers: boolean
  showSaveButton?: boolean
  sx?: any
}


let saveTimeoutId: ReturnType<typeof setTimeout> | null = null;


const SaveIndicator = (props: { loading: boolean, error: boolean }) => {
  return (
    <LoadingButton
      disabled={true}
      loading={props.loading}
      loadingPosition="start"
      startIcon={<CheckIcon/>}
      variant="text"
    >
      Autosaved
    </LoadingButton>
  );
}


const GoalSettingCard = (props: IGoalSettingCardProps) => {
  const [editedGoals, setEditedGoals] = useState<null | ICustomerGoals>(null);

  const goalsApi = useGetCustomerGoalsQuery();
  const dataApi = useGetHistoricalPerformanceQuery();

  const currentAnnualEmissionsTons = getLastYearEmissionsTonsCo2(dataApi?.data?.data?.netFootprint || []);
  const currentAnnualCost = getLastYearCost(dataApi?.data?.data?.netFootprint || []);

  const [saveGoals, saveGoalsApi] = useSaveCustomerGoalsMutation();

  // Format numbers with commas so that they're easier to read.
  const currentEmissionsFmt = currentAnnualEmissionsTons?.toLocaleString('default', { maximumFractionDigits: 0 }) || '';
  const currentCostsFmt = currentAnnualCost?.toLocaleString('default', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }) || '';

  useEffect(() => {
    if (goalsApi.data) {
      setEditedGoals(goalsApi.data.data);
    }
  }, [goalsApi.data]);

  const onAutosaveGoals = (goals: null | ICustomerGoals) => {
    if (!goals) {
      return;
    }
    saveGoals(goals);
  }

  const updateGoals = (key: string, value: number | string) => {
    // Every time the user updates their goals, we reset the save timeout. This
    // prevents an update from firing with every keystroke.
    saveTimeoutId && clearTimeout(saveTimeoutId);
    const newEditedGoals = { ...editedGoals, [key]: value };
    saveTimeoutId = setTimeout(() => onAutosaveGoals(newEditedGoals), 1000);
    setEditedGoals(newEditedGoals);
  };

  // Helper function that the quick select buttons use.
  const updateGoalsPercentage = (key: keyof ICustomerGoals, percentReduction: number) => {
    if (percentReduction <= 0 || percentReduction >= 1 || !currentAnnualEmissionsTons || !currentAnnualCost) {
      return;
    }
    if (key === 'annualEmissionsTons') {
      updateGoals(key, currentAnnualEmissionsTons * (1 - percentReduction));
    } else if (key === 'annualElectricityCost') {
      updateGoals(key, currentAnnualCost * (1 - percentReduction));
    }
  }

  return (
    <CardBase width={12} sx={props.sx}>
      <CardHeader
        title={<Typography gutterBottom variant="seCardTitleText" component="div">Goals</Typography>}
        action={<SaveIndicator loading={saveGoalsApi.isLoading} error={saveGoalsApi.isError}/>}
      />
      <CardContent>
        <Box sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
          <InfoIcon sx={{mr: 2}}/>
          <Typography sx={{mb: 2}}>Currently, your grid usage has a footprint of
            <span style={{fontWeight: 'bold'}}> {currentEmissionsFmt}</span> tons CO₂ and costs
            <span style={{fontWeight: 'bold'}}> {currentCostsFmt}</span> per year.
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={props.inputLayout === 'narrow' ? 12 : 4}>
            <Box sx={{display: 'flex', flexDirection: 'column', p: 2}}>
              <Typography sx={{mb: 2, ml: 0}}>Carbon Emissions</Typography>
              <NumberField
                id="goal-input--emissions"
                value={editedGoals?.annualEmissionsTons || 0}
                onValueChanged={(value: number) => updateGoals('annualEmissionsTons', value)}
                units={'tons CO₂'}
              />
              <Typography variant='seSmallMutedText'>Quick select:
                <Button size="small" sx={{minWidth: 30}} onClick={() => updateGoalsPercentage('annualEmissionsTons', 0.10)}>10%</Button>,
                <Button size="small" sx={{minWidth: 30}} onClick={() => updateGoalsPercentage('annualEmissionsTons', 0.20)}>20%</Button>, or
                <Button size="small" sx={{minWidth: 30}} onClick={() => updateGoalsPercentage('annualEmissionsTons', 0.30)}>30%</Button> reduction
              </Typography>
            </Box>
          </Grid>

          {props.showDividers ? <VerticalDivider/> : ''}

          <Grid item xs={props.inputLayout === 'narrow' ? 12 : 4}>
            <Box sx={{display: 'flex', flexDirection: 'column', p: 2}}>
              <Typography sx={{mb: 2, ml: 0}}>Costs</Typography>
              <NumberField
                id="goal-input--costs"
                value={editedGoals?.annualElectricityCost || 0}
                onValueChanged={(value: number) => updateGoals('annualElectricityCost', value)}
                units={'$'}
              />
              <Typography variant='seSmallMutedText' >Quick select:
                  <Button size="small" sx={{minWidth: 30}} onClick={() => updateGoalsPercentage('annualElectricityCost', 0.10)}>10%</Button>,
                  <Button size="small" sx={{minWidth: 30}} onClick={() => updateGoalsPercentage('annualElectricityCost', 0.20)}>20%</Button>, or
                  <Button size="small" sx={{minWidth: 30}} onClick={() => updateGoalsPercentage('annualElectricityCost', 0.30)}>30%</Button> reduction
                </Typography>
            </Box>
          </Grid>

          {props.showDividers ? <VerticalDivider/> : ''}

          <Grid item xs={props.inputLayout === 'narrow' ? 12 : 4}>
            <Box sx={{display: 'flex', flexDirection: 'column', p: 2}}>
              <Typography sx={{mb: 2, ml: 0}}>Time Horizon</Typography>
              <BasicDropdown
                id={'goal-input--year'}
                value={editedGoals?.targetYear?.toString() || '2040'}
                mapValueToLabel={{
                  2025: 'By 2025',
                  2030: 'By 2030',
                  2035: 'By 2035',
                  2040: 'By 2040',
                }}
                updateCallback={(value: string) => updateGoals('targetYear', value) }
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </CardBase>
  )
}


export default GoalSettingCard;
