import * as React from 'react';
import { NavLink, Route, Routes, Navigate } from "react-router-dom";

import AllocationOverview from 'components/AllocationOverview';
import CustomerAllocation from 'components/CustomerAllocation';
import ProgramAllocation from 'components/ProgramAllocation';
import InventoryAllocation from 'components/InventoryAllocation';

import './style.css';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getCustomerAccountList, getAccountProgramAssignment, getGenerationData, getGeneratorProgramAssignment, getHasAllocationBeenEdited, getPrograms } from 'modules/demo/selectors';
import { CornerDialog } from 'evergreen-ui';
import { runAllocation } from 'demo/rec_allocation';
import { receiveAllocationResults } from 'modules/demo/slice';


const AllocationSidebar = () => {
  return (
    <ul className="allocation--topbar">
      <NavLink to="/allocation/overview" className="allocation-topbar--link">
        Overview
      </NavLink>
      <NavLink to="/allocation/programs" className="allocation-topbar--link">
        Programs
      </NavLink>
      <NavLink to="/allocation/customers" className="allocation-topbar--link">
        Customers
      </NavLink>
      {/* <NavLink to="/allocation/inventory" className="allocation-topbar--link">
        Inventory
      </NavLink> */}
    </ul>
  );
}


const AllocationView = () => {
  const allocationBeenEdited = useAppSelector(getHasAllocationBeenEdited);
  const [isShown, setIsShown] = React.useState(allocationBeenEdited);
  const dispatch = useAppDispatch();
  const programs = useAppSelector(getPrograms);
  const generation = useAppSelector(getGenerationData);
  const customerAccounts = useAppSelector(getCustomerAccountList);
  const customerAccountAssignment = Object.values(useAppSelector(getAccountProgramAssignment));
  const generatorAssignment = Object.values(useAppSelector(getGeneratorProgramAssignment));

  const onRunAllocationClick = () => {
    setIsShown(false);
    runAllocation({
      programs,
      customerAccounts,
      customerAccountAssignment,
      generatorAssignment,
      generation,
      // TODO: make this configurable
      optimizeFor: 'minimalShortfall',
    }).then(data => {
      dispatch(receiveAllocationResults({data}));
    }).catch(err => {
      console.warn('Allocation run failed', err);
    });
  };


  return (
    <div className="allocation-view--container">
      <h1>Allocation Results</h1>
      <AllocationSidebar />
      <Routes>
        <Route path="/" element={<Navigate replace to="/allocation/overview" />} />
        <Route path="/overview" element={<AllocationOverview />} />
        <Route path="/programs" element={<ProgramAllocation />}/>
        <Route path="/customers"  element={<CustomerAllocation />}/>
        {/* <Route path="/inventory"  element={<InventoryAllocation />} /> */}
      </Routes>
      <CornerDialog
        title="Allocation Settings Have Changed"
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        onConfirm={onRunAllocationClick}
        confirmLabel='Run Allocation'
      >
        Allocation settings have changed from edits in the console. Would you like to run allocation?
      </CornerDialog>

    </div>
  )
};

export default AllocationView;