import React from 'react';

import { useNavigate } from "react-router-dom";

import { CardHeader, CardContent, Button, Typography, Box, Skeleton, SvgIcon } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

import CardBase from 'components/CardBase';
import CardTitle from 'components/CardTitle';
import ExpandArrow from 'components/ExpandArrow';

import { ICustomer } from 'api/types';
import { useCalculateCustomerInterventionImpactQuery, useListCustomerInterventionsQuery } from 'api/projections';

import { getCurrentCustomer } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';

import { sum } from 'utils/math';


const ProjectTeaserCard = () => {
  const customer: ICustomer = useAppSelector(getCurrentCustomer);

  const forecastHorizon = (customer.name.toLowerCase() === 'xcel') ? 2030 : 2040;

  // Get a list of suggested and planned interventions for the customer.
  const listInterventionsApi = useListCustomerInterventionsQuery({ forecastHorizon: forecastHorizon });

  // Once the list of interventions is fetched, calculate their marginal impact.
  const scenario = listInterventionsApi.data || [];
  const { data, isLoading, isFetching, isError } = useCalculateCustomerInterventionImpactQuery(
    { forecastHorizon: forecastHorizon, scenario: scenario },
    { skip: scenario.length === 0 });

  const cardIsLoading = listInterventionsApi.isLoading || listInterventionsApi.isFetching || isLoading || isFetching;

  const numberOfPotentialProjects = (data || []).length;
  const totalEmissionReductionTons = -1 * sum((data || []).map(value => value.projectedImpact.netEmissionsLbsCo2)) / 2000;

  const noProjectsText =
    <Typography>
      {`No project suggestions have been added yet. Please check back later.`}
    </Typography>;

  const teaserText = <Typography>
      By {forecastHorizon}, you could reduce your emissions by <b>{totalEmissionReductionTons.toLocaleString('default', { maximumFractionDigits: 0 })} tons CO₂</b> {' '}
      by implementing {' '} <b>{numberOfPotentialProjects || 0}</b> projects.
    </Typography>;

  const cardText = (numberOfPotentialProjects === 0) ? noProjectsText : teaserText;

  const navigate = useNavigate();
  const goToScenariosPage = () => navigate('/scenarios');

  return (
    <CardBase width={12}>
      <CardHeader
        title={
          <Box display='flex' flexDirection='row' justifyItems='center' alignItems='center'>
            <SvgIcon color='success'>
              <LightbulbOutlinedIcon/>
            </SvgIcon>
            <CardTitle title={'Explore projects to reduce your footprint'}/>
          </Box>
        }
        action={
          <ExpandArrow onClick={goToScenariosPage}/>
        }
      />
      <CardContent>
        <Box>
          {
            cardIsLoading ?
              <>
                <Skeleton/>
                <Skeleton/>
              </>
              : cardText
          }
        </Box>
        <Button
          variant='outlined'
          color='primary'
          sx={{mt: 3}}
          onClick={goToScenariosPage}
        >Explore Scenarios</Button>
      </CardContent>
    </CardBase>
  )
}


export default ProjectTeaserCard;
