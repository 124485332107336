import { difference, equals, flatten, groupBy, indexBy, isEmpty, prop } from "ramda";
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { dukeOGE2021FactorsLbsPerMWH, gridCFEFuels, groupByInterval } from "demo/chart_helpers";
import { IGenerationData } from "demo/data/duke/generation";
import { IGenerator } from "demo/data/generators";
import { RootState, store } from "modules/store";
import { mean, sum, makeScalingFunction, sample } from "utils/math";
import { snakeToTitle } from "utils/strings";
import { timer } from "utils/timer";

import { IProgram, StandardRatepayerProgram } from "./slice";
import { IHourlyAllocationResult, instanceOf247DedicatedResult, instanceOf247PooledResult, instanceOfAnnualDedicatedResult, instanceOfAnnualPooledResult } from "demo/rec_allocation";
import { IToplineMetrics } from "demo/components/ToplineMetrics";
import { IUtilityToplineMetrics } from "demo/components/UtilityToplineMetrics";
import { fuelPalette } from "utils/fuelPalette";

const createSelector = createSelectorCreator(
  defaultMemoize,
  equals,
);


interface IGeneratorListItem extends IGenerator {
  id: string;
  programId?: string | null;
  source: string;
  type: string | null;
}

const root = (state: RootState) => state.demo;

export const getPrograms = createSelector(root, (rootState) => rootState.programs)
export const getProgramsList = (state: RootState) => {
  const programs = getPrograms(state);
  return programs.map(p => ({ ...p, totalCerts: Math.floor(getProgramTotalMWh(state, p.id)) }))
}
const getProgramsByIdMap = (state: RootState) => indexBy(prop('id'), getProgramsList(state));
export const getProgram = (state: RootState, programId: string) => getPrograms(state).find(p => p.id === programId);
export const getOtherPrograms = (state: RootState, programId: string) => getPrograms(state).filter(p => p.id !== programId).concat([StandardRatepayerProgram]);
export const getAllocationResults = (state: RootState) => root(state).allocationResults;

export const generatorsById = createSelector(root, state => state.generatorsById);
export const getGenerators = (state: RootState) => Object.values(generatorsById(state));
export const getGenerator = createSelector(
  generatorsById,
  (state, generatorId) => generatorId,
  (gensById: ReturnType<typeof generatorsById>, generatorId: string) => gensById[generatorId]
);

export const getAccountProgramAssignment = (state: RootState) => root(state).customerProgramsByAccountId;
export const getGeneratorProgramAssignment = createSelector(root, rootState => rootState.generatorProgramsByGeneratorId);
const getUnassignedGeneratorIds = (state: RootState) => {
  const allIds = getGenerators(state).map(d => d.plant_id_eia);
  const assignedIds = Object.keys(getGeneratorProgramAssignment(state));

  return new Set(difference(allIds, assignedIds));
}
const getProgramIdForAccountId = (state: RootState, accountId: number) => getAccountProgramAssignment(state)[accountId]?.programId || StandardRatepayerProgram.id;
const getProgramForAccountId = (state: RootState, accountId: number) => getProgram(state, getProgramIdForAccountId(state, accountId)) || StandardRatepayerProgram;
const getAccountIdsForProgram = (state: RootState, id: string) => Object.values(getAccountProgramAssignment(state)).filter(({ programId }) => programId === id).map(prop('accountId'));
const getAccountsForProgram = (state: RootState, programId: string) => getAccountIdsForProgram(state, programId).map(acctId => getCustomerAccount(state, acctId)).filter(d => !!d);
const getAccountsInResidualMix = (state: RootState) => getCustomerAccountList(state).filter(acc => acc.programId === StandardRatepayerProgram.id);
export const getProgramForGenerator = (state: RootState, generatorId: string): IProgram | undefined => {
  const programId = getGeneratorProgramAssignment(state)[generatorId]?.programId
  if (programId) {
    return getProgram(state, programId);
  }
}

const residualGenerators = createSelector(
  getGeneratorProgramAssignment,
  generatorsById,
  (genProgAssignment, gensById) => {
    const usedGeneratorIds = new Set(Object.values(genProgAssignment).filter(d => d.programId !== StandardRatepayerProgram.id).map(d => d.generatorId));
    return Object.values(gensById).filter(g => !usedGeneratorIds.has(g.plant_id_eia));
  }
)


const getCustomerAccountLoad = (state: RootState) => root(state).allCustomerAccountLoad;
export const getCustomerAccountList = createSelector(
  [getCustomerAccountLoad, getAccountProgramAssignment],
  (loads, programAssignment) => {
    return loads.map(acc => ({
      ...acc,
      programId: programAssignment[acc.id]?.programId || StandardRatepayerProgram.id,
    }));
  }
);
export const getCustomerAccountsList = (state: RootState) => {
  const customerAccounts = getCustomerAccountLoad(state).map(acc => ({
    ...acc,
    programId: getAccountProgramAssignment(state)[acc.id]?.programId || StandardRatepayerProgram.id,
  }));
  const groupedAccounts = groupBy(prop('rootCustomerId'), customerAccounts);
  return Object.values(groupedAccounts)
}
export const getCustomerAccountsListWithSummedLoad = createSelector(
  [getCustomerAccountsList],
  (custAcctsList) => {
    return custAcctsList.map(accts => accts.map(acct => ({...acct, totalLoadMWh: sum(acct.load.map(datum => datum.consumed_kwh)) / 1000})));
  }
);
const getCustomerAccountsByIdMap = (state: RootState) => indexBy(prop('id'), getCustomerAccountList(state));
export const getCustomerAccount = (state: RootState, customerId: number) => getCustomerAccountList(state).find(c => c.id === customerId);


const getCustomerLoadMatching = createSelector([
  getCustomerAccountList,
],
  (acctsList): { name: string, id: number, loadMatched: number, totalLoadMWh: number, programId: string }[] => {
    timer.start('getCustomerLoadMatching()');
    // TODO: this is not how it's supposed to be done
    const state = store.getState();
    const results = acctsList.map(acct => {
      return {
        name: acct.name,
        id: acct.id,
        programId: acct.programId,
        totalLoadMWh: sum(acct.load.map(prop('consumed_kwh'))) / 1000.0,
        loadMatched: Math.min(100, getAccountLoadMatched(state, acct.id, null, null) * 100),
      }
    });
    timer.stop('getCustomerLoadMatching()');
    return results;
  },
);

export const getTotalCustomerLoadKWh = createSelector(
  [getCustomerAccountLoad],
  (loads) => sum(loads.map(d => sum(d.load.map(l => l.consumed_kwh)))),
);

const generationData = (state: RootState) => root(state).generationData;
const generationDataStatus = (state: RootState) => generationData(state).status;
export const generationDataNeverLoaded = (state: RootState) => generationDataStatus(state) === null;
export const generationDataLoading = (state: RootState) => generationDataStatus(state) === 'loading';
export const getGenerationData = (state: RootState) => generationData(state).data;
export const getTotalGenerationMWh = createSelector([getGenerationData], (data) => {
  if (!data) return 0;

  return sum(data
    .purchased_specified.map(d => d.net_generation_mwh)
    .concat(data.purchased_unspecified.map(d => d.net_generation_mwh))
    .concat(data.sampled.map(d => d.net_generation_mwh))
    .concat(data.unbundled_recs.map(d => d.net_generation_mwh)))
});

const getSampledGeneratorIds = (state: RootState) => [...new Set(getGenerationData(state)?.sampled.map(d => d.plant_id_eia))];
const getPurchasedSpecifiedGeneratorIds = (state: RootState) => [...new Set(getGenerationData(state)?.purchased_specified.map(d => d.plant_id_eia))];
const getPurchasedUnspecifiedGeneratorIds = (state: RootState) => [...new Set(getGenerationData(state)?.purchased_unspecified.map(d => d.plant_id_eia))];
const getUnbundledRECGeneratorIds = (state: RootState) => [...new Set(getGenerationData(state)?.unbundled_recs.map(d => d.plant_id_eia))];
export const getSampledGenerators = (state: RootState) => getSampledGeneratorIds(state).map(d => getGenerator(state, d)).filter(g => !!g);
const getPurchasedSpecifiedGenerators = (state: RootState) => getPurchasedSpecifiedGeneratorIds(state).map(d => getGenerator(state, d)).filter(g => !!g);
const getPurchasedUnspecifiedGenerators = (state: RootState) => getPurchasedUnspecifiedGeneratorIds(state).map(d => getGenerator(state, d)).filter(g => !!g);
const getUnbundledRECGenerators = (state: RootState) => getUnbundledRECGeneratorIds(state).map(d => getGenerator(state, d)).filter(g => !!g);
export const getGeneratorList = createSelector(
  getGeneratorProgramAssignment,
  getSampledGenerators,
  getPurchasedSpecifiedGenerators,
  getPurchasedUnspecifiedGenerators,
  getUnbundledRECGenerators,
  (assignment, sampled, purchasedSpecified, purchasedUnspecified, unbundledRecGens) => {
    timer.start('getGeneratorList()');
    const genToListItem = (gen: IGenerator, source: string, type: string | null): IGeneratorListItem => ({
      ...gen,
      id: gen.plant_id_eia,
      programId: assignment[gen.plant_id_eia]?.programId || StandardRatepayerProgram.id,
      source,
      type,
    });

    const value = sampled.map(g => genToListItem(g, 'owned', null))
      .concat(purchasedSpecified.map(g => genToListItem(g, 'purchased', 'specified')))
      .concat(purchasedUnspecified.map(g => genToListItem(g, 'purchased', 'unspecified')))
      .concat(unbundledRecGens.map(g => genToListItem(g, 'REC', 'unbundled')));
    timer.stop('getGeneratorList()');
    return value;
  }
);
const fullInventoryGeneration = createSelector(
  getGenerationData,
  (data) => {
    if (!data) {
      return [];
    }

    return data.purchased_specified
      .concat(data.purchased_unspecified)
      .concat(data.sampled)
      .concat(data.unbundled_recs);
  }
);

export const getGeneratorListWithSummedGen = createSelector(
  [getGeneratorList, fullInventoryGeneration],
  (generators, allGeneration) => {
    timer.start('getGeneratorListWithSummedGen()');
    const totalGeneration = groupBy(prop('plant_id_eia'), allGeneration);
    const withSummedGen = generators.map(generator => ({
      ...generator,
      totalGenerationMWh: Math.round(sum((totalGeneration[generator.plant_id_eia] || []).map(prop('net_generation_mwh')))),
    }));
    timer.stop('getGeneratorList()');
    return withSummedGen;
  }
);



export const getResidualMixCarbonIntensity = createSelector([getAllocationResults, generatorsById, generationData],
  (results, generators, genData) => {
    timer.start('getResidualMixCarbonIntensity');

    if (!genData || !genData.data) {
      timer.stop('getResidualMixCarbonIntensity');
      return {
        locationBased: 0,
        marketBased: 0,
        marketBasedCFEPct: 0,
        locationBasedCFEPct: 0,
        fullInventory: 0,
        fullInventoryCFEPct: 0,
      }
    }

    const locationFuelMix: Record<string, number> = {};
    const residualFuelMix: Record<string, number> = {};
    const totalFuelMix: Record<string, number> = {};

    const data = genData.data;

    const locationBasedGeneration = data
      .purchased_specified
      .concat(data.purchased_unspecified)
      .concat(data.sampled);

    const locationWithRECsGeneration = locationBasedGeneration.concat(data.unbundled_recs);

    locationBasedGeneration.forEach(gen => {
      const plant = generators[gen.plant_id_eia];
      if (plant) {
        if (plant.fuel_category_eia930 in locationFuelMix) {
          locationFuelMix[plant.fuel_category_eia930] = locationFuelMix[plant.fuel_category_eia930] + gen.net_generation_mwh;
        } else {
          locationFuelMix[plant.fuel_category_eia930] = gen.net_generation_mwh;
        }
      }
    });

    locationWithRECsGeneration.forEach(gen => {
      const plant = generators[gen.plant_id_eia];
      if (plant) {
        if (plant.fuel_category_eia930 in totalFuelMix) {
          totalFuelMix[plant.fuel_category_eia930] = totalFuelMix[plant.fuel_category_eia930] + gen.net_generation_mwh;
        } else {
          totalFuelMix[plant.fuel_category_eia930] = gen.net_generation_mwh;
        }
      }
    })

    if (results) {
      results.residualMixResults.generation.forEach(gen => {
        const plant = generators[gen.plant_id_eia];
        if (plant) {
          if (plant.fuel_category_eia930 in residualFuelMix) {
            residualFuelMix[plant.fuel_category_eia930] = residualFuelMix[plant.fuel_category_eia930] + gen.net_generation_mwh;
          } else {
            residualFuelMix[plant.fuel_category_eia930] = gen.net_generation_mwh;
          }
        }
      });
    } else {
      Object.entries(totalFuelMix).forEach(([fuel, mwh]) => {
        residualFuelMix[fuel] = mwh;
      });
    }

    const totalLocation = sum(Object.values(locationFuelMix));
    const totalResidual = sum(Object.values(residualFuelMix));
    const totalTotal = sum(Object.values(totalFuelMix));

    const locationBasedCarbonIntensity = sum(Object.entries(locationFuelMix).map(([fuel, mwh]) => (dukeOGE2021FactorsLbsPerMWH[fuel] || 0) * mwh)) / totalLocation;
    const residualMarketBasedCarbonIntensity = sum(Object.entries(residualFuelMix).map(([fuel, mwh]) => (dukeOGE2021FactorsLbsPerMWH[fuel] || 0) * mwh)) / totalResidual;
    const fullInventoryCarbonIntensity = sum(Object.entries(totalFuelMix).map(([fuel, mwh]) => (dukeOGE2021FactorsLbsPerMWH[fuel] || 0) * mwh)) / totalTotal;
    const residualCfeMWh = sum(Object.entries(residualFuelMix).filter(([fuel]) => gridCFEFuels.has(fuel)).map(d => d[1]));
    const locationalCfeMWh = sum(Object.entries(locationFuelMix).filter(([fuel]) => gridCFEFuels.has(fuel)).map(d => d[1]));
    const fullInfentoryCfeMWh = sum(Object.entries(totalFuelMix).filter(([fuel]) => gridCFEFuels.has(fuel)).map(d => d[1]));

    timer.stop('getResidualMixCarbonIntensity');
    return {
      locationBased: locationBasedCarbonIntensity,
      marketBased: residualMarketBasedCarbonIntensity,
      marketBasedCFEPct: Math.min(1, residualCfeMWh / totalResidual) * 100,
      locationBasedCFEPct: Math.min(1, locationalCfeMWh / totalLocation) * 100,
      fullInventory: fullInventoryCarbonIntensity,
      fullInventoryCFEPct: Math.min(1, fullInfentoryCfeMWh / totalTotal) * 100,
    }
  }
);


export const getProgramTotalMWh = createSelector(
  getGeneratorProgramAssignment,
  residualGenerators,
  fullInventoryGeneration,
  getAllocationResults,
  (state, programId) => programId,
  (
    genAssignment: ReturnType<typeof getGeneratorProgramAssignment>,
    residualGens: ReturnType<typeof residualGenerators>,
    data: ReturnType<typeof fullInventoryGeneration>,
    allocationResults: ReturnType<typeof getAllocationResults>,
    programId: string,
  ) => {
    const generatorIds = new Set(Object.values(genAssignment).filter(d => d.programId === programId).map(d => d.generatorId));

    if (programId === 'Standard Ratepayer') {
      residualGens.forEach(gen => generatorIds.add(gen.plant_id_eia));
    }

    if (!data) {
      return 0;
    }

    const programGenMWh = sum(data.filter(gen => generatorIds.has(gen.plant_id_eia)).map(gen => gen.net_generation_mwh));

    const releasedToProgramMWh = allocationResults?.certReleaseResults?.reduce((soFar, releaseResult) => soFar + (releaseResult.certsClaimable.claimed[programId] || 0), 0) || 0;
    const releasedFromProgramMWh = allocationResults?.certReleaseResults
      ?.filter(d => d.fromProgramId === programId)
      .reduce((soFar, releaseResult) => soFar + sum(Object.values(releaseResult.certsClaimable.claimed)) || 0, 0) || 0;

    return programGenMWh + releasedToProgramMWh - releasedFromProgramMWh;
  },
)

export const getProgramLoad = createSelector(
  [
    getAccountIdsForProgram,
    getCustomerAccountList,
  ],
  (accountIds, accounts) => flatten(accounts.filter(acc => accountIds.includes(acc.id)).map(prop('load'))),
);

const getProgramCarbonIntensity = createSelector(
  [
    (_, programId: string) => programId,
    getGeneratorProgramAssignment,
    residualGenerators,
    generatorsById,
    getGenerationData,
    getAllocationResults,
  ],
  (programId, genAssignment, residualGens, generators, data, allocationResults) => {
    const generatorIds = new Set(Object.values(genAssignment).filter(d => d.programId === programId).map(d => d.generatorId));

    if (programId === 'Standard Ratepayer') {
      residualGens.forEach(gen => generatorIds.add(gen.plant_id_eia));
    }

    if (!data) {
      return 0;
    }

    // TODO: this should probably be using allocation results, not just the generators
    const programGeneration = data
      .purchased_specified
      .concat(data.purchased_unspecified)
      .concat(data.sampled)
      .concat(data.unbundled_recs);

    const fuelMix: Record<string, number> = {};

    programGeneration.filter(gen => generatorIds.has(gen.plant_id_eia)).forEach(gen => {
      const plant = generators[gen.plant_id_eia];
      if (plant) {
        if (plant.fuel_category_eia930 in fuelMix) {
          fuelMix[plant.fuel_category_eia930] = fuelMix[plant.fuel_category_eia930] + gen.net_generation_mwh;
        } else {
          fuelMix[plant.fuel_category_eia930] = gen.net_generation_mwh;
        }
      }
    });

    const totalProgramFuelMix = sum(Object.values(fuelMix));

    const programCi = sum(Object.entries(fuelMix).map(([fuel, mwh]) => (dukeOGE2021FactorsLbsPerMWH[fuel] || 0) * mwh)) / totalProgramFuelMix;

    const mwhTakenByProgramId: Record<string, number> = {};

    allocationResults?.certReleaseResults?.forEach(release => {
      const mwhClaimed: undefined | number = release.certsClaimable.claimed[programId];
      if (mwhClaimed) {
        if (!mwhTakenByProgramId[release.fromProgramId]) {
          mwhTakenByProgramId[release.fromProgramId] = 0;
        }
        mwhTakenByProgramId[release.fromProgramId] = mwhTakenByProgramId[release.fromProgramId] + mwhClaimed;
      }
    });

    let totalMWh = totalProgramFuelMix;

    const otherProgramsCi = Object.entries(mwhTakenByProgramId).map(([programId, mwhTaken]) => {
      totalMWh = totalMWh + mwhTaken;
      return {
        ci: getProgramCarbonIntensity(store.getState(), programId),
        mwh: mwhTaken,
      };
    });

    const weightedCI: number = otherProgramsCi.concat([{ ci: programCi, mwh: totalProgramFuelMix }]).reduce((sofar, nextup) => {
      return sofar + (nextup.ci * (nextup.mwh / totalMWh));
    }, 0);

    return weightedCI;

  },
  {memoizeOptions: {maxSize: 10}},
)

const getProgramCarbonIntensityForAccount = createSelector(
  [
    getProgramIdForAccountId,
  ],
  (programId) => {
    return getProgramCarbonIntensity(store.getState(), programId);
  }
)

export const getCustomerLoadMatchedPct = (state: RootState, customerId: number) => {
  const timerName = `getCustomerLoadMatchedPct(${customerId})`;
  timer.start(timerName);
  const customersLoadMatching = getCustomerLoadMatching(state);
  timer.stop(timerName);
  return customersLoadMatching.find(c => c.id === customerId)?.loadMatched;
}

export const getProgramCarbonIntensityForCustomerLoad = createSelector(
  [getProgram, getResidualMixCarbonIntensity, getAccountIdsForProgram],
  (program, residualMixCI, accountIds) => {
    if (!program || program.id === StandardRatepayerProgram.id) {
      return residualMixCI.marketBased;
    }
    const timerName = `getProgramCarbonIntensityForCustomerLoad(${program.id})`;
    timer.start(timerName);

    const customerCIs = accountIds.map(accId => getCustomerCarbonIntensity(store.getState(), accId, null, null));
    const results = mean(customerCIs);
    timer.stop(timerName);
    return results;
  },
  { memoizeOptions: { maxSize: 10 } },
);


export const getHasAllocationBeenEdited = (state: RootState) => {
  const results = getAllocationResults(state);
  const custAssignment = getAccountProgramAssignment(state);
  const genAssignment = getGeneratorProgramAssignment(state);

  const customerOrGeneratorAssignmentNotEmpty = !isEmpty(custAssignment) || !isEmpty(genAssignment);

  if (!results && customerOrGeneratorAssignmentNotEmpty) return true;

  const programs = getPrograms(state);

  const candidate = {
    programs,
    cust: custAssignment,
    gen: genAssignment,
  };

  if (results) {
    const saved = {
      programs: results.lastRunState.programs,
      cust: results.lastRunState.customerProgramsByAccountId,
      gen: results.lastRunState.generatorProgramsByGeneratorId,
    };

    return !equals(saved, candidate);
  } else {
    return false;
  }
};

export const getAllocationNotRun = (state: RootState) => !getAllocationResults(state);
const residualMix = createSelector(
  [getAllocationResults],
  (results) => results?.residualMixResults.generation || [],
);
const getResidualMixByHour = createSelector([residualMix],
  (mix) => {
    timer.start('getResidualMixByHour()');
    const ret: Record<string, IGenerationData[]> = {};
    mix.forEach(mix => {
      const key = mix.datetime_utc;
      if (!ret[key]) {
        ret[key] = [mix];
      } else {
        ret[key].unshift(mix);
      }
    });
    timer.stop('getResidualMixByHour()');
    return ret;
  }
);

export interface IConsumptionGenerationData {
  date: Date,
  consumedKwh: number,
  generatedKwh: number,
  residualMix: Record<string, number>,
  bySource: Record<string, number>,
}

export interface IGenDataBySource {
  date: Date,
  generatedMWh: number,
  generationSource: 'owned' | 'purchased.specified' | 'REC.unbundled' | 'certificate_release',
}


export const getAnnualProgramResults = (state: RootState, customerAccountId: number) => {
  const results = getAllocationResults(state);

  if (!results) return null;

  const programId = getAccountProgramAssignment(state)[customerAccountId]?.programId;
  const programResults = results.allocationResults.find(d => d.programId === programId);

  if ('customerResults' in programResults) {
    return programResults.customerResults.find(res => res.accountId === customerAccountId)
  } else if ('shortFallMWh' in programResults) {
    return {
      shortFallMWh: programResults.shortFallMWh,
      overageMWh: programResults.overageMWh,
      totalGenMWh: programResults.totalGenMWh,
      totalLoadMWh: programResults.totalLoadMWh,
    }
  }
};

export const getCustomerSummaryResults = (state: RootState, rootCustomerId: string, startDate: Date, endDate: Date) => {
  const results = getAllocationResults(state);
  if (!results) return null;

  const groupedAccounts = getCustomerAccountsList(state);
  const customerAccounts = groupedAccounts.find(acts => acts[0].rootCustomerId === rootCustomerId);

  let wgthdAvgPercentMatchNumerator = 0;   // AVG
  let wghtdAvgAnnualCINumerator = 0;       // AVG
  let totalGenMWhCustomer = 0;             // SUM
  let totalCustomerDiff = 0;               // SUM
  let overallLoad = 0;                     // SUM
  const certsBySource = { 'rec': 0, 'owned': 0, 'purchased.specified': 0, 'purchased.unspecified': 0 };

  customerAccounts.forEach(act => {
    const totalActLoadMWh = sum(act.load.map(l => l.consumed_kwh)) / 1000;
    overallLoad += totalActLoadMWh;

    const customerCI = getCustomerCarbonIntensity(state, act.id, null, null);
    wghtdAvgAnnualCINumerator += customerCI * totalActLoadMWh;

    const consumptionAndGenerationData = getConsumptionAndGenerationData(state, act.id, startDate, endDate);
    const bySourceTotal = consumptionAndGenerationData.map(d => d.bySource).reduce((soFar, nextUp) => {
      const copy = { ...soFar };
      Object.entries(nextUp).forEach(([source, mwh]) => {
        if (!copy[source]) {
          copy[source] = mwh;
        } else {
          copy[source] = copy[source] + mwh;
        }
      });
      return copy;
    }, {});
    certsBySource.rec += bySourceTotal.rec || 0;
    certsBySource.owned += bySourceTotal.owned || 0;
    certsBySource['purchased.specified'] += bySourceTotal['purchased.specified'] || 0;
    certsBySource['purchased.unspecified'] += bySourceTotal['purchased.unspecified'] || 0;

    const programId = getAccountProgramAssignment(state)[act.id]?.programId;
    const program = getProgram(state, programId);
    const is247Program = !program || program.is247Program;

    if (!is247Program) {
      const annualProgramResults = getAnnualProgramResults(state, act.id);
      const distributionFactor = annualProgramResults.totalGenMWh / annualProgramResults.totalLoadMWh;
      const certsAllocated = Math.round(distributionFactor * totalActLoadMWh);
      const custDiff = certsAllocated - totalActLoadMWh;
      const pctCovered = Math.min(1, certsAllocated / totalActLoadMWh) * 100;

      wgthdAvgPercentMatchNumerator += pctCovered * totalActLoadMWh;
      totalGenMWhCustomer += annualProgramResults.totalGenMWh;
      totalCustomerDiff += custDiff;
    } else {
      const loadMatchedPct = getCustomerLoadMatchedPct(state, act.id);
      wgthdAvgPercentMatchNumerator += loadMatchedPct * totalActLoadMWh;
    }
  });

  return {
    avgMatchedPercent: totalGenMWhCustomer ? wgthdAvgPercentMatchNumerator / overallLoad : 0,
    avgAccountCI: wghtdAvgAnnualCINumerator ? wghtdAvgAnnualCINumerator / overallLoad : 0,
    totalGenMWh: totalGenMWhCustomer,
    totalDiff: totalCustomerDiff,
    certsBySource,
  }
}

export const getOverallProgramCustomerLoadMatching = (state: RootState, programId: string) => mean(getProgramCustomerLoadMatching(state, programId).map(d => d.loadMatched));

const getGeneratorToFuelCategory = createSelector([getGeneratorList],
  (genList) => {
    timer.start('getGeneratorToFuelCategory');
    const idToFuelCategory: Record<string, string> = {}
    genList.forEach((gen) => {
      idToFuelCategory[gen.plant_id_eia] = gen.fuel_category;
    })
    timer.stop('getGeneratorToFuelCategory');
    return idToFuelCategory;
  }
);

const getResidualFuelCategoryMixByHour = createSelector([getResidualMixByHour, getGeneratorToFuelCategory],
  (residualMixByHour, eiaGeneratorIdToFuelCategory) => {
    timer.start('getResidualFuelCategoryMixByHour()');
    const residualFuelMixByHour: Record<string, Record<string, number>> = {};
    Object.entries(residualMixByHour).forEach(([dtStr, mixes]) => {
      if (!residualFuelMixByHour[dtStr]) {
        residualFuelMixByHour[dtStr] = {};
      }
      mixes.forEach((mix) => {
        const fuelCategory = eiaGeneratorIdToFuelCategory[mix.plant_id_eia];
        if (!residualFuelMixByHour[dtStr][fuelCategory]) {
          residualFuelMixByHour[dtStr][fuelCategory] = mix.net_generation_mwh;
        } else {
          residualFuelMixByHour[dtStr][fuelCategory] = mix.net_generation_mwh + residualFuelMixByHour[dtStr][fuelCategory];
        }
      });
    });
    timer.stop('getResidualFuelCategoryMixByHour()');
    return residualFuelMixByHour;
  }
);


export const getConsumptionAndGenerationData = createSelector(
  [
    getAllocationResults,
    getAccountProgramAssignment,
    getPrograms,
    getCustomerAccountLoad,
    getResidualFuelCategoryMixByHour,
    (state, accountId: number) => accountId,
    (state, acctId, start: Date | null) => start,
    (state, acctId, start, end: Date | null) => end
  ],
  (results, acctProgAssignment, programs, custAcctLoad, residualFuelMixByHour, accountId, start, end) => {
    const timerName = `getConsumptionAndGenerationData(${accountId}, ${start}, ${end})`
    timer.start(timerName);
    const isDateInRange = (date: Date) => (start === null || date > start) && (date < end || end === null)

    if (!results) {
      timer.stop(timerName);
      return [];
    }

    const programId = acctProgAssignment[accountId]?.programId;
    const program = programs.find(p => p.id === programId);
    const acctLoad = custAcctLoad.find(d => d.id === accountId);

    if (!acctLoad) {
      timer.stop(timerName);
      return null;
    }

    const finalResults: IConsumptionGenerationData[] = [];

    if (!program) {
      acctLoad.load.forEach(load => {
        const date = new Date(load.start_date)
        if (isDateInRange(date)) {
          // TODO: make this a helper/util function
          const hourStr = date.toISOString().replace('T', ' ').replace('Z', '+00:00').replace('.000', '');
          finalResults.push({
            generatedKwh: 0,
            consumedKwh: load.consumed_kwh,
            date,
            residualMix: residualFuelMixByHour[hourStr] || {},
            bySource: {},
          });
        }
      });

      return finalResults;
    };

    const programResults = results.allocationResults.find(d => d.programId === programId);

    if (!programResults) {
      timer.stop(timerName);
      return finalResults;
    }

    if (instanceOf247DedicatedResult(programResults)) {
      timer.start(`${timerName}.24/7Dedicated`);
      programResults.results
        .find(d => d.accountId === accountId)
        ?.hourlyResults.forEach(hourlyResult => {
          const date = new Date(hourlyResult.hour);
          if (isDateInRange(date)) {
            // TODO: make this a helper/util function
            const hourStr = date.toISOString().replace('T', ' ').replace('Z', '+00:00').replace('.000', '');
            finalResults.push({
              date,
              consumedKwh: hourlyResult.loadMWh * 1000,
              generatedKwh: hourlyResult.genMWh * 1000,
              residualMix: residualFuelMixByHour[hourStr] || {},
              bySource: hourlyResult.bySource,
            })
          }
        });
      timer.stop(`${timerName}.24/7Dedicated`);
    }

    if (instanceOf247PooledResult(programResults)) {
      timer.start(`${timerName}.24/7Pooled`);
      const loadKwhByHour: Record<number, number> = {};
      acctLoad.load.forEach(load => {
        loadKwhByHour[new Date(load.start_date).getTime()] = load.consumed_kwh;
      });
      programResults.hourlyResults.forEach(hourlyResult => {
        const date = new Date(hourlyResult.hour);
        const distributionFactor = (loadKwhByHour[date.getTime()] || 0) / (hourlyResult.loadMWh * 1000);
        const dedicatedGenMWh = hourlyResult.genMWh * distributionFactor;
        if (isDateInRange(date)) {
          // TODO: make this a helper/util function
          const hourStr = date.toISOString().replace('T', ' ').replace('Z', '+00:00').replace('.000', '');
          finalResults.push({
            date,
            consumedKwh: loadKwhByHour[date.getTime()] || 0,
            generatedKwh: dedicatedGenMWh * 1000,
            residualMix: residualFuelMixByHour[hourStr] || {},
            bySource: hourlyResult.bySource,
          })
        }
      });
      timer.stop(`${timerName}.24/7Pooled`);
    }

    if (instanceOfAnnualDedicatedResult(programResults)) {
      timer.start(`${timerName}.annualDedicated`);
      programResults.customerResults.forEach(custResult => {
        if (custResult.accountId === accountId) {
          custResult.hourlyResults.forEach(hourlyResult => {
            const date = new Date(hourlyResult.hour);
            if (isDateInRange(date)) {
              // TODO: make this a helper/util function
              const hourStr = date.toISOString().replace('T', ' ').replace('Z', '+00:00').replace('.000', '');
              finalResults.push({
                date,
                consumedKwh: hourlyResult.loadMWh * 1000,
                generatedKwh: hourlyResult.genMWh * 1000,
                residualMix: residualFuelMixByHour[hourStr] || {},
                bySource: hourlyResult.bySource,
              })
            }
          });
        }
      });
      timer.stop(`${timerName}.annualDedicated`);
    }

    if (instanceOfAnnualPooledResult(programResults)) {
      timer.start(`${timerName}.annualPooled`);
      const loadKwhByHour: Record<number, number> = {};
      acctLoad.load.forEach(load => {
        loadKwhByHour[new Date(load.start_date).getTime()] = load.consumed_kwh;
      });
      const acctLoadMWh = sum(acctLoad.load.map(prop('consumed_kwh'))) / 1000;
      const totalProgramLoadMWh = sum(programResults.hourlyResults.map(prop('loadMWh')));
      const distributionFactor = acctLoadMWh / totalProgramLoadMWh;
      programResults.hourlyResults.forEach(hourlyResult => {
        const date = new Date(hourlyResult.hour);
        const dedicatedGenMwh = hourlyResult.genMWh * distributionFactor;
        if (isDateInRange(date)) {
          // TODO: make this a helper/util function
          const hourStr = date.toISOString().replace('T', ' ').replace('Z', '+00:00').replace('.000', '');
          finalResults.push({
            date,
            consumedKwh: loadKwhByHour[date.getTime()] || 0,
            generatedKwh: dedicatedGenMwh * 1000,
            residualMix: residualFuelMixByHour[hourStr] || {},
            bySource: hourlyResult.bySource,
          })
        }
      });
      timer.stop(`${timerName}.annualPooled`);
    }

    timer.stop(timerName);
    return finalResults;
  },
  { memoizeOptions: { maxSize: 50 } }
);

const getAccountLoadMatched = createSelector(
  [getConsumptionAndGenerationData, getProgramForAccountId],
  (conGenForAccount, program) => {
    if (!program || program.id === StandardRatepayerProgram.id) {
      return null;
    }
    timer.start(`getAccountLoadMatched(${program.id})`);

    if (program.is247Program) {
      const totalCoverage = conGenForAccount.reduce((pctSoFar, conGen, _, arr) => {
        const genKWh = isNaN(conGen.generatedKwh) ? 0 : conGen.generatedKwh;
        const loadKWh = isNaN(conGen.consumedKwh) ? 0 : conGen.consumedKwh;
        const coveredPctInConGen = Math.min(1, genKWh / (loadKWh || 1));
        const pctCoveredRelativeToWhole = coveredPctInConGen / arr.length;
        return pctSoFar + pctCoveredRelativeToWhole;
      }, 0);
      timer.stop(`getAccountLoadMatched(${program.id})`);
      return totalCoverage;
    } else {
      const totalLoadKwh = sum(conGenForAccount.map(conGen => conGen.consumedKwh));
      const totalGenKwh = sum(conGenForAccount.map(conGen => conGen.generatedKwh));
      timer.stop(`getAccountLoadMatched(${program.id})`);
      return Math.min(1, totalGenKwh / (totalLoadKwh || 1));
    }
  }
);


const getConsumptionAndGenerationDataByAccountId = createSelector(
  [state => state, getCustomerAccountList],
  (state: RootState, acctList) => {
    timer.start('getConsumptionAndGenerationDataByAccountId()');
    const results: Record<number, IConsumptionGenerationData[]> = {};

    acctList.forEach(d => {
      results[d.id] = getConsumptionAndGenerationData(state, d.id, null, null);
    })

    timer.stop('getConsumptionAndGenerationDataByAccountId()');
    return results;
  }
);

export const getProgramCustomerLoadMatching = createSelector(
  [
    getProgram,
    getAccountsForProgram,
    getConsumptionAndGenerationDataByAccountId,
  ],
  (program, accounts, conGenDataByAcctId): { name: string, id: number, loadMatched: number, totalLoadMWh: number }[] => {
    if (!program) {
      return []
    }

    timer.start(`getProgramCustomerLoadMatching(${program.id})`);
    const customerAccountData = flatten([...accounts.map(acc => acc.id)].map(accountId => ({ accountId, data: conGenDataByAcctId[accountId] })));
    const results = customerAccountData.map(d => {
      const account = accounts.find(acct => acct.id === d.accountId);
      if (!account) {
        return undefined;
      }
      return {
        name: account.name,
        id: account.id,
        totalLoadMWh: Math.round(d.data.reduce((soFar, nextUp) => soFar + nextUp.consumedKwh, 0) / 1000),
        loadMatched: Math.min(100, getAccountLoadMatched(store.getState(), account.id, null, null) * 100),
      }
    }).filter(acc => !!acc);
    timer.stop(`getProgramCustomerLoadMatching(${program.id})`);
    return results;
  },
);

const EMPTY_TOPLINE_METRICS: IToplineMetrics = {
  cfe: {
    gridPct: 0,
    programPct: null,
  },
  totals: {
    consumptionMWh: 0,
    generationMWh: 0,
  },
  excess: {
    consumptionMWh: 0,
    generationMWh: 0,
  },
};

const getStandardRatepayerToplineMetrics = createSelector(
  [getResidualMixCarbonIntensity, getAccountsInResidualMix, residualMix],
  (residualMixCI, accts, mix): IToplineMetrics => {
    timer.start('getStandardRatepayerToplineMetrics()');
    const consumptionMWh = sum(flatten(accts.map(acct => acct.load.map(l => l.consumed_kwh)))) / 1000;
    const generationMWh = sum(mix.map(d => d.net_generation_mwh));
    timer.stop('getStandardRatepayerToplineMetrics()');
    return {
      cfe: {
        gridPct: residualMixCI.marketBasedCFEPct,
        programPct: null,
      },
      totals: {
        consumptionMWh,
        generationMWh,
      },
      excess: {
        consumptionMWh: 0,
        generationMWh: 0,
      }
    };
  },
);

export const getProgramToplineMetrics = createSelector(
  [
    (state, programId: string) => programId,
    getAllocationResults,
    getResidualMixCarbonIntensity,
    getStandardRatepayerToplineMetrics,
  ],
  (programId, allocation, residualMixCI, standardRatepayerToplineMetrics): IToplineMetrics => {
    const timerName = `getProgramToplineMetrics(${programId})`
    timer.start(timerName);
    if (!allocation) {
      timer.stop(timerName);
      return EMPTY_TOPLINE_METRICS;
    }

    const allocationResult = allocation.allocationResults.find(d => d.programId === programId);

    if (!allocationResult) {
      timer.stop(timerName);
      return standardRatepayerToplineMetrics;
    }

    if (instanceOfAnnualDedicatedResult(allocationResult)) {
      let loadMWh = 0;
      let genMWh = 0;
      let excessGenMWh = 0;
      let excessLoadMWh = 0;
      allocationResult.customerResults.forEach(d => {
        loadMWh += d.totalLoadMWh;
        genMWh += d.totalGenMWh;
        excessGenMWh += d.overageMWh;
        excessLoadMWh += d.shortFallMWh;
      });
      // TODO: use the selector that gets the program CI
      const pctProgramCFE = Math.min(1, genMWh / (loadMWh || 1)) * 100;
      const pctLoadFromGrid = Math.max((loadMWh - genMWh) / (loadMWh || 1), 0);
      const pctGridCFE = residualMixCI.marketBasedCFEPct * pctLoadFromGrid;
      timer.stop(timerName);
      return {
        cfe: { gridPct: pctGridCFE, programPct: pctProgramCFE },
        totals: { consumptionMWh: loadMWh, generationMWh: genMWh },
        excess: { consumptionMWh: excessLoadMWh, generationMWh: excessGenMWh },
      };
    } else if (instanceOfAnnualPooledResult(allocationResult)) {
      timer.stop(timerName);
      return {
        cfe: { gridPct: residualMixCI.marketBasedCFEPct * (allocationResult.shortFallMWh / (allocationResult.totalLoadMWh || 1)), programPct: Math.min(1, allocationResult.totalGenMWh / (allocationResult.totalLoadMWh || 1)) * 100 },
        totals: { consumptionMWh: allocationResult.totalLoadMWh, generationMWh: allocationResult.totalGenMWh },
        excess: { consumptionMWh: allocationResult.shortFallMWh, generationMWh: allocationResult.overageMWh },
      }
    } else if (instanceOf247PooledResult(allocationResult)) {
      let loadMWh = 0;
      let genMWh = 0;
      let excessGenMWh = 0;
      let excessLoadMWh = 0;
      let pctProgramCFE = 0;
      let consumptionFromGridMWh = 0;
      allocationResult.hourlyResults.forEach((hourlyResult, _, arr) => {
        loadMWh += hourlyResult.loadMWh;
        genMWh += hourlyResult.genMWh;
        excessGenMWh += hourlyResult.overageMWh;
        excessLoadMWh += hourlyResult.shortFallMWh;
        consumptionFromGridMWh += hourlyResult.shortFallMWh;
        const cfemid = (Math.min(1, hourlyResult.genMWh / (hourlyResult.loadMWh || 1)) / arr.length) * 100;
        pctProgramCFE += cfemid;
      });
      const pctGridCFE = residualMixCI.marketBasedCFEPct;
      const pctLoadFromGrid = Math.max(consumptionFromGridMWh / (loadMWh || 1), 0);
      timer.stop(timerName);
      return {
        cfe: { gridPct: pctGridCFE * pctLoadFromGrid, programPct: pctProgramCFE },
        totals: { consumptionMWh: loadMWh, generationMWh: genMWh },
        excess: { consumptionMWh: excessLoadMWh, generationMWh: excessGenMWh },
      };
    } else if (instanceOf247DedicatedResult(allocationResult)) {
      let loadMWh = 0;
      let genMWh = 0;
      let excessGenMWh = 0;
      let excessLoadMWh = 0;
      let pctProgramCFE = 0;
      let consumptionFromGridMWh = 0;
      allocationResult.results.forEach((result, _, arr) => {
        let pctCustomerCFE = 0;
        result.hourlyResults.forEach((hourlyResult, _, arr) => {
          loadMWh += hourlyResult.loadMWh;
          genMWh += hourlyResult.genMWh;
          excessGenMWh += hourlyResult.overageMWh;
          excessLoadMWh += hourlyResult.shortFallMWh;
          consumptionFromGridMWh += hourlyResult.shortFallMWh;
          pctCustomerCFE += (Math.min(1, hourlyResult.genMWh / (hourlyResult.loadMWh || 1)) / arr.length) * 100;
        });
        pctProgramCFE += pctCustomerCFE / arr.length;
      });
      const pctLoadFromGrid = Math.max(consumptionFromGridMWh / (loadMWh || 1), 0);
      const pctGridCFE = residualMixCI.marketBasedCFEPct;
      timer.stop(timerName);
      return {
        totals: { consumptionMWh: loadMWh, generationMWh: genMWh },
        cfe: { programPct: pctProgramCFE, gridPct: pctGridCFE * pctLoadFromGrid },
        excess: { consumptionMWh: excessLoadMWh, generationMWh: excessGenMWh },
      };
    } else {
      timer.stop(timerName);
      return EMPTY_TOPLINE_METRICS;
    }
  },
  {memoizeOptions: {maxSize: 10}},
);

const getAllToplineMetrics = (state: RootState) => {
  return getProgramsList(state).map(prog => getProgramToplineMetrics(state, prog.id))
}

const getProgramToplineMetricsForAccountId = (state: RootState, accountId: number) => {
  const programId = getProgramIdForAccountId(state, accountId);
  if (programId) {
    return getProgramToplineMetrics(state, programId);
  } else {
    return EMPTY_TOPLINE_METRICS;
  }
}

export const getAccountToplineMetrics = createSelector(
  [
    getProgramForAccountId,
    getConsumptionAndGenerationData,
    getResidualMixCarbonIntensity,
    getProgramToplineMetricsForAccountId,
  ],
  (program, consumptionAndGeneration, residualCI, programToplineMetrics): IToplineMetrics => {
    const timerName = `getAccountToplineMetrics(${program.id})`;
    timer.start(timerName);
    let loadMWh = 0;
    let genMWh = 0;
    let excessGenMWh = 0;
    let excessLoadMWh = 0;

    consumptionAndGeneration.forEach((conGen, _, arr) => {
      const thisLoadKwh = isNaN(conGen.consumedKwh) ? 0 : conGen.consumedKwh;
      const thisGenKwh = isNaN(conGen.generatedKwh) ? 0 : conGen.generatedKwh;
      loadMWh += thisLoadKwh / 1000;
      genMWh += thisGenKwh / 1000;
      excessGenMWh += Math.max(0, (thisGenKwh - thisLoadKwh) / 1000);
      excessLoadMWh += Math.max(0, (thisLoadKwh - thisGenKwh) / 1000);
    });

    if (!program.is247Program) {
      // the above logic was working exclusively for 24/7 programs
      // we need slightly different logic for annual programs
      const pctEnergyFromProgram = Math.min(genMWh / (loadMWh || 1), 1);
      excessGenMWh = Math.max(0, genMWh - loadMWh);
      excessLoadMWh = Math.max(0, loadMWh - genMWh);
    }

    timer.stop(timerName);
    return {
      totals: { consumptionMWh: loadMWh, generationMWh: genMWh },
      cfe: { gridPct: (residualCI.marketBasedCFEPct) * (excessLoadMWh / loadMWh), programPct: program.id === StandardRatepayerProgram.id ? null : programToplineMetrics.cfe.programPct },
      excess: { consumptionMWh: excessLoadMWh, generationMWh: excessGenMWh },
    }
  }
)

export const getTotalCertValues = createSelector(
  [getPrograms, getGeneratorProgramAssignment, fullInventoryGeneration, getAllocationResults],
  (programs, assignment, allGenerationData, allocationResults) => {
    const timerName = 'getTotalCertValues()';
    timer.start(timerName);
    let total = 0;
    let matched = 0;
    let excess = 0;
    const progById = indexBy(prop('id'), programs);

    allGenerationData.filter(d => d.plant_id_eia in assignment).forEach(gen => {
      const costPerMWh = progById[assignment[gen.plant_id_eia]?.programId]?.costPerMWh || 1;
      total += gen.net_generation_mwh * costPerMWh;
    });

    allocationResults?.allocationResults.forEach(result => {
      const costPerMWh = progById[result.programId]?.costPerMWh || 1;
      if (instanceOf247DedicatedResult(result)) {
        result.results.forEach(result => {
          result.hourlyResults.forEach(hourlyResult => {
            matched += (hourlyResult.loadMWh - hourlyResult.shortFallMWh) * costPerMWh;
            excess += hourlyResult.overageMWh * costPerMWh;
          })
        })
      } else if (instanceOf247PooledResult(result)) {
        result.hourlyResults.forEach(hourlyResult => {
          matched += (hourlyResult.loadMWh - hourlyResult.shortFallMWh) * costPerMWh;
          excess += hourlyResult.overageMWh * costPerMWh;
        })
      } else if (instanceOfAnnualDedicatedResult(result)) {
        result.customerResults.forEach(accResult => {
          matched += (accResult.totalLoadMWh - accResult.shortFallMWh) * costPerMWh;
          excess += accResult.overageMWh * costPerMWh;
        })
      }
      if (instanceOfAnnualPooledResult(result)) {
        excess += result.overageMWh * costPerMWh;
        matched += (result.totalLoadMWh - result.shortFallMWh) * costPerMWh;
      }
    });

    // subtract the "released" certificates from the excess
    allocationResults?.certReleaseResults.forEach(release => {
      const costFromProgram = progById[release.fromProgramId]?.costPerMWh || 1;
      Object.entries(release.certsClaimable.claimed).forEach(([toProgramId, mwhClaimed]) => {
        const costPerMWh = progById[toProgramId]?.costPerMWh || 1;
        matched += mwhClaimed * costPerMWh;
        excess -= mwhClaimed * costFromProgram;
        const oldCost = mwhClaimed * costFromProgram;
        const newCost = mwhClaimed * costPerMWh;
        // subtract the old cost from the total and add the new cost
        total -= oldCost
        total += newCost;
      });
    });

    timer.stop(timerName);
    return { total: Math.floor(total), matched: Math.ceil(matched), excess: Math.floor(excess) };
  }
)


export const getProgramCertValues = createSelector(
  [getProgram, getGeneratorProgramAssignment, fullInventoryGeneration, getAllocationResults],
  (program, assignment, allGenerationData, allocationResults) => {
    if (!program) {
      return { total: 0, matched: 0, excess: 0 };
    }

    const timerName = `getProgramCertValues(${program.name})`;
    timer.start(timerName);
    let matched = 0;
    let excess = 0;
    const costPerMWh = program?.costPerMWh || 1;

    const result = allocationResults?.allocationResults.find(result => result.programId === program.id)
    if (result) {
      if (instanceOf247DedicatedResult(result)) {
        result.results.forEach(result => {
          result.hourlyResults.forEach(hourlyResult => {
            matched += (hourlyResult.loadMWh - hourlyResult.shortFallMWh) * costPerMWh;
            excess += hourlyResult.overageMWh * costPerMWh;
          })
        })
      } else if (instanceOf247PooledResult(result)) {
        result.hourlyResults.forEach(hourlyResult => {
          matched += (hourlyResult.loadMWh - hourlyResult.shortFallMWh) * costPerMWh;
          excess += hourlyResult.overageMWh * costPerMWh;
        })
      } else if (instanceOfAnnualDedicatedResult(result)) {
        result.customerResults.forEach(accResult => {
          matched += (accResult.totalLoadMWh - accResult.shortFallMWh) * costPerMWh;
          excess += accResult.overageMWh * costPerMWh;
        })
      }
      if (instanceOfAnnualPooledResult(result)) {
        excess += result.overageMWh * costPerMWh;
        matched += (result.totalLoadMWh - result.shortFallMWh) * costPerMWh;
      }
    }

    timer.stop(timerName);
    return {
      total: Math.ceil(matched) + Math.floor(excess), matched: Math.ceil(matched), excess: Math.floor(excess)
    };
  },
  { memoizeOptions: { maxSize: 20 } },
)


export const getAccountCertValues = createSelector(
  [
    getProgramForAccountId,
    getConsumptionAndGenerationData,
    (state, accountId: number) => accountId,
  ],
  (program, consumptionAndGeneration, accountId): IUtilityToplineMetrics => {
    const timerName = `getAccountCertValues(${accountId})`;
    timer.start(timerName);
    const costPerMWh = program?.costPerMWh || 1;

    let loadMWh = 0;
    let genMWh = 0;
    let excessGenMWh = 0;
    let excessLoadMWh = 0;

    consumptionAndGeneration.forEach((conGen, _, arr) => {
      const thisGenMwh = isNaN(conGen.generatedKwh) ? 0 : conGen.generatedKwh;
      const thisLoadMWh = isNaN(conGen.consumedKwh) ? 0 : conGen.consumedKwh;
      loadMWh += thisLoadMWh / 1000;
      genMWh += thisGenMwh / 1000;
      excessGenMWh += Math.max(0, (thisGenMwh - thisLoadMWh) / 1000);
      excessLoadMWh += Math.max(0, (thisLoadMWh - thisGenMwh) / 1000);
      // this is the % of the CFE from the grid source
    });

    if (!program.is247Program) {
      // the above logic was working exclusively for 24/7 programs
      // we need slightly different logic for annual programs
      excessGenMWh = Math.max(0, genMWh - loadMWh);
      excessLoadMWh = Math.max(0, loadMWh - genMWh);
    }

    const total = Math.ceil((loadMWh - excessLoadMWh) * costPerMWh) + Math.floor(excessGenMWh * costPerMWh)

    timer.stop(timerName);
    return {
      certificateValue: { total, matched: Math.ceil((loadMWh - excessLoadMWh) * costPerMWh), excess: Math.floor(excessGenMWh * costPerMWh) },
    }
  },
  { memoizeOptions: { maxSize: 10 } },
)


export const getInventoryAllocationData = createSelector(
  [getAllocationResults, fullInventoryGeneration, getUnassignedGeneratorIds, getCustomerAccountsByIdMap, getProgramsByIdMap],
  (allocationResults, fullInventoryGeneration, unassignedPlantIds, acctsById, progsById) => {
    const results: Record<string, Record<number, number>> = {}

    // if allocation has not be run, just return the full inventory as the standard ratepayer
    const residualEpochToMWh: Record<number, number> = {};
    fullInventoryGeneration.forEach(inv => {
      if (unassignedPlantIds.has(inv.plant_id_eia)) {
        const epoch = new Date(inv.datetime_utc).valueOf();
        if (!residualEpochToMWh[epoch]) {
          residualEpochToMWh[epoch] = inv.net_generation_mwh;
        } else {
          residualEpochToMWh[epoch] += inv.net_generation_mwh;
        }
      }
    });
    results['Standard Ratepayer'] = residualEpochToMWh;

    if (allocationResults) {
      allocationResults.allocationResults.forEach(result => {
        if (instanceOf247DedicatedResult(result)) {
          result.results.forEach(result => {
            const acct = acctsById[result.accountId]
            if (acct) {
              const mwhByEpoch: Record<number, number> = {}
              result.hourlyResults.forEach((hour) => {
                const epoch = new Date(hour.hour).valueOf();
                if (!mwhByEpoch[epoch]) {
                  mwhByEpoch[epoch] = hour.genMWh;
                } else {
                  mwhByEpoch[epoch] += hour.genMWh;
                }
              });
              results[acct.name] = mwhByEpoch;
            }
          });
        }

        if (instanceOf247PooledResult(result)) {
          const mwhByEpoch: Record<number, number> = {}
          result.hourlyResults.forEach(hour => {
            const epoch = new Date(hour.hour).valueOf();
            if (!mwhByEpoch[epoch]) {
              mwhByEpoch[epoch] = hour.genMWh;
            } else {
              mwhByEpoch[epoch] += hour.genMWh;
            }
          });
          if (!isEmpty(mwhByEpoch)) {
            results[progsById[result.programId].name] = mwhByEpoch;
          }
        }

        if (instanceOfAnnualPooledResult(result)) {
          const mwhByEpoch: Record<number, number> = {}
          result.hourlyResults.forEach(hour => {
            const epoch = new Date(hour.hour).valueOf();
            if (!mwhByEpoch[epoch]) {
              mwhByEpoch[epoch] = hour.genMWh;
            } else {
              mwhByEpoch[epoch] += hour.genMWh;
            }
          });
          if (!isEmpty(mwhByEpoch)) {
            results[progsById[result.programId].name] = mwhByEpoch;
          }
        }

        if (instanceOfAnnualDedicatedResult(result)) {
          result.customerResults.forEach(result => {
            const acct = acctsById[result.accountId]
            if (acct) {
              const mwhByEpoch: Record<number, number> = {}
              result.hourlyResults.forEach((hour) => {
                const epoch = new Date(hour.hour).valueOf();
                if (!mwhByEpoch[epoch]) {
                  mwhByEpoch[epoch] = hour.genMWh;
                } else {
                  mwhByEpoch[epoch] += hour.genMWh;
                }
              });
              results[acct.name] = mwhByEpoch;
            }
          });
        }
      });
    }

    return results;
  }
)

export const getAllocationOverviewExcessMetrics = createSelector(
  [getAllToplineMetrics],
  (allToplineMetrics) => {
    return allToplineMetrics
      .map(prop('excess'))
      .reduce((soFar, nextUp) => ({
        consumptionMWh: soFar.consumptionMWh + nextUp.consumptionMWh,
        generationMWh: soFar.generationMWh + nextUp.generationMWh
      }), { consumptionMWh: 0, generationMWh: 0 });
  }
);

export const getProgramCertificateAllocation = createSelector(
  [getProgramsList, getAllocationResults],
  (programs, allocationResults) => {
    const programTotals: Record<string, number> = {}

    // loop over allocationResults for each program to get total program generation
    if (allocationResults?.allocationResults) {
      allocationResults.allocationResults.forEach(programAllocation => {
        let programTotal = 0;
        if (instanceOfAnnualDedicatedResult(programAllocation)) {
          programAllocation.customerResults.forEach(customerResult => {
            programTotal += customerResult.totalGenMWh;
          });
        } else if (instanceOfAnnualPooledResult(programAllocation)) {
          programTotal += programAllocation.totalGenMWh;
        } else if (instanceOf247DedicatedResult(programAllocation)) {
          programAllocation.results.forEach(customerMatch => {
            customerMatch.hourlyResults.forEach(result => programTotal += result.genMWh);
          })
        } else if (instanceOf247PooledResult(programAllocation)) {
          programAllocation.hourlyResults.forEach(result => programTotal += result.genMWh);
        }

        programTotals[programAllocation.programId] = programTotal;
      });
    }

    const programMappings: Record<string, Record<string, { releasedTo: number }>> = {};
    programs.forEach(program => programMappings[program.id] = {});

    // loop over the certs that were released and track the certs being released to each program
    if (allocationResults?.certReleaseResults) {
      allocationResults.certReleaseResults.forEach(release => {
        const releasingProgramResults = programMappings[release.fromProgramId];
        const claimedMap = release.certsClaimable?.claimed;
        Object.entries(claimedMap).forEach(([key, value]) => {
          if (releasingProgramResults[key]) {
            releasingProgramResults[key].releasedTo += value;
          } else {
            releasingProgramResults[key] = {
              releasedTo: value,
            }
          }
        });
      });
    }

    // calculate the percent flowing between programs. returns rows of program names and ids with percent values
    const rows: any[] = [];
    Object.entries(programMappings).forEach(([key, value]) => {
      const currentProgram = programs.find(p => p.id === key);
      const programTotal = programTotals[key];
      const row: Record<string, string | number> = {
        name: currentProgram.name,
        id: key,
      }
      let totalReleasedFromProgram = 0;
      Object.entries(value).forEach(([ikey, ivalue]) => {
        row[ikey] = ivalue.releasedTo / (programTotal + ivalue.releasedTo);
        totalReleasedFromProgram += ivalue.releasedTo;
      });
      // set the identity column
      let identityDenominator = programTotal + totalReleasedFromProgram;
      if (identityDenominator === 0 || isNaN(identityDenominator)) identityDenominator = 1;
      row[key] = programTotal / identityDenominator;
      rows.push(row);
    });

    return rows;
  }
);

export const getProgramGenSourceData = createSelector(
  [getProgram, getAllocationResults, getResidualFuelCategoryMixByHour],
  (program, allocationResults, residualMixByHour) => {

    if (!program || program.id === StandardRatepayerProgram.id) {
      return Object.entries(residualMixByHour).map(([dtstr, mix]) => {
        return {
          epoch: new Date(dtstr).valueOf(),
          genBySource: {
            program: 0,
            gridCFE: sum(Object.entries(mix).map(([fuel, mwh]) => gridCFEFuels.has(fuel) ? mwh : 0)),
            gridNonCFE: sum(Object.entries(mix).map(([fuel, mwh]) => gridCFEFuels.has(fuel) ? 0 : mwh)),
          }
        }
      })
    }

    if (!allocationResults) {
      return [];
    }

    const allocationResult = allocationResults.allocationResults.find(ar => ar.programId === program.id);

    if (!allocationResult) {
      return [];
    }

    const mwhMixByEpoch: Record<number, Record<'program' | 'gridCFE' | 'gridNonCFE', number>> = {};

    const allocateForHour = (hour: IHourlyAllocationResult) => {
      const date = new Date(hour.hour);
      const hourStr = date.toISOString().replace('T', ' ').replace('Z', '+00:00').replace('.000', '');
      const epoch = date.valueOf();

      const mwhFromGrid = Math.max(0, hour.loadMWh - hour.genMWh);

      const residualFuelMix = residualMixByHour[hourStr] || {};
      const mwhInResidualMix = sum(Object.values(residualFuelMix));
      const cfeMwhInResidualMix = sum(Object.entries(residualFuelMix).filter(([fuel]) => gridCFEFuels.has(fuel)).map(([_, mwh]) => mwh))
      const pctResidualMixCfe = cfeMwhInResidualMix / mwhInResidualMix;

      const cfeMwhFromGrid = pctResidualMixCfe * mwhFromGrid;
      const nonCfeMWhFromGrid = mwhFromGrid - cfeMwhFromGrid;

      if (!mwhMixByEpoch[epoch]) {
        mwhMixByEpoch[epoch] = { program: 0, gridCFE: 0, gridNonCFE: 0 };
      }

      mwhMixByEpoch[epoch].program += hour.genMWh;
      mwhMixByEpoch[epoch].gridCFE += cfeMwhFromGrid;
      mwhMixByEpoch[epoch].gridNonCFE += nonCfeMWhFromGrid;
    }

    if (instanceOf247DedicatedResult(allocationResult)) {
      allocationResult.results.forEach(accountResult => {
        accountResult.hourlyResults.forEach(allocateForHour);
      });
    }

    if (instanceOf247PooledResult(allocationResult) || instanceOfAnnualPooledResult(allocationResult)) {
      allocationResult.hourlyResults.forEach(allocateForHour);
    }

    if (instanceOfAnnualDedicatedResult(allocationResult)) {
      allocationResult.customerResults.forEach(accountResult => {
        accountResult.hourlyResults.forEach(allocateForHour);
      })
    }

    return Object.entries(mwhMixByEpoch).map(([epochStr, source]) => ({ epoch: parseInt(epochStr), genBySource: source }));
  },
);

interface HighchartsSankeyNode {
  id: string,
  name: string,
  color?: string,
  tooltipValue?: string | number,
  tooltipInfo?: string,
}

interface HighchartsSankeyPoint {
  from: string,
  to: string,
  weight: number,
  realWeight: number,
  ci?: number,
  color?: string,
  tooltipInfo?: string,
}

// the base highBound is the CI for petroleum, the highest CI we report
const getCIColorFromNumber = (value: number, highBound: number = 2500.0) => {
  const brightGreen = { r: 194, g: 245, b: 189 };
  const blueGrey = { r: 67, g: 74, b: 87 };

  const boundedCI = Math.min(highBound, value);
  const percent = boundedCI / highBound;
  const newR = brightGreen.r + Math.round((blueGrey.r - brightGreen.r) * percent);
  const newG = brightGreen.g + Math.round((blueGrey.g - brightGreen.g) * percent);
  const newB = brightGreen.b + Math.round((blueGrey.b - brightGreen.b) * percent);

  return `rgb(${newR},${newG},${newB})`;
}

export const getCertificateFlowData = createSelector(
  [
    getProgramsList,
    getCustomerAccountList,
    getGeneratorProgramAssignment,
    getCustomerLoadMatching,
    getGeneratorListWithSummedGen,
  ],
  (programs, customers, generatorProgramAssignments, customerLoadMatching, generatorsWithMwh) => {
    timer.start('getCertificateFlowData()');
    // this is not the right way to do this
    const state = store.getState();
    // Returns data for the Highcharts Sankey diagram.
    //  { nodes, points } Points are the connections between Nodes.

    // Build the Nodes
    const nodes: HighchartsSankeyNode[] = [];
    generatorsWithMwh.forEach(generator => {
      const generatorCI = generator.fuel_category ? dukeOGE2021FactorsLbsPerMWH[generator.fuel_category] : 1000;
      const titleCaseCategory = snakeToTitle(generator.fuel_category);
      const genName = `${titleCaseCategory} (ID: ${generator.id})`;
      nodes.push({
        id: generator.id,
        name: genName,
        color: getCIColorFromNumber(generatorCI),
        tooltipValue: Math.round(generator.totalGenerationMWh),
        tooltipInfo: `Fuel Source: <b>${titleCaseCategory}</b>,</br>` +
          `CI: <b>${Math.round(generatorCI)}</b> lbs CO2 per MWh`
      });
    });
    timer.start('getCertificateFlowData().programsLoop');
    [...programs, { id: 'Standard Ratepayer', name: 'Standard Ratepayer' }].forEach(program => {
      const programToplineMetrics = getProgramToplineMetrics(state, program.id);
      const unmatchedConsumptionPercent = 1 - Math.min(programToplineMetrics.totals.generationMWh / programToplineMetrics.totals.consumptionMWh, 1);
      const programCI = Math.round(getProgramCarbonIntensityForCustomerLoad(state, program.id));
      const numberOfEnrollments = customerLoadMatching.filter(cust => cust.programId === program.id).length;
      const cfePercentage = programToplineMetrics.cfe.programPct || programToplineMetrics.cfe.gridPct;
      nodes.push({
        id: program.id,
        name: program.name,
        color: getCIColorFromNumber(programCI),
        tooltipValue: Math.round(programToplineMetrics.totals.generationMWh),
        tooltipInfo: `Average CI: <b>${programCI}</b> lbs CO2 per MWh </br>` +
          `Number of enrollments: <b>${numberOfEnrollments}</b> </br>` +
          `Percentage CFE: <b>${Math.round(cfePercentage)}%</b> </br>` +
          `Unmatched Consumption Percent: <b>${Math.round(unmatchedConsumptionPercent * 100)}%`,
      });
    });
    timer.stop('getCertificateFlowData().programsLoop');

    const actUnmatchedConsumptionPercent: Record<number, number> = {};
    const rootCustomerGeneration: Record<string, number> = {};
    timer.start('getCertificateFlowData().customersLoop');
    customers.forEach(customer => {
      const actToplineMetrics = getAccountToplineMetrics(state, customer.id, null, null);
      const unmatchedConsumptionPercent = 1 - Math.min(actToplineMetrics.totals.generationMWh / actToplineMetrics.totals.consumptionMWh, 1);
      actUnmatchedConsumptionPercent[customer.id] = unmatchedConsumptionPercent;
      const actName = customer.rootCustomerId === customer.name ? 'Default Account' : `Account ${customer.id}`;
      const actCI = Math.round(getCustomerCarbonIntensity(state, customer.id, null, null));
      const numberOfCerts = Math.min(actToplineMetrics.totals.generationMWh, actToplineMetrics.totals.consumptionMWh);
      if (rootCustomerGeneration[customer.rootCustomerId]) {
        rootCustomerGeneration[customer.rootCustomerId] += numberOfCerts;
      } else {
        rootCustomerGeneration[customer.rootCustomerId] = numberOfCerts;
      }
      nodes.push({
        id: customer.id.toString(),
        name: actName,
        color: getCIColorFromNumber(actCI),
        tooltipValue: Math.round(numberOfCerts),
        tooltipInfo: `Average CI: <b>${actCI}</b> lbs CO2 per MWh</br>` +
          `Percentage CFE: <b>${Math.round(actToplineMetrics.cfe.programPct)}%</b></br>` +
          `Unmatched Consumption Percent: <b>${Math.round(unmatchedConsumptionPercent * 100)}%</b>`,
      });
    });
    timer.stop('getCertificateFlowData().customersLoop');

    timer.start('getCertificateFlowData().rootCustomerGenerationLoop');
    Object.entries(rootCustomerGeneration).forEach(([key, value]) => {
      const rootCustSummary = getCustomerSummaryResults(state, key, null, null);
      let unmatchedConsumptionPercent;
      let rootCustomerCI;
      if (rootCustSummary?.avgMatchedPercent) {
        unmatchedConsumptionPercent = 100 - rootCustSummary.avgMatchedPercent;
        rootCustomerCI = rootCustSummary.avgAccountCI;
      } else {
        const actId = customers.find(c => c.rootCustomerId === key).id;
        unmatchedConsumptionPercent = actUnmatchedConsumptionPercent[actId] * 100;
        rootCustomerCI = getCustomerCarbonIntensity(state, actId, null, null);
      }
      const numberOfAccounts = customers.filter(c => c.rootCustomerId === key).length;
      nodes.push({
        id: key,
        name: key,
        color: getCIColorFromNumber(rootCustomerCI),
        tooltipValue: Math.round(value),
        tooltipInfo: `CI: <b>${Math.round(rootCustomerCI)}</b> lbs CO2 per MWh</br>` +
          `Number of accounts: <b>${numberOfAccounts}</b></br>` +
          `Unmatched consumption: ${Math.round(unmatchedConsumptionPercent)}%`,
      });
    });
    timer.stop('getCertificateFlowData().rootCustomerGenerationLoop');

    // Build the Points. Points connect between Nodes (edges in a graph)
    const points: HighchartsSankeyPoint[] = [];
    const scalingFn = makeScalingFunction(
      { floor: Math.min(...generatorsWithMwh.map(g => g.totalGenerationMWh)), ceiling: Math.max(...generatorsWithMwh.map(g => g.totalGenerationMWh)) },
      { floor: 5000, ceiling: 50000 });

    // build the generator to program points
    timer.start('getCertificateFlowData().generatorsWithMwhLoop');
    const gensToPrograms: HighchartsSankeyPoint[] = [];
    generatorsWithMwh.forEach(gen => {
      const generatorCI = gen.fuel_category ? dukeOGE2021FactorsLbsPerMWH[gen.fuel_category] : 1000;
      gensToPrograms.push({
        from: gen.id,
        to: generatorProgramAssignments[gen.id]?.programId || 'Standard Ratepayer',
        weight: gen.totalGenerationMWh ? scalingFn(gen.totalGenerationMWh) : 0,
        realWeight: gen.totalGenerationMWh,
        ci: generatorCI,
        tooltipInfo: `CI: <b>${Math.round(generatorCI)}</b> lbs CO2 per MWh`,
      });
    });
    timer.stop('getCertificateFlowData().generatorsWithMwhLoop');
    timer.start('getCertificateFlowData().gensToPrograms.sort');
    gensToPrograms.sort((a, b) => a.ci - b.ci || a.to.localeCompare(b.to));
    timer.stop('getCertificateFlowData().gensToPrograms.sort');
    points.push(...gensToPrograms);

    // build the program to customer points and customer to account points
    const programToRootCustomerWeights: Record<string, number> = {};
    const customersToAccounts: HighchartsSankeyPoint[] = [];
    timer.start('getCertificateFlowData().customerLoadMatchingLoop');
    customerLoadMatching.forEach(customer => {
      const toplineMetrics = getAccountToplineMetrics(state, customer.id, null, null);
      let numberCerts = Math.min(toplineMetrics.totals.consumptionMWh, toplineMetrics.totals.generationMWh);
      if (customer.programId === 'Standard Ratepayer') {
        numberCerts = toplineMetrics.totals.consumptionMWh; // it's assumed load from a Standard RP is matched.
      }
      const cust = customers.find(c => c.id === customer.id);
      const edgeKey = `${customer.programId}.${cust.rootCustomerId}`;
      if (!programToRootCustomerWeights[edgeKey]) {
        programToRootCustomerWeights[edgeKey] = numberCerts;
      } else {
        programToRootCustomerWeights[edgeKey] += numberCerts;
      }

      const actCI = getCustomerCarbonIntensity(state, customer.id, null, null);
      customersToAccounts.push({
        from: cust.rootCustomerId,
        to: cust.id.toString(),
        weight: scalingFn(numberCerts),
        realWeight: numberCerts,
        color: getCIColorFromNumber(actCI),
        tooltipInfo: `Market-based Carbon Intensity: <b>${Math.round(actCI)}</b> lbs CO2 / MWh`,
      });
    });
    timer.stop('getCertificateFlowData().customerLoadMatchingLoop');
    points.push(...customersToAccounts);

    timer.start('getCertificateFlowData().programToRootCustomerWeightsLoop');
    const programsToCustomers: HighchartsSankeyPoint[] = [];
    Object.entries(programToRootCustomerWeights).forEach(([key, value]) => {
      const [programId, rootCustId] = key.split('.');
      const programCI = getProgramCarbonIntensityForCustomerLoad(state, programId);
      programsToCustomers.push({
        from: programId,
        to: rootCustId,
        weight: scalingFn(value),
        realWeight: value,
        color: getCIColorFromNumber(programCI),
        tooltipInfo: `Market-based Carbon Intensity: <b>${Math.round(programCI)}</b> lbs CO2 per MWh`,
      });
    });
    timer.stop('getCertificateFlowData().programToRootCustomerWeightsLoop');
    timer.start('getCertificateFlowData().programsToCustomers.sort');
    programsToCustomers.sort((a, b) => a.from.localeCompare(b.from));
    timer.stop('getCertificateFlowData().programsToCustomers.sort');
    points.push(...programsToCustomers);

    timer.stop('getCertificateFlowData()');
    return { nodes, points }
  }
);


export const getDailyGenDataGroupedByFuelSource = createSelector(
  [fullInventoryGeneration, generatorsById, (_, start: Date | null, end: Date | null, interval: 'year' | 'month' | 'day' | 'hour') => ({start, end, interval})],
  (allGeneration, gensById, {start, end, interval}) => {
    timer.start('getDailyGenDataGroupedByFuelSource()');
    const fuelToColor: Record<string, string> = {
      nuclear: '#3BBC95',
      solar: '#0F9F73',
      natural_gas: '#E6AB7A',
      coal: '#DC4D4E',
      hydro: '#10AE7E',
      biomass: '#B54040',
      petroleum: '#DF5D5E',
    }

    // map of:
    // epoch -> map of:
    // fuel type -> mwh generated
    const dayEpochToFuelGeneration: Record<number, Record<string, number>> = {};
    allGeneration.forEach(gen => {
      const generator = gensById[gen.plant_id_eia];
      if (!generator) {
        return;
      }
      const datetime = new Date(gen.datetime_utc);
      switch (interval) {
        case 'hour':
          datetime.setHours(datetime.getUTCHours(), 0, 0, 0);
          break;
        case 'day':
          datetime.setUTCHours(0, 0, 0, 0);
          break;
        case 'month':
          datetime.setUTCHours(0, 0, 0, 0);
          datetime.setUTCDate(1);
          datetime.setUTCMonth(datetime.getUTCMonth() + 1);
          break;
        case 'year':
          datetime.setUTCHours(0, 0, 0, 0);
          datetime.setUTCDate(1);
          datetime.setUTCMonth(1);
          break;
      }
      const epoch = datetime.valueOf();

      if (!dayEpochToFuelGeneration.hasOwnProperty(epoch)) {
        dayEpochToFuelGeneration[epoch] = {};
      }

      const fuel = generator.fuel_category;

      if (!dayEpochToFuelGeneration[epoch].hasOwnProperty(fuel)) {
        dayEpochToFuelGeneration[epoch][fuel] = 0;
      }

      dayEpochToFuelGeneration[epoch][fuel] += gen.net_generation_mwh;
    });

    // map of:
    // fuel -> [[x, y], [x, y], ...]
    const fuelCategoryToXYDataArrays: Record<string, number[][]> = {};

    const isDateInRange = (date: number) => (start === null || date > start.valueOf()) && (end === null || date < end.valueOf())

    Object.entries(dayEpochToFuelGeneration)
      .sort(([epoch1], [epoch2]) => parseInt(epoch1) - parseInt(epoch2))
      .forEach(([epochStr, fuelGenMap]) => {
        const epoch = parseInt(epochStr);
        Object.entries(fuelGenMap).forEach(([fuel, genMw]) => {
          if (!fuelCategoryToXYDataArrays.hasOwnProperty(fuel)) {
            fuelCategoryToXYDataArrays[fuel] = [];
          }

          if (isDateInRange(epoch)) {
            fuelCategoryToXYDataArrays[fuel].push([epoch, genMw]);
          }
        });
      });

    const value = Object.entries(fuelCategoryToXYDataArrays).map(([fuelCategory, data]) => ({
      data,
      name: snakeToTitle(fuelCategory),
      color: fuelToColor[fuelCategory],
    }));
    timer.stop('getDailyGenDataGroupedByFuelSource()');
    return value;
  }
);

export const getLoadMatchingForAccount = createSelector(
  [getCustomerAccount, getAccountLoadMatched],
  (acct, loadMatched) => ({
    name: acct.name,
    id: acct.id,
    programId: acct.programId,
    totalLoadMWh: sum(acct.load.map(prop('consumed_kwh'))) / 1000.0,
    loadMatched: Math.min(100, loadMatched * 100),
  }),
  { memoizeOptions: { maxSize: 10 } },
)

export const getCustomerCarbonIntensity = createSelector(
  [
    getResidualMixCarbonIntensity,
    getLoadMatchingForAccount,
    (_, acctId) => acctId,
    getProgramCarbonIntensityForAccount,
  ],
  (carbonIntensities, forCustomer, accountId, programCI) => {
    const timerName = `getCustomerCarbonIntensity(${accountId})`;
    timer.start(timerName);

    if (!forCustomer || forCustomer.programId === 'Standard Ratepayer' || !forCustomer.programId) {
      timer.stop(timerName);
      return carbonIntensities.marketBased;
    }

    const marketCI = carbonIntensities.marketBased;
    const loadMatchedPct = forCustomer.loadMatched / 100;
    const marketShare = 1 - loadMatchedPct;
    const programShare = 1 - marketShare;

    timer.stop(timerName);
    return marketShare * marketCI + programShare * programCI;
  },
  { memoizeOptions: { maxSize: 10 } },
)


export const getInventoryAllocationSeriesData = createSelector(
  [getInventoryAllocationData, getPrograms, getCustomerAccountList, (_, interval) => interval, (_, _i, startDate: Date | null) => startDate, (_, _i, _s, endDate: Date | null) => endDate],
  (rawData, programs, accounts, interval: 'year' | 'month' | 'day' | 'hour', after: Date | null, before: Date | null) => {
    timer.start('makeSeriesData()');
    const customerNames = accounts.map(acct => acct.name);
    const series: {
      name: string,
      color: string,
      type: string,
      data: {x: number, y: number}[],
    }[] = [];

    if (rawData.customer) {
      const customerGrouped = groupByInterval(rawData.customer, interval);
      const customerData = Object.entries(customerGrouped).filter(([dtstr, _]) => {
        const dt = new Date(dtstr);
        return dt.getTime() > (after?.getTime() || 0) && dt.getTime() <= (before?.getTime() || Infinity);
      }).map(([dtstr, rows]) => {
        return {
          x: new Date(dtstr).getTime(),
          y: sum(rows.map(([_x, y]) => y)),
        }
      });
      series.push({
        name: 'Assigned to customer',
        color: '#0F2128',
        type: 'column',
        data: customerData,
      });
    }

    customerNames.forEach((custName, idx) => {
      if (custName in rawData) {
        const custGrouped = groupByInterval(rawData[custName], interval);
        const custData = Object.entries(custGrouped).filter(([dtstr, _]) => {
          const dt = new Date(dtstr);
          return dt.getTime() > (after?.getTime() || 0) && dt.getTime() <= (before?.getTime() || Infinity);
        }).map(([dtstr, rows]) => {
          return {
            x: new Date(dtstr).getTime(),
            y: sum(rows.map(([_x, y]) => y)),
          }
        });

        series.push({
          name: custName,
          color: sample(Object.values(fuelPalette), idx),
          type: 'column',
          data: custData
        });
      }
    });

    programs.filter(p => p.generatorCertificateDistribution === 'pooled').forEach((program, idx) => {
      if (program.name in rawData) {
        const programGrouped = groupByInterval(rawData[program.name], interval);
        const programData = Object.entries(programGrouped).filter(([dtstr, _]) => {
          const dt = new Date(dtstr);
          return dt.getTime() > (after?.getTime() || 0) && dt.getTime() <= (before?.getTime() || Infinity);
        }).map(([dtstr, rows]) => {
          return {
            x: new Date(dtstr).getTime(),
            y: sum(rows.map(([_x, y]) => y)),
          }
        });

        series.push({
          name: program.name,
          color: sample(Object.values(fuelPalette), idx * customerNames.length + 2),
          type: 'column',
          data: programData
        });
      }
    });

    const residualGrouped = groupByInterval(rawData['Standard Ratepayer'], interval);
    const residualData = Object.entries(residualGrouped).filter(([dtstr, _]) => {
      const dt = new Date(dtstr);
      return dt.getTime() > (after?.getTime() || 0) && dt.getTime() <= (before?.getTime() || Infinity);
    }).map(([dtstr, rows]) => {
      return {
        x: new Date(dtstr).getTime(),
        y: sum(rows.map(([_x, y]) => y)),
      }
    });

    series.push({
      name: 'Standard Ratepayer',
      color: '#2f785d',
      type: 'column',
      data: residualData,
    });

    timer.stop('makeSeriesData()');
    return series;
  }
);