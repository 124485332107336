import { DEBUG } from "config/resources";

class Timer {
  start(timername: string) {
    if (DEBUG) {
      console.time(timername);
    }
  }

  stop(timername: string) {
    if (DEBUG) {
      console.timeEnd(timername);
    }
  }
}

export const timer = new Timer();