import { ICertificate } from 'constants/types';
import { uuid } from 'utils/id';
import { IGenerator } from './data/generators';
import { dukeOGE2021FactorsLbsPerMWH } from './chart_helpers';


interface ICertFactoryOptions {
  customerId?: string;
  ownedBy?: string;
  status?: string;
  hasAssetEvent?: boolean;
  energyCarrier?: string;
  productionSource?: string;
  productionTechnology?: string;
  gridInjection?: string;
  informsConsumerDisclosure?: boolean;
}


export const CertificateFactory = (options: ICertFactoryOptions) => {
  const deviceId = uuid();

  const makeCertificate = (customerId: string, generator: IGenerator, timeStamp: string, numberWithinHour: number, costPerMWh = 0) => {
    const end = new Date(timeStamp);
    const start = new Date(end.valueOf());
    end.setMinutes(0, 0, 0);
    start.setHours(end.getHours() - 1, 0, 0, 0);
    // TODO: use the correct region's emission factors
    const emissionFactor = dukeOGE2021FactorsLbsPerMWH[generator.fuel_category_eia930] || dukeOGE2021FactorsLbsPerMWH[generator.fuel_category];
    const cert: ICertificate = {
      id: window.btoa(`${numberWithinHour}.${timeStamp}.${generator.plant_id_eia}`),
      customerId: options.customerId || customerId || 'GTNN',
      ownedBy: options.ownedBy || null,
      status: options.status || 'active',
      assetEventId: options.hasAssetEvent ? uuid() : null,
      energyCarrier: options.energyCarrier || 'electricity',
      deviceOpStart: 'unspecified',
      start: start.toISOString(),
      end: end.toISOString(),
      issuedAt: timeStamp || new Date().toISOString(),
      issuedLocation: generator.location ? `lat: ${generator.location.lat}, lng: ${generator.location.lng}` : generator.state,
      productionSource: options.productionSource || generator.fuel_category || 'coal',
      productionTechnology: options.productionTechnology || `${generator.fuel_category} plant`,
      wattHours: 1000000,
      gridInjection: options.gridInjection || generator.ba_code,
      informsConsumerDisclosure: options.informsConsumerDisclosure || false,
      device: {
        id: deviceId,
        name: `${generator.fuel_category} plant in ${generator.state}`,
        capacity: 20000000,
        capacityUnits: 'btu',
        biddingZone: generator.ba_code || 'DUK',
        balancingAuthority: generator.ba_code || 'DUK',
      },
      emissions: {
        emissionFactor,
        emissionFactorMethodology: 'eGRID 2019 unadjusted',
        emissionFactorUnits: 'lbs_per_MWh',
      },
      value: {
        currency: 'USD',
        amount: costPerMWh,
      },
    }

    return cert;
  }

  return makeCertificate;
}
