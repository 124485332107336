import { api } from './base';
import { IRealtimeChartData, IRealtimeSummaryStats, IResourceMixData, IGridStatus } from './types';


interface IGetRealtimeChartDataResponse {
  data: IRealtimeChartData
}


interface IGetRealtimeChartDataV2Response {
  emissionFactors: {[fuelCategory: string]: { source: string, value: number }}
  hourlyConsumption: {
    startDate: string,
    data: {
      maxConsumedKw: number,
      minConsumedKw: number,
      meanConsumedKw: number,
      sumConsumedKwh: number
    }
  }[]
  forecastedHourlyConsumption: {
    startDate: string,
    data: {
      sumConsumedKwh: number
    }
  }[]
  forecastedHourlyGridStatus: IGridStatus[]
  hourlyGridStatus: IGridStatus[]
  totalConsumedKwh: number
  totalEmissionsLbs: number
}


interface IGetRealtimeChartDataV2Params {
  pretendTodayIs?: string
  timezone?: string
}


interface IGetRealtimeFuelMixResponse {
  data: IResourceMixData
}


interface IGetRealtimeSummaryResponse {
  data: IRealtimeSummaryStats
}


export const RealtimeApi = api.injectEndpoints({
  endpoints: (build) => ({
    // TODO(milo): Eventually break into separate Realtime usage and carbon intensity endpoints?
    getRealtimeChartData: build.query<IGetRealtimeChartDataResponse, void>({
      query: () => {
        return {
          url: '/v1/mock/real-time',
          method: 'GET'
        }
      },
      providesTags: [
        { type: 'RealtimeUsage' },
        { type: 'RealtimeCarbonIntensity' }
      ]
    }),
    getRealtimeFuelMix: build.query<IGetRealtimeFuelMixResponse, void>({
      query: () => {
        return {
          url: '/v1/mock/real-time/mix',
          method: 'GET'
        }
      },
      providesTags: [{ type: 'RealtimeFuelMix' }]
    }),
    getRealtimeSummary: build.query<IGetRealtimeSummaryResponse, void>({
      query: () => {
        return {
          url: '/v1/mock/real-time/summary',
          method: 'GET',
        }
      },
      providesTags: [{ type: 'RealtimeSummary' }]
    }),
    getRealtimeChartDataV2: build.query<IGetRealtimeChartDataV2Response, IGetRealtimeChartDataV2Params>({
      query: (params: IGetRealtimeChartDataV2Params) => {
        return {
          url: '/v2/dashboard/real-time',
          method: 'GET',
          params: params
        }
      },
      providesTags: [{ type: 'RealtimeSummary' }]
    })
  })
});


export const {
  useGetRealtimeChartDataQuery,
  useGetRealtimeFuelMixQuery,
  useGetRealtimeSummaryQuery,
  useGetRealtimeChartDataV2Query
} = RealtimeApi;
