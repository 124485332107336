import * as React from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LoadingButton } from '@mui/lab';
import { Snackbar } from '@mui/material';

import { useCreateAssetMutation, useFetchAssetsPageQuery, usePrefetch } from 'api/assets';
import { assetTypeToName, timeResolutionToName } from 'constants/app';

import './style.css';

interface IAssetRow {
  id: string,
  name: string | null,
  description: string | null,
  region: string,
  type: string,
}

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    type: 'string',
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    type: 'string',
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    sortable: false,
    type: 'string',
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 160,
    sortable: false,
    type: 'string',
  },
  {
    field: 'region',
    headerName: 'Region',
    width: 100,
    sortable: false,
    type: 'string',
  },
  {
    field: 'baseResolution',
    headerName: 'Base Resolution',
    width: 160,
    sortable: false,
    type: 'string'
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    width: 100,
    renderCell: (params: GridRenderCellParams<IAssetRow>) => {
      const prefetch = usePrefetch('fetchAsset');
      return <div className="asset-list-page-row-actions--container" onMouseEnter={() => prefetch({id: params.row.id})}>
        <Link to={`/assets/${params.row.id}`}>View</Link>
        <Link to={`/assets/${params.row.id}/edit`}>Edit</Link>
      </div>
    }
  }
]


const AssetTable = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const nav = useNavigate();

  const {data, isError, isFetching, isLoading} = useFetchAssetsPageQuery({page, per_page: rowsPerPage});
  const [create, createRes] = useCreateAssetMutation();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const loading = isLoading || isFetching;

  const rows = data?.data.map((asset) => ({
    id: asset.id,
    name: asset.name,
    description: asset.description,
    region: asset.location.region || 'unknown',
    type: assetTypeToName[asset.assetType] || 'unknown',
    baseResolution: asset.baseResolution ? timeResolutionToName[asset.baseResolution] : 'no resolution'
  } as IAssetRow)) || [];

  const createAsset = () => {
    create({payload: {name: '', description: '', region: 'ISONE', asset_type: 'electrical_meter', base_resolution: '15m'}})
      .then((res) => {
        if ('data' in res) {
          nav(`/assets/${res.data.data.id}/edit`)
        } else {
          setSnackbarOpen(true);
        }
      });
  }

  return (
    <div className='asset-list-page--container'>
      <h1>Your assets</h1>
      <div className='asset-list-page--description'>
        <p>The list of all assets under your management.</p>
        <LoadingButton variant='contained' startIcon={<AddCircleOutlineIcon />} onClick={createAsset} loading={createRes.isLoading}>New Asset</LoadingButton>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message="Failed to create asset"
      />
      <DataGrid
        loading={loading}
        rowCount={(data?.meta.pagination.total || 0)}
        pagination={true}
        rows={rows}
        error={isError || undefined}
        columns={columns}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={setRowsPerPage}
        onPageChange={(p) => setPage(p + 1)}
        paginationMode="server"
        autoHeight={true}
      />
    </div>
  )
};

export default AssetTable;